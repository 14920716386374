// src/App.js

import React from 'react';
import ReactApexChart from 'react-apexcharts';

class BarChartClub extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [44, 55, 13],
      options: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Logistics', 'Printing', 'Acquisition'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        dataLabels: {
          enabled: false, // Set this to false to hide data labels
        },
        legend: {
          position: 'bottom', // Set this to 'bottom' to show legend labels below the chart
        },
        fill: {
            colors: ['#4339F2', '#FFB200', '#FF3A29'], // Custom colors for each category
          },
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart options={this.state.options} series={this.state.series} type="pie" width={380} />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default BarChartClub;
