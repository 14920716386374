import React, { useState, useEffect } from 'react';

function AvatarCard({name, role}) {

    return (
<div className="flex-shrink-0 group block my-4">
  <div className="flex items-center">
  <div className="avatar placeholder">
  <div className="bg-secondary text-primary rounded-full w-12">
  <span className="text-lg uppercase">{name.charAt(0)}{name.charAt(1)}</span>
    
  </div>
</div>
    <div className="ms-3">
      <h3 className="font-semibold text-lg mb-0 text-gray-800">{name}</h3>
      <p className="text-sm font-medium mb-0 text-gray-400">{role}</p>
    </div>
  </div>
</div>

    )
}

export default AvatarCard;