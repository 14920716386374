const Achievements_Data = [
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Full attendance",
    Technology: "1",
    Leadership: "1",
    "Interpersonal Skills": "1",
    "Social Responsibility": "1",
    "Critical Thinking": "1",
    "Physical Fitness": "1",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Academic excellence",
    Technology: "1",
    Leadership: "1",
    "Interpersonal Skills": "1",
    "Social Responsibility": "",
    "Critical Thinking": "1",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Sports scholarship",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Merit scholarship",
    Technology: "",
    Leadership: "3",
    "Interpersonal Skills": "1",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Research grant",
    Technology: "",
    Leadership: "3",
    "Interpersonal Skills": "3",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Poem Recitation",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Spoken Word Contest",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Play/Musical",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Choir Performance",
    Technology: "",
    Leadership: "1",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Aerobics Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "3",
    "Social Responsibility": "",
    "Critical Thinking": "1",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Bake Sale",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "2",
    "Critical Thinking": "",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Artbeat",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Debates Competition",
    Technology: "",
    Leadership: "2",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "School/college MUN",
    Technology: "",
    Leadership: "2",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Math Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Trivia Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Science Competition",
    Technology: "2",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Science Fair",
    Technology: "2",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Dance Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Short Film Competition",
    Technology: "3",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Robotics Competition",
    Technology: "3",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Poster Making Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Gaming Competition",
    Technology: "3",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Fashion Design Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Cooking Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Modeling Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "1",
    "Social Responsibility": "",
    "Critical Thinking": "",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Talent Show",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "3",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Handwriting Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Essay Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Acting Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "3",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Drawing Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Photography Competition",
    Technology: "3",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Painting Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Composition Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Lucky Draw",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Spelling Competition",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Achievements",
    "Sub Categories": "Certificate of Achievement",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "1",
    "Physical Fitness": "",
  },
];

export default Achievements_Data;
