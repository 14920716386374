import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import TimelineStack from "./TimelineStack";
import { Calendar, globalizeLocalizer } from "react-big-calendar";
import globalize from "globalize";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { ca } from "date-fns/locale";
import randomColor from "randomcolor";
import AdminLayout from "./AdminLayout";
import EventList from "./EventList";

function SchoolDetails() {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const lanArabic = i18n.language === "ar";
  let params = useParams();
  let profileID = params.id;
  const { school_id } = useParams();
  const userID = user["user_id"];

  let isMyself = true;
  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  const [profile, setProfile] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [staff, setStaff] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`)
      .then((response) => {
        setProfile(response.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  }, []);

  const [profileData, setProfileData] = useState({
    profileID: profileID,
    name: "",
    lastname: "",
    lastname: "",
    bio: "",
    status: "",
    contact: "",
    city: "",
    grade: "",
  });
  const [campus, setCampus] = useState(null);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/campus_details/${school_id}/`)
      .then((response) => {
        setCampus(response.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  }, [school_id]);

  const [students, setStudents] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/students_school/${school_id}/`)
      .then((response) => {
        console.warn(`std `, response);
        setStudents(response.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  }, [school_id]);

  useEffect(() => {
    // Fetch data from the '/get_staffs/' endpoint
    fetch(
      `${process.env.REACT_APP_API_KEY}/api/get_campus_staffs/${school_id}/`
    )
      .then((response) => response.json())
      .then((data) => {
        let filteredStaff = [];
        if (user.role === "Network Admin") {
          filteredStaff = data.filter(
            (staff) => staff.network === profile.network_id
          );
        } else if (user.role === "School Admin") {
          filteredStaff = data.filter(
            (staff) => staff.school === profile.school_id
          );
        }

        setStaff(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [user, profile]);


  const [clubs, setClubs] = useState([]);



  const [network_profile, setnetwork_profile] = useState();
  let { logoutUser } = useContext(AuthContext);
  let url = "";
  if (user["role"] === "Student") {
    url = "profile";
  } else if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  } else if (user["role"] === "Staff") {
    url = "staff";
  }

  let role = user["role"];

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((response) => {
        const data = response.data;
        setnetwork_profile(data);
        //console.log("Profile Photo URL: ", data.profile_photo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID, url]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/clubs/`
        );
        
        
        let data = await response.json();
        data = data.filter(club => club?.network === network_profile?.network_id);
        // Filter clubs based on network ID
        // if (profile && profile.network_id) {
        //   data = data.filter(club => club.network === profile.network_id);
        // }
  
        setClubs(data);
        console.log("CLUBS ", data);
      } catch (err) {
        console.log("ERROR");
      }
    };
    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);
    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [user, network_profile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  console.warn(staff);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredStudents = students.filter((student) =>
    student.name.toLowerCase().includes(searchQuery.toLowerCase())
  );




  const localizer = globalizeLocalizer(globalize);

  // const myEventsList = [
  //     {
  //       id: 1,
  //       title: 'School Assembly',
  //       start: new Date(2024, 0, 10, 9, 0),
  //       end: new Date(2024, 0, 10, 10, 0),
  //     },
  //     {
  //       id: 2,
  //       title: 'Parent-Teacher Conference',
  //       start: new Date(2024, 0, 15, 14, 0),
  //       end: new Date(2024, 0, 15, 16, 0),
  //     },
  //     {
  //       id: 3,
  //       title: 'Science Fair',
  //       start: new Date(2024, 1, 5, 10, 0),
  //       end: new Date(2024, 1, 5, 15, 0),
  //     },
  //     {
  //       id: 4,
  //       title: 'Field Trip - Zoo',
  //       start: new Date(2024, 1, 20, 8, 30),
  //       end: new Date(2024, 1, 20, 15, 0),
  //     },
  //     {
  //       id: 5,
  //       title: 'Sports Day',
  //       start: new Date(2024, 2, 1, 13, 0),
  //       end: new Date(2024, 2, 1, 17, 0),
  //     },
  //   ];

  const { id } = useParams();
  const [list, setList] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_all_schedules/`)
      .then((res) => {
        setList(res.data);
        console.warn("SCHEDULE:  ", res.data);
      })
      .catch((err) => {
        console.error("ERROR SCHEDULE:  ", err);
        console.log(err);
      });
  }, []);


  function mergeAdjacentEvents(events) {
    // Sort events based on start_date
    events.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

    let mergedEvents = [];
    let currentEvent = null;

    for (let event of events) {
      if (!currentEvent) {
        currentEvent = { ...event };
      } else {
        const currentEndDate = new Date(currentEvent.end_date);
        const eventStartDate = new Date(event.start_date);

        if (currentEndDate >= eventStartDate) {
          // Merge events
          currentEvent.end_date = event.end_date;
        } else {
          // Push current merged event
          mergedEvents.push(currentEvent);
          // Start a new merged event
          currentEvent = { ...event };
        }
      }
    }

    // Push the last merged event
    if (currentEvent) {
      mergedEvents.push(currentEvent);
    }

    // Format date strings in ISO format
    mergedEvents.forEach((event) => {
      event.start_date = event.start_date.slice(0, 19);
      event.end_date = event.end_date.slice(0, 19);
    });

    return mergedEvents;
  }

  // Example usage
  const mergedEvents = list;
  console.warn(mergedEvents);

  const newData = mergedEvents?.map((item) => {
    const id = item?.id;
    const title = item?.name;
    const start = new Date(item?.start_date);
    const end = new Date(item?.end_date);
    const club = item.club;

    return { id, title, start, end, club };
  });

console.warn("SCHD: ",newData)

  const handleDeleteEvent = (id) => {
    // Update the events array after deleting a schedule
    setList(list.filter((event) => event.id !== id));
  };

  return (
    <AdminLayout>
      <div className="w-full px-6 py-6 mx-auto">
        <div class="relative w-full mx-auto  ">
          <div class="relative flex flex-col flex-auto min-w-0 p-4 mx-6 overflow-hidden break-words bg-white border-0  shadow-3xl rounded-2xl bg-clip-border">
            <div class="flex flex-col md:flex-row justify-between">
              <div class="flex flex-col md:flex-row justify-center gap-2 items-center md:justify-start py-4 px-12">
                {/* <div class="relative inline-flex items-center justify-center  text-white transition-all duration-200 ease-in-out text-base h-19 w-19 rounded-xl">
                        
                      </div> */}
                <img
                  src={campus?.avatar}
                  alt="profile_image"
                  class="h-12 w-auto"
                />
                <div className="ml-4">
                  <h5 class="h-max m-0">{campus?.name}</h5>
                  <p className="text-gray-400  m-0 text-sm">{campus?.email}</p>
                </div>
              </div>
              <div class="">
                <div>
                  <section className="rounded-lg text-gray-800">
                    <div className="container p-0 mx-auto grid justify-center grid-cols-2 lg:grid-cols-4 text-center">
                      <div className="flex flex-col justify-start m-2 lg:m-6">
                        <p className="text-sm sm:text-base"> Students</p>
                        <p className="text-2xl text-primary font-bold leadi ">
                          {campus?.student_count}
                        </p>
                      </div>

                      <div className="flex flex-col justify-start m-2 lg:m-6">
                        <p className="text-sm sm:text-base">Staff Members</p>
                        <p className="text-2xl text-primary font-bold leadi ">
                          {campus?.staff_count}
                        </p>
                      </div>

                      <div className="flex flex-col justify-start m-2 lg:m-6">
                        <p className="text-sm  sm:text-base">Clubs</p>
                        <p className="text-2xl text-primary font-bold leadi ">
                          {campus?.club_count}
                        </p>
                      </div>

                      <div className="flex flex-col justify-start m-2 lg:m-6">
                        <p className="text-sm sm:text-base">Budget</p>
                        <p className="text-2xl text-primary font-bold leadi ">
                          {campus?.budget_count}
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full p-6 mx-auto">
          <div class="flex flex-wrap -mx-3 ">
            <div class="w-full max-w-full px-3 shrink-0 md:w-7/12 md:flex-0">
              
              <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                <Tabs className="mt-4">
                  <TabList className="tab-list">
                    <Tab className="tab">Student Information</Tab>
                    <Tab className="tab">Faculty Information</Tab>
                  </TabList>

                  <TabPanel className="tab-panel">
                    <div class="flex flex-col">
                      <div class="-m-1.5 overflow-x-auto">
                        <div class="p-1.5 min-w-full inline-block align-middle">
                          <div className="flex justify-end">
                            <input
                              type="text"
                              placeholder="Search by name..."
                              value={searchQuery}
                              className="input input-bordered mt-2"
                              onChange={handleSearchChange}
                            />
                          </div>
                          <div class="overflow-hidden">
                            <table class="min-w-full divide-y divide-gray-200 darki:divide-gray-700">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                                  ></th>
                                  <th
                                    scope="col"
                                    class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                                  >
                                    Grade
                                  </th>

                                  <th
                                    scope="col"
                                    class="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="divide-y divide-gray-200 darki:divide-gray-700">
                                
                                {filteredStudents.map((student) => (
                                  <tr
                                    key={student.id} // Assuming student has a unique id
                                    className="hover:bg-gray-100 darki:hover:bg-gray-700 rounded-xl"
                                  >
                                    <td className="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-800 darki:text-gray-200">
                                      <img
                                        className="w-10 h-auto mx-auto img-fit rounded-full"
                                        src={student.avatar}
                                        alt={student.name}
                                      />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 darki:text-gray-200">
                                      {student.firstname} {student.lastname}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 darki:text-gray-200">
                                      {student.grade}
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                                      <Link to={`/student_nw/${student.user}`}>
                                        <button
                                          type="button"
                                          className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none darki:text-blue-500 darki:hover:text-blue-400 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                                        >
                                          Details
                                        </button>
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel className="tab-panel overflow-scroll">
                    <table className="table">
                      {/* head */}
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Role(s)</th>

                          {/* {(() => {
                            if (user["role"] === "Network Admin") {
                              return <th>Campus Assigned</th>;
                            }
                          })()} */}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {staff.map((st, index) => {
                          const randomBgColor = randomColor({
                            luminosity: "light",
                            format: "rgba",
                            alpha: 0.5,
                          });
                          return (
                            <tr key={index}>
                              <td>
                                <div className="avatar  placeholder">
                                  <div
                                    className="rounded-full w-12"
                                    style={{
                                      backgroundColor: randomBgColor,
                                    }}
                                  >
                                    <span className="text-xl">
                                      {st.name.charAt(0)}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="flex items-center gap-3">
                                  <div>
                                    <div className="font-bold">{st.firstname} {st.lastname}</div>
                                    <div className="text-sm opacity-50">
                                      {st.email}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className="badge-sm font-medium">
                                  {st.roles.map((role, index) => (
                                    <React.Fragment key={index}>
                                      <span className="badge bg-primary mr-1">
                                        {role
                                          .replace(/_/g, " ")
                                          .toLowerCase()
                                          .replace(/\b\w/g, (c) =>
                                            c.toUpperCase()
                                          )}
                                      </span>
                                    </React.Fragment>
                                  ))}
                                  <br />
                                </span>

                                <br />
                              </td>
                              {/* {(() => {
                                if (user["role"] === "Network Admin") {
                                  return <td>{st.campus}</td>;
                                }
                              })()} */}

                              <th>
                                <button className="btn btn-ghost btn-xs">
                                  details
                                </button>
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            <div class="w-full max-w-full px-3 mt-6 shrink-0 md:w-5/12 md:flex-0 md:mt-0">


            <div class="relative flex mb-6 flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                
                <div className="h-[20rem] overflow-scroll p-4">

<h3 className="text-lg">Events</h3>
               

<EventList
                      events={newData}
                      onDeleteEvent={handleDeleteEvent}
                    />


                </div>
                
              </div>

              <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                
                <div className="h-[20rem] overflow-scroll p-4">

<h3 className="text-lg">Student Clubs</h3>
                {clubs.map((club) => (
                  <Link
  className="group my-4 flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition darki:bg-slate-900 darki:border-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
  to={`/club_details/${club?.id}`}
>
  <div className="p-4 md:p-5">
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <img
          className="size-[38px] w-12 h-auto rounded-full"
          src={club?.logo}
          alt="Image Description"
        />
        <div className="ms-3">
          <h3 className="group-hover:text-blue-600 text-lg font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
            {club?.name} 
          </h3>
        </div>
      </div>
      <div className="ps-3">
        <svg
          className="flex-shrink-0 size-5"
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m9 18 6-6-6-6" />
        </svg>
      </div>
    </div>
  </div>
</Link>

            ))}

                


                </div>
                
              </div>



              


            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default SchoolDetails;
