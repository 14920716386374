import React, { useEffect, useState, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import AuthContext from '../context/AuthContext'

//import ProfileEditForm from "./components/ProfileEditForm";
//import UpdateProfile from "./UpdateProfile";
import { toast, Toaster } from 'react-hot-toast';
import { getRoles } from "@testing-library/react";
import { Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//competency data
import Achievements_Data from "./CompetencyData/AchievementsCompetency";
import Certifications_Data from "./CompetencyData/CertificationsCompetency";
import Clubs_Data from "./CompetencyData/ClubsCompetency";
//import Event_Management_Data from "./CompetencyData/EventManagementData";
import Languages_Data from "./CompetencyData/LanguagesCompetency";
import Leadership_Data from "./CompetencyData/LeadershipCompetency";
import Social_Work_Data from "./CompetencyData/SocialWorkCompetency";
import Special_Talents_Data from "./CompetencyData/SpecialTalentsCompetency";
import Sports_Data from "./CompetencyData/SportsCompetency";


import axios from "axios";
import SideInnerSM from "../components/SideInnerSM";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { useLanguage } from "../components/language";
/*import Barchart from "./components/Barchart";
import RadarChart from "./components/RadarChart";
import MobileBottom from "./components/MobileBottom";
*/

const SocietyStage = () => {
  let { user } = useContext(AuthContext);
  const { t, changeLanguage, lanArabic } = useLanguage();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

let userID = user["user_id"];
  // Parse the JSON string from the database

  // extract profile id
  let params = useParams();
  let profileID = user['user_id'];

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`);

        // parse the data in json
        let data = await response.json();

        setProfile(data);
      } catch (err) {
        console.log("The requested profile does not exists.");
      }
    };
    getProfile();
  }, [profileID]);


  const [societiesGet, setsocietiesGet] = useState();

  useEffect(() => {


      axios.get(`${process.env.REACT_APP_API_KEY}/api/get_society/`)
      .then(response => {
        setsocietiesGet(response.data);
      })
      .catch(error => {
        toast.error('Oh No! Something went wrong: '+error, {
          icon: '😓',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      });



  }, [profileID]);

  //console.log(profile);

  const [showProfile, setshowProfile] = useState(true);
  const [showForm, setshowForm] = useState(false);

  const handle_showProfile = (event) => {
    setshowProfile((current) => !current);
    setshowForm(false);
  };

  const handle_showForm = (event) => {
    setshowForm((current) => !current);
    setshowProfile(false);
  };

  let { logoutUser } = useContext(AuthContext);
  console.log(profile?.lead_activities);

  function getLeadActivities(profile) {
    const leadActivities = [];

    if (profile?.lead_activity && Array.isArray(profile.lead_activity)) {
      profile.lead_activity.forEach((activity) => {
        const leadActivity = {
          name: activity.name,
          grade: activity.grade,
          duration: activity.duration,
          description: activity.description,
          scope: activity.scope,
          service: activity.service,
          startdate: activity.startdate,
          enddate: activity.enddate,
        };
        leadActivities.push(leadActivity);
      });
    }

    return leadActivities;
  }

  function getSocietyActivities(profile) {
    const societyActivities = [];

    if (profile?.society_activity && Array.isArray(profile.society_activity)) {
      profile.society_activity.forEach((activity) => {
        const societyActivity = {
          name: activity.name,
          role: activity.role,
          grade: activity.grade,
          duration: activity.duration,
          service: activity.service,
          description: activity.description,
        };
        societyActivities.push(societyActivity);
      });
    }

    return societyActivities;
  }
  const navigate = useNavigate();
  function getSocialActivities(profile) {
    const socialActivities = [];

    if (profile?.social_activity && Array.isArray(profile.social_activity)) {
      profile.social_activity.forEach((activity) => {
        const socialActivity = {
          name: activity.name,
          role: activity.role,
          scope: activity.scope,
          grade: activity.grade,
          duration: activity.duration,
          service: activity.service,
          description: activity.description,
        };
        socialActivities.push(socialActivity);
      });
    }

    return socialActivities;
  }
  function getAwardsActivities(profile) {
    const awardsActivities = [];

    if (profile?.award_activity && Array.isArray(profile.award_activity)) {
      profile.award_activity.forEach((award) => {
        const awardsActivity = {
          name: award.name,
          role: award.rank,
          scope: award.scope,
          grade: award.grade,
          duration: award.duration,
          description: award.description,
        };
        awardsActivities.push(awardsActivity);
      });
    }

    return awardsActivities;
  }

  function getSportsActivities(profile) {
    const sportsActivities = [];

    if (profile?.sports_activity && Array.isArray(profile.sports_activity)) {
      profile.sports_activity.forEach((activity) => {
        const sportsActivity = {
          name: activity.name,
          role: activity.role,
          grade: activity.grade,
          scope: activity.scope,
          duration: activity.duration,
          description: activity.description,
        };
        sportsActivities.push(sportsActivity);
      });
    }

    return sportsActivities;
  }

  function getInternActivities(profile) {
    const internActivities = [];

    if (profile?.intern_activity && Array.isArray(profile.intern_activity)) {
      profile.intern_activity.forEach((activity) => {
        const internActivity = {
          name: activity.name,
          role: activity.role,
          grade: activity.grade,
          duration: activity.duration,
          description: activity.description,
        };
        internActivities.push(internActivity);
      });
    }

    return internActivities;
  }

  function getCertificationActivities(profile) {
    const certificationActivities = [];

    if (profile?.cert_activity && Array.isArray(profile.cert_activity)) {
      profile.cert_activity.forEach((certification) => {
        const certificationActivity = {
          name: certification.name,
          title: certification.type,
          award: certification.type,
          duration: certification.duration,
          description: certification.description,
        };
        certificationActivities.push(certificationActivity);
      });
    }

    return certificationActivities;
  }

  function getLanguageActivities(profile) {
    const languageActivities = [];

    if (profile?.lang_activity && Array.isArray(profile.lang_activity)) {
      profile.lang_activity.forEach((language) => {
        const languageActivity = {
          name: language.name,
          level: language.prof,
        };
        languageActivities.push(languageActivity);
      });
    }

    return languageActivities;
  }

  function getEventManagementActivities(profile) {
    const eventManagementActivities = [];

    if (profile?.event_activity && Array.isArray(profile.event_activity)) {
      profile.event_activity.forEach((activity) => {
        const eventManagementActivity = {
          name: activity.name,
          team: activity.grade,
          rank: activity.rank,
          scope: activity.scope,
        };
        eventManagementActivities.push(eventManagementActivity);
      });
    }

    return eventManagementActivities;
  }

  function getHobbiesActivities(profile) {
    const hobbiesActivities = [];

    if (
      profile?.hobbiesActivities &&
      Array.isArray(profile.hobbiesActivities)
    ) {
      profile.hobbiesActivities.forEach((activity) => {
        const hobbiesActivity = {
          name: activity.hobbies_name,
          description: activity.hobbies_description,
        };
        hobbiesActivities.push(hobbiesActivity);
      });
    }

    return hobbiesActivities;
  }

  function getOtherActivities(profile) {
    const otherActivities = [];

    if (profile?.otherActivities && Array.isArray(profile.otherActivities)) {
      profile.otherActivities.forEach((activity) => {
        const otherActivity = {
          name: activity.other_name,
          description: activity.other_description,
        };
        otherActivities.push(otherActivity);
      });
    }

    return otherActivities;
  }

  // const leadActivities = [
  //   {
  //     name: profile?.lead_activity[0].name,
  //     grade: profile?.lead_activity[0].grade,
  //     duration: profile?.lead_activity[0].duration,
  //     description: profile?.lead_activity[0].description,
  //     scope: profile?.lead_activity[0].scope,
  //     service: profile?.lead_activity[0].service,
  //   },
  // ];

  // const societyActivities = [
  //   {
  //     name: profile?.society_name,
  //     role: profile?.society_role,
  //     grade: profile?.society_grade,
  //     duration: profile?.society_duration,
  //     service: profile?.society_service,
  //     description: profile?.society_description,
  //   },
  // ];

  // const socialActivities = [
  //   {
  //     name: profile?.social_name,
  //     role: profile?.social_role,
  //     scope: profile?.social_scope,
  //     grade: profile?.social_grade,
  //     duration: profile?.social_duration,
  //     service: profile?.social_service,
  //     description: profile?.social_description,
  //   },]

  // const awardsActivities = [
  //   {
  //     name: profile?.award_name,
  //     role: profile?.award_rank,
  //     scope: profile?.award_scope,
  //     grade: profile?.award_grade,
  //     duration: profile?.award_duration,
  //     description: profile?.award_description,
  //   },
  // ];

  // const sportsActivities = [
  //   {
  //     name: profile?.sports_name,
  //     role: profile?.sports_role,
  //     grade: profile?.sports_grade,
  //     scope: profile?.sports_scope,
  //     duration: profile?.sports_duration,
  //     description: profile?.sports_description,
  //   },]

  // const internActivities = [
  //   {
  //     name: profile?.intern_name,
  //     role: profile?.intern_role,
  //     grade: profile?.intern_grade,
  //     duration: profile?.intern_duration,
  //     description: profile?.intern_description,
  //   },
  // ];

  // const certificationActivities = [
  //   {
  //     name: profile?.cert_type,
  //     title: profile?.cert_name,
  //     award: profile?.cert_association,
  //     duration: profile?.cert_duration,
  //     description: profile?.cert_description,
  //   },]
  // const languageActivities = [
  //   {
  //     name: profile?.language_name,
  //     level: profile?.language_prof,
  //   },
  // ];

  // const eventManagementActivities = [
  //   {
  //     name: profile?.event_name,
  //     team: profile?.event_team,
  //     rank: profile?.event_rank,
  //     scope: profile?.event_scope,
  //   },
  // ]

  // const hobbiesActivities = [
  //   {
  //     name: profile?.hobbies_name,
  //     description: profile?.hobbies_description,
  //   },]

  // const otherActivities = [
  //   {
  //     name: profile?.other_name,
  //     description: profile?.other_description,
  //   },
  // ]

  const leadActivities = getLeadActivities(profile);
  const societyActivities = getSocietyActivities(profile);
  const socialActivities = getSocialActivities(profile);
  const awardsActivities = getAwardsActivities(profile);
  const sportsActivities = getSportsActivities(profile);
  const internActivities = getInternActivities(profile);
  const certificationActivities = getCertificationActivities(profile);
  const languageActivities = getLanguageActivities(profile);
  const eventManagementActivities = getEventManagementActivities(profile);
  const hobbiesActivities = getHobbiesActivities(profile);
  const otherActivities = getOtherActivities(profile);
  // let index_map = {
  //   Technology: 0,
  //   Leadership: 1,
  //   "Interpersonal Skills": 2,
  //   "Social Responsibility": 3,
  //   "Critical Thinking": 4,
  //   "Physical Fitness": 5,
  // };
  //calculation functions
  //function that gets the competency data, takes input of the activity and the array of data
  function getCompetencyData(activity, data) {
    // console.log("inside get comp", activity, data);
    let competencyData = data.filter((item) => {
      return item["Sub Categories"] === activity;
    });

    let final_scores = [];

    //make array of the competency data
    console.log("competency data", competencyData);
    if (competencyData.length === 0) {
      return final_scores;
    }
    final_scores.push(competencyData[0]["Technology"]);
    final_scores.push(competencyData[0]["Leadership"]);
    final_scores.push(competencyData[0]["Interpersonal Skills"]);
    final_scores.push(competencyData[0]["Social Responsibility"]);
    final_scores.push(competencyData[0]["Critical Thinking"]);
    final_scores.push(competencyData[0]["Physical Fitness"]);

    //replace all blank values with 0 and convert to int
    for (var i = 0; i < final_scores.length; i++) {
      if (final_scores[i] === "") {
        final_scores[i] = 0;
      } else {
        final_scores[i] = parseInt(final_scores[i]);
      }
    }
    console.log("final scores", final_scores);
    return final_scores;
  }

  function sumArrays(arr) {
    var total = [0, 0, 0, 0, 0, 0];
    for (var i = 0; i < arr.length; i++) {
      for (var j = 0; j < arr[i].length; j++) {
        total[j] += arr[i][j];
      }
    }
    console.log("new total", total);
    return total;
  }

  function calculateSum(dataArray, propertyNames) {
    var total = 0;
    for (var i = 0; i < dataArray.length; i++) {
      var score = 1;
      if (typeof dataArray[i]["name"] === "number") {
        for (var j = 0; j < propertyNames.length; j++) {
          score *= parseFloat(dataArray[i][propertyNames[j]]);
        }
        total += score;
        console.log(dataArray, "scotr", score);
      }
    }
    return parseInt(total);
  }


  let lead_count = 0;
  let society_count=0;
  let social_count=0;
  let award_count=0;
  let sports_count =0;
  let intern_count = 0;
  let cert_count = 0;
  let talent_count = 0;
  let lang_count = 0;
  let event_count=0;



  let sum_lead = 0;

  for (let i = 0; i < profile?.lead_activity?.length; i++) {
    let obj = profile?.lead_activity[i];
    let lead_activity_val = parseFloat(obj.name) || 0;
    let lead_startdate_val = obj.startdate ? new Date(obj.startdate) : null;
    let lead_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
    let lead_duration_val = 0;
    let lead_scope_val = parseFloat(obj.scope) || 0;
    let lead_service_val = parseFloat(obj.service) || 0;
  
    if (lead_startdate_val && lead_enddate_val) {
      let diffInMonths = (lead_enddate_val.getFullYear() - lead_startdate_val.getFullYear()) * 12;
      diffInMonths += lead_enddate_val.getMonth() - lead_startdate_val.getMonth();
      lead_duration_val = diffInMonths/10;
    }
  
    sum_lead +=
      lead_activity_val * lead_duration_val * lead_scope_val * lead_service_val;

      console.log("Data: "+lead_activity_val+','+lead_duration_val+','+lead_scope_val+','+lead_service_val);

      lead_count++;
  }


let sum_society = 0;

for (let i = 0; i < profile?.society_activity?.length; i++) {
  let obj = profile?.society_activity[i];
  let society_name_val = parseFloat(obj.name) || 0;
  let society_startdate_val = obj.startdate ? new Date(obj.startdate) : null;
    let society_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
  let society_duration_val = 0;
  let society_service_val = parseFloat(obj.service) || 0;
  let society_role_val = parseFloat(obj.role) || 0;

  if (society_startdate_val && society_enddate_val) {
    let diffInMonths = (society_enddate_val.getFullYear() - society_startdate_val.getFullYear()) * 12;
    diffInMonths += society_enddate_val.getMonth() - society_startdate_val.getMonth();
    society_duration_val = diffInMonths/10;
  }

  sum_society +=
  society_name_val * society_duration_val * society_service_val * society_role_val;

  society_count++;
  }


let sum_social = 0;

for (let i = 0; i < profile?.social_activity?.length; i++) {
  let obj = profile?.social_activity[i];
  let social_name = parseFloat(obj.name) || 0;
  let social_startdate_val = obj.startdate ? new Date(obj.startdate) : null;
    let social_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
  let social_duration = 0;
  let social_scope = parseFloat(obj.scope) || 0;
  let social_service = parseFloat(obj.service) || 0;
  let social_role = parseFloat(obj.role) || 0;

  if (social_startdate_val && social_enddate_val) {
    let diffInMonths = (social_enddate_val.getFullYear() - social_startdate_val.getFullYear()) * 12;
    diffInMonths += social_enddate_val.getMonth() - social_startdate_val.getMonth();
    social_duration = diffInMonths/10;
  }

  sum_social +=
  social_name * social_duration * social_scope * social_service * social_role;
  social_count++;
  }


let sum_award = 0;

for (let i = 0; i < profile?.award_activity?.length; i++) {
  let obj = profile?.award_activity[i];
  let award_name = parseFloat(obj.name) || 0;
  let award_scope = parseFloat(obj.scope) || 0;
  let award_role = parseFloat(obj.rank) || 0;

  sum_award +=
  award_name * award_scope * award_role;
  award_count++;
  }



let sum_sports = 0;

for (let i = 0; i < profile?.sports_activity?.length; i++) {
  let obj = profile?.sports_activity[i];
  let sports_name = parseFloat(obj.name) || 0;
  let sports_scope = parseFloat(obj.scope) || 0;
  let sports_role = parseFloat(obj.role) || 0;
  let sports_startdate_val = obj.startdate ? new Date(obj.startdate) : null;
    let sports_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
  let sports_duration = 0;
  let sports_service = parseFloat(obj.service) || 0;

  if (sports_startdate_val && sports_enddate_val) {
    let diffInMonths = (sports_enddate_val.getFullYear() - sports_startdate_val.getFullYear()) * 12;
    diffInMonths += sports_enddate_val.getMonth() - sports_startdate_val.getMonth();
    sports_duration = diffInMonths/10;
  }

  sum_sports +=
  sports_name * sports_scope * sports_role * sports_duration * sports_service;
  sports_count++;
  }


let sum_intern = 0;

for (let i = 0; i < profile?.intern_activity?.length; i++) {
  let obj = profile?.intern_activity[i];
  let intern_name = parseFloat(obj.name) || 0;
  let intern_startdate_val = obj.startdate ? new Date(obj.startdate) : null;
    let intern_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
  let intern_duration = 0;
  let intern_role = parseFloat(obj.role) || 0;
  let intern_scope = parseFloat(obj.scope) || 0;


  if (intern_startdate_val && intern_enddate_val) {
    let diffInMonths = (intern_enddate_val.getFullYear() - intern_startdate_val.getFullYear()) * 12;
    diffInMonths += intern_enddate_val.getMonth() - intern_startdate_val.getMonth();
    intern_duration = diffInMonths/10;
  }

  sum_intern +=
  intern_name * intern_duration * intern_role * intern_scope;

  intern_count++;
  }


let sum_cert = 0;

for (let i = 0; i < profile?.cert_activity?.length; i++) {
  let obj = profile?.cert_activity[i];
  let cert_type = parseFloat(obj.type) || 0;
  sum_cert +=
  cert_type;

  cert_count++;
  }


let sum_talent = 0;

for (let i = 0; i < profile?.talent_activity?.length; i++) {
  let obj = profile?.talent_activity[i];
  let talent_name = parseFloat(obj.name) || 0;
  let talent_service = parseFloat(obj.service) || 0;
  sum_talent +=
  talent_name * talent_service;

  talent_count++;
  }


let sum_lang = 0;

for (let i = 0; i < profile?.lang_activity?.length; i++) {
  let obj = profile?.lang_activity[i];
  let lang_prof = parseFloat(obj.prof) || 0;
  sum_lang +=
  7*lang_prof;

  lang_count++;
  }





let round_lead = Math.round(sum_lead);



let round_society = Math.round(sum_society);



let round_social = Math.round(sum_social);





let round_award = Math.round(sum_award);


let round_sports = Math.round(sum_sports);




let round_intern = Math.round(sum_intern);




let round_cert = Math.round(sum_cert);




let round_talent = Math.round(sum_talent);



let round_lang = Math.round(sum_lang);


const round_lead_result = Math.floor(sum_lead)
const round_society_result = Math.floor(sum_society)
const round_social_result = Math.floor(sum_social)
const round_award_result = Math.floor(sum_award)
const round_sports_result = Math.floor(sum_sports)
const round_intern_result = Math.floor(sum_intern)
const round_cert_result = Math.floor(sum_cert)

const round_lead_duration = profile?.lead_duration*10
const round_lead_duration2 = profile?.lead_duration2*10
const round_lead_duration3 = profile?.lead_duration3*10

const round_society_duration = profile?.society_duration*10
const round_society_duration2 = profile?.society_duration2*10
const round_society_duration3 = profile?.society_duration3*10

const round_social_duration = profile?.social_duration*10
const round_social_duration2 = profile?.social_duration2*10
const round_social_duration3 = profile?.social_duration3*10

const totalScore = (round_lead + round_society_result + round_social_result + round_award_result + round_sports_result + round_cert_result + round_intern_result )*15

  function sumAll(dataArray) {
    console.log("inside sum all", dataArray);
    var total = 0;
    for (var i = 0; i < dataArray.length; i++) {
      total += dataArray[i];
    }
    return total;
  }



  function calculateActivityCompetency(
    activityArray,
    labelFunction,
    competencyData
  ) {
    // console.log(
    //   "inside",
    //   "activity array: ",
    //   activityArray,
    //   labelFunction,
    //   competencyData
    // );
    var activities = [];
    var activityLabels = [];
    var competencyValues = [];

    for (var i = 0; i < activityArray.length; i++) {
      console.log("name", activityArray[i].name, typeof activityArray[i].name);
      console.log("startdate", activityArray[i].startdate, typeof activityArray[i].startdate);
      console.log("enddate", activityArray[i].enddate, typeof activityArray[i].enddate);
      if (typeof activityArray[i].name === "string") {
        activities.push(activityArray[i].name);
        var label = labelFunction(activityArray[i].name);
        console.log("label", label);
        if (label !== "-") {
          activityLabels.push(label);
          //console.log(label);
          competencyValues.push(getCompetencyData(label, competencyData));
          console.log("competency", competencyValues);
        }
      }
    }
    console.log("activity labels", activityLabels);
    console.log("Sum arrays", sumArrays(competencyValues));
    return sumArrays(competencyValues);
  }
  //MOIZ
  let global_comp = [0, 0, 0, 0, 0, 0];
  function calculateActivityCompetency2(
    activityArray,
    labelFunction,
    competencyData
  ) {
    activityArray.forEach((activity) => {
      console.log("activity", activity.name);
      if (typeof activity.name === "string" && activity.name != "-") {
        let label = labelFunction(parseInt(activity.name));
        let objectArr = competencyData.filter((item) => {
          return item["Sub Categories"] === label;
        });
        objectArr.forEach((object) => {
          global_comp[0] +=
            object["Technology"] === ""
              ? 0
              : parseInt(object["Technology"]) * 15;
          global_comp[1] +=
            object["Leadership"] === ""
              ? 0
              : parseInt(object["Leadership"]) * 15;
          global_comp[2] +=
            object["Interpersonal Skills"] === ""
              ? 0
              : parseInt(object["Interpersonal Skills"]) * 15;
          global_comp[3] +=
            object["Social Responsibility"] === ""
              ? 0
              : parseInt(object["Social Responsibility"]) * 15;
          global_comp[4] +=
            object["Critical Thinking"] === ""
              ? 0
              : parseInt(object["Critical Thinking"]) * 15;
          global_comp[5] +=
            object["Physical Fitness"] === ""
              ? 0
              : parseInt(object["Physical Fitness"]) * 15;
        });
      }
    });
  }

  calculateActivityCompetency2(
    leadActivities,
    getLeadershipActivityLabel,
    Leadership_Data
  );
  calculateActivityCompetency2(
    societyActivities,
    getSocietyNameLabel,
    Clubs_Data
  );
  calculateActivityCompetency2(
    socialActivities,
    getSocialActivityLabel,
    Social_Work_Data
  );

  calculateActivityCompetency2(
    awardsActivities,
    getAwardsLabel,
    Achievements_Data
  );
  calculateActivityCompetency2(
    languageActivities,
    getLanguageLabel,
    Languages_Data
  );
  calculateActivityCompetency2(
    certificationActivities,
    getCertificationLabel,
    Certifications_Data
  );

  console.log("global", global_comp);

  function level(score) {
    console.log("in score", score);
    if (score >= 1100) {
      return "Level 6";
    } else if (score >= 900) {
      return "Level 5";
    } else if (score >= 700) {
      return "Level 4";
    } else if (score >= 400) {
      return "Level 3";
    } else if (score >= 200) {
      return "Level 2";
    } else return "Level 1";
  }

  //COMPETENCY AND SKILL POINTS CALCULATION
  //calcultating competency points from each type of activity
  var competency_totals_catergory = [];
  competency_totals_catergory.push(
    calculateActivityCompetency(
      leadActivities,
      getLeadershipActivityLabel,
      Leadership_Data
    )
  );
  competency_totals_catergory.push(
    calculateActivityCompetency(
      societyActivities,
      getSocietyNameLabel,
      Clubs_Data
    )
  );
  competency_totals_catergory.push(
    calculateActivityCompetency(
      socialActivities,
      getSocialActivityLabel,
      Social_Work_Data
    )
  );
  competency_totals_catergory.push(
    calculateActivityCompetency(
      awardsActivities,
      getAwardsLabel,
      Achievements_Data
    )
  );

  competency_totals_catergory.push(
    calculateActivityCompetency(sportsActivities, getSportsLabel, Sports_Data)
  );
  //competency_totals.push(calculateActivityCompetency(internActivities, getInternshipTypeLabel, Internships_Data));
  competency_totals_catergory.push(
    calculateActivityCompetency(
      certificationActivities,
      getCertificationLabel,
      Certifications_Data
    )
  );
  competency_totals_catergory.push(
    calculateActivityCompetency(
      languageActivities,
      getLanguageLabel,
      Languages_Data
    )
  );

  console.log("totals categry", competency_totals_catergory);
  //combining all the competency totals
  var competency_totals = sumArrays(competency_totals_catergory);
  console.log("competency totals", competency_totals);
  //applying level to each competency
  competency_totals.forEach(function (element, index, array) {
    array[index] = level(element);
  });
  console.log("new compsss", competency_totals);

  //creating variables for each competency to display in the html
  var total_tech_Competencies1 = competency_totals[0];
  var total_leadership_Competencies1 = competency_totals[1];
  var total_ips_Competencies1 = competency_totals[2];
  var total_ct_Competencies1 = competency_totals[3];
  var total_social_Competencies1 = competency_totals[4];
  var total_physical_Competencies1 = competency_totals[5];

  //calculate the sum of all the activities
  var leadership_sum = calculateSum(leadActivities, [
    "name",
    "scope",
    "duration",
    "service",
  ]);
  var society_sum = calculateSum(societyActivities, [
    "name",
    "role",
    "duration",
    "service",
  ]);
  var socials_sum = calculateSum(socialActivities, [
    "name",
    "scope",
    "duration",
    "service",
  ]);
  var awards_sum = calculateSum(awardsActivities, ["name", "scope", "role"]);
  var sports_sum = calculateSum(sportsActivities, [
    "name",
    "role",
    "duration",
    "scope",
  ]);
  var intern_sum = calculateSum(internActivities, ["name", "role", "duration"]);
  var cert_sum = calculateSum(certificationActivities, ["name", "duration"]); //gives a NaN because of the duration
  var lang_sum = calculateSum(languageActivities, ["name", "level"]);
  var event_sum = calculateSum(eventManagementActivities, [
    "name",
    "rank",
    "scope",
  ]);
  var hobbies_sum = calculateSum(hobbiesActivities, ["name"]);
  var other_sum = calculateSum(otherActivities, ["name"]);
  //calculate the sum of all the skills
  var total_activity_points =
    sumAll([
      leadership_sum,
      society_sum,
      socials_sum,
      awards_sum,
      sports_sum,
      intern_sum,
      lang_sum,
      event_sum,
      hobbies_sum,
      other_sum,
    ]) * 15;

  //Labels
  function getPositionLabel(position) {
    const positionMapping = {
      1: "Participation",
      1.1: "3rd",
      1.2: "2nd",
      1.5: "1st",
    };

    return positionMapping[position] || "-";
  }

  function getScopeLabel(scope) {
    const scopeMapping = {
      1: "School",
      1.2: "Local",
      1.5: "International",
    };

    return scopeMapping[scope] || "-";
  }

  function getDurationLabel(duration) {
    const durationMapping = {
      0.1: "1 month",
      0.3: "3 months",
      0.6: "6 months",
      0.9: "9 months",
      1.2: "12 months",
    };

    return durationMapping[duration] || "-";
  }

  function getSocialRoleLabel(role) {
    const roleMapping = {
      1: "Volunteer",
      1.2: "Organized",
      1.5: "Donated",
    };

    return roleMapping[role] || "-";
  }

  function getLeadershipScopeLabel(scope) {
    const scopeMapping = {
      1: "Class",
      1.2: "Section",
      1.51: "House",
      1.52: "School",
    };

    return scopeMapping[scope] || "-";
  }

  function getSocietyRoleLabel(role) {
    const roleMapping = {
      1: "President",
      1.2: "Vice President",
      1.5: "Member",
    };

    return roleMapping[role] || "-";
  }

  function getEngagementLabel(service) {
    const serviceMapping = {
      1: "Light",
      1.2: "Moderate",
      1.4: "Heavy",
      1.5: "Extra",
    };

    return serviceMapping[service] || "-";
  }

  function getLeadershipActivityLabel(name) {
    const labelMap = {
      9: "Student Council General Secretary",
      8.01: "Deputy Head Student",
      8.02: "House Captain",
      8.03: "Class Prefect",
      8.04: "Captain Sports Team",
      7: "Class Monitor",
      10: "Head Student",
    };

    return labelMap[name] || "-";
  }

  function getSocietyNameLabel(name) {
    const labelMap = {
      8: "STEM/STEAM Society",
      8.02: "Art Society",
      8.01: "Music Society",
      8.03: "Debates Society",
      8.04: "Drama/Theater Society",
      8.05: "Math Society",
      8.06: "Literature Society",
      8.07: "Environment Society",
      8.08: "Community Service Society",
      8.09: "Media & Publications Society",
      7: "Astronomy Society",
      8.1: "Sports Society",
      7.02: "Choir Society",
      7.03: "Cooking Society",
      7.04: "Yoga Club",
      7.05: "Book Club",
      7.06: "School Media Club",
      7.07: "Cardio Club",
      7.01: "Film Society",
      8.2: "School Magazine",
    };
    return labelMap[name] || "-";
  }

  function getSocialActivityLabel(name) {
    const labelMap = {
      8: "Clothes drive",
      8.01: "Charity collection",
      9: "Blood donation drive",
      9.01: "Hospital volunteer",
      8.02: "Trash clean-up",
      8.03: "Charity bake sale",
      8.04: "Food drive",
    };
    return labelMap[name] || "-";
  }

  function getAwardsLabel(value) {
    const awardsMapping = {
      10: "Full attendance",
      10.01: "Academic excellence",
      10.02: "Sports scholarship",
      10.03: "Merit scholarship",
      10.04: "Research grant",
      7: "Poem Recitation",
      7.01: "Spoken Word Contest",
      8: "Play/Musical",
      7.02: "Choir Performance",
      7.03: "Aerobics Competition",
      7.04: "Bake Sale",
      7.05: "Artbeat",
      8.01: "Debates Competition",
      8.02: "School/college MUN",
      8.03: "Math Competition",
      7.06: "Trivia Competition",
      8.04: "Science Competition",
      8.05: "Science Fair",
      7.07: "Dance Competition",
      8.04: "Short Film Competition",
      8.05: "Robotics Competition",
      7.08: "Poster Making Competition",
      7.09: "Gaming Competition",
      "7.10": "Fashion Design Competition",
      7.11: "Cooking Competition",
      7.12: "Modeling Competition",
      7.13: "Talent Show",
      8.06: "Handwriting Competition",
      8.07: "Essay Competition",
      7.14: "Acting Competition",
      8.08: "Drawing Competition",
      8.09: "Photography Competition",
      "8.10": "Painting Competition",
      8.11: "Composition Competition",
      6: "Lucky Draw",
      7.15: "Spelling Competition",
      10.05: "Certificate of Achievement",
    };

    return awardsMapping[value] || "-";
  }

  let currentLevel = 0;
  if(totalScore>=0 && totalScore<=199){
    currentLevel = 1
  }

  else if(totalScore>=200 && totalScore<=399){
     currentLevel = 2
  }

  else if(totalScore>=400 && totalScore<=699){
     currentLevel = 3
  }

  else if(totalScore>700 && totalScore<=1100){
     currentLevel = 4
  }

  else if(totalScore>1100){
     currentLevel = 5
  }

  function getSportsLabel(value) {
    const sportsMapping = {
      7: "Basketball",
      7.01: "Cricket",
      7.02: "Hockey",
      7.03: "Badminton",
      7.04: "Tennis",
      7.05: "Throwball",
      7.06: "Gymnastics",
      7.07: "Futsal",
      7.08: "Archery",
      7.09: "Cycling",
      "7.10": "Sprint",
      7.2: "Football",
      7.3: "Relay race",
      7.4: "Baseball",
      7.5: "Fencing",
      7.6: "Golf",
      7.7: "Handball",
      7.8: "Netball",
      7.9: "Rowing",
      "7.10": "Skateboarding",
      7.11: "Road bicycle racing",
      Ballet: "Ballet",
      7.12: "Kabaddi",
      7.13: "Kickboxing",
      7.14: "Boxing",
      7.15: "Strongman",
      7.16: "Lacrosse",
      7.17: "Polo",
      7.18: "Water Polo",
      7.19: "Long Jump",
      "7.20": "High Jump",
      7.21: "Shot put",
      7.22: "Pole Vault",
      7.23: "Pickleball",
      8: "Chess",
      7.24: "Track & field",
      7.25: "Curling",
      7.26: "Endurance running",
      7.27: "Hurdle races",
      7.28: "Ice skating",
      7.29: "Surfing",
      "7.30": "Skiing",
      7.31: "Snowboarding",
      7.32: "Dodgeballt",
      7.33: "Climbing",
      7.34: "Snooker",
      7.35: "Squash",
      7.36: "Swimming",
      7.37: "Volleyball",
      7.38: "Weightlifting",
      7.39: "Rugby",
    };

    return sportsMapping[value] || "-";
  }

  function getInternshipTypeLabel(value) {
    const internshipTypeMapping = {
      1: "Virtual internship",
      1.2: "On-site internship",
    };

    return internshipTypeMapping[value] || "-";
  }

  

  function getCertificationLabel(value) {
    const certificationMapping = {
      10: "First aid certification",
      5: "Driving license",
      8: "Google certification",
      8.01: "Coursera course",
      8.02: "edX course",
      8.03: "Udemy course",
      8.04: "Masterclass course",
      8.05: "Duolingo course",
      8.06: "Khan Academy course",
      8.07: "Codecademy course",
      8.08: "Domestika course",
      8.09: "Udacity course",
    };

    return certificationMapping[value] || "-";
  }

  function getLanguageLabel(value) {
    const languageMapping = {
      af: "Afrikaans",
      sq: "Albanian - shqip",
      am: "Amharic - አማርኛ",
      ar: "Arabic - العربية",
      an: "Aragonese - aragonés",
      hy: "Armenian - հայերեն",
      ast: "Asturian - asturianu",
      az: "Azerbaijani - azərbaycan dili",
      eu: "Basque - euskara",
      be: "Belarusian - беларуская",
      bn: "Bengali - বাংলা",
      bs: "Bosnian - bosanski",
      br: "Breton - brezhoneg",
      bg: "Bulgarian - български",
      ca: "Catalan - català",
      ckb: "Central Kurdish - کوردی (دەستنوسی عەرەبی)",
      zh: "Chinese - 中文",
      "zh-HK": "Chinese (Hong Kong) - 中文（香港）",
      "zh-CN": "Chinese (Simplified) - 中文（简体）",
      "zh-TW": "Chinese (Traditional) - 中文（繁體）",
      co: "Corsican",
      hr: "Croatian - hrvatski",
      cs: "Czech - čeština",
      da: "Danish - dansk",
      nl: "Dutch - Nederlands",
      eo: "Esperanto - esperanto",
      et: "Estonian - eesti",
      fo: "Faroese - føroyskt",
      fil: "Filipino",
      fi: "Finnish - suomi",
      fr: "French - français",
      "fr-CA": "French (Canada) - français (Canada)",
      "fr-FR": "French (France) - français (France)",
      "fr-CH": "French (Switzerland) - français (Suisse)",
      gl: "Galician - galego",
      ka: "Georgian - ქართული",
      de: "German - Deutsch",
      "de-AT": "German (Austria) - Deutsch (Österreich)",
      "de-DE": "German (Germany) - Deutsch (Deutschland)",
      "de-LI": "German (Liechtenstein) - Deutsch (Liechtenstein)",
      "de-CH": "German (Switzerland) - Deutsch (Schweiz)",
      el: "Greek - Ελληνικά",
      gn: "Guarani",
      gu: "Gujarati - ગુજરાતી",
      ha: "Hausa",
      haw: "Hawaiian - ʻŌlelo Hawaiʻi",
      he: "Hebrew - עברית",
      hi: "Hindi - हिन्दी",
      hu: "Hungarian - magyar",
      is: "Icelandic - íslenska",
      id: "Indonesian - Indonesia",
      ia: "Interlingua",
      ga: "Irish - Gaeilge",
      it: "Italian - italiano",
      "it-IT": "Italian (Italy) - italiano (Italia)",
      "it-CH": "Italian (Switzerland) - italiano (Svizzera)",
      ja: "Japanese - 日本語",
      kn: "Kannada - ಕನ್ನಡ",
      kk: "Kazakh - қазақ тілі",
      km: "Khmer - ខ្មែរ",
      ko: "Korean - 한국어",
      ku: "Kurdish - Kurdî",
      ky: "Kyrgyz - кыргызча",
      lo: "Lao - ລາວ",
      la: "Latin",
      lv: "Latvian - latviešu",
      ln: "Lingala - lingála",
      lt: "Lithuanian - lietuvių",
      mk: "Macedonian - македонски",
      ms: "Malay - Bahasa Melayu",
      ml: "Malayalam - മലയാളം",
      mt: "Maltese - Malti",
      mr: "Marathi - मराठी",
      mn: "Mongolian - монгол",
      ne: "Nepali - नेपाली",
      no: "Norwegian - norsk",
      nb: "Norwegian Bokmål - norsk bokmål",
      nn: "Norwegian Nynorsk - nynorsk",
      oc: "Occitan",
      or: "Oriya - ଓଡ଼ିଆ",
      om: "Oromo - Oromoo",
      ps: "Pashto - پښتو",
      fa: "Persian - فارسی",
      pl: "Polish - polski",
      pt: "Portuguese - português",
      "pt-BR": "Portuguese (Brazil) - português (Brasil)",
      "pt-PT": "Portuguese (Portugal) - português (Portugal)",
      pa: "Punjabi - ਪੰਜਾਬੀ",
      qu: "Quechua",
      ro: "Romanian - română",
      mo: "Romanian (Moldova) - română (Moldova)",
      rm: "Romansh - rumantsch",
      ru: "Russian - русский",
      gd: "Scottish Gaelic",
      sr: "Serbian - српски",
      sh: "Serbo-Croatian - Srpskohrvatski",
      sn: "Shona - chiShona",
      sd: "Sindhi",
      si: "Sinhala - සිංහල",
      sk: "Slovak - slovenčina",
      sl: "Slovenian - slovenščina",
      so: "Somali - Soomaali",
      st: "Southern Sotho",
      es: "Spanish - español",
      "es-AR": "Spanish (Argentina) - español (Argentina)",
      "es-419": "Spanish (Latin America) - español (Latinoamérica)",
      "es-MX": "Spanish (Mexico) - español (México)",
      "es-ES": "Spanish (Spain) - español (España)",
      "es-US": "Spanish (United States) - español (Estados Unidos)",
      su: "Sundanese",
      sw: "Swahili - Kiswahili",
      sv: "Swedish - svenska",
      tg: "Tajik - тоҷикӣ",
      ta: "Tamil - தமிழ்",
      tt: "Tatar",
      te: "Telugu - తెలుగు",
      th: "Thai - ไทย",
      ti: "Tigrinya - ትግርኛ",
      to: "Tongan - lea fakatonga",
      tr: "Turkish - Türkçe",
      tk: "Turkmen",
      tw: "Twi",
      uk: "Ukrainian - українська",
      ug: "Uyghur",
      uz: "Uzbek - o‘zbek",
      vi: "Vietnamese - Tiếng Việt",
      wa: "Walloon - wa",
      cy: "Welsh - Cymraeg",
      fy: "Western Frisian",
      xh: "Xhosa",
      yi: "Yiddish",
      yo: "Yoruba - Èdè Yorùbá",
      zu: "Zulu - isiZulu",
    };
    return languageMapping[value] || "-";
  }
  function GlobalCompList({ global_comp }) {
    return (
      <ul className="flex flex-col gap-2" style={{textAlign: 'start'}}>
        <li className="flex flex-row justify-between"><span>🧑‍💻 Technology</span> <span className="badge badge-primary badge-outline">{global_comp[0]}</span></li>
        <li className="flex flex-row justify-between"><span>🔰 Leadership</span> <span className="badge badge-primary badge-outline">{global_comp[1]}</span></li>
        <li className="flex flex-row justify-between"><span>📍 Interpersonal</span><span className="badge badge-primary badge-outline">{global_comp[2]}</span></li>
        <li className="flex flex-row justify-between"><span>🆘 Social Responsibility</span><span className="badge badge-primary badge-outline">{global_comp[3]}</span></li>
        <li className="flex flex-row justify-between"><span>🧠 Critical Thinking</span> <span className="badge badge-primary badge-outline">{global_comp[4]}</span></li>
        <li className="flex flex-row justify-between"><span>💪 Physical Fitness</span><span className="badge badge-primary badge-outline">{global_comp[5]}</span></li>
      </ul>
    );
  }

  //display Components
  const SingleField = ({ label, value }) => {
    let content = (
      <div>
        <p className="text-sm text-gray-600"> {label} </p>
        {(() => {
        if (value!==undefined) {
          return (
            <span > {value} </span>
          )
        }
      })()}
        
      </div>
    );
    return content;
  };

  const SkillsTag = ({ skill }) => {
    let content = (
      <div className="mr-2 my-1 rounded-full border border-primary px-4 text-sm py-2 font-medium text-primary">
        {skill}
      </div>
    );

    return content;
  };

  const CompetencyTag = ({ icon, activity, level }) => {
    let content = (
      <div className="flex items-center justify-between w-full">
        <span>
          {" "}
          {icon} {activity}{" "}
        </span>
        <span className="rounded-full border border-primary px-2 text-sm font-small text-primary">
          {level}
        </span>
      </div>
    );
    return content;
  };



  //diplays for each category
  const LeadershipDetails = ({
    name,
    grade,
    startdate,
    enddate,
    scope,
    service,
    description,
    duration,
    status
  }) => {

    const startDateObj = new Date(startdate);
    const endDateObj = new Date(enddate);
  
    const monthsDiff = (endDateObj.getFullYear() - startDateObj.getFullYear()) * 12 +
      (endDateObj.getMonth() - startDateObj.getMonth());


      function getMonthsDifference(startdate, enddate) {
        const start = new Date(startdate);
        const end = new Date(enddate);
        const monthsDifference =
          (end.getFullYear() - start.getFullYear()) * 12 +
          (end.getMonth() - start.getMonth()) + 1;
        return monthsDifference;
      }
      
  
    let content = (

<div className="card g-base-100 stageCard">
  <div className="card-body">

    <h2 className="card-title items-center">
      <SingleField value={getLeadershipActivityLabel(name)} /> 

      {parseInt(status) === 0 ? (
  <div className="badge badge-warning gap-2 text-xs">

    Pending
  </div>
) : (
  <div className="badge badge-success gap-2 text-xs">

    Approved
  </div>
)}

    </h2>
    <div className="grid grid-cols-3 gap-4 text-xs font-medium mb-2 text-neutral-500">

      <div className="badge badge-ghost w-full items-end">Grade <SingleField value={grade} /></div>
      <div className="badge badge-ghost w-full items-end"><SingleField value={duration} /> month(s)</div>
      <div className="badge badge-ghost w-full items-end"><SingleField value={getEngagementLabel(service)}  /></div>
      <div className="badge badge-ghost w-full items-end"><SingleField value={getLeadershipScopeLabel(scope)} /> </div>

    </div>

  </div>
</div>

    );
    return content;
  };




  const SocietiesDetails = ({
    name,
    role,
    grade,
    duration,
    service,
    description,
    status,
  }) => {
    let content = (

      <div className="card g-base-100 stageCard">
  <div className="card-body">

    <h2 className="card-title items-baseline">
      <SingleField value={name} /> 

      {parseInt(status) === 0 ? (
  <div className="badge badge-warning gap-2 text-xs">

    Pending
  </div>
) : (
  <div className="badge badge-success gap-2 text-xs">

    Approved
  </div>
)}

    </h2>
    <div className="grid grid-cols-3 gap-4 text-xs font-medium mb-2 text-neutral-500">

      <div className="badge badge-ghost w-full items-end">Grade <SingleField value={grade} /></div>
      <div className="badge badge-ghost w-full items-end"><SingleField value={duration} /> month(s)</div>
      <div className="badge badge-ghost w-full items-end"><SingleField value={getEngagementLabel(service)}  /></div>
      <div className="badge badge-ghost w-full items-end"><SingleField value={getSocietyRoleLabel(role)} /> </div>

    </div>

  </div>
</div>



    );
    return content;
  };

  const SocialsDetails = ({
    name,
    role,
    grade,
    scope,
    service,
    duration,
    description,
  }) => {
    let content = (
      <div>
        <div className="flex flex-col justify-between gap-4">
          <SingleField label="Activity" value={name} />
          <SingleField label="Role" value={getSocialRoleLabel(role)} />
          <SingleField label="Grade" value={grade} />
          <SingleField label="Scope" value={getScopeLabel(scope)} />
          <SingleField
            label="Engagement"
            value={getEngagementLabel(service)}
          />
          <SingleField label="Duration" value={getDurationLabel(duration)} />
        </div>
        <SingleField label="Description" value={description} />
      </div>
    );

    return content;
  };

  const AwardsDetails = ({ name, role, grade, scope, description }) => {
    let content = (
      <div>
        <div className="flex flex-col justify-between gap-4">
          <SingleField label="Award" value={getAwardsLabel(name)} />
          <SingleField label="Role" value={getPositionLabel(role)} />
          <SingleField label="Grade" value={grade} />
          <SingleField label="Scope" value={getScopeLabel(scope)} />
        </div>
        <SingleField label="Description" value={description} />
      </div>
    );
    return content;
  };

  const SportsDetails = ({
    name,
    role,
    grade,
    scope,
    duration,
    description,
  }) => {
    let content = (
      <div>
        <div className="flex flex-col justify-between gap-4">
          <SingleField label="Sports Activity" value={getSportsLabel(name)} />
          <SingleField label="Role" value={getPositionLabel(role)} />
          <SingleField label="Grade" value={grade} />
          <SingleField label="Scope" value={getScopeLabel(scope)} />
          <SingleField label="Duration" value={getDurationLabel(duration)} />
        </div>
        <div className="mt-4">
          <SingleField label="Description" value={description} />
        </div>
      </div>
    );

    return content;
  };

  const InternshipDetails = ({
    name,
    role,
    grade,
    scope,
    duration,
    description,
  }) => {
    let content = (
      <div>
        <div className="flex flex-col justify-between gap-4">
          <SingleField
            label="Internship Type"
            value={getInternshipTypeLabel(name)}
          />
          <SingleField label="Role" value={role} />
          <SingleField label="Grade" value={grade} />
          <SingleField label="Duration" value={getDurationLabel(duration)} />
        </div>
        <div className="mt-4">
          <SingleField label="Description" value={description} />
        </div>
      </div>
    );
    return content;
  };

  const CertificationDetails = ({
    type,
    title,
    award,
    duration,
    description,
  }) => {
    let content = (
      <div>
        <div className="flex flex-col justify-between gap-4">
          <SingleField label="Type" value={getCertificationLabel(type)} />
          <SingleField label="Title" value={title} />
          <SingleField label="Award Associated" value={award} />
          <SingleField label="Duration" value={getDurationLabel(duration)} />
        </div>
        <div className="mt-4">
          <SingleField label="Description" value={description} />
        </div>
      </div>
    );
    return content;
  };

  const LanguageDetails = ({ name, proficiency, description }) => {
    let content = (
      <div>
        <div className="flex flex-col justify-between gap-4">
          <SingleField label="Language" value={getLanguageLabel(name)} />
          <SingleField label="Proficiency" value={proficiency} />
        </div>
        <div className="mt-4">
          <SingleField label="Description" value={description} />
        </div>
      </div>
    );
    return content;
  };

  const EventManagementRow = ({ name, team, rank, scope }) => {
    let content = (
      <div className="flex flex-col justify-between gap-4">
        <SingleField label="Event" value={name} />
        <SingleField label="Team" value={team} />
        <SingleField label="Rank" value={rank} />
        <SingleField label="Scope" value={getScopeLabel(scope)} />
      </div>
    );

    return content;
  };

  const HobbiesDetails = ({ name, description }) => {
    let content = (
      <div>
        <SingleField label="Hobby" value={name} />
        <div className="mt-4">
          <SingleField label="Description" value={description} />
        </div>
      </div>
    );
    return content;
  };

  const OthersDetails = ({ name, description }) => {
    let content = (
      <div>
        <SingleField label="Other" value={name} />
        <div className="mt-4">
          <SingleField label="Description" value={description} />
        </div>
      </div>
    );
    return content;
  };




  let compL1 = 0;
  let compL2 = 0;
  let compL3 = 0;
  let compL4 = 0;
  let compL5 = 0;
  let compL6 = 0;

  if(global_comp[0]<=199){
    compL1 = "Lvl1 1";
  }

  else if(global_comp[0]>199 && global_comp[0]<=399){
    compL1 = "Lvl1 2";
  }

  else if(global_comp[0]>399 && global_comp[0]<=699){
    compL1 = "Lvl1 3";
  }

  else if(global_comp[0]>699 && global_comp[0]<=999){
    compL1 = "Lvl1 4";
  }

  else if(global_comp[0]>999){
    compL1 = "Lvl1 5";
  }



  if(global_comp[1]<=199){
    compL1 = "Lvl1 1";
  }

  else if(global_comp[1]>199 && global_comp[1]<=399){
    compL1 = "Lvl1 2";
  }

  else if(global_comp[1]>399 && global_comp[1]<=699){
    compL1 = "Lvl1 3";
  }

  else if(global_comp[1]>699 && global_comp[1]<=999){
    compL1 = "Lvl1 4";
  }

  else if(global_comp[1]>999){
    compL1 = "Lvl1 5";
  }


  if(global_comp[2]<=199){
    compL2 = "Lvl1 1";
  }

  else if(global_comp[2]>199 && global_comp[2]<=399){
    compL2 = "Lvl1 2";
  }

  else if(global_comp[2]>399 && global_comp[2]<=699){
    compL2 = "Lvl1 3";
  }

  else if(global_comp[2]>699 && global_comp[2]<=999){
    compL2 = "Lvl1 4";
  }

  else if(global_comp[2]>999){
    compL2 = "Lvl1 5";
  }




  if(global_comp[3]<=199){
    compL3 = "Lvl1 1";
  }

  else if(global_comp[3]>199 && global_comp[3]<=399){
    compL3 = "Lvl1 2";
  }

  else if(global_comp[3]>399 && global_comp[3]<=699){
    compL3 = "Lvl1 3";
  }

  else if(global_comp[3]>699 && global_comp[3]<=999){
    compL3 = "Lvl1 4";
  }

  else if(global_comp[3]>999){
    compL3 = "Lvl1 5";
  }



  if(global_comp[4]<=199){
    compL4 = "Lvl1 1";
  }

  else if(global_comp[4]>199 && global_comp[4]<=399){
    compL4 = "Lvl1 2";
  }

  else if(global_comp[4]>399 && global_comp[4]<=699){
    compL4 = "Lvl1 3";
  }

  else if(global_comp[4]>699 && global_comp[4]<=999){
    compL4 = "Lvl1 4";
  }

  else if(global_comp[4]>999){
    compL4 = "Lvl1 5";
  }



  if(global_comp[5]<=199){
    compL5 = "Lvl1 1";
  }

  else if(global_comp[5]>199 && global_comp[5]<=399){
    compL5 = "Lvl1 2";
  }

  else if(global_comp[5]>399 && global_comp[5]<=699){
    compL5 = "Lvl1 3";
  }

  else if(global_comp[5]>699 && global_comp[5]<=999){
    compL5 = "Lvl1 4";
  }

  else if(global_comp[5]>999){
    compL5 = "Lvl1 5";
  }



  if(global_comp[6]<=199){
    compL6 = "Lvl1 1";
  }

  else if(global_comp[6]>199 && global_comp[6]<=399){
    compL6 = "Lvl1 2";
  }

  else if(global_comp[6]>399 && global_comp[6]<=699){
    compL6 = "Lvl1 3";
  }

  else if(global_comp[6]>699 && global_comp[6]<=999){
    compL6 = "Lvl1 4";
  }

  else if(global_comp[6]>999){
    compL6 = "Lvl1 5";
  }


  let totalComps = lead_count + society_count + social_count + award_count + sports_count + intern_count + cert_count + talent_count + lang_count;


  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState);
  }
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (






    <div>




    <div>
    
    
    <div><>
    
    <div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>
    
    
    <Sidebar />
    
    {isSidebarVisible && <SideInnerSM/>}
    
        <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? 'xl:mr-68' : 'xl:ml-68'} rounded-xl`}>
          {/* Navbar */}
          <Navbar toggleSidebar={toggleSidebar} />
    
          {/* end Navbar */}
          {/* cards */}
          <div className="w-full px-6 py-6 mx-auto">
    
    
          <div className="lg:p-12 bg-white rounded-lg">
            
    
    
    
    
    
            <div className="flex flex-row justify-between p-4 items-center z-50 sticky top-0">
                <h3 className="text-xl font-medium">♣️ Society & Clubs </h3>
                <Link  to={`/add_society`}>
                <button className="btn rounded-full bg-primary text-white"> Add new </button>
                </Link>
            </div>
            
            
            
            <div className="p-4 mb-20 rounded-xl grid md:grid-cols-3 lg:grid-cols-4 sm: grid-cols-1 gap-6">
  {societiesGet?.filter(lead => parseInt(lead.posted_by) === userID).length > 0 ? (
    societiesGet.filter(lead => parseInt(lead.posted_by) === userID).map((activity, index) => {

        return (
          <div className="my-4 rounded-lg lg:shadow-lg" key={index}>
            <SocietiesDetails
              name={activity?.activity_name}
              position={activity?.position}
              grade={activity?.grade}
              duration={activity?.duration}
              startdate={activity?.startdate}
              enddate={activity?.enddate}
              role={activity?.role}
              service={activity?.service_hours}
              status={activity?.status}
            />
          </div>
        );

    })
  ) : (
    <div className="flex flex-col gap-4 justify-center items-center h-full" style={{position:'fixed', textAlign: 'center', display:'flex', top:0,bottom:0,left:0,right:0}}>



<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-gray-400 w-8 h-8">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>


      <p className="text-center px-8">You don't have any activities in <b>Socities & Clubs</b></p>
    </div>
  )}
</div>
            
            <div style={{ zIndex: 999999999999999, marginBottom: '2vh' }}>
                    <Toaster position="bottom-center" />
                  </div>
            
            
            
                </div>
    
          </div>
        </main>
      </>
      </div>
    
        
        </div>
    
    
    
     </div>

//     <div className="lg:p-12">
        
//         <div className={`navbar justify-between sticky top-0 z-30 w-full pt-8 pb-4 px-6 flex ${scroll ? 'nav-scroll' : ''}`}>


// <Link to="/cats"> 



// <button className="btn btn-ghost gap-2">
// <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
//   <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
// </svg>
//    Go Back
// </button>


// </Link>

// </div>




// <div className="flex flex-row justify-between p-4 items-center z-50 sticky top-0">
//     <h3 className="text-xl font-medium">👥 Clubs & Societies</h3>
//     <Link  to={`/add_society/${user.user_id}`}>
//     <button className="btn rounded-full bg-primary"> Add new </button>
//     </Link>
// </div>





// <div style={{ zIndex: 999999999999999, marginBottom: '2vh' }}>
//         <Toaster position="bottom-center" />
//       </div>

// <MobileBottom/>

//     </div>


  );
};

export default SocietyStage;
