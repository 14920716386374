import axios from "axios";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

function AttendancePageSample({soceityID}) {
  const [clubs, setClubs] = useState([]);

  const [schedules, setSchedules] = useState([
    {
        "id": 1340,
        "start_date": "2024-05-16T09:00:00Z",
        "end_date": "2024-05-16T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1341,
        "start_date": "2024-05-20T11:00:00Z",
        "end_date": "2024-05-20T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1342,
        "start_date": "2024-05-23T09:00:00Z",
        "end_date": "2024-05-23T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1343,
        "start_date": "2024-05-27T11:00:00Z",
        "end_date": "2024-05-27T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1344,
        "start_date": "2024-05-30T09:00:00Z",
        "end_date": "2024-05-30T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1345,
        "start_date": "2024-06-03T11:00:00Z",
        "end_date": "2024-06-03T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1346,
        "start_date": "2024-06-06T09:00:00Z",
        "end_date": "2024-06-06T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1347,
        "start_date": "2024-06-10T11:00:00Z",
        "end_date": "2024-06-10T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1348,
        "start_date": "2024-06-13T09:00:00Z",
        "end_date": "2024-06-13T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1349,
        "start_date": "2024-06-17T11:00:00Z",
        "end_date": "2024-06-17T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1350,
        "start_date": "2024-06-20T09:00:00Z",
        "end_date": "2024-06-20T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1351,
        "start_date": "2024-06-24T11:00:00Z",
        "end_date": "2024-06-24T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1352,
        "start_date": "2024-06-27T09:00:00Z",
        "end_date": "2024-06-27T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1353,
        "start_date": "2024-07-01T11:00:00Z",
        "end_date": "2024-07-01T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1354,
        "start_date": "2024-07-04T09:00:00Z",
        "end_date": "2024-07-04T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1355,
        "start_date": "2024-07-08T11:00:00Z",
        "end_date": "2024-07-08T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1356,
        "start_date": "2024-07-11T09:00:00Z",
        "end_date": "2024-07-11T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1357,
        "start_date": "2024-07-15T11:00:00Z",
        "end_date": "2024-07-15T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1358,
        "start_date": "2024-07-18T09:00:00Z",
        "end_date": "2024-07-18T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1359,
        "start_date": "2024-07-22T11:00:00Z",
        "end_date": "2024-07-22T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1360,
        "start_date": "2024-07-25T09:00:00Z",
        "end_date": "2024-07-25T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1361,
        "start_date": "2024-07-29T11:00:00Z",
        "end_date": "2024-07-29T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1362,
        "start_date": "2024-08-01T09:00:00Z",
        "end_date": "2024-08-01T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1363,
        "start_date": "2024-08-05T11:00:00Z",
        "end_date": "2024-08-05T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1364,
        "start_date": "2024-08-08T09:00:00Z",
        "end_date": "2024-08-08T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1365,
        "start_date": "2024-08-12T11:00:00Z",
        "end_date": "2024-08-12T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1366,
        "start_date": "2024-08-15T09:00:00Z",
        "end_date": "2024-08-15T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1367,
        "start_date": "2024-08-19T11:00:00Z",
        "end_date": "2024-08-19T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1368,
        "start_date": "2024-08-22T09:00:00Z",
        "end_date": "2024-08-22T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1369,
        "start_date": "2024-08-26T11:00:00Z",
        "end_date": "2024-08-26T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1370,
        "start_date": "2024-08-29T09:00:00Z",
        "end_date": "2024-08-29T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1371,
        "start_date": "2024-09-02T11:00:00Z",
        "end_date": "2024-09-02T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1372,
        "start_date": "2024-09-05T09:00:00Z",
        "end_date": "2024-09-05T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1373,
        "start_date": "2024-09-09T11:00:00Z",
        "end_date": "2024-09-09T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1374,
        "start_date": "2024-09-12T09:00:00Z",
        "end_date": "2024-09-12T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1375,
        "start_date": "2024-09-16T11:00:00Z",
        "end_date": "2024-09-16T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1376,
        "start_date": "2024-09-19T09:00:00Z",
        "end_date": "2024-09-19T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1377,
        "start_date": "2024-09-23T11:00:00Z",
        "end_date": "2024-09-23T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1378,
        "start_date": "2024-09-26T09:00:00Z",
        "end_date": "2024-09-26T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1379,
        "start_date": "2024-09-30T11:00:00Z",
        "end_date": "2024-09-30T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1380,
        "start_date": "2024-10-03T09:00:00Z",
        "end_date": "2024-10-03T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1381,
        "start_date": "2024-10-07T11:00:00Z",
        "end_date": "2024-10-07T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1382,
        "start_date": "2024-10-10T09:00:00Z",
        "end_date": "2024-10-10T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1383,
        "start_date": "2024-10-14T11:00:00Z",
        "end_date": "2024-10-14T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1384,
        "start_date": "2024-10-17T09:00:00Z",
        "end_date": "2024-10-17T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1385,
        "start_date": "2024-10-21T11:00:00Z",
        "end_date": "2024-10-21T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1386,
        "start_date": "2024-10-24T09:00:00Z",
        "end_date": "2024-10-24T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1387,
        "start_date": "2024-10-28T11:00:00Z",
        "end_date": "2024-10-28T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1388,
        "start_date": "2024-10-31T09:00:00Z",
        "end_date": "2024-10-31T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1389,
        "start_date": "2024-11-04T11:00:00Z",
        "end_date": "2024-11-04T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1390,
        "start_date": "2024-11-07T09:00:00Z",
        "end_date": "2024-11-07T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1391,
        "start_date": "2024-11-11T11:00:00Z",
        "end_date": "2024-11-11T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1392,
        "start_date": "2024-11-14T09:00:00Z",
        "end_date": "2024-11-14T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1393,
        "start_date": "2024-11-18T11:00:00Z",
        "end_date": "2024-11-18T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1394,
        "start_date": "2024-11-21T09:00:00Z",
        "end_date": "2024-11-21T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1395,
        "start_date": "2024-11-25T11:00:00Z",
        "end_date": "2024-11-25T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1396,
        "start_date": "2024-11-28T09:00:00Z",
        "end_date": "2024-11-28T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1397,
        "start_date": "2024-12-02T11:00:00Z",
        "end_date": "2024-12-02T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1398,
        "start_date": "2024-12-05T09:00:00Z",
        "end_date": "2024-12-05T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1399,
        "start_date": "2024-12-09T11:00:00Z",
        "end_date": "2024-12-09T12:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1400,
        "start_date": "2024-12-12T09:00:00Z",
        "end_date": "2024-12-12T10:00:00Z",
        "club": soceityID,
        
    },
    {
        "id": 1401,
        "start_date": "2024-12-16T11:00:00Z",
        "end_date": "2024-12-16T12:00:00Z",
        "club": soceityID,
        
    }
]);
  const [selectedSchedule, setSelectedSchedule] = useState("");
  const [attendees, setAttendees] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selecedStudents, setSelecedStudents] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);



const { id } = useParams();
  const [selectedClub, setSelectedClub] = useState(soceityID);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/clubs/`)
      .then((res) => {
        setClubs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

//   useEffect(() => {
//     if (selectedClub) {
//       axios
//         .get(
//           `${process.env.REACT_APP_API_KEY}/api/get_club_schedules/${selectedClub}/`
//         )
//         .then((res) => {
//           const currentDate = new Date();
//           const filteredSchedules = res.data.filter(
//             (schedule) => new Date(schedule.start_date) <= currentDate
//           );
//           setSchedules(filteredSchedules);
//           if (filteredSchedules.length > 0) {
//             setSelectedSchedule(
//               filteredSchedules[filteredSchedules.length - 1].id
//             );
//           } else {
//             setSelectedSchedule(""); // Reset selected schedule if no schedules available
//           }
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//   }, [selectedClub]);

  const leaders = [
    { Name: "Sana Faisal", value: "Sana Faisal" },
    { Name: "Kimberly Savia Fernandes", value: "Kimberly Savia Fernandes" },
    { Name: "Heba Qaiser", value: "Heba Qaiser" },
    { Name: "Wania Khalid", value: "Wania Khalid" },
    { Name: "Hassaan Ahmed", value: "Hassaan Ahmed" },
    { Name: "Adam Noor", value: "Adam Noor" },
    { Name: "Mohammed Kashan", value: "Mohammed Kashan" },
    { Name: "Ali Affan", value: "Ali Affan" },
    { Name: "Hamdiya Ahmed", value: "Hamdiya Ahmed" },
    { Name: "Abdur Rehman", value: "Abdur Rehman" },
  ];
  const getAttendees = () => {
    if (!selectedClub || !selectedSchedule) {
      setAttendees([]);
      return;
    }
  
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_club_attendance/${selectedSchedule}/`)
      .then((res) => {
        const statusResponse = res.data || [];
        const attendees = leaders.map((leader, index) => {
          const status = statusResponse[index]?.status || null;
          return {
            id: index + 1,
            firstname: leader.Name.split(' ')[0],
            lastname: leader.Name.split(' ').slice(1).join(' '),
            grade: null,
            email: null,
            date: new Date().toISOString(),
            status: status,
            student: index + 1,
            club: selectedClub,
            schedule: selectedSchedule,
            event: null
          };
        });
        setAttendees(attendees);
      })
      .catch((err) => {
        console.log(err);
        // Ensure attendees are still set if there's an error
        const attendees = leaders.map((leader, index) => {
          return {
            id: index + 1,
            firstname: leader.Name.split(' ')[0],
            lastname: leader.Name.split(' ').slice(1).join(' '),
            grade: null,
            email: null,
            date: new Date().toISOString(),
            status: null,
            student: index + 1,
            club: selectedClub,
            schedule: selectedSchedule,
            event: null
          };
        });
        setAttendees(attendees);
      });
  };

  useEffect(() => {
    getAttendees(); // Fetch attendees when selectedClub or selectedSchedule changes
  }, [selectedClub, getAttendees , selectedSchedule]);

  const handleClubChange = (e) => {
    setSelectedClub(e.target.value);
    setSelectedSchedule("");
  };
  const handleScheduleChange = (e) => {
    setSelectedSchedule(e.target.value);
  };

  const handleAttendence = (id, status) => {
    axios
      .put(`${process.env.REACT_APP_API_KEY}/api/handle_attendance/`, {
        id: id,
        status: status,
      })
      .then((res) => {
        getAttendees();
        // toast.success("Attendance Updated Successfully");
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${day} ${monthNames[monthIndex]} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}`;
  };

  const handleSelectAllCheckbox = () => {
    setSelectAllChecked((prevState) => !prevState);
    if (!selectAllChecked) {
      const allAttendeeIds = attendees.map((attendee) => attendee.id);
      setSelecedStudents(allAttendeeIds);
    } else {
      setSelecedStudents([]);
    }
  };

  const handleCheckboxAttendance = async (id, isChecked) => {
    if (isChecked) {
      setSelecedStudents((prevSelected) => [...prevSelected, id]);
    } else {
      setSelecedStudents((prevSelected) =>
        prevSelected.filter((studentId) => studentId !== id)
      );
    }
  };

  const markSelectedAttendeesPresent = () => {
    selecedStudents.forEach((studentId) => {
      handleAttendence(studentId, true);
    });
    setSelecedStudents([]);
    setSelectAllChecked(false);
  };

  return (
    <>
      {/* Table Section */}
      <div className="o">
        {/* Card */}
        <div className="flex flex-row justify-between items-center bg-white p-6 rounded-xl mb-8">
          <span className="text-sm font-medium">
            Select Club
            <span>
              <select
                className="select select-bordered w-full max-w-xs"
                value={selectedClub}
                onChange={handleClubChange}
              >
                <option selected disabled value="">
                  Select a club
                </option>
                {clubs.map((club) => (
                  <option key={club.id} value={club.id}>
                    {club.name}
                  </option>
                ))}
              </select>
            </span>
          </span>

          <span className="text-sm font-medium">
            Select Time Slot
            <select
              className="select select-bordered w-full max-w-xs"
              value={selectedSchedule}
              onChange={handleScheduleChange}
            >
              <option value="">Select Time</option>
              {schedules.map((schedule) => (
                <option key={schedule.id} value={schedule.id}>
                  {formatDate(schedule.start_date)} -{" "}
                  {formatDate(schedule.end_date)}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="flex justify-end mb-6">
          <button
            onClick={markSelectedAttendeesPresent}
            className="btn btn-primary "
          >
            Mark as Present
          </button>
        </div>
        <div className="flex flex-col bg-white rounded-xl">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                      >
                        <input
                          type="checkbox"
                          className="form-checkbox h-5 w-5 text-primary ml-7 rounded-md border-gray-300 focus:ring-primary"
                          checked={selectAllChecked}
                          onChange={handleSelectAllCheckbox}
                        />
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                      ></th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                      >
                        Grade
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="text-center px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 darki:divide-gray-700">
                    {attendees?.map((attendee) => (
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 darki:text-gray-200">
                          <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-primary ml-7 rounded-md border-gray-300 focus:ring-primary"
                            checked={selecedStudents.includes(attendee.id)}
                            onChange={(e) =>
                              handleCheckboxAttendance(
                                attendee.id,
                                e.target.checked
                              )
                            }
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 darki:text-gray-200">
                          <div className="avatar placeholder">
                            <div className="bg-purple-100 text-primary rounded-full w-10">
                              <span className="text-base">
                                {attendee?.firstname?.substring(0, 1)}
                                {attendee?.lastname?.substring(0, 1)}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 darki:text-gray-200">
                          {attendee?.firstname} {attendee?.lastname} <br />{" "}
                          <span className="text-gray-400">
                            {attendee?.email}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 darki:text-gray-200">
                          {attendee?.grade}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 darki:text-gray-200">
                          {attendee?.status === true ? (
                            <span class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-teal-100 text-teal-800 darki:bg-teal-800/30 darki:text-teal-500">
                              Present
                            </span>
                          ) : attendee?.status === false ? (
                            <span class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-100 text-red-800 darki:bg-red-800/30 darki:text-red-500">
                              Absent
                            </span>
                          ) : (
                            <span class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 darki:bg-yellow-800/30 darki:text-yellow-500">
                              Pending
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                          <div class="inline-flex rounded-lg gap-2">
                            <button
                              type="button"
                              class="btn btn-sm btn-success"
                              onClick={() =>
                                handleAttendence(attendee?.id, true)
                              }
                            >
                              Mark Present
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-error"
                              onClick={() =>
                                handleAttendence(attendee?.id, false)
                              }
                            >
                              Mark Absent
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* End Card */}
      </div>
      {/* End Table Section */}
    </>
  );
}

export default AttendancePageSample;
