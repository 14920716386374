import React, { useState, useEffect } from "react";
import { FcBusinessman, FcCalendar, FcConferenceCall, FcCurrencyExchange, FcHighPriority, FcOk, FcPuzzle, FcRating } from "react-icons/fc";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from 'swiper/modules';
// import 'swiper/swiper-bundle.min.css';
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from 'react-i18next';







function MinimalStats() {
  const { t } = useTranslation();
const { i18n } = useTranslation();

const stats = [
  {
    title: t('Staff Members'),
    numbers: `05`,
    icon: FcBusinessman,
  },
  {
    title: t('Students'),
    numbers: `30`,
    icon: FcConferenceCall,
  },
  {
    title: t('Events'),
    numbers: `15`,
    icon: FcCalendar,
  },

  {
    title: t('Clubs'),
    numbers: `10`,
    icon: FcPuzzle,
  },
  {
    title: t('Councils'),
    numbers: `01`,
    icon: FcRating,
  },
  // {
  //   title: `Participation`,
  //   numbers: `98%`,
  //   icon: FcOk,
  // },


];
  return (
    <>
      <Swiper
        slidesPerView={5}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        className="mySwiper cursor-pointer"
      >
        {stats.map((stat, index) => (
          <SwiperSlide>
            <div
              id="jh-stats-positive"
              className="flex flex-col items-center justify-center px-4 py-4 bg-white rounded-2xl shadow-xl"
            >
              <stat.icon size={30} />
              <div>
                <p className="text-xl mt-3 mb-0 font-semibold text-center text-gray-800">
                  {stat.numbers}
                </p>
                <p className="text-lg m-0 text-center text-gray-500">
                  {stat.title}
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default MinimalStats;
