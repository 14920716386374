import React from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineTranslate } from "react-icons/md";
import ReactCountryFlag from "react-country-flag";

const LanguageDropdown = ({darkC}) => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  };

  const getLanguageName = (lng) => {
    switch (lng) {
      case "en":
        return "English";
      case "ar":
        return "العربية";
      default:
        return "Select Language";
    }
  };

  return (
    <div className="dropdown dropdown-end ">
      <div tabIndex={0} role="button" className={`btn m-1 btn-ghost ${darkC? 'text-white ':'text-primary '} `}>
        <MdOutlineTranslate />
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
      >
        <li>
          <button
            className={`btn h-max btn-sm capitalize btn-ghost p-2 ${
              i18n.language === "en" ? "bg-purple-100" : ""
            }`}
            onClick={() => changeLanguage("en")}
          >
            <ReactCountryFlag countryCode="GB" svg title="GB" />
            English
          </button>
        </li>
        <li>
          <button
            className={`btn btn-sm capitalize btn-ghost p-2 ${
              i18n.language === "ar" ? "bg-purple-100" : ""
            }`}
            onClick={() => changeLanguage("ar")}
          >
          <ReactCountryFlag countryCode="SA" svg title="KSA" />
            العربية
          </button>
        </li>
      </ul>
    </div>
  );
};

export default LanguageDropdown;
