import React, { useState, useEffect } from "react";
// import { LoremIpsum } from "lorem-ipsum";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ClubMembersListSample from "./ClubMembersListSample";
import StudentCouncilTracker from "../components/StudentCouncilTracker";
import StudentCouncilTrackerWeekly from "../components/StudentCouncilTrackerWeekly";


function ClubDetailsSImple({ soceity }) {
  // const lorem = new LoremIpsum({
  //   sentencesPerParagraph: {
  //     max: 6,
  //     min: 4,
  //   },
  //   wordsPerSentence: {
  //     max: 16,
  //     min: 4,
  //   },
  // });

  // const [paragraph, setParagraph] = useState("");

  // useEffect(() => {
  //   setParagraph(lorem.generateParagraphs(1));
  // }, []); // Empty dependency array ensures this runs only once on mount

  return (
<div className="">
  <div className="py-8">
    <div className="px-4">
      {/* <div className="col-span-4 sm:col-span-3">
        <div className="bg-white shadow rounded-lg p-6 sticky top-[5rem]">
          <div className="flex flex-col items-center">
            <img
              src={soceity?.logo}
              className="w-32 h-32 bg-gray-300 rounded-full mb-4 shrink-0"
            />
            <h1 className="text-xl font-bold">{soceity?.name}</h1>
            <p className="text-gray-700">
              {parseFloat(soceity.fees) === 0 ||
              parseFloat(soceity.fees) === null
                ? "During School "
                : "After School "}{" "}
              Club
            </p>
          </div>
          <hr className="my-2 border-t border-gray-300" />
          <div className="flex flex-col">
            <span className="text-gray-700 uppercase font-bold tracking-wider mb-2">
              About Club
            </span>
            Exercitation consequat non sint amet incididunt magna qui incididunt officia ad fugiat et elit. Nisi id aliquip non consectetur est velit pariatur veniam excepteur do. Deserunt proident minim et nisi nostrud cupidatat est mollit. Consequat enim commodo occaecat Lorem ea ad ullamco ullamco quis culpa tempor aute minim amet aliquip. Consectetur in sit eu eiusmod dolor eiusmod sit proident est aliqua qui do exercitation incididunt. Magna pariatur ex ipsum quis fugiat esse occaecat nulla id ut mollit.
          </div>
          <hr className="my-2 border-t border-gray-300" />
        </div>
      </div> */}
      <div className="">
        <div className="bg-white shadow rounded-lg p-6">
          
          <Tabs>
        <div className="">
          <div className="border-b border-gray-200">
            <TabList className="-mb-px flex gap-6 cursor-pointer" aria-label="Tabs">
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Tracker
              </Tab>
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Members
              </Tab>
            </TabList>
          </div>
        </div>

        <TabPanel>
          {/* <StudentCouncilTrackerWeekly /> */}
         <StudentCouncilTracker type='club' needWeeks={true}/>
        </TabPanel>

        <TabPanel>
          <ClubMembersListSample />
        </TabPanel>
      </Tabs>

          
        </div>
      </div>
    </div>
  </div>
</div>

  );
}

export default ClubDetailsSImple;
