// Breadcrumb.js
import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ crumbs }) => {
    console.warn(`crb`,crumbs)
  return (
   <>
     { crumbs.length > 0? (
      <ol className="hidden md:flex items-center whitespace-nowrap mb-8">
      {crumbs.map((crumb, index) => (
        <li key={index} className="inline-flex items-center">
          <Link
            className={index < crumbs.length - 1 ? `flex items-center text-sm text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600` : `inline-flex items-center text-sm font-semibold text-gray-800 truncate`}

            to={crumb.path}
          >
            {crumb.label}
          </Link>
          {index < crumbs.length - 1 && (
            <svg
      className="flex-shrink-0 mx-2 overflow-visible size-4 text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
          )}
        </li>
      ))}
    </ol>
    )  : null }
   </>
    
  );
};

export default Breadcrumbs;
