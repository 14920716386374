import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const RoleDropdown = () => {
  // State to manage the selected value
  const [selectedRole, setSelectedRole] = useState(localStorage.getItem('selectedRole') || 'Public');
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect to update local storage when selected value changes
  // useEffect(() => {
  //   localStorage.setItem('selectedRole', selectedRole);
  // }, [selectedRole]);

  useEffect(() => {
    localStorage.setItem('selectedRole', selectedRole);
    if(selectedRole === "Public") {
      navigate("/allclubpublic");
    }else {
      navigate("/myclub");
    }

  }, [selectedRole]);


  // Function to handle change in the dropdown selection
  const handleSelectChange = (event) => {
    setSelectedRole(event.target.value);

    // Perform any page navigation logic based on the selected value here
    // For now, we're just logging the selected value to the console
    console.warn('Selected Role:', event.target.value);


  };


  

  return (
    <div className="flex w-min justify-end">
      <select
        id="roleSelect"
        name="role"
        value={selectedRole}
        onChange={handleSelectChange}
        className="select select-bordered w-content"
      >
        <option value="Public">Public</option>
        <option value="Member">Member</option>
        <option value="Admin">Admin</option>
      </select>
    </div>
  );
};

export default RoleDropdown;
