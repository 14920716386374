import React, {useEffect, useState} from 'react';
import DynamicTable from "./DynamicTable";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
const EventTabs = ({data}) => {
    const [tabIndex, setTabIndex] = useState(0);
   
  

    

   

   

    

    return (
        <Tabs className="pb-4" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>Form Data</Tab>
          <Tab>Guests</Tab>
          <Tab>Auditoriums</Tab>
          <Tab>Venues</Tab>
          <Tab>Security Clearance</Tab>
          <Tab>Purchase</Tab>
        </TabList>
        <TabPanel>
      <div className="grid grid-cols-2 gap-4 p-2">
        <div className="font-bold">Applicant Name:</div>
        <div>{data?.applicant_name}</div>

        <div className="font-bold">Applicant Contact:</div>
        <div>{data?.applicant_contact}</div>

        <div className="font-bold">Applicant Email:</div>
        <div>{data?.applicant_email}</div>
        <div className="font-bold">Applicant Designation:</div>
        <div>{data?.applicant_designation}</div>

        <div className="font-bold">Society Name</div>
        <div>{data?.society_name}</div>

        <div className="font-bold">Start Date:</div>
        <div>{data?.start_date}</div>
        <div className="font-bold">End Date:</div>
        <div>{data?.end_date}</div>

        <div className="font-bold">Event Name:</div>
        <div>{data?.event_name}</div>

        <div className="font-bold">Event Description:</div>
        <div>{data?.event_description}</div>
        <div className="font-bold">EventHead</div>
        <div>{data?.isChecked_EventHead ? "True" : "False"}</div>

        <div className="font-bold">Request Type:</div>
        <div>{data?.requestType}</div>
        <div className="font-bold">PDC FILE:</div>
        <div>{data?.pdc_file}</div>


        
        <div className="font-bold">Labour Service:</div>
        <div>{data?.labour_service}</div>
        <div className="font-bold">Plumbing Service:</div>
        <div>{data?.plumbing_service}</div>
        <div className="font-bold">Security Service:</div>
        <div>{data?.security_service}</div>
        <div className="font-bold">Welder Service:</div>
        <div>{data?.welder_service}</div>
        <div className="font-bold">Carpenter Service:</div>
        <div>{data?.carpenter_service}</div>
    
    </div>
        </TabPanel>
        <TabPanel>
        <div className='overflow-auto'>
            <DynamicTable data={data?.all_guests} />
            </div>
            
            
        </TabPanel>
        
        <TabPanel>
        <div className='overflow-auto'>
            <DynamicTable data={data?.auditoriums} />
            </div>
            
            
        </TabPanel>
        <TabPanel>
        <div className='overflow-auto'>
            <DynamicTable data={data?.venues} />
            </div>
            
            
        </TabPanel>
        <TabPanel>
        <div className='overflow-auto'>
            <DynamicTable data={data?.security_clearance_service} />
            </div>
            
            
        </TabPanel>
        <TabPanel>
        <div className='overflow-auto'>
        <table className="min-w-full divide-y divide-gray-200 overflow-x-auto">
        <thead>
          <tr>
          <th className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">Selected Category</th>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">Category</th>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">Due Date</th>
           
            
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data?.all_purchases?.map((item, index) => (
            <tr key={index} className='hover:bg-gray-100 cursor-pointer'>
                 <td className="px-6 py-4 whitespace-nowrap">{item.selected_category}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {item?.category?.map((category, categoryIndex) => (
                  <div key={categoryIndex}>
                    <p className='m-0'>{category.categoryName}</p>
                    {category.file && (
                      <a href={category.file} className="text-blue-500" target="_blank" rel="noopener noreferrer">
                        View File
                      </a>
                    )}
                  </div>
                ))}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{item.due_date}</td>
             
            </tr>
          ))}
        </tbody>
      </table>
     
            </div>
            
            
        </TabPanel>
      </Tabs>
    );
};

export default EventTabs;
