// blogsData.js
const ClubsData = [
    {
      id: 1,
      title: 'Strikers FC',
      category: 'Sports',
      content: 'Renowned Football club',
      img: 'https://res.cloudinary.com/ahbranding/image/upload/v1705663616/Group_34784500_avny0v.png'
    },
    {
      id: 2,
      title: 'Coding Club',
      category: 'Technology',
      content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      img:'https://img.freepik.com/free-vector/gradient-code-logo-with-tagline_23-2148811020.jpg?q=10&h=200'
    },
    {
      id: 3,
      title: 'Book Club',
      category: 'Literature',
      content: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
      img:'https://img.freepik.com/free-vector/hand-drawn-flat-design-bookstore-logo_23-2149330200.jpg'
    },
    {
      id: 4,
      title: 'Art Club',
      category: 'Arts',
      content: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.',
      img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIgB8rjJWimXxf_X_mVKBFRlDQvu_UOJ_ihw&usqp=CAU'
    },
    {
      id: 5,
      title: 'Music Club',
      category: 'Arts',
      content: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.',
      img: 'https://blog.photoadking.com/wp-content/uploads/2023/02/1677501266470-1.jpg'
    },
    {
      id: 6,
      title: 'Science Club',
      category: 'Science',
      content: 'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.',
      img:'https://res.cloudinary.com/ahbranding/image/upload/v1705663616/Group_34784499_gei8qi.png'
    },
    {
      id: 7,
      title: 'Debate Club',
      category: 'Education',
      content: 'Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.',
      img:'https://static.vecteezy.com/system/resources/thumbnails/005/542/672/small/stairs-stage-with-microphone-logo-symbol-icon-graphic-design-illustration-idea-creative-vector.jpg'
    },
    {
      id: 8,
      title: 'Environmental Club',
      category: 'Nature',
      content: 'Consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore.',
    },
    {
      id: 9,
      title: 'Dance Club',
      category: 'Arts',
      content: 'Nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit.',
    },
    {
      id: 10,
      title: 'Film Club',
      category: 'Entertainment',
      content: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti.',
    },
    // Add 10 more clubs
  ];
  
  export default ClubsData;
  