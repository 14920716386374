import React, { useContext, useEffect, useState } from "react";

import AuthContext from "../context/AuthContext";
import axios from "axios";
import toast from "react-hot-toast";
import TagSelector from "./TagSelector";

const AddSingleStudent = () => {
  const [school, setSchool] = useState(null);

  const { user } = useContext(AuthContext);
  const userID = user["user_id"];
  const profileID = userID;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("1");
  const [schools, setSchools] = useState([]);
  const [profile, setprofile] = useState("");

  let url = "";
  if (user["role"] === "Student") {
    url = "profile";
  } else if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  } else if (user["role"] === "Staff") {
    url = "staff";
  }

  const [currentNetwork, setCurrentNetwork] = useState(null); // Initialize the currentNetwork state
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((response) => {
        setprofile(response?.data);
        // setNetwork(response?.data?.network);
        if (url === "network_profile") {
          setCurrentNetwork(response?.data?.network_id); // Ensure data is not null before accessing the property
        } else if (url === "school_profile") {
          setCurrentNetwork(response?.data?.school_id); // Ensure data is not null before accessing the property
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleGradeChange = (e) => {
    setSelectedGrade(e.target.value);
  };

  useEffect(() => {
    if (currentNetwork) {
      // Fetch schools from the Django API
      fetch(`${process.env.REACT_APP_API_KEY}/api/schools/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const filteredSchools = data.filter(
            (school) =>
              parseInt(school.network) === parseInt(profile?.network_id)
          );
          setSchools(filteredSchools);
        })
        .catch((error) => {
          console.error("Error fetching schools:", error);
        });
    }
  }, [currentNetwork]);

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  let campus = 0;
  let network = 0;

  if (user["role"] === "Network Admin") {
    campus = selectedOption;
    network = currentNetwork;
  } else if (user["role"] === "School Admin") {
    campus = profile?.school_id;
    network = profile?.network;
  }

  const handleSubmit = () => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/single_register/`, {
        name: name,
        email: email,
        grade: selectedGrade,
        school_id: campus,
        network_id: network,
      })
      .then((response) => {
        console.log(response);
        toast.success("Student Added");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Student Not Added");
      });
  };

  return (
    <div className="bg-white rounded-xl p-6">
      <h3 className="text-lg">Add single student</h3>
      <p>Add single user instead of uploading file</p>

      <div className="flex flex-row gap-x-4  my-2">
        <div>
          <label>
            Name:
            <input
              type="text"
              className="input input-bordered w-full max-w-xs"
              value={name}
              onChange={handleNameChange}
              placeholder="Student Full Name"
            />
          </label>
        </div>
        <div>
          <label>
            Email:
            <input
              type="text"
              className="input input-bordered w-full max-w-xs"
              value={email}
              onChange={handleEmailChange}
              placeholder="Student Email Address"
            />
          </label>
        </div>
      </div>

      <div className="flex flex-row gap-x-4 my-2">
        <div className="w-full">
          <label className="w-full">
            Grade:
            <select
              className="select select-bordered w-full "
              value={selectedGrade}
              onChange={handleGradeChange}
            >
              <option value="">Select a Grade</option>
              {[...Array(12).keys()].map((grade) => (
                <option key={grade + 1} value={(grade + 1).toString()}>
                  Grade {grade + 1}
                </option>
              ))}
            </select>
          </label>
        </div>

        {(() => {
          if (user["role"] === "Network Admin") {
            return (
              <div className="w-full">
                <label className="w-full">
                  Campus:
                  <select
                    className="select select-bordered w-full "
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option value="">Select a campus</option>
                    {schools.map((option, index) => (
                      <option key={index} value={option.school_id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            );
          }
        })()}
      </div>


      <TagSelector/>

      <div>
        <button className="btn btn-primary mt-6" onClick={handleSubmit}>
          Add Student
        </button>
      </div>
    </div>
  );
};

export default AddSingleStudent;
