import React, { useState } from "react";
import { axiosInstance } from "../Axios";

import { useContext, useRef } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";

import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";
import { confirmAlert } from "react-confirm-alert";
import Select from "react-select";

import * as XLSX from "xlsx";

import SampleFile from "../pages/SampleFile";

import Network_StudentList from "../components/Network_StudentList";
import { useLanguage } from "../components/language";
import toast from "react-hot-toast";
import axios from "axios";
import Navbar from "../components/Navbar";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import ReactModal from "react-modal";
export default function NetworkAddBulkStd() {
  const { t, changeLanguage, lanArabic } = useLanguage();
  const [schools, setSchools] = useState([]);
  const [students, setStudents] = useState([]);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);
  const userID = user["user_id"];
  const profileID = userID;
  const fileInputRef = useRef(null);

  const [loadingStudents, setLoadingStudents] = useState(true);

  const [profile, setProfile] = useState();
  const [currentNetwork, setCurrentNetwork] = useState(null); // Initialize the currentNetwork state

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedUser, setEditedUser] = useState(null);

  // Function to open modal and set the user being edited
  const handleEditUser = (index) => {
    setIsModalOpen(true);
    setEditedUser(users[index]);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setEditedUser(null);
  };

  // Function to handle changes to the edited user
  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  // JSX for the modal
  const modalContent = (
    <ReactModal isOpen={isModalOpen} onRequestClose={closeModal}>
      {editedUser && (
        <>
          <h2>Edit User</h2>
          <form>
            <label>
              First Name:
              <input
                type="text"
                name="firstName"
                value={editedUser.firstName}
                onChange={handleUserChange}
              />
            </label>
            <label>
              Last Name:
              <input
                type="text"
                name="lastName"
                value={editedUser.lastName}
                onChange={handleUserChange}
              />
            </label>
            {/* Add more input fields for other user properties */}
            <button type="submit">Save Changes</button>
          </form>
          <button onClick={closeModal}>Cancel</button>
        </>
      )}
    </ReactModal>
  );
  let url = "";
  if (user["role"] === "Student") {
    url = "profile";
  } else if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  }
  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/${url}/${profileID}`
        );
        const data = await response.json();
        setProfile(data);

        // Set the currentNetwork state once the profile data is fetched
        if (url === "network_profile") {
          setCurrentNetwork(data?.network_id); // Ensure data is not null before accessing the property
        } else if (url === "school_profile") {
          setCurrentNetwork(data?.school_id); // Ensure data is not null before accessing the property
        }
      } catch (err) {
        console.log("The requested profile does not exist.");
      }
    };

    getProfile();
  }, [profileID]);

  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const response = axios.get(`${process.env.REACT_APP_API_KEY}/api/roles/`);
    response.then((res) => {
      setRoles(res.data);
    });
  }, []);

  useEffect(() => {
    const response = axios.get(
      `${process.env.REACT_APP_API_KEY}/api/permissions/`
    );
    response.then((res) => {
      setPermissions(res.data);
    });
  }, []);

  const saveUsersToLocalStorage = (usersToSave) => {
    localStorage.setItem("users", JSON.stringify(usersToSave));
  };

  const handleDeleteUser = (index) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this user?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const updatedUsers = [...users];
            updatedUsers.splice(index, 1);
            setUsers(updatedUsers);
            saveUsersToLocalStorage(updatedUsers);
            toast.success("User deleted successfully!");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [selectedRoles, setSelectedRoles] = useState([]);
  const handleRoleChange = (selectedOptions, index) => {
    setUsers((prevUsers) =>
      prevUsers.map((user, i) =>
        i === index ? { ...user, selectedRoles: selectedOptions } : user
      )
    );
  };
  const handlePermissionChange = (selectedOptions, index) => {
    setUsers((prevUsers) =>
      prevUsers.map((user, i) =>
        i === index ? { ...user, selectedPermissions: selectedOptions } : user
      )
    );
  };

  useEffect(() => {
    if (currentNetwork) {
      // Fetch schools from the Django API
      fetch(`${process.env.REACT_APP_API_KEY}/api/schools/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const filteredSchools = data.filter(
            (school) =>
              parseInt(school.network) === parseInt(profile?.network_id)
          );
          setSchools(filteredSchools);
        })
        .catch((error) => {
          console.error("Error fetching schools:", error);
          setError(error.message);
        });
    }
  }, [currentNetwork]);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const storedUsers = localStorage.getItem("users");
    if (storedUsers) {
      const parsedUsers = JSON.parse(storedUsers);
      setUsers(parsedUsers);
    }
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const parsedUsers = XLSX.utils.sheet_to_json(sheet);

          // Log parsed users to the console
          console.log(parsedUsers);

          // Set the users state
          setUsers(parsedUsers);

          // Save data to localStorage
          localStorage.setItem("users", JSON.stringify(parsedUsers));

          // Show success toast
          toast.success("File uploaded successfully!");
          console.log("ROLES SET", roles);
        };

        reader.readAsBinaryString(file);
      } catch (error) {
        // Show error toast
        toast.error(
          "Error parsing the file. Please make sure it is a valid CSV/XLS file."
        );
      }
    }
  };
  const [selectedOption, setSelectedOption] = useState("");
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  let campus = 0;
  let network = 0;

  if (user["role"] === "Network Admin") {
    campus = selectedOption;
    network = currentNetwork;
  } else if (user["role"] === "School Admin") {
    campus = profile?.school_id;
    network = profile?.network;
  }
  const handleSubmit = () => {
    if (users.length === 0) {
      toast.error("No users to upload!");
      return;
    }
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/bulk_register/`, {
        users: users ?? [],

        school_id: campus,
        network_id: network,
      })
      .then((red) => {
        console.log(red.data);
        toast.success("Users uploaded successfully!");
        setUsers([]);
        localStorage.removeItem("users");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error uploading users!");
      });
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
    // users: users ?? []
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleDeleteAllUsers = () => {
    // Display confirmation dialog
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete all users?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // Clear users state and local storage
            setUsers([]);
            localStorage.removeItem("users");
            toast.success("All users deleted successfully!");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div className="flex gap-4 flex-row">
      {/* {campus} {network} */}
      <div className={`w-${users.length ? "2/5" : "full"}`}>
        <div>
          <>
            {/* Card Section */}
            <div className="mx-auto h-screen">
              {/* Card */}
              <div className="bg-white rounded-xl shadow p-4 sm:p-7 darki:bg-slate-900">
                <div className="mb-8">
                  <h2 className="text-xl font-bold text-gray-800 darki:text-gray-200">
                    Upload Students{" "}
                    <span className="text-gray-300 font-normal text-base ml-4">
                      {/* {step} of {totalsteps} */}
                    </span>
                  </h2>
                  <p className="text-sm text-gray-600 darki:text-gray-400">
                    Upload students to your school space
                  </p>
                </div>

                {/* Grid */}
                <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="af-account-email"
                      className="inline-block text-sm text-gray-800 mt-2.5 darki:text-gray-200"
                    >
                      Sample File
                    </label>
                  </div>
                  {/* End Col */}
                  <div className="sm:col-span-9">
                    <SampleFile />
                  </div>

                  <div className="sm:col-span-3">
                    <label className="inline-block text-sm text-gray-800 mt-2.5 darki:text-gray-200">
                      Upload File
                    </label>
                  </div>

                  {/* End Col */}

                  <div className="sm:col-span-9">
                    <div className="block w-full">
                      <div
                        className="flex gap-x-2"
                        style={{ width: "-moz-available" }}
                      >
                        <div class="flex items-center justify-center w-full">
                          <label
                            for="dropzone-file"
                            class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 darki:hover:bg-bray-800 darki:bg-gray-700 hover:bg-gray-100 darki:border-gray-600 darki:hover:border-gray-500 darki:hover:bg-gray-600"
                          >
                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                              <svg
                                class="w-10 h-10 mb-3 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                ></path>
                              </svg>
                              <p class="mb-2 text-sm text-gray-500 darki:text-gray-400">
                                <span class="font-semibold">
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </p>
                              <p class="text-xs text-gray-500 darki:text-gray-400">
                                XSLS (MAX. 2MB)
                              </p>
                            </div>
                            <input
                              id="dropzone-file"
                              type="file"
                              class="hidden"
                              onChange={handleFileChange}
                              ref={fileInputRef}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {(() => {
                    if (user["role"] === "Network Admin") {
                      return (
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="af-account-email"
                            className="inline-block text-sm text-gray-800 mt-2.5 darki:text-gray-200"
                          >
                            Select Campus
                          </label>
                        </div>
                      );
                    }
                  })()}

                  {(() => {
                    if (user["role"] === "Network Admin") {
                      return (
                        <div className="sm:col-span-9">
                          <select
                            className="select select-bordered w-full max-w-xs ml-10"
                            value={selectedOption}
                            onChange={handleSelectChange}
                          >
                              <option value="">
                                Select a campus
                              </option>
                            {schools.map((option, index) => (
                              <option key={index} value={option.school_id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      );
                    }
                  })()}

                  {(() => {
                    if (user["role"] === "Network Admin") {
                      return <div></div>;
                    }
                  })()}
                </div>
              </div>
              {/* End Card */}
            </div>
            {/* End Card Section */}
          </>
        </div>
      </div>

      {/* Conditionally render the right side only when users array is not empty */}
      {users.length > 0 && (
        <div className="w-3/5">
          <div className="mx-auto h-screen ">
            <div className="bg-white rounded-xl shadow p-4 sm:p-7 darki:bg-slate-900">
              {users.length > 0 && (
                <button
                  className="btn btn-sm bg-red-100 text-red-400"
                  onClick={handleDeleteAllUsers}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                    />
                  </svg>
                  Delete All
                </button>
              )}
              {users?.length
                ? users.map((user, index) => (
                    <div>
                      <div
                        key={index}
                        className="bg-[#f4effb52] p-4 rounded-xl my-4"
                      >
                        <div className="sm:col-span-9 w-full">
                          <div className="join my-4 gap-6 flex justify-between flex-row">
                            <div className="w-full">
                              <div className="mr-4">
                                <span className="text-sm ">
                                  <strong>{`${user["FIRST NAME"]} ${user["LAST NAME"]}`}</strong>{" "}
                                  <br />
                                  {user["Email"]}
                                </span>
                              </div>
                              <div className="flex gap-2 items-center mt-2">
                                <button
                                  // className=""
                                  onClick={() => handleEditUser(index)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                    />
                                  </svg>
                                </button>
                                {/* Delete button */}
                                <button
                                  className=""
                                  onClick={() => handleDeleteUser(index)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="text-red-500 w-5 h-5"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <div className="w-11/12">
                              <label>
                                <strong>Grade: </strong>
                              </label>{" "}
                              {user["Grade"]}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : null}

              <button className="btn btn-primary" onClick={handleSubmit}>
                Upload
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
