import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import SSMS_logo_white from '../media/SSMS_white.png';
import { Link } from "react-router-dom";
import { toast, Toaster } from 'react-hot-toast';
import { axiosInstance } from "../Axios";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
const ChangePasswordForm = () => {


  const {t} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  
  const lanArabic = i18n.language === 'ar'
const [isDrawerOpen, setIsDrawerOpen] = useState(false);


const [isDropdownOpen, setIsDropdownOpen] = useState(false);

const toggleDropdown = () => {
  setIsDropdownOpen(!isDropdownOpen);
};



  const [username, setUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  let { user } = useContext(AuthContext);
  const handleChange = (e) => {
    if (e.target.name === "username") {
      setUsername(e.target.value);
    } /*else if (e.target.name === "newPassword") {
      setNewPassword(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    }*/
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post( `${process.env.REACT_APP_API_KEY}/api/change_password/`, {
        username,
        //new_password: newPassword,
        //email,
      });

      if (response.status === 200) {
        setMessage("Email sent successfully!");
        toast.success('Confirmation email sent.', {
            icon: '📨',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });
      } else {
        setMessage("Error sending email.");
      }
    } catch (error) {
      setMessage("An error occurred.");
      console.error(error);
    }
  };

  const [loader, setloader] = useState(false);

  const handleClick = () => {
    setloader(true);

    // Simulate a delay of 4 seconds before hiding the spinner
    setTimeout(() => {
      setloader(false);
    }, 4000);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to mimic content loading
    setTimeout(() => {
      setLoading(false); // Set loading to false when content is loaded
    }, 2000); // Adjust the delay as needed
  }, []);

  return (
    <div>
      <div>
        {loading ? (
          <div className="flex items-center gap-4 justify-center h-screen">
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
          </div>
        ) : (
          // Your page content goes here
          <div>
            <main class="mt-0 transition-all duration-200 ease-in-out">
              <section>
                <div class="relative flex items-center min-h-screen p-0 overflow-hidden bg-center bg-cover">
                  <div class="container z-1">
                    <div class="flex flex-wrap -mx-3">
                      <div class="flex flex-col w-full max-w-full px-3 mx-auto lg:mx-0 shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-4/12">
                        <div class="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none lg:py4 rounded-2xl bg-clip-border">
                          <div className={`p-6 pb-0 mb-0 ${lanArabic ? 'rtl' : ''}`}>

                          <div className="alert mb-8">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-error shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
  <span>Forgot <strong>Username?</strong> Contact your school administration.</span>
</div>


                            <h4 class="font-bold">{t("Change Password")}</h4>
                            <p class="mb-0">
                              {t("Enter your details to change password")}
                            </p>
                          </div>
                          <div class="flex-auto p-6">
                            <form role="form" onSubmit={handleSubmit}>
                            {lanArabic ? (
                              <div class="mb-4 rtl">
                                <input
                                  type="text"
                                  name="username"
                                  value={username}
                                  onChange={handleChange}
                                  placeholder="أدخل اسم المستخدم"
                                  class="focus:shadow-primary-outline    text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                />
                              </div>):(

                                <div class="mb-4">
                                <input
                                  type="text"
                                  name="username"
                                  value={username}
                                  onChange={handleChange}
                                  placeholder="Enter Username"
                                  class="focus:shadow-primary-outline    text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                />
                              </div>

                              )}

                              {/* <div class="mb-4">
                                <input
                                  type="email"
                                  name="email"
                                  value={email}
                                  onChange={handleChange}
                                  placeholder="Enter Email address"
                                  class="focus:shadow-primary-outline    text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                />
                              </div>

                              <div class="mb-4">
                                <input
                                  type="text"
                                  name="newPassword"
                                  value={newPassword}
                                  onChange={handleChange}
                                  placeholder="Enter New Password"
                                  class="focus:shadow-primary-outline    text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                />
                              </div> */}

                              <div class="text-center">
                                <button
                                  type="submit"
                                  onClick={handleClick}
                                  disabled={loading}
                                  class="inline-block w-full px-16 py-3.5 mt-6 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-primary border-0 rounded-lg cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25"
                                >
                                  {loader ? (
                                    <div
                                      className="spinner-border text-light"
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    <div>{t("Reset Password")}</div>
                                  )}
                                </button>
                              </div>
                            </form>

                            <div class="border-black/12.5 rounded-b-2xl border-t-0 border-solid p-6 text-center pt-0 px-1 sm:px-6 mt-4">
                              <p class="mx-auto mb-6 leading-normal text-sm">
                                {t('Back to')}{" "}
                                <Link
                                  to="/login"
                                  class="font-semibold text-transparent bg-clip-text bg-gradient-to-tl from-blue-500 to-violet-500"
                                >
                                  {t('Sign In')}
                                </Link>
                              </p>
                            </div>

                            {message && <div>

                                <div className="flex shadow-md gap-6 rounded-lg overflow-hidden divide-x max-w-2xl bg-gray-50 text-gray-800 divide-gray-300">
	<div className="flex flex-1 flex-col p-4 border-l-8 border-violet-600">
		<span className="text-2xl">Message</span>
		<span className="text-xs text-gray-600">{message}.</span>
	</div>
</div>
                            
                            </div>}
                          </div>
                        </div>
                      </div>
                      <div class="absolute top-0 right-0 flex-col justify-center hidden w-6/12 h-full max-w-full px-3 pr-0 my-auto text-center flex-0 lg:flex">
          <div class="relative flex flex-col justify-center h-full bg-cover px-24 m-4 overflow-hidden bg-[url('https://res.cloudinary.com/ahbranding/image/upload/v1697657957/AHbranding/Group_34784350_xgb8gv.png')] rounded-xl ">
            <span class="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-blue-500 to-violet-500 opacity-60"></span>
            <img src={SSMS_logo_white} className='w-1/2 mb-4 mx-auto ' />
            <h4 class="z-20 mt-12 font-bold text-white">Welcome to SSMS</h4>
            <p class="z-20 text-white ">Revolutionizing education with innovative tools that capture every achievement, highlighting students’ contributions beyond the classroom, to help them unleash their full potential.
</p>
          </div>
        </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        )}
      </div>




      <div style={{ zIndex: 999999999999999}}>
        <Toaster position="bottom-center" />
      </div>
    </div>
  );
};

export default ChangePasswordForm;
