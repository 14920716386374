import React, { useContext, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import AdminLayout from "./AdminLayout";
import { createGlobalStyle } from "styled-components";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const StudentCouncilForm = () => {
  const [councilName, setCouncilName] = useState("");
  const [campus, setCampus] = useState([]);
  const [roles, setRoles] = useState([]);
  const [description, setDescription] = useState("");

  // Dummy data for campus and roles options
  const campusOptions = [
    { label: "Campus A", value: "campus_a" },
    { label: "Campus B", value: "campus_b" },
    { label: "Campus C", value: "campus_c" },
  ];

  const roleOptions = [
    { label: "Head Boy", value: "head_boy" },
    { label: "Head Girl", value: "head_girl" },
    { label: "Deputy Head Boy", value: "deputy_head_boy" },
    { label: "Deputy Head Girl", value: "deputy_head_girl" },
    { label: "House Captain", value: "house_captain" },
    { label: "Sports Leader (Boy)", value: "sports_leader_boy" },
    { label: "Sports Leader (Girl)", value: "sports_leader_girl" },
    { label: "Vice Captain", value: "vice_captain" },
    { label: "Prefect", value: "prefect" },
  ];

  const GlobalStyle = createGlobalStyle`
  .rmsc .dropdown-container{
    padding: 0 !important;
  }
`;

  const [selectedSchool, setselectedSchool] = useState("");
  const [selected, setSelected] = useState([]);
  const handleSelectionChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value);
    setSelected(selectedIds);
  };
  const handleSelectChange = (event) => {
    setselectedSchool(event.target.value);
  };
  const [network, setNetwork] = useState("");

  const [schools, setSchools] = useState([]);
  let { user } = useContext(AuthContext);

  let profileID = 0;

  if(user[`role`]===`Parent`){
    profileID = 12;
  } else{
    profileID = user["user_id"];
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`)
      .then((res) => {
        setNetwork(res?.data?.network_id);
      });
  }, [profileID]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_KEY}/api/schools/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const filteredSchools = data.filter(
          (school) => school.network === network
        );
        setSchools(filteredSchools);
      })
      .catch((error) => {
        console.error("Error fetching schools:", error);
      });
  }, [network]);

  const [facultyAdvisors, setFacultyAdvisors] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/faculty/${selectedSchool}/`)
      .then((res) => {
        setFacultyAdvisors(res?.data);
      });
  }, [selectedSchool]);
  const [faculty, setFaculty] = useState([]);

  const handleSubmit = (e) => {
    // Handle form submission here
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/student_council/`, {
        name: councilName,
        school: selectedSchool,
        roles: roles,
        description: description,
        faculty: faculty,
      })
      .then((res) => {
        toast.success("Student council created successfully!");
        console.log(res.data);
        setCouncilName("");
        setselectedSchool("");
        setRoles([]);
        setFaculty([]);
        setDescription("");
      })
      .catch((err) => {
        toast.error("Error creating student council:");
      });

    console.log("Form submitted:", { councilName, campus, roles, description });
  };

  return (
    <AdminLayout>
      <GlobalStyle />
      <div className="w-full mx-auto">
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="councilName"
          >
            Council Name
          </label>
          <input
            className="input input-bordered  w-full"
            id="councilName"
            type="text"
            placeholder="Enter council name"
            value={councilName}
            onChange={(e) => setCouncilName(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="description"
          >
            Council Description
          </label>
          <textarea
            className="input input-bordered w-full p-4 h-32"
            id="description"
            placeholder="Enter council description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="flex flex-col ">
          <label className="flex justify-between text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
            <span>Campus</span>
          </label>

          <select
            className="select select-bordered w-full "
            value={selectedSchool}
            onChange={handleSelectChange}
          >
            <option selected disabled value="">
              Select a campus
            </option>

            {schools.map((option, index) => (
              <option key={index} value={option.school_id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col">
          <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
            Faculty Advisor(s)
          </label>

          <MultiSelect
            className=""
            options={facultyAdvisors.map((faculty) => ({
              value: faculty.id,
              label: faculty.first_name + " " + faculty.last_name,
            }))}
            value={faculty}
            onChange={setFaculty}
            labelledBy="Select"
            renderOption={({ label, value, avatar }) => (
              <div>
                {avatar && (
                  <img
                    src={avatar}
                    alt={`${label}'s Avatar`}
                    style={{
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                      marginRight: "8px",
                    }}
                    crossOrigin="anonymous"
                  />
                )}
                {label}
              </div>
            )}
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="roles"
          >
            Roles
          </label>
          <MultiSelect
            options={roleOptions}
            value={roles}
            onChange={setRoles}
            labelledBy="Select Roles"
            disableSearch={true}
            className="p-0"
            overrideStrings={{ selectSomeItems: "Select Roles" }}
          />
        </div>
        <div className="flex items-center justify-start">
          <button
            className="btn btn-primary"
            type="submit"
            onClick={handleSubmit}
          >
            Create Council
          </button>
        </div>
        <div style={{ zIndex: 999999999999999, marginBottom: "2vh" }}>
          <Toaster position="bottom-center" />
        </div>
      </div>
    </AdminLayout>
  );
};

export default StudentCouncilForm;