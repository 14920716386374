import { format } from "date-fns";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import axios from "axios";
import toast from "react-hot-toast";

function EventList({ events, onDeleteEvent }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [editEventId, setEditEventId] = useState(null);

  const handleEditClick = (event) => {
    setSelectedEvent(event);
    setStartDate(event.start);
    setEndDate(event.end);
    setEditEventId(event.id);
    setShowEditModal(true);
  };

  const handleConfirmChanges = () => {
    // Handle confirming changes here
    // You can perform any actions needed with startDate and endDate
    handleEdit(editEventId);
    setShowEditModal(false);
  };

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_KEY}/api/delete_schedule/${id}/`)
      .then((res) => {
        onDeleteEvent(id);
        toast.success("Schedule Deleted Successfully!");
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  const handleEdit = (id) => {
    axios
      .put(`${process.env.REACT_APP_API_KEY}/api/update_schedule/${id}/`, {
        start_date: startDate,
        end_date: endDate,
      })
      .then((res) => {
        toast.success("Schedule Updated Successfully!");
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <ul className="flex flex-col divide-y divide-gray-200 darki:divide-gray-700 w-full">
      {events.map((event) => (
        <div
          key={event.id}
          className="flex flex-col gap-x-2 py-3 px-4 text-sm font-medium text-gray-800 darki:text-white transition-colors duration-300"
        >
          <Link to={"/event_details"} className="flex flex-col justify-start text-start gap-x-2 items-start">
            <span className="font-semibold">{event.title}</span>
            <span className="font-normal text-gray-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                ></path>
              </svg>
              {format(event.start, "MMM dd, yyyy HH:mm")}
            </span>
          </Link>
          <div className="flex gap-x-2 mt-2">
            <button
              onClick={() => handleEditClick(event)}
              className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
  <path d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
  <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
</svg>

            </button>
            <button
              className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={() => handleDelete(event?.id)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
  <path fill-rule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clip-rule="evenodd" />
</svg>

            </button>
          </div>
        </div>
      ))}
      <ReactModal
        className="w-5/12 h-min m-auto mt-8 rounded-xl bg-white"
        overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-opacity-50 bg-black"
        isOpen={showEditModal}
        onRequestClose={() => setShowEditModal(false)}
      >
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Edit Event</h2>
            <button
              className="btn btn-error btn-circle"
              onClick={() => setShowEditModal(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          {selectedEvent && (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Previous Start Date
                </label>
                <p className="mt-1 text-sm text-gray-600">
                  {format(selectedEvent.start, "MMM dd, yyyy HH:mm")}
                </p>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Previous End Date
                </label>
                <p className="mt-1 text-sm text-gray-600">
                  {format(selectedEvent.end, "MMM dd, yyyy HH:mm")}
                </p>
              </div>
            </>
          )}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              New Start Date
            </label>
            <input
              type="datetime-local"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              New End Date
            </label>
            <input
              type="datetime-local"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
            />
          </div>
          <button
            onClick={handleConfirmChanges}
            className="btn btn-primary mt-6"
          >
            Confirm Changes
          </button>
        </div>
      </ReactModal>
    </ul>
  );
}

export default EventList;
