import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import toast from "react-hot-toast";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import SideInnerSM from "./SideInnerSM";
import { FaMobileButton } from "react-icons/fa6";
import MobileBottom from '../components/MobileBottom'
import { useLanguage } from "./language";

function StudentProfile() {
  const { user } = useContext(AuthContext);
  const profileID = user["user_id"];
  const { t, changeLanguage, lanArabic } = useLanguage();
  let isMyself = true;
  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  const [profile, setProfile] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`)
      .then((response) => {
        setProfile(response.data);
        setProfileData(response.data);
      })

      .catch((err) => {
        console.log("Error: ", err);
      });
  }, []);

  const [profileData, setProfileData] = useState({
    profileID: profileID,
    name: "",
    lastname: "",
    firstname: "",
    bio: "",
    status: "",
    contact: "",
    city: "",
    grade: "",
    branch: "",
    school: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Update the database with new data
    const dataToSend = {
      ...profile,
      name: profile.name,
      firstname: profileData.firstname,
      lastname: profileData.lastname,
      bio: profileData.bio,
      status: profileData.status,
      contact: profileData.contact,
      city: profileData.city,
      grade: profileData.grade,
      branch: profileData.branch,
      school: profileData.school,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/update_profile/${profileID}/`,
        dataToSend
      )
      .then((res) => {
        toast.success("Profile Updated Successfully");
      })
      .catch((err) => {
        toast.error("Error in updating profile");
      });
  };

  const handlefileChange = (e) => {
    setfile(e.target.files[0]);
  };
  const [file, setfile] = useState("");

  const handleFileSubmit = (e) => {
    e.preventDefault();
    if (file) {
      const form = new FormData();
      form.append("profile_picture", file);
      form.append("name", profileData.profileID);
      axios
        .post(
          `${process.env.REACT_APP_API_KEY}/api/upload_profile_pic/${profileID}/`,
          form
        )
        .then((response) => {
          console.log(response.data);
        })
        .then((res) => {
          toast.success("Profile Pic Updated Successfully");
          window.location.reload();
        })
        .catch((err) => {
          toast.error("Failed To Update Profile Picture");
        });
    }
  };
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };
  return (
    <div>
      <div>
        <>
          <div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>

          <Sidebar />

          {isSidebarVisible && <SideInnerSM />}

          <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? 'xl:mr-68' : 'xl:ml-68'} rounded-xl`}>
            {/* Navbar */}
            <Navbar toggleSidebar={toggleSidebar} />

            {/* end Navbar */}
            {/* cards */}
            <div className="w-full px-6 py-6 mx-auto">
              <div class="relative w-full mx-auto  ">
                <div class="relative flex flex-col flex-auto min-w-0 p-4 mx-6 overflow-hidden break-words bg-white border-0  shadow-3xl rounded-2xl bg-clip-border">
                  <div class="flex flex-wrap -mx-3">
                    <div class="flex-none w-auto max-w-full px-3">
                      <div class="relative inline-flex items-center justify-center text-white transition-all duration-200 ease-in-out text-base h-19 w-19 rounded-xl">
                        {/* <img
                          src={`${process.env.REACT_APP_API_KEY}/api/media/profile_picture/${profile.avatar}/`}
                          alt="profile_image"
                          class="w-full shadow-2xl rounded-xl"
                        /> */}

                        <img
                          src={profile.avatar}
                          alt="profile_image"
                          class="w-full shadow-2xl rounded-xl"
                        />
                      </div>
                    </div>
                    <div class="flex-none w-auto max-w-full px-3 my-auto">
                      <div class="h-full">
                        <h5 class="mb-1 ">
                          {profile.firstname} {profile.lastname}
                        </h5>
                        <p class="mb-0 font-semibold leading-normal   text-sm">
                          @{user?.username}
                        </p>
                      </div>
                    </div>
                    <div class="w-full  px-3 mx-auto mt-4 sm:my-auto sm:mr-0 md:w-1/2 md:flex-none lg:w-4/12">
                      <select
                        id="statusSelector"
                        className="select select-bordered"
                        name="active_status"
                        value={profile.status}
                        onChange={handleChange}
                      >
                        <option value="Available 😊">Available 😊</option>
                        <option value="Busy ⚒️">Busy ⚒️</option>
                        <option value="In a meeting 📺">In a meeting 📺</option>
                        <option value="Away ✈️">Away ✈️</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full p-6 mx-auto">
                <div class="flex flex-wrap -mx-3">
                  <div class="w-full max-w-full px-3 shrink-0 md:w-8/12 md:flex-0">
                    <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                      <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                        <div class="flex items-center">
                          <p class="mb-0 /80">Edit Profile</p>
                          <button
                            type="submit"
                            onClick={handleSubmit}
                            class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                          >
                            Update Data
                          </button>
                        </div>
                      </div>
                      <div class="flex-auto p-6">
                        <p class="leading-normal uppercase   text-sm">
                          User Information
                        </p>
                        <div class="flex flex-wrap -mx-3">
                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0 hidden">
                            <div class="mb-4">
                              <label
                                for="username"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Username
                              </label>
                              <input
                                type="text"
                                name="name"
                                disabled
                                value={profileData.name}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>
                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="email"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Email address
                              </label>
                              <input
                                type="email"
                                disabled
                                name="email"
                                value={user?.email}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>
                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="firstname"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                First Name
                              </label>
                              <input
                                type="text"
                                name="firstname"
                                //value={profileData.firstname}
                                value={profileData.firstname}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="lastname"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Last Name
                              </label>
                              <input
                                type="text"
                                name="lastname"
                                value={profileData.lastname}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="contact"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Contact
                              </label>
                              <input
                                type="text"
                                name="contact"
                                value={profileData.contact}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="grade"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Grade
                              </label>
                              <input
                                type="text"
                                name="grade"
                                value={profileData.grade}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="city"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                City
                              </label>
                              <input
                                type="text"
                                name="city"
                                value={profileData.city}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="city"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                School
                              </label>
                              <input
                                type="text"
                                name="school"
                                value={profileData.school}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="city"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Branch
                              </label>
                              <input
                                type="text"
                                name="branch"
                                value={profileData.branch}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>
                        </div>
                        <hr class="h-px mx-0 my-4 bg-transparent border-0 opacity-25 bg-gradient-to-r from-transparent via-black/40 to-transparent  " />

                        <div>
                          {/* ...Other profile fields... */}
                          <div className="mb-6">
                            <label
                              htmlFor="profile_photo"
                              className="block text-sm font-semibold text-gray-600"
                            >
                              Profile Photo
                            </label>
                            <input
                              type="file"
                              name="file"
                              onChange={handlefileChange}
                              className="mt-2 border border-gray-300 py-2 px-3 rounded-lg w-full focus:outline-none focus:ring focus:border-blue-400"
                            />
                          </div>

                          {/* ...Other profile fields... */}

                          <button
                            type="submit"
                            onClick={handleFileSubmit}
                            class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                          >
                            Upload Photo
                          </button>
                          {/* ...Other buttons... */}
                        </div>

                        <hr class="h-px mx-0 my-4 bg-transparent border-0 opacity-25 bg-gradient-to-r from-transparent via-black/40 to-transparent  " />

                        <p class="leading-normal uppercase   text-sm">
                          About me
                        </p>
                        <div class="flex flex-wrap -mx-3">
                          <div class="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0">
                            <div class="mb-4">
                              <label
                                for="about me"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                About me
                              </label>
                              <textarea
                                type="text"
                                onChange={handleChange}
                                value={profile.bio}
                                name="bio"
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full hidden md:block max-w-full px-3 mt-6 shrink-0 md:w-4/12 md:flex-0 md:mt-0 ">
                    <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                      <img
                        class="w-full rounded-t-2xl"
                        src="https://t3.ftcdn.net/jpg/06/04/80/16/360_F_604801664_HSbqxVq1qWoO5C8D1ls1PwcT9GTWgFhj.jpg"
                        alt="profile cover image"
                      />
                      <div class="flex flex-wrap justify-center -mx-3">
                        <div class="w-4/12 max-w-full px-3 flex-0 ">
                          <div class="mb-6 -mt-6 lg:mb-0 lg:-mt-16">
                            <a href="javascript:;">
                              <img
                                class="h-auto max-w-full border-2 border-white border-solid rounded-circle"
                                src={profile.avatar}
                                alt="profile image"
                              />
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="flex-auto p-6 pt-0">
                        <div class="mt-6 text-center">
                          <h5 class=" ">
                            {profile?.firstname} {profile?.lastname}
                            <span class="font-light"></span>
                          </h5>
                          <div class="mb-2 font-semibold leading-relaxed text-base /80 text-slate-700">
                            @{user?.username}
                          </div>

                          <div class="/80">{profile.bio}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      </div>

      <MobileBottom/>
    </div>
  );
}

export default StudentProfile;