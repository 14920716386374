import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { Link, useParams } from 'react-router-dom';
// import Avatar from 'boring-avatars';
import Calendar from "react-calendar";
import MobileBottom from '../components/MobileBottom';
import { toast, Toaster } from 'react-hot-toast';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import SearchInput from './SearchInput';
import NewModerator from './NewModerator';
const ModeratorAdmin = () => {
  const [timelineData, setTimelineData] = useState([]);
  let { user } = useContext(AuthContext);
  let userID = user['user_id'];
  let params = useParams();
  let profileID = userID;
  const [profile, setProfile] = useState();
  const [leadScore,setLeadScore] = useState('');
  const [leadFeadBack,setLeadFeedBack] = useState('');
  const [societiesScore,setSocietiesScore] = useState('')
  const [societiesFeedback,setSocietiesFeedback] = useState('')
  const [sportScore,setSportScore] = useState('')
  const [sportFeedback,setSportFeedback] = useState('')
  const [eventScore,setEventScore] = useState('')
  const [eventFeedback,setEventFeedback] = useState('')


  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`);
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  


  const [showPending, setshowPending] = useState(true);
  const [showChecked, setshowChecked] = useState(false);

  const handlePending = event => {
    setshowPending(current => !current);
    setshowChecked(false);
  };

  const handleChecked = event => {
    setshowPending(false);
    setshowChecked(current => !current);
  };


  const [leadershipData, setLeadershipData] = useState([]);
  const [societiesData, setSocietiesData] = useState([]);
  const [sportsData, setSportsData] = useState([]);
  const [eventsData, setEventsData] = useState([]);



  const fetchLeaderShipData = async () => {
    try {
      const leadershipResponse = await axios.get(`${process.env.REACT_APP_API_KEY}/api/get_lead/`);
      const leadershipDataFiltered = leadershipResponse.data.filter((activity) => parseInt(activity.school_in) === 8);
      setLeadershipData(leadershipDataFiltered);

      
    } catch (err) {
      console.log("Error fetching data for tabs:", err);
    }
  };

  const fetchSocietyData = async () => {
    try {
      const societiesResponse = await axios.get(`${process.env.REACT_APP_API_KEY}/api/get_society/`);
      const societiesDataFiltered = societiesResponse.data.filter((activity) => parseInt(activity.school_in) === 8);
      setSocietiesData(societiesDataFiltered);


      
    } catch (err) {
      console.log("Error fetching data for tabs:", err);
    }
  };

  const fetchSportsData = async () => {
    try {
      const sportsResponse = await axios.get(`${process.env.REACT_APP_API_KEY}/api/get_sports/`);
      const sportsDataFiltered = sportsResponse.data.filter((activity) => parseInt(activity.school_in) === 8);
      setSportsData(sportsDataFiltered);


      
    } catch (err) {
      console.log("Error fetching data for tabs:", err);
    }
  };
  const fetchEventData = async () => {
    try {
      const eventsResponse = await axios.get(`${process.env.REACT_APP_API_KEY}/api/get_eventStd/`);
      const eventsDataFiltered = eventsResponse.data.filter((activity) => parseInt(activity.school_in) === 8);
      setEventsData(eventsDataFiltered);

      
    } catch (err) {
      console.log("Error fetching data for tabs:", err);
    }
  };


 

  useEffect(() => {
    fetchLeaderShipData();
    fetchSocietyData();
    fetchSportsData();
    fetchEventData();
  }, []); // Trigger the fetch whenever profile?.school_in changes

  //ACCEPT REJECT FOR LEADACTIVITY

  const handleApproveLeadActivity = async(index,activity) => {
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_KEY}/api/update_lead/${activity?.id}/`, {
          status: "1",
          feedback: leadFeadBack?.[index],
          score:leadScore?.[index],
          activity_name: activity.activity_name,
          duration: activity.duration,
          grade: activity.grade,
          scope: activity.scope,
          service_hours: activity.service_hours,
          posted_by: activity.posted_by,
           post_date: activity.post_date,
          school_in: activity.school_in,
          start_date: activity.start_date,
          category: activity.category,
          // Include other fields here, but don't modify them
        });
        console.log('Activity updated successfully:', response.data);
        toast.success('Good Job! Activity Saved', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        
        setLeadScore('')
        setLeadFeedBack('')
        fetchLeaderShipData()
  
      } catch (error) {
        console.log('Error updating activity:', error);
      }
     
  } 
  const handleRejectLeadActivity = async(index,activity) => {
     
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_KEY}/api/update_lead/${activity?.id}/`, {
          status: "2",
          feedback: leadFeadBack?.[index],
          score:leadScore?.[index],
          activity_name: activity.activity_name,
          duration: activity.duration,
          grade: activity.grade,
          scope: activity.scope,
          service_hours: activity.service_hours,
          posted_by: activity.posted_by,
           post_date: activity.post_date,
          school_in: activity.school_in,
          start_date: activity.start_date,
          category: activity.category,
          // Include other fields here, but don't modify them
        });
        console.log('Activity updated successfully:', response.data);
        toast.success('Good Job! Activity Saved', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        setLeadScore('')
        setLeadFeedBack('')
        fetchLeaderShipData()
  
      } catch (error) {
        console.log('Error updating activity:', error);
      }
     
  } 



//ACCEPT REJECT FOR SOCIETIES

const handleApproveSocietyActivity = async(index,activity) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_KEY}/api/update_society/${activity?.id}/`, {
      status: "1",
      feedback: societiesFeedback?.[index],
      score:societiesScore?.[index],
      activity_name: activity.activity_name,
      duration: activity.duration,
      grade: activity.grade,
      role: activity.role,
      service_hours: activity.service_hours,
      posted_by: activity.posted_by,
      post_date: activity.post_date,
      school_in: activity.school_in,
      start_date: activity.start_date,
      category: activity.category,
      // Include other fields here, but don't modify them
    });
    console.log('Activity updated successfully:', response.data);
    toast.success('Good Job! Activity Saved', {
      icon: '👏',
      style: {
        borderRadius: '55px',
        marginBottom: '15px',
      },
    });
    setSocietiesScore('')
    setSocietiesFeedback('')
    fetchSocietyData();

  } catch (error) {
    console.log('Error updating activity:', error);
  }
 
}

const handleRejectSocietyActivity = async(index,activity) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_KEY}/api/update_society/${activity?.id}/`, {
      status: "2",
      feedback: societiesFeedback?.[index],
      score:societiesScore?.[index],
      activity_name: activity.activity_name,
      duration: activity.duration,
      grade: activity.grade,
      role: activity.role,
      service_hours: activity.service_hours,
      posted_by: activity.posted_by,
      post_date: activity.post_date,
      school_in: activity.school_in,
      start_date: activity.start_date,
      category: activity.category,
      // Include other fields here, but don't modify them
    });
    console.log('Activity updated successfully:', response.data);
    toast.success('Good Job! Activity Saved', {
      icon: '👏',
      style: {
        borderRadius: '55px',
        marginBottom: '15px',
      },
    });
    setSocietiesScore('')
    setSocietiesFeedback('')
    fetchSocietyData();

  } catch (error) {
    console.log('Error updating activity:', error);
  }
 
}


//ACCEPT REJECT FOR Sport

const handleApproveSportActivity = async(index,activity) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_KEY}/api/update_sports/${activity?.id}/`, {
      status: "1",
      feedback: sportFeedback?.[index],
      score:sportScore?.[index],
      activity_name: activity.activity_name,
        duration: activity.duration,
        grade: activity.grade,
        role: activity.role,
        scope: activity.scope,
        service_hours: activity.service_hours,
        posted_by: activity.posted_by,
        post_date: activity.post_date,
        school_in: activity.school_in,
        start_date: activity.start_date,
        category: activity.category,
      // Include other fields here, but don't modify them
    });
    console.log('Activity updated successfully:', response.data);
    toast.success('Good Job! Activity Saved', {
      icon: '👏',
      style: {
        borderRadius: '55px',
        marginBottom: '15px',
      },
    });
    setSportScore('')
    setSportFeedback('')
    fetchSportsData();

  } catch (error) {
    console.log('Error updating activity:', error);
  }
 
}

const handleRejectSportActivity = async(index,activity) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_KEY}/api/update_sports/${activity?.id}/`, {
      status: "2",
      feedback: sportFeedback?.[index],
      score:sportScore?.[index],
      activity_name: activity.activity_name,
      duration: activity.duration,
      grade: activity.grade,
      role: activity.role,
      scope: activity.scope,
      service_hours: activity.service_hours,
      posted_by: activity.posted_by,
      post_date: activity.post_date,
      school_in: activity.school_in,
      start_date: activity.start_date,
      category: activity.category,
      // Include other fields here, but don't modify them
    });
    console.log('Activity updated successfully:', response.data);
    toast.success('Good Job! Activity Saved', {
      icon: '👏',
      style: {
        borderRadius: '55px',
        marginBottom: '15px',
      },
    });
    setSportScore('')
    setSportFeedback('')
    fetchSportsData();

  } catch (error) {
    console.log('Error updating activity:', error);
  }
 
}


//ACCEPT REJECT FOR Event

const handleApproveEventActivity = async(index,activity) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_KEY}/api/update_eventStd/${activity?.id}/`, {
      status: "1",
      feedback: eventFeedback?.[index],
      score:eventScore?.[index],
      activity_name: activity.activity_name,
      duration: activity.duration,
      grade: activity.grade,
      role: activity.role,
      scope: activity.scope,
      service_hours: activity.service_hours,
      posted_by: activity.posted_by,
      post_date: activity.post_date,
      school_in: activity.school_in,
      start_date: activity.start_date,
      category: activity.category,
      // Include other fields here, but don't modify them
    });
    console.log('Activity updated successfully:', response.data);
    toast.success('Good Job! Activity Saved', {
      icon: '👏',
      style: {
        borderRadius: '55px',
        marginBottom: '15px',
      },
    });
    setEventScore('')
    setEventFeedback('')
    fetchEventData();
  } catch (error) {
    console.log('Error updating activity:', error);
  }
 
}

const handleRejectEventActivity = async(index,activity) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_KEY}/api/update_eventStd/${activity?.id}/`, {
      status: "2",
      feedback: eventFeedback?.[index],
      score:eventScore?.[index],
      activity_name: activity.activity_name,
      duration: activity.duration,
      grade: activity.grade,
      role: activity.role,
      scope: activity.scope,
      service_hours: activity.service_hours,
      posted_by: activity.posted_by,
      post_date: activity.post_date,
      school_in: activity.school_in,
      start_date: activity.start_date,
      category: activity.category,
      // Include other fields here, but don't modify them
    });
    console.log('Activity updated successfully:', response.data);
    toast.success('Good Job! Activity Saved', {
      icon: '👏',
      style: {
        borderRadius: '55px',
        marginBottom: '15px',
      },
    });
    setEventScore('')
    setEventFeedback('')
    fetchEventData();

  } catch (error) {
    console.log('Error updating activity:', error);
  }
 
}



  function getPositionLabel(position) {
    const positionMapping = {
      1: "Participation",
      1.1: "3rd",
      1.2: "2nd",
      1.5: "1st",
    };
  
    return positionMapping[position] || "-";
  }
  
  
  function getLangProfLabel(prof) {
    const langProfMapping = {
      1: "Beginner",
      1.2: "Early Intermediate",
      1.23: "Intermediate",
      1.24: "Fluent",
      1.5: "Native",
    };
  
    return langProfMapping[prof] || "-";
  }
  
  
  function getScopeLabel(scope) {
    const scopeMapping = {
      1: "School",
      1.2: "Local",
      1.5: "International",
    };
  
    return scopeMapping[scope] || "-";
  }
  
  function getDurationLabel(duration) {
    const durationMapping = {
      0.1: "1 month",
      0.3: "3 months",
      0.6: "6 months",
      0.9: "9 months",
      1.2: "12 months",
    };
  
    return durationMapping[duration] || "-";
  }
  
  function getSocialRoleLabel(role) {
    const roleMapping = {
      1: "Volunteer",
      1.2: "Organized",
      1.5: "Donated",
    };
  
    return roleMapping[role] || "-";
  }
  
  function getLeadershipScopeLabel(scope) {
    const scopeMapping = {
      1: "Class",
      1.2: "Section",
      1.51: "House",
      1.52: "School",
    };
  
    return scopeMapping[scope] || "-";
  }
  
  function getSocietyRoleLabel(role) {
    const roleMapping = {
      1: "President",
      1.2: "Vice President",
      1.5: "Member",
    };
  
    return roleMapping[role] || "-";
  }
  
  function getEngagementLabel(engagement) {
    const engagementMapping = {
      1: "Light",
      1.2: "Moderate",
      1.4: "Heavy",
      1.5: "Extra",
    };
  
    return engagementMapping[engagement] || "-";
  }
  
  function getLeadershipActivityLabel(name) {
    const labelMap = {
      9: "Student Council General Secretary",
      8.01: "Deputy Head Student",
      8.02: "House Captain",
      8.03: "Class Prefect",
      8.04: "Captain Sports Team",
      7: "Class Monitor",
      10: "Head Student",
    };
  
    return labelMap[name] || "-";
  }
  
  function getSocietyNameLabel(name) {
    const labelMap = {
      8: "STEM/STEAM Society",
      8.02: "Art Society",
      8.01: "Music Society",
      8.03: "Debates Society",
      8.04: "Drama/Theater Society",
      8.05: "Math Society",
      8.06: "Literature Society",
      8.07: "Environment Society",
      8.08: "Community Service Society",
      8.09: "Media & Publications Society",
      7: "Astronomy Society",
      8.1: "Sports Society",
      7.02: "Choir Society",
      7.03: "Cooking Society",
      7.04: "Yoga Club",
      7.05: "Book Club",
      7.06: "School Media Club",
      7.07: "Cardio Club",
      7.01: "Film Society",
      8.2: "School Magazine",
    };
    return labelMap[name] || "-";
  }
  
  function getSocialActivityLabel(name) {
    const labelMap = {
      8: "Clothes drive",
      8.01: "Charity collection",
      9: "Blood donation drive",
      9.01: "Hospital volunteer",
      8.02: "Trash clean-up",
      8.03: "Charity bake sale",
      8.04: "Food drive",
    };
    return labelMap[name] || "-";
  }
  
  
  function getEventManagementLabels(name) {
    const labelMap = {
      9: "Executive Council",
      8: "Logistics Team",
      8.1: "Registrations Team",
      8.2: "Socials Team",
      8.3: "Publications Team",
      8.4: "Finance Team",
      8.5: "Promotions Team",
      8.6: "Communications Team",
      8.7: "IT Team",
      8.8: "Security Team",
      8.9: "Operations Team",
    };
    return labelMap[name] || "-";
  }
  
  
  function getTalentActivityLabel(name) {
    const labelMap = {
      7: "Videography",
      7: "Gaming",
      7: "SEO",
      7: "GFX",
      7: "Kni,tting/Crochet",
      7: "Resin art",
      7: "Embroidery",
      7: "Photography",
      7: "Painting",
      7: "Drawing",
      7: "Sculpture",
      7: "Graphic Design",
      7: "Writing",
      7: "Reading",
      7: "Makeup",
      7: "Cooking",
      7: "Photography",
      7: "Piano",
  
      7: "Ukulele",
      7: "Violin",
      7: "Singing",
      7: "Flute",
      7: "Guitar",
      7: "Cycling",
      7: "Editing",
      7: "Jewellery Making",
      7: "Dancing",
      7: "Cardio",
      7: "Weightlifting",
      7: "Football",
      7: "Cricket",
      7: "Swimming",
      7: "Basketball",
  
      7: "Poetry",
      7: "Hockey",
      7: "Tennis",
      7: "Ballet",
      7: "Football",
      7: "Table Tennis",
      7: "Chess",
      7: "Horseriding",
  
      7: "Skating",
      7: "Social Media Influencer",
  
      7: "Badminton"
    };
    return labelMap[name] || "-";
  }
  
  
  function getAwardsLabel(name) {
    const awardsMapping = {
      10: "Full attendance",
      10.01: "Academic excellence",
      10.02: "Sports scholarship",
      10.03: "Merit scholarship",
      10.04: "Research grant",
      7: "Poem Recitation",
      7.01: "Spoken Word Contest",
      8: "Play/Musical",
      7.02: "Choir Performance",
      7.03: "Aerobics Competition",
      7.04: "Bake Sale",
      7.05: "Artbeat",
      8.01: "Debates Competition",
      8.02: "School/college MUN",
      8.03: "Math Competition",
      7.06: "Trivia Competition",
      8.04: "Science Competition",
      8.05: "Science Fair",
      7.07: "Dance Competition",
      8.04: "Short Film Competition",
      8.05: "Robotics Competition",
      7.08: "Poster Making Competition",
      7.09: "Gaming Competition",
      7.10: "Fashion Design Competition",
      7.11: "Cooking Competition",
      7.12: "Modeling Competition",
      7.13: "Talent Show",
      8.06: "Handwriting Competition",
      8.07: "Essay Competition",
      7.14: "Acting Competition",
      8.08: "Drawing Competition",
      8.09: "Photography Competition",
      8.10: "Painting Competition",
      8.11: "Composition Competition",
      6: "Lucky Draw",
      7.15: "Spelling Competition",
      10.05: "Certificate of Achievement",
    };
  
    return awardsMapping[name] || "-";
  }
  
  
  function getSportsLabel(name) {
    const sportsMapping = {
      7: "Basketball",
      7.01: "Cricket",
      7.02: "Hockey",
      7.03: "Badminton",
      7.04: "Tennis",
      7.05: "Throwball",
      7.06: "Gymnastics",
      7.07: "Futsal",
      7.08: "Archery",
      7.09: "Cycling",
      7.10: "Sprint",
      7.2: "Football",
      7.3: "Relay race",
      7.4: "Baseball",
      7.5: "Fencing",
      7.6: "Golf",
      7.7: "Handball",
      7.8: "Netball",
      7.9: "Rowing",
      7.10: "Skateboarding",
      7.11: "Road bicycle racing",
      7.411: "Ballet",
      7.12: "Kabaddi",
      7.13: "Kickboxing",
      7.14: "Boxing",
      7.15: "Strongman",
      7.16: "Lacrosse",
      7.17: "Polo",
      7.18: "Water Polo",
      7.19: "Long Jump",
      7.20: "High Jump",
      7.21: "Shot put",
      7.22: "Pole Vault",
      7.23: "Pickleball",
      8: "Chess",
      7.24: "Track & field",
      7.25: "Curling",
      7.26: "Endurance running",
      7.27: "Hurdle races",
      7.28: "Ice skating",
      7.29: "Surfing",
      7.30: "Skiing",
      7.31: "Snowboarding",
      7.32: "Dodgeballt",
      7.33: "Climbing",
      7.34: "Snooker",
      7.35: "Squash",
      7.36: "Swimming",
      7.37: "Volleyball",
      7.38: "Weightlifting",
      7.39: "Rugby",
    };
  
    return sportsMapping[name] || "-";
  }
  
  function getInternshipTypeLabel(name) {
    const internshipTypeMapping = {
      1: "Part Time Internship",
      1.2: "Virtual Internship",
      1.5: "On-site Internship",
    };
    return internshipTypeMapping[name] || "-";
  }
  
  
  
  function getCertificationLabel(type) {
    const certificationMapping = {
      10: "First aid certification",
      5: "Driving license",
      8: "Google certification",
      8.01: "Coursera course",
      8.02: "edX course",
      8.03: "Udemy course",
      8.04: "Masterclass course",
      8.05: "Duolingo course",
      8.06: "Khan Academy course",
      8.07: "Codecademy course",
      8.08: "Domestika course",
      8.09: "Udacity course",
    };
  
    return certificationMapping[type] || "-";
  }
  
  function getLanguageLabel(name) {
    const languageMapping = {
      af: "Afrikaans",
      sq: "Albanian - shqip",
      am: "Amharic - አማርኛ",
      ar: "Arabic - العربية",
      an: "Aragonese - aragonés",
      hy: "Armenian - հայերեն",
      ast: "Asturian - asturianu",
      az: "Azerbaijani - azərbaycan dili",
      eu: "Basque - euskara",
      be: "Belarusian - беларуская",
      bn: "Bengali - বাংলা",
      bs: "Bosnian - bosanski",
      br: "Breton - brezhoneg",
      bg: "Bulgarian - български",
      ca: "Catalan - català",
      ckb: "Central Kurdish - کوردی (دەستنوسی عەرەبی)",
      zh: "Chinese - 中文",
      "zh-HK": "Chinese (Hong Kong) - 中文（香港）",
      "zh-CN": "Chinese (Simplified) - 中文（简体）",
      "zh-TW": "Chinese (Traditional) - 中文（繁體）",
      co: "Corsican",
      hr: "Croatian - hrvatski",
      cs: "Czech - čeština",
      da: "Danish - dansk",
      nl: "Dutch - Nederlands",
      eo: "Esperanto - esperanto",
      et: "Estonian - eesti",
      fo: "Faroese - føroyskt",
      fil: "Filipino",
      fi: "Finnish - suomi",
      fr: "French - français",
      "fr-CA": "French (Canada) - français (Canada)",
      "fr-FR": "French (France) - français (France)",
      "fr-CH": "French (Switzerland) - français (Suisse)",
      gl: "Galician - galego",
      ka: "Georgian - ქართული",
      de: "German - Deutsch",
      "de-AT": "German (Austria) - Deutsch (Österreich)",
      "de-DE": "German (Germany) - Deutsch (Deutschland)",
      "de-LI": "German (Liechtenstein) - Deutsch (Liechtenstein)",
      "de-CH": "German (Switzerland) - Deutsch (Schweiz)",
      el: "Greek - Ελληνικά",
      gn: "Guarani",
      gu: "Gujarati - ગુજરાતી",
      ha: "Hausa",
      haw: "Hawaiian - ʻŌlelo Hawaiʻi",
      he: "Hebrew - עברית",
      hi: "Hindi - हिन्दी",
      hu: "Hungarian - magyar",
      is: "Icelandic - íslenska",
      id: "Indonesian - Indonesia",
      ia: "Interlingua",
      ga: "Irish - Gaeilge",
      it: "Italian - italiano",
      "it-IT": "Italian (Italy) - italiano (Italia)",
      "it-CH": "Italian (Switzerland) - italiano (Svizzera)",
      ja: "Japanese - 日本語",
      kn: "Kannada - ಕನ್ನಡ",
      kk: "Kazakh - қазақ тілі",
      km: "Khmer - ខ្មែរ",
      ko: "Korean - 한국어",
      ku: "Kurdish - Kurdî",
      ky: "Kyrgyz - кыргызча",
      lo: "Lao - ລາວ",
      la: "Latin",
      lv: "Latvian - latviešu",
      ln: "Lingala - lingála",
      lt: "Lithuanian - lietuvių",
      mk: "Macedonian - македонски",
      ms: "Malay - Bahasa Melayu",
      ml: "Malayalam - മലയാളം",
      mt: "Maltese - Malti",
      mr: "Marathi - मराठी",
      mn: "Mongolian - монгол",
      ne: "Nepali - नेपाली",
      no: "Norwegian - norsk",
      nb: "Norwegian Bokmål - norsk bokmål",
      nn: "Norwegian Nynorsk - nynorsk",
      oc: "Occitan",
      or: "Oriya - ଓଡ଼ିଆ",
      om: "Oromo - Oromoo",
      ps: "Pashto - پښتو",
      fa: "Persian - فارسی",
      pl: "Polish - polski",
      pt: "Portuguese - português",
      "pt-BR": "Portuguese (Brazil) - português (Brasil)",
      "pt-PT": "Portuguese (Portugal) - português (Portugal)",
      pa: "Punjabi - ਪੰਜਾਬੀ",
      qu: "Quechua",
      ro: "Romanian - română",
      mo: "Romanian (Moldova) - română (Moldova)",
      rm: "Romansh - rumantsch",
      ru: "Russian - русский",
      gd: "Scottish Gaelic",
      sr: "Serbian - српски",
      sh: "Serbo-Croatian - Srpskohrvatski",
      sn: "Shona - chiShona",
      sd: "Sindhi",
      si: "Sinhala - සිංහල",
      sk: "Slovak - slovenčina",
      sl: "Slovenian - slovenščina",
      so: "Somali - Soomaali",
      st: "Southern Sotho",
      es: "Spanish - español",
      "es-AR": "Spanish (Argentina) - español (Argentina)",
      "es-419": "Spanish (Latin America) - español (Latinoamérica)",
      "es-MX": "Spanish (Mexico) - español (México)",
      "es-ES": "Spanish (Spain) - español (España)",
      "es-US": "Spanish (United States) - español (Estados Unidos)",
      su: "Sundanese",
      sw: "Swahili - Kiswahili",
      sv: "Swedish - svenska",
      tg: "Tajik - тоҷикӣ",
      ta: "Tamil - தமிழ்",
      tt: "Tatar",
      te: "Telugu - తెలుగు",
      th: "Thai - ไทย",
      ti: "Tigrinya - ትግርኛ",
      to: "Tongan - lea fakatonga",
      tr: "Turkish - Türkçe",
      tk: "Turkmen",
      tw: "Twi",
      uk: "Ukrainian - українська",
      ug: "Uyghur",
      uz: "Uzbek - o‘zbek",
      vi: "Vietnamese - Tiếng Việt",
      wa: "Walloon - wa",
      cy: "Welsh - Cymraeg",
      fy: "Western Frisian",
      xh: "Xhosa",
      yi: "Yiddish",
      yo: "Yoruba - Èdè Yorùbá",
      zu: "Zulu - isiZulu",
    };
    return languageMapping[name] || "-";
  }


  const [requestee, setrequestee] = useState([]);

  useEffect(() => {


    // Fetch all Super Admin profiles from Django backend API
    axios.get(`${process.env.REACT_APP_API_KEY}/api/student_count/`)
      .then(response => {
        // Filter the profiles based on profileID
        setrequestee(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });


      
  }, [profileID]);

  let { logoutUser } = useContext(AuthContext);

  const [filteredLeadershipData, setFilteredLeadershipData] = useState([]);
  const [filteredSocietiesData, setFilteredSocietiesData] = useState([]);
  const [filteredSportsData, setFilteredSportsData] = useState([]);
  const [filteredEventsData, setFilteredEventsData] = useState([]);

//   const handleSearch = (query) => {
//     // Filter Leadership Data
//     const filteredLeadership = leadershipData
//       .filter((activity) => activity.status === '0')
//       .filter((activity) =>
//         activity.activity_name.toLowerCase().includes(query.toLowerCase())
//       );
//     setFilteredLeadershipData(filteredLeadership);

//     // Filter Societies Data
//     const filteredSocieties = societiesData
//       .filter((activity) => activity.status === '0')
//       .filter((activity) =>
//         activity.activity_name.toLowerCase().includes(query.toLowerCase())
//       );
//     setFilteredSocietiesData(filteredSocieties);

//     // Filter Sports Data
//     const filteredSports = sportsData
//       .filter((activity) => activity.status === '0')
//       .filter((activity) =>
//         activity.activity_name.toLowerCase().includes(query.toLowerCase())
//       );
//     setFilteredSportsData(filteredSports);

//     // Filter Events Data
//     const filteredEvents = eventsData
//       .filter((activity) => activity.status === '0')
//       .filter((activity) =>
//         activity.activity_name.toLowerCase().includes(query.toLowerCase())
//       );
//     setFilteredEventsData(filteredEvents);
//   };




const handleSearch = (query) => {
    // Filter Leadership Data
    const filteredLeadership = leadershipData
      .filter((activity) => activity.status === '0')
      .filter((activity) =>
        activity.activity_name.toLowerCase().includes(query.toLowerCase()) ||
        requestee.some((student) =>
          [
            student.firstname.toLowerCase(),
            student.lastname.toLowerCase(),
            student.name.toLowerCase(),
            student.grade.toString(),
          ].some((info) => info.includes(query.toLowerCase()))
        )
      );
    setFilteredLeadershipData(filteredLeadership);
  
    // Filter Societies Data
    const filteredSocieties = societiesData
      .filter((activity) => activity.status === '0')
      .filter((activity) =>
        activity.activity_name.toLowerCase().includes(query.toLowerCase()) ||
        requestee.some((student) =>
          [
            student.firstname.toLowerCase(),
            student.lastname.toLowerCase(),
            student.name.toLowerCase(),
            student.grade.toString(),
          ].some((info) => info.includes(query.toLowerCase()))
        )
      );
    setFilteredSocietiesData(filteredSocieties);
  
    // Filter Sports Data
    const filteredSports = sportsData
      .filter((activity) => activity.status === '0')
      .filter((activity) =>
        activity.activity_name.toLowerCase().includes(query.toLowerCase()) ||
        requestee.some((student) =>
          [
            student.firstname.toLowerCase(),
            student.lastname.toLowerCase(),
            student.name.toLowerCase(),
            student.grade.toString(),
          ].some((info) => info.includes(query.toLowerCase()))
        )
      );
    setFilteredSportsData(filteredSports);
  
    // Filter Events Data
    const filteredEvents = eventsData
      .filter((activity) => activity.status === '0')
      .filter((activity) =>
        activity.activity_name.toLowerCase().includes(query.toLowerCase()) ||
        requestee.some((student) =>
          [
            student.firstname.toLowerCase(),
            student.lastname.toLowerCase(),
            student.name.toLowerCase(),
            student.grade.toString(),
          ].some((info) => info.includes(query.toLowerCase()))
        )
      );
    setFilteredEventsData(filteredEvents);
  };
  


  return (

<div>


<main className="flex w-full h-full rounded-3xl" style={{height: '-webkit-fill-available'}}>
<section className="w-full px-4 flex flex-col bg-white rounded-r-3xl">
      <div className="flex justify-between items-center h-48 border-b-2 mb-8 px-4 py-8 ">
        <div className="flex space-x-4 items-center">
          <div className="h-12 w-12 rounded-full overflow-hidden">
            <img src={profile?.avatar} loading="lazy" className="h-full w-full object-cover" />
          </div>
          <div className="flex flex-col">
            <h3 className="font-semibold text-lg m-0">{profile?.firstname} {profile?.lastname} <span className='badge badge-ghost'> MODERATOR </span></h3>
            <p className="text-light text-gray-400">{profile?.school} • {profile?.branch} </p>
          </div>
        </div>
<Link to="/login" onClick={logoutUser}>
        <button className="btn btn-outline btn-error gap-2"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
Logout</button> </Link>
 
      </div>
      </section>





     


  </main>
  <NewModerator/>
</div>
  );
};

export default ModeratorAdmin;