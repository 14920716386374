import React, {useEffect, useState, useContext} from 'react'
import { useParams, Link, useNavigate, NavLink } from 'react-router-dom';
import AuthContext from '../context/AuthContext'


import MobileBottom from '../components/MobileBottom';
import { useLanguage } from '../components/language';
const MobileSideNav = () => {


    const navigate = useNavigate();
    let {user} = useContext(AuthContext)
  
    // extract profile id
    let params = useParams()
    let profileID = user['user_id']
    
    // validate the user if he is the owner of the profile
    let isMyself = true
  
    if (user !== null && user['user_id'] === parseInt(profileID)) {
      isMyself = true
    }
  
    const { t, changeLanguage, lanArabic } = useLanguage();
    // initalize thread and posts component state
    const [profile, setProfile] = useState()
  
    useEffect(()=> {
      const getProfile = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`)
  
          // parse the data in json
          let data = await response.json()
  
          setProfile(data)
        } catch (err) {
          console.log("The requested profile does not exists.")
      }
          
      }
  
      getProfile()
    }, [profileID])
    const [showMobileSideNav, setShowMobileSideNav] = useState(false);
  
    const [showProfile, setshowProfile] = useState(true);
    const [showForm, setshowForm] = useState(false);
  
    const handle_showProfile = event => {
      setshowProfile(current => !current);
      setshowForm(false);
    };
  
    const handle_showForm = event => {
      setshowForm(current => !current);
      setshowProfile(false);
    };
  
    let {logoutUser} = useContext(AuthContext)

    
    
  let sum_lead = 0;

  for (let i = 0; i < profile?.lead_activity?.length; i++) {
    let obj = profile?.lead_activity[i];
    let lead_activity_val = parseFloat(obj.name) || 0;
    let lead_duration_val = parseFloat(obj.duration) || 0;
    let lead_scope_val = parseFloat(obj.scope) || 0;
    let lead_service_val = parseFloat(obj.service) || 0;

    sum_lead +=
      lead_activity_val * lead_duration_val * lead_scope_val * lead_service_val;
  }

let sum_society = 0;

for (let i = 0; i < profile?.society_activity?.length; i++) {
  let obj = profile?.society_activity[i];
  let society_name = parseFloat(obj.name) || 0;
  let society_duration = parseFloat(obj.duration) || 0;
  let society_service = parseFloat(obj.service) || 0;
  let society_role = parseFloat(obj.role) || 0;

  sum_society +=
  society_name * society_duration * society_service * society_role;
  }


let sum_social = 0;

for (let i = 0; i < profile?.social_activity?.length; i++) {
  let obj = profile?.social_activity[i];
  let social_name = parseFloat(obj.name) || 0;
  let social_duration = parseFloat(obj.duration) || 0;
  let social_scope = parseFloat(obj.scope) || 0;
  let social_service = parseFloat(obj.service) || 0;
  let social_role = parseFloat(obj.role) || 0;

  sum_social +=
  social_name * social_duration * social_scope * social_service * social_role;
  }


let sum_award = 0;

for (let i = 0; i < profile?.award_activity?.length; i++) {
  let obj = profile?.award_activity[i];
  let award_name = parseFloat(obj.name) || 0;
  let award_scope = parseFloat(obj.scope) || 0;
  let award_role = parseFloat(obj.rank) || 0;

  sum_award +=
  award_name * award_scope * award_role;
  }



let sum_sports = 0;

for (let i = 0; i < profile?.sports_activity?.length; i++) {
  let obj = profile?.sports_activity[i];
  let sports_name = parseFloat(obj.name) || 0;
  let sports_scope = parseFloat(obj.scope) || 0;
  let sports_role = parseFloat(obj.role) || 0;
  let sports_duration = parseFloat(obj.duration) || 0;
  let sports_service = parseFloat(obj.service) || 0;

  sum_sports +=
  sports_name * sports_scope * sports_role * sports_duration * sports_service;
  }


let sum_intern = 0;

for (let i = 0; i < profile?.intern_activity?.length; i++) {
  let obj = profile?.intern_activity[i];
  let intern_name = parseFloat(obj.name) || 0;
  let intern_duration = parseFloat(obj.duration) || 0;

  sum_intern +=
  intern_name * intern_duration;
  }


let sum_cert = 0;

for (let i = 0; i < profile?.cert_activity?.length; i++) {
  let obj = profile?.cert_activity[i];
  let cert_type = parseFloat(obj.name) || 0;
  sum_cert +=
  cert_type / profile?.cert_activity?.length;
  }


let sum_talent = 0;

for (let i = 0; i < profile?.talent_activity?.length; i++) {
  let obj = profile?.talent_activity[i];
  let talent_name = parseFloat(obj.name) || 0;
  let talent_service = parseFloat(obj.service) || 0;
  sum_talent +=
  talent_name * talent_service;
  }


let sum_lang = 0;

for (let i = 0; i < profile?.lang_activity?.length; i++) {
  let obj = profile?.lang_activity[i];
  let lang_prof = parseFloat(obj.prof) || 0;
  sum_lang +=
  lang_prof;
  }

    const round_lead_result = Math.floor(sum_lead)
    const round_society_result = Math.floor(sum_society)
    const round_social_result = Math.floor(sum_social)
    const round_award_result = Math.floor(sum_award)
    const round_sports_result = Math.floor(sum_sports)
    const round_intern_result = Math.floor(sum_intern)
    const round_cert_result = Math.floor(sum_cert)
  
    const round_lead_duration = profile?.lead_duration*10
    const round_lead_duration2 = profile?.lead_duration2*10
    const round_lead_duration3 = profile?.lead_duration3*10
  
    const round_society_duration = profile?.society_duration*10
    const round_society_duration2 = profile?.society_duration2*10
    const round_society_duration3 = profile?.society_duration3*10
  
    const round_social_duration = profile?.social_duration*10
    const round_social_duration2 = profile?.social_duration2*10
    const round_social_duration3 = profile?.social_duration3*10
  
  const totalScore = (round_lead_result + round_society_result + round_social_result + round_award_result + round_sports_result + round_cert_result + round_intern_result )*15
  //const totalScore = 2500;
  
    let parsed_hobboes = profile?.hobbies  
  
  
    {/* Competency Calculation */}
  
    let comp_lead_tech = 0;
    let comp_lead_tech2 = 0;
    let comp_lead_tech3 = 0;
  
    let comp_lead_lead = 0;
    let comp_lead_lead2 = 0;
    let comp_lead_lead3 = 0;
  
    let comp_lead_ips = 0;
    let comp_lead_ips2 = 0;
    let comp_lead_ips3 = 0;
  
    let comp_lead_ct = 0;
    let comp_lead_ct2 = 0;
    let comp_lead_ct3 = 0;
  
    let comp_lead_social = 0;
    let comp_lead_social2 = 0;
    let comp_lead_social3 = 0;
  
    let comp_lead_physical = 0;
    let comp_lead_physical2 = 0;
    let comp_lead_physical3 = 0;
  
  
                        {/*
                        <option value="8.04">Captain Sports Team</option>
                        <option value="7">Class Monitor</option>
                        <option value="10">Head Student</option>
                        <option value="0.9">Student Council General Secretary</option>
                        <option value="8.01">Deputy Head Student</option>
                        <option value="8.02">House Captain</option>
                        <option value="8.03">Class Prefect</option>
                        */}
  
    
    {/* Activity: leadership | Comp: leadership*/}
  
    if(profile?.lead_activity==10.0 || profile?.lead_activity==9.0 || profile?.lead_activity==8.02|| profile?.lead_activity==8.03){
      comp_lead_lead = 3;
    }
  
    if(profile?.lead_activity==8.01 || profile?.lead_activity==7.0 || profile?.lead_activity==8.04){
      comp_lead_lead = 2;
    }
  
  
    if(profile?.lead_activity2==10.0 || profile?.lead_activity2==9.0 || profile?.lead_activity2==8.02 || profile?.lead_activity2==8.03){
      comp_lead_lead2 = 3;
    }
  
    if(profile?.lead_activity3==8.01 || profile?.lead_activity3==7.0 || profile?.lead_activity3==8.04){
      comp_lead_lead2 = 2;
    }
  
  
    if(profile?.lead_activity3==10.0 || profile?.lead_activity3==9.0 || profile?.lead_activity3==8.02 || profile?.lead_activity3==8.03){
      comp_lead_lead3 = 3;
    }
  
    if(profile?.lead_activity3==8.01 || profile?.lead_activity3==7.0 || profile?.lead_activity3==8.04){
      comp_lead_lead3 = 2;
    }
  
    {/* Activity: leadership | Comp: IPS */}
  
    if(profile?.lead_activity==10.0 || profile?.lead_activity==9.0 || profile?.lead_activity==8.02 ){
      comp_lead_ips = 3;
    }
  
    if(profile?.lead_activity==8.01 || profile?.lead_activity==7.0 || profile?.lead_activity==8.04|| profile?.lead_activity==8.03){
      comp_lead_ips = 2;
    }
  
  
    if(profile?.lead_activity2==10.0 || profile?.lead_activity2==9.0 || profile?.lead_activity2==8.02 ){
      comp_lead_ips2 = 3;
    }
  
    if(profile?.lead_activity3==8.01 || profile?.lead_activity3==7.0 || profile?.lead_activity3==8.04 || profile?.lead_activity2==8.03){
      comp_lead_ips2 = 2;
    }
  
  
    if(profile?.lead_activity3==10.0 || profile?.lead_activity3==9.0 || profile?.lead_activity3==8.02 ){
      comp_lead_ips3 = 3;
    }
  
    if(profile?.lead_activity3==8.01 || profile?.lead_activity3==7.0 || profile?.lead_activity3==8.04 || profile?.lead_activity3==8.03){
      comp_lead_ips3 = 2;
    }
  
      {/* Activity: leadership | Comp: Critical Thinking */}
  
      if(profile?.lead_activity==10.0 || profile?.lead_activity==9.0 ){
        comp_lead_ct = 3;
      }
    
      if(profile?.lead_activity==8.01 || profile?.lead_activity==7.0 || profile?.lead_activity==8.04|| profile?.lead_activity==8.03 || profile?.lead_activity==8.02 ){
        comp_lead_ct = 2;
      }
    
    
      if(profile?.lead_activity2==10.0 || profile?.lead_activity2==9.0 ){
        comp_lead_ct2 = 3;
      }
    
      if(profile?.lead_activity3==8.01 || profile?.lead_activity3==7.0 || profile?.lead_activity3==8.04 || profile?.lead_activity2==8.03 || profile?.lead_activity2==8.02 ){
        comp_lead_ct2 = 2;
      }
    
    
      if(profile?.lead_activity3==10.0 || profile?.lead_activity3==9.0  ){
        comp_lead_ct3 = 3;
      }
    
      if(profile?.lead_activity3==8.01 || profile?.lead_activity3==7.0 || profile?.lead_activity3==8.04 || profile?.lead_activity3==8.03 || profile?.lead_activity3==8.02){
        comp_lead_ct3 = 2;
      }
  
        {/* Activity: leadership | Comp: Social */}
  
          {/* Activity: leadership | Comp: Physcial */}
  
  
  
          if(profile?.lead_activity==8.04){
            comp_lead_physical = 2;
          }
  
          if(profile?.lead_activity2==8.04){
            comp_lead_physical2 = 2;
          }
  
          if(profile?.lead_activity3==8.04){
            comp_lead_physical3 = 2;
          }
  
  
  
  
   var total_tech_Competencies1 = comp_lead_tech + comp_lead_tech2 + comp_lead_tech3
    //var total_tech_Competencies1 = 20
    var total_leadership_Competencies1 = comp_lead_lead + comp_lead_lead2 + comp_lead_lead3
    var total_ips_Competencies1 = comp_lead_ips + comp_lead_ips2 + comp_lead_ips3
    var total_ct_Competencies1 = comp_lead_ct + comp_lead_ct2 + comp_lead_ct3
    var total_social_Competencies1 = comp_lead_social + comp_lead_social2 + comp_lead_social3
    var total_physical_Competencies1 = comp_lead_physical + comp_lead_physical2 + comp_lead_physical3
  
    {/* Competency Calculation */}
  
  /*
    let hobbies_arr = profile?.hobbies.substring(1, profile?.hobbies.length - 1);
    try {
      const stringArray = hobbies_arr.replace(/[{}]/g, "").split(", ");
      // console.log(hobbies_arr);
  
      // Extract values from each string element
      hobbies_arr = stringArray.map((element) => {
        const keyValuePairs = element.split(", ");
        const valuePairs = keyValuePairs.map((pair) => pair.split(": ")[1]);
        return valuePairs;
      });
      hobbies_arr = hobbies_arr.flat();
    } catch (err) {
      console.log(err);
    }
    hobbies_arr = [...new Set(hobbies_arr)];
*/



const handleSidebarToggle = () => {
  setShowMobileSideNav(showMobileSideNav);
};

    return (

<div className="flex h-screen mobileSidebar flex-col justify-between border-e bg-primary" style={{    overflowY: 'scroll'}}>
  <div className="px-4 py-6" style={{    overflowY: 'scroll'}}>
  <NavLink to={`/student_profile`}>
  <div className="sticky inset-x-0 bottom-0 border-b border-gray-100" >


    <div className="avatar mx-auto flex justify-center">
  <div className="w-24 mask mask-squircle">
    <img src={profile?.avatar} />
  </div>
</div>
 
    <a
      href="#"
      className="flex items-center justify-center gap-2 p-4 "
    >
     <div className='flex flex-col gap-4 items-center'>
 
      <div>
        <p className="text-xs text-center text-white">
          <strong className="block font-medium text-lg">{profile?.firstname} {profile?.lastname}</strong>
          <span> @{profile?.name} </span>
        </p>
      </div>
      </div>




    </a>
  </div>  </NavLink>
    <ul className="mt-6 space-y-6 flex gap-8 flex-col mt-6 justify-between text-white" >
      
      <div className='flex flex-col mt-6 gap-8 sidenavList' > 

      <li className=''>
      <div className='flex flex-row items-center gap-4'>
      <NavLink to={`/challenges`}
          href=""
          className="flex flex-row gap-4 items-center rounded-lg  px-4 py-2 text-base font-medium text-white" 
          
        >

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
</svg>


          Challenges
        </NavLink></div>

      </li>


      <li>
 
<div className='flex flex-row items-center gap-4'>
        <a
          href=""
          className="flex flex-row gap-4 items-center rounded-lg  px-4 py-2 text-base font-medium text-white" style={{opacity: '.4'}}
        >



        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
</svg>

          Calendar
        </a>

<div className='badge gap-2 flex bg-amber-500	'> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-white">
  <path fill-rule="evenodd" d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z" clip-rule="evenodd" />
</svg>
 Coming Soon </div>

        </div>
      </li>



      <li>
      <div className='flex flex-row items-center gap-4'>
      <NavLink to={`/career_library`}
          href=""
          className="flex flex-row gap-4 items-center rounded-lg  px-4 py-2 text-base font-medium text-white" 
        >

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
</svg>

          Library
        </NavLink>
        </div>
      </li>


      <li>
      <div className='flex flex-row items-center gap-4'>
        <a
          href=""
          className="flex flex-row gap-4 items-center rounded-lg  px-4 py-2 text-base font-medium text-white" style={{opacity: '.4'}}
        >
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
</svg>



          Reports
        </a>        <div className='badge gap-2 flex bg-amber-500	'> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-white">
  <path fill-rule="evenodd" d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z" clip-rule="evenodd" />
</svg>
 Coming Soon </div></div>

      </li>
      

      <li className='hidden'>
      <div className='flex flex-row items-center gap-4'>
      <NavLink to={`/student_timeline`}
          href=""
          className="flex flex-row gap-4 items-center rounded-lg  px-4 py-2 text-base font-medium text-white" 
          
        >
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
</svg>

          My Timeline
        </NavLink></div>

      </li>




      <li className='hidden'>
      <div className='flex flex-row items-center gap-4'>
        <a
          href=""
          className="flex flex-row gap-4 items-center rounded-lg  px-4 py-2 text-base font-medium text-white" 
        >
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>


          Settings
        </a></div>

      </li>


      <li>

      <NavLink to={`/login`} onClick={logoutUser}>
        <a
          href=""
          className="flex flex-row gap-4  items-center rounded-lg  px-4 py-2 text-base font-medium text-red-400"
        >
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
</svg>

          Logout
        </a>
        </NavLink>
      </li>

</div>


    </ul>

 

  </div>

<MobileBottom/>

</div>



)
}

export default MobileSideNav