import React, { useState } from 'react';
import { IoTrashBin } from "react-icons/io5";

const FaqFormBuilder = () => {
  const [faqs, setFaqs] = useState([]);

  const handleAddFaq = () => {
    setFaqs([...faqs, { title: '', description: '' }]);
  };

  const handleRemoveFaq = (index) => {
    const newFaqs = faqs.filter((_, i) => i !== index);
    setFaqs(newFaqs);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newFaqs = [...faqs];
    newFaqs[index][name] = value;
    setFaqs(newFaqs);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitted FAQs:', faqs);
  };

  return (
    <div className="p-4">
      <form onSubmit={handleSubmit}>
        {faqs.map((faq, index) => (
          <div key={index} className="mb-4">
            <div className="mb-2">
              <label className="block text-gray-700">FAQ Title</label>
              <input
                type="text"
                name="title"
                value={faq.title}
                onChange={(e) => handleChange(index, e)}
                className="input input-bordered w-full"
                placeholder="Enter FAQ title"
              />
            </div>
            <div className="mb-2">
              <label className="block text-gray-700">FAQ Description</label>
              <textarea
                name="description"
                value={faq.description}
                onChange={(e) => handleChange(index, e)}
                className="textarea textarea-bordered w-full"
                placeholder="Enter FAQ description"
              ></textarea>
            </div>
            <button
              type="button"
              onClick={() => handleRemoveFaq(index)}
              className="btn btn-sm btn-outline btn-error "
            >
              <IoTrashBin /> Remove 
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddFaq}
          className="mb-4 btn btn-sm btn-warning"
        >
          + Add FAQ
        </button>






   
      </form>
    </div>
  );
};

export default FaqFormBuilder;
