const Leadership_Data = [
  {
    "Main Categories": "Leadership",
    "Sub Categories": "Head Student",
    Technology: "0",
    Leadership: "3",
    "Interpersonal Skills": "3",
    "Social Responsibility": "0",
    "Critical Thinking": "3",
    "Physical Fitness": "0",
  },
  {
    "Main Categories": "Leadership",
    "Sub Categories": "Student Council General Secretary",
    Technology: "0",
    Leadership: "3",
    "Interpersonal Skills": "3",
    "Social Responsibility": "0",
    "Critical Thinking": "3",
    "Physical Fitness": "0",
  },
  {
    "Main Categories": "Leadership",
    "Sub Categories": "Deputy Head Student",
    Technology: "0",
    Leadership: "2",
    "Interpersonal Skills": "2",
    "Social Responsibility": "0",
    "Critical Thinking": "2",
    "Physical Fitness": "0",
  },
  {
    "Main Categories": "Leadership",
    "Sub Categories": "House Captain",
    Technology: "0",
    Leadership: "3",
    "Interpersonal Skills": "3",
    "Social Responsibility": "0",
    "Critical Thinking": "2",
    "Physical Fitness": "0",
  },
  {
    "Main Categories": "Leadership",
    "Sub Categories": "Class Prefect",
    Technology: "0",
    Leadership: "3",
    "Interpersonal Skills": "2",
    "Social Responsibility": "0",
    "Critical Thinking": "2",
    "Physical Fitness": "0",
  },
  {
    "Main Categories": "Leadership",
    "Sub Categories": "Class Monitor",
    Technology: "0",
    Leadership: "2",
    "Interpersonal Skills": "2",
    "Social Responsibility": "0",
    "Critical Thinking": "0",
    "Physical Fitness": "0",
  },
  {
    "Main Categories": "Leadership",
    "Sub Categories": "Captain Sports Team",
    Technology: "0",
    Leadership: "2",
    "Interpersonal Skills": "2",
    "Social Responsibility": "0",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
];

export default Leadership_Data;
//use label function tp get the name of the sub categories
//make one array and keep adding the values of the sub categories in it corresponding to labels
