import React, { useEffect, useRef, useState } from "react";
import { createGlobalStyle } from "styled-components";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import Papa from "papaparse";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import Select from "react-select";
import SampleFile from "./SampleFile";
import axios from "axios";

const SchoolOnboardForm = () => {
  const [step, setStep] = useState(1);

  //   const nextStep = () => {
  //     setStep(step + 1);
  //   };

  const nextStep = () => {
    if (step === 1) {
      // Check condition for skipping step 2
      if (hasCampuses) {
        setStep(step + 1);
      } else {
        setStep(step + 2); // Skip step 2 and move to step 3
      }
    } else {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      if (step === 3 && !hasCampuses) {
        setStep(step - 2); // Skip step 2 and move back to step 1
      } else {
        setStep(step - 1);
      }
    }
  };

  let totalsteps = 4;

  const GlobalStyle = createGlobalStyle`
    html {
      background: #F9F3FF !important;
      /* Add other global styles here if needed */
    }
  `;

  const [hasCampuses, sethasCampuses] = useState(false);

  const [campuses, setCampuses] = useState([{ name: "", email: "" }]);

  const handleInputChange = (index, field, value) => {
    const newCampuses = [...campuses];
    newCampuses[index][field] = value;
    setCampuses(newCampuses);
  };

  const addMoreCampus = () => {
    setCampuses([...campuses, { name: "", email: "" }]);
  };

  const [users, setUsers] = useState([]);
  const fileInputRef = useRef(null);
  const [schoolName, setSchool] = useState("");
  const [schoolEmail, setSchoolEmail] = useState("");
  const [schoolLogo, setSchoolLogo] = useState("");

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const parsedUsers = XLSX.utils.sheet_to_json(sheet);

          // Log parsed users to the console
          console.log(parsedUsers);

          // Set the users state
          setUsers(parsedUsers);

          // Save data to localStorage
          localStorage.setItem("users", JSON.stringify(parsedUsers));

          // Show success toast
          toast.success("File uploaded successfully!");
        };

        reader.readAsBinaryString(file);
      } catch (error) {
        // Show error toast
        toast.error(
          "Error parsing the file. Please make sure it is a valid CSV/XLS file."
        );
      }
    }
  };

  // Load data from localStorage on component mount
  useEffect(() => {
    const storedUsers = localStorage.getItem("users");
    if (storedUsers) {
      const parsedUsers = JSON.parse(storedUsers);
      setUsers(parsedUsers);
    }
  }, []);

  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    // Load data from localStorage on component mount
    const storedUsers = localStorage.getItem("users");
    if (storedUsers) {
      const parsedUsers = JSON.parse(storedUsers);
      setUsers(parsedUsers);
    }
  }, []);

  const handleEditUser = (index) => {
    setSelectedUser(users[index]);
    setIsEditModalOpen(true);
  };

  const handleDeleteUser = (index) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this user?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const updatedUsers = [...users];
            updatedUsers.splice(index, 1);
            setUsers(updatedUsers);
            saveUsersToLocalStorage(updatedUsers);
            toast.success("User deleted successfully!");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSaveEdit = () => {
    // Implement logic to save edited user data
    // For demonstration purposes, just log the changes to the console
    console.log("Saving edited user:", selectedUser);

    const updatedUsers = users.map((user) =>
      user === selectedUser ? { ...user, ...selectedUser } : user
    );
    setUsers(updatedUsers);
    saveUsersToLocalStorage(updatedUsers);

    setIsEditModalOpen(false);
    setSelectedUser(null);
  };

  const [logo, setLogo] = useState("");

  const submitImage = async (e) => {
    const file = e.target.files[0];
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "sda7kxdr");
    data.append("cloud_name", "doxi2odzm");
    const response = await fetch(
      "https://api.cloudinary.com/v1_1/doxi2odzm/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    if (!response.ok) {
      toast.error("Error uploading image");
      throw new Error("Image upload failed");
    } else {
      const sendData = await response.json();
      toast.success("Image Upload Success");
      console.log("URL---", sendData.secure_url);
      // setPicture(sendData.secure_url);
      setLogo(sendData.secure_url);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const saveUsersToLocalStorage = (usersToSave) => {
    localStorage.setItem("users", JSON.stringify(usersToSave));
  };

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  // Sample role and permission options
  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "teacher", label: "Teacher" },
    // Add more role options as needed
  ];

  const permissionOptions = [
    { value: "read", label: "Read" },
    { value: "write", label: "Write" },
    // Add more permission options as needed
  ];

  const handleRoleChange = (selectedOptions, index) => {
    setUsers((prevUsers) =>
      prevUsers.map((user, i) =>
        i === index ? { ...user, selectedRoles: selectedOptions } : user
      )
    );
  };

  const handlePermissionChange = (selectedOptions, index) => {
    setUsers((prevUsers) =>
      prevUsers.map((user, i) =>
        i === index ? { ...user, selectedPermissions: selectedOptions } : user
      )
    );
  };

  const handleForm = async () => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/create_network/`, {
        schoolName,
        schoolEmail,
        logo,
        campuses: campuses ?? [],
        users: users ?? [],
      })
      .then((response) => {
        toast.success("Form Submitted Succesfully", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      })
      .catch((error) => {
        toast.error("Error Submitting Form");
        console.error("Error submitting Form:", error);
      });
  };

  return (
    <div className="">
      <GlobalStyle />

      {step === 1 && (
        <div>
          <>
            {/* Card Section */}
            <div className="max-w-4xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto h-screen">
              {/* Card */}
              <div className="bg-white rounded-xl shadow p-4 sm:p-7 darki:bg-slate-900">
                <div className="mb-8">
                  <h2 className="text-xl font-bold text-gray-800 darki:text-gray-200">
                    Network Information{" "}

                  </h2>
                  <p className="text-sm text-gray-600 darki:text-gray-400">
                    Add basic details like School Name, Email address and
                    environment media.
                  </p>
                </div>

                {/* Grid */}
                <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="af-account-email"
                      className="inline-block text-sm text-gray-800 mt-2.5 darki:text-gray-200"
                    >
                      School Name
                    </label>
                  </div>
                  {/* End Col */}
                  <div className="sm:col-span-9">
                    <input
                      id="network_name"
                      type="text"
                      value={schoolName}
                      onChange={(e) => setSchool(e.target.value)}
                      className="input input-bordered w-full"
                      placeholder="eg: Northville School"
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="af-account-email"
                      className="inline-block text-sm text-gray-800 mt-2.5 darki:text-gray-200"
                    >
                      School Email
                    </label>
                  </div>
                  {/* End Col */}
                  <div className="sm:col-span-9">
                    <input
                      id="network_email"
                      type="email"
                      value={schoolEmail}
                      className="input input-bordered w-full"
                      placeholder="eg: hello@northville.com"
                      onChange={(e) => setSchoolEmail(e.target.value)}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label className="inline-block text-sm text-gray-800 mt-2.5 darki:text-gray-200">
                      Logo
                    </label>
                  </div>

                  {/* End Col */}

                  <div className="sm:col-span-9">
                    <div className="flex items-center gap-5">
                      <div className="flex gap-x-2">
                        <div>
                          <form>
                            <label htmlFor="file-input" className="sr-only">
                              Choose file
                            </label>
                            <input
                              type="file"
                              name="file-input"
                              onChange={(e) => submitImage(e)}
                              id="file-input"
                              className="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600
    file:bg-gray-50 file:border-0
    file:bg-gray-100 file:me-4
    file:py-3 file:px-4
    darki:file:bg-gray-700 darki:file:text-gray-400"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Card */}
            </div>
            {/* End Card Section */}
          </>
        </div>
      )}

      <div className="fixed inset-x-0 bottom-0">
        <div className="relative flex items-center justify-center gap-4 bg-primary px-4 py-3 text-white">
          <button type="button" onClick={handleForm} class="btn btn-ghost">
            Confirm & Create Network
          </button>
        </div>
      </div>

      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        contentLabel="Edit User"
      >
        <h2>Edit User</h2>
        <form>
          <label>
            First Name:
            <input
              type="text"
              name="FIRST NAME"
              value={selectedUser ? selectedUser["FIRST NAME"] : ""}
              onChange={handleEditInputChange}
            />
          </label>
          <label>
            Last Name:
            <input
              type="text"
              name="LAST NAME"
              value={selectedUser ? selectedUser["LAST NAME"] : ""}
              onChange={handleEditInputChange}
            />
          </label>
          <label>
            Email:
            <input
              type="text"
              name="Email"
              value={selectedUser ? selectedUser["Email"] : ""}
              onChange={handleEditInputChange}
            />
          </label>
          <button type="button" onClick={handleSaveEdit}>
            Save
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default SchoolOnboardForm;
