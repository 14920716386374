import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import toast from "react-hot-toast";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import SideInnerSM from "./SideInnerSM";
import { useParams } from "react-router-dom";
import TimelineStack from "./TimelineStack";
import Stats_sm from "./Stats_sm";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Barchart from "./Barchart";
import RadarChart from "./RadarChart";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import AdminLayout from "../pages/AdminLayout";
import BarchartShowcase from "./BarchartShowcase";
import RadarChartShowcase from "./RadarChartShowcase";
import ClubList from "./ClubList";
import EventList from "./EventList";
import Club from "./Clubs.json";
import StudentPerformanceTable from "./StudentPerformanceTable";
import CouncilRosterStats from "./CouncilRosterStats";
import Events from './Events.json'

function NetworkStudentDetails() {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const lanArabic = i18n.language === "ar";
  let params = useParams();
  let profileID = params.id;
  const userID = user["user_id"];

  let isMyself = true;
  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  const [profile, setProfile] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetching, setisFetching] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`)
      .then((response) => {
        setProfile(response.data);
        setisFetching(false);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  }, []);

  const [profileData, setProfileData] = useState({
    profileID: profileID,
    name: "",
    lastname: "",
    lastname: "",
    bio: "",
    status: "",
    contact: "",
    city: "",
    grade: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const [selectedTab, setSelectedTab] = useState('Student Information');

  const handleTabChange = (tabName) => {
    setSelectedTab(tabName);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Update the database with new data
    const dataToSend = {
      ...profile,
      name: profile.name,
      firstname: profile.firstname,
      lastname: profile.lastname,
      bio: profile.bio,
      status: profile.status,
      contact: profile.contact,
      city: profile.city,
      grade: profile.grade,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/update_profile/${profileID}/`,
        dataToSend
      )
      .then((res) => {
        toast.success("Profile Updated Successfully");
      })
      .catch((err) => {
        toast.error("Failed To Update Profile");
      });
  };

  const handlefileChange = (e) => {
    setfile(e.target.files[0]);
  };
  const [file, setfile] = useState("");

  const handleFileSubmit = (e) => {
    e.preventDefault();
    if (file) {
      const form = new FormData();
      form.append("profile_picture", file);
      form.append("name", profileData.profileID);
      axios
        .post(
          `${process.env.REACT_APP_API_KEY}/api/upload_profile_pic/${profileID}/`,
          form
        )
        .then((response) => {
          console.log(response.data);
        })
        .then((res) => {
          toast.success("Profile Pic Updated Successfully");
          window.location.reload();
        })
        .catch((err) => {
          toast.error("Failed To Update Profile Picture");
        });
    }
  };
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);

    const getRandomClubs = () => {
      const shuffled = Club.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 2);
    };
  };

  if (Club.length === 0) {
    return null;
  }

  


  return (
    <AdminLayout>
      <div className="">
        <div class="relative w-full mx-auto  ">
          <div class="relative flex flex-col flex-auto min-w-0 p-4 mb-4 overflow-hidden break-words bg-white border-0  shadow-3xl rounded-2xl bg-clip-border">
            <div class="flex flex-wrap -mx-3 items-center">
              <div class="flex-none w-auto max-w-full px-3 pl-6">
                <div class="relative inline-flex items-center justify-center text-white transition-all duration-200 ease-in-out text-base h-19 w-19 rounded-xl">
                  <div className="avatar placeholder">
                    <div className="bg-secondary text-primary rounded-full w-16">
                      {!isFetching ? (
                        <span className="text-lg">{`${profile.firstname.charAt(
                          0
                        )}${profile.lastname.charAt(0)}`}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-none w-auto max-w-full px-3 my-auto">
                <div class="h-full">
                  <h5 class="mb-1 ">
                    {profile.firstname} {profile.lastname}
                  </h5>
                  <p class="mb-0 font-semibold leading-normal   text-sm">
                    Parent{" "}
                    <span className="opacity-60">{profile.lastname} Saeed</span>
                  </p>
                </div>
              </div>
              <div class="w-full  px-3 mx-auto mt-4 sm:my-auto sm:mr-0 md:w-1/2 md:flex-none lg:w-6/12">
                <Stats_sm source="Param" />
              </div>
            </div>

            <div className="flex flex-row gap-x-3 ml-3 mt-2">
              {Club.slice(0, 2).map((club, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-2 bg-white shadow-xl px-2 rounded-full"
                >
                  <img
                    className="w-5 h-5 rounded-full my-2"
                    src={club.logo}
                    alt={club.name}
                  />
                  <span className="text-sm">{club.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div class="">
          <div class="flex flex-wrap -mx-3 ">
            <div class={`w-full max-w-full px-3 shrink-0 ${selectedTab==='Clubs' || selectedTab==='Events'?(`md:w-full`):(`md:w-7/12`)}  md:flex-0`}>
              <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                <Tabs className="mt-4"  onSelect={(index, lastIndex, event) => handleTabChange(event.target.textContent)}>
                  <TabList className="tab-list flex-wrap gap-3">
                    <Tab className="tab">Student Information</Tab>
                    <Tab className="tab">Activity Map</Tab>
                    <Tab className="tab">Competency Map</Tab>
                    <Tab className="tab">Clubs</Tab>
                    <Tab className="tab">Events</Tab>
                  </TabList>

                  <TabPanel className="tab-panel">
                    <div class="flex-auto p-6">
                      <p class="leading-normal uppercase   text-sm">
                        User Information
                      </p>

                      <div class="flex flex-wrap -mx-3">
                        

                        <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                          <div class="mb-4">
                            <label
                              for="firstname"
                              class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                            >
                              Grade
                            </label>
                            <p>{profile.grade}</p>
                          </div>
                        </div>

                        <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                          <div class="mb-4">
                            <label
                              for="firstname"
                              class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                            >
                              City
                            </label>
                            <p>{profile.city}</p>
                          </div>
                        </div>

                        <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                          <div class="mb-4">
                            <label
                              for="firstname"
                              class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                            >
                              School
                            </label>
                            <p>{profile.school}</p>
                          </div>
                        </div>

                        <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                          <div class="mb-4">
                            <label
                              for="firstname"
                              class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                            >
                              Branch
                            </label>
                            <p>{profile.branch}</p>
                          </div>
                        </div>

                        {/* <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0 opacity-0">
                            <div class="mb-4">
                              <label
                                for="firstname"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Bio
                              </label>
                              <p>{profile.bio}</p>
                            </div>
                          </div> */}

                        <div class="w-full max-w-full px-3 shrink-0 md:flex-0 ">
                          <div class="mb-4">
                            <label
                              for="firstname"
                              class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                            >
                              Roadmap
                            </label>
                            <p>
                              To enhance your extracurricular activities,
                              consider diversifying your interests beyond your
                              current focus. Exploring new clubs or
                              organizations related to your passions can broaden
                              your horizons and help you develop new skills.
                              Additionally, taking on leadership roles within
                              existing clubs or starting your own initiatives
                              can showcase your initiative and teamwork
                              abilities. Seek opportunities to participate in
                              community service or volunteer projects, as they
                              not only benefit others but also demonstrate your
                              commitment to making a positive impact. Lastly,
                              don't underestimate the value of networking and
                              building connections within your chosen
                              extracurriculars, as these relationships can lead
                              to valuable mentorship and future opportunities.
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr class="h-px mx-0 my-4 bg-transparent border-0 opacity-25 bg-gradient-to-r from-transparent via-black/40 to-transparent  " />
                    </div>
                  </TabPanel>

                  <TabPanel className="tab-panel">
                    <BarchartShowcase />
                  </TabPanel>

                  <TabPanel className="tab-panel">
                    <RadarChartShowcase source="Param" />
                  </TabPanel>

                  <TabPanel className="tab-panel">
                  <CouncilRosterStats needTypeFilter={true} type={'Club'} clublist={Club}/>
                    <StudentPerformanceTable  needClubSelect={true} />
                  </TabPanel>

                  <TabPanel className="tab-panel">
                  <CouncilRosterStats needTypeFilter={true} type={'Event'}  clublist={Events}/>
                  <StudentPerformanceTable shortLength={true} needClubSelect={true} />
                  </TabPanel>
                </Tabs>
              </div>
            </div>

            {selectedTab !== 'Clubs' &&  selectedTab !== 'Events' ?(
              <div class="w-full max-w-full px-3 mt-6 shrink-0 md:w-5/12 md:flex-0 md:mt-0">
              <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                <TimelineStack source="Param" />
              </div>
            </div>
            ):null}
            
            
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default NetworkStudentDetails;
