import React, { useContext, useEffect, useState } from "react";
import { axiosInstance } from "../Axios";
import ReactPaginate from "react-paginate";
import AuthContext from "../context/AuthContext";
import { Popover } from '@headlessui/react';

export default function RequestsList() {

    const requestList = [
        {
          "id": 1,
          "req_details": "{\"name\": \"John Doe\", \"email\": \"john.doe@example.com\", \"contact\": \"1234567890\", \"current_designation\": \"Teacher\", \"action\": \"Promote\", \"designation\": \"Senior Teacher\", \"school\": \"ABC School\"}",
          "type": "M",
          "status": "P",
          "society_name": "Music Society",
          "email": "music@example.com"
        },
        {
          "id": 2,
          "req_details": "{\"name\": \"Jane Smith\", \"email\": \"jane.smith@example.com\", \"contact\": \"9876543210\", \"current_designation\": \"Student\", \"designation\": \"President\", \"school\": \"XYZ School\"}",
          "type": "S",
          "status": "A",
          "society_name": "Sports Society",
          "email": "sports@example.com"
        },
        {
          "id": 3,
          "req_details": "{\"name\": \"Michael Johnson\", \"email\": \"michael.johnson@example.com\", \"contact\": \"2345678901\", \"current_designation\": \"Teacher\", \"action\": \"Hire\", \"designation\": \"Science Teacher\", \"school\": \"PQR School\"}",
          "type": "M",
          "status": "P",
          "society_name": "Science Society",
          "email": "science@example.com"
        },
        {
          "id": 4,
          "req_details": "{\"name\": \"Emily Brown\", \"email\": \"emily.brown@example.com\", \"contact\": \"8765432109\", \"current_designation\": \"Parent\", \"action\": \"Register\", \"designation\": \"Parent Volunteer\", \"school\": \"LMN School\"}",
          "type": "M",
          "status": "P",
          "society_name": "Parent Volunteers",
          "email": "volunteers@example.com"
        },
        {
          "id": 5,
          "req_details": "{\"name\": \"David Wilson\", \"email\": \"david.wilson@example.com\", \"contact\": \"3456789012\", \"current_designation\": \"Alumni\", \"action\": \"Connect\", \"designation\": \"Alumni Mentor\", \"school\": \"EFG School\"}",
          "type": "MS",
          "status": "R",
          "society_name": "Alumni Association",
          "email": "alumni@example.com"
        }
      ];
      
  
  const [requests, setRequests] = useState(requestList);
  const [allOptions, setAllOptions] = useState("");
  const [msg, setMsg] = useState("");
  const [retryCount, setRetryCount] = useState(0);
  const [sortStatus, setSortStatus] = useState("all"); // Default to "All"
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [filterPopoverVisible, setFilterPopoverVisible] = useState(false);
  const [searchInput, setSearchInput] = useState(""); // New state for search input

  const [checkId, setCheckId] = useState([]);
  const [checkedBoxes, setCheckedBoxes] = useState([]);

  // Add a new state variable to track the selected filter status
  const [filterStatus, setFilterStatus] = useState("all");
  let { user } = useContext(AuthContext);

  const filterRequests = (data) => {
    if (searchInput === "") {
      return data;
    } else {
      return data.filter((request) => {
        // Customize the filter logic based on your requirements
        // For example, search in the request.society_name and request.type fields
        return (
          request.society_name
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          request.type.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    }
  };

  // useEffect(() => {
  //   axiosInstance
  //     .get("requests/")
  //     .then((response) => {
  //       const reversedRequests = response.data.reverse();
  //       setRequests(reversedRequests);
  //       setFilteredRequests(reversedRequests);
  //     })
  //     .catch((error) => {
  //       alert(error.response.data);
  //     });
  // }, [retryCount]);



 

  useEffect(() => {}, []);

  useEffect(() => {
    if (filterStatus === "all") {
      setFilteredRequests(requests);
    } else {
      const filtered = requests.filter(
        (request) => request.status === filterStatus
      );
      setFilteredRequests(filtered);
    }
  }, [filterStatus, requests]);



  const handleCheckboxChange = (requestId) => {
    if (checkedBoxes.includes(requestId)) {
      setCheckedBoxes((prevCheckedBoxes) =>
        prevCheckedBoxes.filter((id) => id !== requestId)
      );
    } else {
      setCheckedBoxes((prevCheckedBoxes) => [...prevCheckedBoxes, requestId]);
    }
  };

  const handleApproveAll = (approve) => {
    console.log(approve, "------------------>>>>>>.");
    if (allOptions === "") {
      return;
    }
    setMsg("Processing Requests");
    axiosInstance
      .put(`approve_all_requests/`, {
        type: allOptions,
        checkId: checkId,
        approve: approve,
      })
      .then((response) => {
        const updatedRequests = requests.map((request) =>
          request.type === allOptions
            ? { ...request, status: "approved" }
            : request
        );
        // setCheckId();
        setRequests(updatedRequests);
        setFilteredRequests(updatedRequests);
  

        setMsg("Requests processed successfully"); // Set a success message or remove this line if you don't want one
      })
      .catch((error) => {
        setMsg(
          "Error while processing requests, refresh the page and try again"
        );
      })
      .finally(() => {
        // Hide the message after 2 seconds regardless of success or failure
        setTimeout(() => {
          setMsg(""); // reset the message to empty
        }, 2000);
      });
  };

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // Number of items to display per page

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const paginatedRequests = filterRequests(filteredRequests).slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div className="overflow-x-auto">
      <div className="join justify-center p-4">
        <button
          className="btn join-item"
          onClick={() => setFilterPopoverVisible(!filterPopoverVisible)}
        >
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
  <path d="M14 2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2.172a2 2 0 0 0 .586 1.414l2.828 2.828A2 2 0 0 1 6 9.828v4.363a.5.5 0 0 0 .724.447l2.17-1.085A2 2 0 0 0 10 11.763V9.829a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 0 14 4.172V2Z" />
</svg>
        </button>

        <div>
          {filterPopoverVisible && (
            <div className="absolute z-10 mt-2 mr-8 py-2 bg-white border border-gray-300 rounded-lg shadow-lg">
              <ul className="space-y-2 p-2">
                <li>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      onChange={() => setFilterStatus("all")}
                      checked={filterStatus === "all"}
                      className="checkbox checkbox-primary"
                    />
                    <span className="ml-2">All</span>
                  </label>
                </li>
                <li>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      onChange={() => setFilterStatus("P")}
                      checked={filterStatus === "P"}
                      className="checkbox checkbox-primary"
                    />
                    <span className="ml-2">Pending</span>
                  </label>
                </li>
                <li>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      onChange={() => setFilterStatus("A")}
                      checked={filterStatus === "A"}
                      className="checkbox checkbox-primary"
                    />
                    <span className="ml-2">Approved</span>
                  </label>
                </li>
              </ul>
            </div>
          )}
        </div>

        <div>
          <div>
            <input
              className="input input-bordered join-item"
              placeholder="Search"
            />
          </div>
        </div>

        {(() => {
          if (user["role"] === "Network Admin" || user["role"] === "manager") {
            return (
              <div>
                <select
                  className="select select-bordered join-item"
                  onChange={(e) => setAllOptions(e.target.value)}
                  value={allOptions}
                >
                  <option value="NULL">Select Option</option>
                  <option value="M">Add Members</option>
                  <option value="MS">Member Status</option>
                </select>
                <div className="indicator ml-2">
                  <button
                    className="btn btn-outline btn-success"
                    onClick={() => handleApproveAll("yes")}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-outline btn-error"
                    onClick={() => handleApproveAll("no")}
                    style={{ marginLeft: "10px" }}
                  >
                    Reject
                  </button>
                </div>
              </div>
            );
          }
        })()}
      </div>


      <table class="items-center w-full mb-0 align-top border-collapse  text-slate-500">
        <thead class="align-bottom">
          <tr>
            <th class="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-collapse shadow-none   text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>
            <th class="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-collapse shadow-none   text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
              Society
            </th>
            <th class="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-collapse shadow-none   text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
              Type
            </th>
            <th class="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-collapse shadow-none   text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
              Details
            </th>
            <th class="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-collapse shadow-none   text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
              Status
            </th>
            <th class="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-collapse border-solid shadow-none   tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>
          </tr>
        </thead>
        <tbody>
          {paginatedRequests.map((request, index) => {
            let member = JSON.parse(request.req_details);

            let type = request.type;

            return (
              <tr>
                <td class="p-2 align-middle bg-transparent border-b  whitespace-nowrap shadow-transparent">
                  {(() => {
                    if (
                      user["role"] === "Network Admin" ||
                      user["role"] === "manager"
                    ) {
                      return (
                        <div class="flex px-2 py-1">
                          <div class="flex flex-col justify-center">
                            <input
                              type="checkbox"
                              onChange={() => {
                                setCheckId((prevId) => [...prevId, request.id]);
                              }}
                              // checked={checkedBoxes.includes(request.id)}
                              className="checkbox checkbox-accent"
                            />
                          </div>
                        </div>
                      );
                    }
                  })()}
                </td>

                <td class="p-2 align-middle bg-transparent border-b  whitespace-nowrap shadow-transparent">
                  <div class="flex px-2 py-1">
                    <div class="flex flex-col justify-center">
                      <h6 class="mb-0 text-sm leading-normal ">
                        {request.society_name}
                      </h6>
                      <p class="mb-0 text-xs leading-tight   text-slate-400">
                        {request.email}
                      </p>
                    </div>
                  </div>
                </td>
                <td class="p-2 align-middle bg-transparent border-b  whitespace-nowrap shadow-transparent">
                  {(() => {
                    if (request.type === "M" || request.type === "ME") {
                      return (
                        <p class="mb-0 text-xs leading-tight   text-slate-400">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                            />
                          </svg>
                          Member
                        </p>
                      );
                    } else if (request.type === "E") {
                      return (
                        <p class="mb-0 text-xs leading-tight   text-slate-400">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                            />
                          </svg>
                          Event
                        </p>
                      );
                    } else if (request.type === "MS") {
                      return (
                        <p class="mb-0 text-xs leading-tight   text-slate-400">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                            />
                          </svg>
                          Status
                        </p>
                      );
                    } else if (request.type === "S") {
                      return (
                        <p class="mb-0 text-xs leading-tight   text-slate-400">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                            />
                          </svg>
                          Society
                        </p>
                      );
                    } else {
                      return <div>{request.type}</div>;
                    }
                  })()}
                </td>

                <td className="p-2 text-center align-middle bg-transparent border-b  whitespace-nowrap shadow-transparent">
  <Popover className="relative">
    {({ open }) => (
      <>
        <Popover.Button className="focus:outline-none">
          <span className="mb-0 text-xs leading-tight text-slate-400 cursor-pointer">
            View Details
          </span>
        </Popover.Button>

        <Popover.Panel className="absolute z-10 w-64 max-w-xs px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-lg">
          <MemberDetails member={member} type={type} />
        </Popover.Panel>
      </>
    )}
  </Popover>
</td>

                <td class="p-2 text-sm leading-normal text-center align-middle bg-transparent border-b  whitespace-nowrap shadow-transparent">
                  {(() => {
                    if (request.status === "P") {
                      return (
                        <div className="bg-amber-500 text-xs items-center gap-1 flex w-max p-1 px-2 rounded-full text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          Pending
                        </div>
                      );
                    } else if (
                      request.status === "A" ||
                      request.status === "approved"
                    ) {
                      return (
                        <div className="bg-green-500 text-xs items-center gap-1 flex w-max p-1 px-2 rounded-full text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          Approved
                        </div>
                      );
                    } else if (
                      request.status === "R" ||
                      request.status === "rejected"
                    ) {
                      return (
                        <div className="bg-red-500 text-xs items-center gap-1 flex w-max p-1 px-2 rounded-full text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          Rejected
                        </div>
                      );
                    } else {
                      return <div>{request.status}</div>;
                    }
                  })()}
                </td>

                {(() => {
                  if (
                    user["role"] !== "society" &&
                    user["role"] !== "manager"
                  ) {
                    return (
                      <td class="p-2 align-middle bg-transparent border-b  whitespace-nowrap shadow-transparent">
                        <a
                          href="javascript:;"
                          class="text-xs font-semibold leading-tight   text-slate-400"
                        >
                          {(() => {
                            if (request.status === "P") {
                              return (
                                <div className="flex flex-row items-center gap-2">
                                  <button
                                    className="btn btn-sm btn-success btn-circle"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M4.5 12.75l6 6 9-13.5"
                                      />
                                    </svg>
                                  </button>

                                  <button
                                    className="btn btn-sm btn-error btn-circle"

                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-6 w-6"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              );
                            }
                          })()}
                        </a>
                      </td>
                    );
                  }
                })()}
              </tr>
            );
          })}
        </tbody>
      </table>

      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        pageCount={Math.ceil(requests.length / itemsPerPage)}
        onPageChange={handlePageChange}
        containerClassName={"pagination "}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
}

function MemberDetails({ member, type }) {
  return (
    member && (
      <div>
        <ul>
          <li>{member.name}</li>
          <li>{member.email}</li>
          <li>{member.contact}</li>
          {member.current_designation && (
            <li>Current Designation: {member.current_designation}</li>
          )}
          {member.action && <li>Action: {member.action}</li>}
          <li>{member.designation}</li>
          <li>{member.school}</li>
        </ul>
      </div>
    )
  );
}
