import React from 'react';
import {
    Link,
    Routes,
    Route,
    useNavigate,
  } from 'react-router-dom';
import { useLanguage } from '../components/language';

function Error() {

  const { t, changeLanguage, lanArabic } = useLanguage();
    const navigate = useNavigate();

  return (
<>

<div className="grid h-screen px-4 bg-white place-content-center">
  <div className="text-center">
    
    <img className='w-64' src='https://i.pinimg.com/originals/0e/c0/db/0ec0dbf1e9a008acb9955d3246970e15.gif'/>

    <p className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
      Uh-oh!
    </p>

    <p className="mt-2 text-gray-500">We ran into some error.</p>

    <button
      href="#"
      className="btn mt-6 btn-primary"
      onClick={() => navigate(-1)}
    >
      Go Back
    </button>
  </div>
</div>

</>

  );
}

export default Error;
