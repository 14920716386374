import React, { useState } from "react";
import TrackerCreation from "./TrackerCreation";
import Clubs from "./Clubs.json";
import events from "./Events.json";
import staff from "./StaffMembers.json";
import studentLeaders from "./Leaders.json";
import students from "./Students.json"
import AdminLayout from "../pages/AdminLayout";

const TrackerApp = () => {
  const [trackers, setTrackers] = useState([]);

  const addTracker = (tracker) => {
    setTrackers([...trackers, tracker]);
  };

  return (
 <AdminLayout>
       <div className="app">
      <TrackerCreation
        students={students}
        clubs={Clubs}
        events={events}
        staff={staff}
        studentLeaders={studentLeaders}
        addTracker={addTracker}
      />

    </div>
 </AdminLayout>
  );
};

export default TrackerApp;
