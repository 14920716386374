import React, { useState } from "react";
import AttendancePopUp from "./AttendancePopUp";
import Modal from "react-modal";

function AttendanceSummaryTable({ attendance,attendanceUnmarked }) {
  const formatStartDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${day} ${monthNames[monthIndex]} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}`;
  };

  const formatEndDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return ` ${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  };


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedScheduleID, setselectedScheduleID] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  // Function to open the modal and set selected club and date
  const openModal = (clubName, scheduleID, scheduleDate) => {
    setSelectedClub(clubName);
    setselectedScheduleID(scheduleID);
    setSelectedDate(scheduleDate);
    setIsModalOpen(true);
    console.warn("SELECTED: "+selectedClub+selectedDate)
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
        <thead className="ltr:text-left rtl:text-right">
          <tr>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Club & Time
            </th>

            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Presents
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Absents
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Explained Absents
            </th>

            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Participation
            </th>
            <th className="px-4 py-2" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 text-center">
          {attendance?.map((campus, index) => (
            <tr key={index}>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                {campus.club_name}
                <br />
                <span className="text-gray-400 font-normal">
                  {formatStartDate(campus.start_date)} {"-"}
                  {formatEndDate(campus.end_date)}
                </span>
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                {campus.present}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                {campus.absent}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">0</td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                {campus.participation}
              </td>
              {/* <td className="whitespace-nowrap px-4 py-2">
                <button
                onClick={() =>
                                        openModal(
                                          attendanceUnmarked.club_id,
                                          attendanceUnmarked.schedule_id,
                                          attendanceUnmarked.schedule_name
                                        )
                                      }
                  className="inline-block rounded bg-primary px-4 py-2 text-xs font-medium text-white hover:bg-indigo-700"
                >
                  View
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
                                <Modal
                                  isOpen={isModalOpen}
                                  onClose={closeModal}
                                >
                                <div className="flex justify-end ">
                                <button className="btn btn-circle btn-ghost" onClick={closeModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>
</button>
                                </div>
                                  <AttendancePopUp
                                    club={selectedClub} scheduleID={selectedScheduleID} date={selectedDate} />
                                  
                                </Modal>
                              )}
    </div>
  );
}

export default AttendanceSummaryTable;