import React, { useState, useEffect } from 'react';
import AdminLayout from '../pages/AdminLayout';

function SportsPage(props) {
    const [state, setState] = useState('');

    useEffect(() => {
        return () => {

        }
    }, []);

    return (
        <AdminLayout>
            <p className='text-center'>This page is still being developed</p>
        </AdminLayout>
    )
}

export default SportsPage;