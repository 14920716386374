// import React from 'react';
import AdminLayout from "./AdminLayout";
import AdminStats from "./AdminStats";
import TopClubs from "./TopClubs";
import EventCalendar from "../components/EventCalendar";
import Modal from "react-modal";
import { useTranslation } from 'react-i18next';

import Events from "../components/Events.json";
import Clubs from "../components/Clubs.json";

import React, { useContext, useEffect, useState } from "react";
import AdminDashSideBar from "./AdminDashSideBar";
import AdminDashNavbar from "./AdminDashNavbar";
import { createGlobalStyle } from "styled-components";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import CampusTable from "./CampusTable";
import ScheduleTrend from "../components/ScheduleTrend";
import ClubSegregation from "./ClubSegregation";
import ClubBudgetBreakdown from "./ClubBudgetBreakdown";
import ClubMemberSegregation from "./ClubMemberSegregation";
import RetentionChart from "./RetentionChart";
import ReportDownloadCard from "./ReportDownloadCard";
import CCAStats from "../components/CCAStats";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import RequestsList from "../components/RequestsList";
import MiniCalendar from "../components/MiniCalendar";
import MinimalStats from "../components/MinimalStats";
import MonthlyAttendanceChart from "../components/MonthlyAttendanceChart";
import GenderDiversityChart from "../components/GenderDiversityChart";
import Network_StudentList from "../components/Network_StudentList";
import AttendanceBreakdown from "./AttendanceBreakdown";
import AttendancePopUp from "./AttendancePopUp";
import { Link } from "react-router-dom";

function AdminDashboardPage() {
  const [profile, setProfile] = useState();
  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);

  const userID = user["user_id"];

  let url = "";
  if (user["role"] === "Student") {
    url = "profile";
  } else if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  } else if (user["role"] === "Staff") {
    url = "staff";
  }

  let role = user["role"];

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");
  const { t } = useTranslation();
const { i18n } = useTranslation();
  const [profileData, setProfileData] = useState({
    name: "",
    dob: "",
    gender: "",
    contact: "",
    bio: "",
    active_status: "",
    employeeID: "",
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((response) => {
        const data = response.data;
        setProfileData(data);
        setProfile(data);
        setProfilePhotoUrl(data.profile_photo);
        //console.log("Profile Photo URL: ", data.profile_photo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);

  const campusesData = [
    {
      name: "Northville North",
      location: "north@northville.com",
      students: 120,
      staffMembers: 15,
      events: 13,
      budget: "$1,500",
      participation: "28%",
    },
    {
      name: "Northville South",
      location: "south@northville.com",
      students: 105,
      staffMembers: 15,
      events: 12,
      budget: "$1,300",
      participation: "60%",
    },
    {
      name: "North Guardian",
      location: "guardian@northville.com",
      students: 216,
      staffMembers: 25,
      events: 26,
      budget: "$3,500",
      participation: "89%",
    },
  ];

  const campusesDataDemo = [
    {
      id: 2,
      name: "DC UAE Campus",
      location: "uae@dc.com",
      students: 5,
      staffMembers: 4,
      events: 13,
      budget: "$1,500",
      participation: "79%",
    },
  ];

  const clubsData = [
    {
      name: "Basketball Society",
      campus: "Dubai Campus, UAE",
      members: 4,
      staffMembers: 1,
      events: 10,
      budget: "$3,500",
      participation: "89%",
    },
    {
      name: "Brass Ensemble Society",
      campus: "Dubai Campus, UAE",
      members: 5,
      staffMembers: 1,
      events: 3,
      budget: "$4,500",
      participation: "65%",
    },
    {
      name: "Science Communicator Society",
      campus: "Dubai Campus, UAE",
      members: 5,
      staffMembers: 1,
      events: 0,
      budget: "$3,500",
      participation: "55%",
    },
  ];

  const clubsDataDemo = [
    {
      name: "BasketBall",
      campus: "DC UAE",
      members: 4,
      staffMembers: 1,
      events: 10,
      budget: "$3,500",
      participation: "89%",
    },
    {
      name: "Brass Ensemble",
      campus: "DC UAE",
      members: 5,
      staffMembers: 1,
      events: 3,
      budget: "$4,500",
      participation: "65%",
    },
    {
      name: "Science Communicator",
      campus: "DC UAE",
      members: 5,
      staffMembers: 1,
      events: 0,
      budget: "$3,500",
      participation: "55%",
    },
  ];

  const getCurrentDateTime = () => {
    const now = new Date();
    const isoDateTime = now.toISOString().split(".")[0]; // Get ISO format without milliseconds
    return isoDateTime;
  };

  const getNextDays = (daysToAdd) => {
    const now = new Date();
    now.setDate(now.getDate() + daysToAdd); // Add days to the current date
    const isoDateTime = now.toISOString().split(".")[0]; // Get ISO format without milliseconds
    return isoDateTime;
  };

  const events = [
    {
      id: 1,
      title: "Annual Sports Day",
      start: getNextDays(1),
      end: getNextDays(1),
      description:
        "A day filled with sports competitions and fun activities for students.",
      location: "School Sports Ground",
    },
    {
      id: 2,
      title: "Parent-Teacher Conference",
      start: getNextDays(1),
      end: getNextDays(1),
      description:
        "Opportunity for parents to meet teachers and discuss student progress.",
      location: "School Auditorium",
    },
    {
      id: 3,
      title: "Science Fair",
      start: getNextDays(3),
      end: getNextDays(3),
      description: "Students showcase their science projects and experiments.",
      location: "School Science Lab",
    },
    {
      id: 4,
      title: "Cultural Diversity Day",
      start: getNextDays(6),
      end: getNextDays(6),
      description:
        "Celebration of different cultures through performances and exhibitions.",
      location: "School Courtyard",
    },
    {
      id: 5,
      title: "Annual Music Concert",
      start: getNextDays(7),
      end: getNextDays(7),
      description:
        "Students showcase their musical talents in a grand concert.",
      location: "School Auditorium",
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${day} ${monthNames[monthIndex]} `;
  };

  const [network, setNetwork] = useState(null);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((response) => {
        const data = response.data;
        setProfileData(data);
        setProfile(data);
        if (user["role"] === "Network Admin") {
          setNetwork(data?.network_id);
        } else if (user["role"] === "School Admin") {
          setNetwork(data?.school_id);
        }

        setProfilePhotoUrl(data.profile_photo);
        //console.log("Profile Photo URL: ", data.profile_photo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);

  let Attendanceurl = "";
  if (user["role"] === "Network Admin") {
    Attendanceurl = "get_network_attendence_stats";
  } else if (user["role"] === "School Admin") {
    Attendanceurl = "get_school_attendance_stats";
  }

  const [attendanceData, setAttendanceData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${Attendanceurl}/${network}/`)
      .then((response) => {
        const data = response.data;
        setAttendanceData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [network, Attendanceurl]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedScheduleID, setselectedScheduleID] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  // Function to open the modal and set selected club and date
  const openModal = (clubName, scheduleID, scheduleDate) => {
    setSelectedClub(clubName);
    setselectedScheduleID(scheduleID);
    setSelectedDate(scheduleDate);
    setIsModalOpen(true);
    console.warn("SELECTED: " + selectedClub + selectedDate);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredAttendances = attendanceData?.unmarked_attendance
    ?.filter((attendance) =>
      attendance.club_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    ?.sort((a, b) => new Date(b.schedule_name) - new Date(a.schedule_name));

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAttendances = filteredAttendances?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const students = [
    { id: 1, fullName: 'Ahmed Al Mansouri' },
    { id: 2, fullName: 'Fatima Al Zarooni' },
    { id: 3, fullName: 'Hassan Al Nuaimi' },
    { id: 4, fullName: 'Maryam Al Shamsi' },
  ];

  const unmoderatedCounts = [5, 6, 7, 8];


  const [selectedCategory, setSelectedCategory] = useState('');

  const eventCategories = [
    'Sports Day',
    'Science Fair',
    'Art Exhibition',
    'Music Concert',
    'Drama Festival',
    'Debate Competition',
    'Parent-Teacher Meeting',
    'Graduation Ceremony'
  ];

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <AdminLayout>
      {/* <AdminStats /> */}
      <MinimalStats />

      {/* <ReportDownloadCard user={user}/> */}

      <div className="">
        {/* <TopClubs/> */}

        <div className="flex flex-wrap  -mx-3">
          <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-7/12 lg:flex-none">
            <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid shadow-xl    border-black-125 rounded-2xl bg-clip-border min-h-[22.5rem]">
              <div className="p-4 pb-0 mb-0 rounded-t-4">
                <div className="flex justify-between">
                  <h6 className="mb-2 ">{t('Student Engagement')}</h6>
                </div>
              </div>

<div className="flex  justify-end mr-4">
<select className="select select-bordered w-max">
<option>Last 7 days</option>
    <option>Last month</option>
    <option>Last 3 months</option>
    <option>Last 6 months</option>
    <option>This year</option>
</select>
</div>
              <div className="grid grid-cols-6 gap-4 p-4 items-center">



                <div className="flex flex-col gap-3 col-span-2">
                  <button
                    type="button"
                    class="py-3 px-4 flex justify-between flex-row items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800  hover:bg-gray-50 disabled:opacity-50"
                  >
                    {t('Attendance')}
                    <b>98%</b>
                  </button>

                  <button
                    type="button"
                    class="py-3 px-4 flex justify-between flex-row items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800  hover:bg-gray-50 disabled:opacity-50"
                  >
                    {t('Involvement')}
                    <b>{t('High')}</b>
                  </button>

                  <button
                    type="button"
                    class="py-3 px-4 flex justify-between flex-row items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800  hover:bg-gray-50 disabled:opacity-50"
                  >
                    {t('Participation')}
                    <b>{t('Active')}</b>
                  </button>

                  <button
                    type="button"
                    class="py-3 px-4 flex justify-between flex-row items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800  hover:bg-gray-50 disabled:opacity-50"
                  >
                    {t('Performance')}
                    <b>{t('High')}</b>
                  </button>
                </div>
                <div className="col-span-4">
                  <MonthlyAttendanceChart />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full max-w-full px-3 mt-0 lg:w-5/12 lg:flex-none">
            <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
              <div className="p-4 pb-0 rounded-t-4">
                <h6 className="mb-0 ">{t('Moderations Tracker')}</h6>
                <span> Total unmoderated <b>sessions: 4</b> </span>
              </div>
              
              


              <div className="max-w-4xl mx-auto p-4">
      <table className="table">
        <thead>
          <tr>
            <th className=""></th>
            <th className="">Full Name</th>
            <th className="">Unmoderated Section</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student, index) => (
            <tr key={student.id}>
              <td className="">
                <input type="checkbox" className="checkbox checkbox-error" />
              </td>
              <td className="">{student.fullName}</td>
              <td className="text-center">
                {unmoderatedCounts[index % unmoderatedCounts.length]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button className="btn btn-error mt-2 w-full mb-4">Notify Concerned Staff</button>
    </div>




            </div>
          </div>
        </div>


        

        <div className="flex flex-wrap mt-6 -mx-3">
     
          <div className="w-full max-w-full px-3 mt-0 lg:w-6/12 lg:flex-none">
            <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
              <div className="p-4 pb-0 rounded-t-4">
                <h6 className="mb-0 ">{t('Events')}</h6>
                <span> List active Events </span>

              </div>
              

<div className="grid grid-cols-2 mx-4 gap-x-3 mt-3">
  <select className="select select-bordered">
  {eventCategories.map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
  </select>
  <button className="btn btn-outline capitalize">Average Event Turnout  <b>98%</b></button>
</div>


<div className="grid grid-cols-2 mx-4 gap-x-3 mt-3">
  <select className="select select-bordered">
  <option>Last 7 days</option>
    <option>Last month</option>
    <option>Last 3 months</option>
    <option>Last 6 months</option>
    <option>This year</option>
  </select>
  <button className="btn btn-outline capitalize">Average Performance  <b>High</b></button>
</div>

{Events.slice(0, 2).reverse().map((event, index) => (
        <div key={index} className="flex p-5 my-2 gap-5 items-center justify-between shadow-xl w-base rounded-xl m-3">
          <div className="flex flex-row gap-4">
          <img
            src={event?.banner}
            alt="event"
            className="h-14 w-14 rounded-full"
          />
          <div>
            <h2 className="text-black mb-0 font-semibold text-lg">{event?.name}</h2>

            <span> {event?.external_event? ('External Event'):(`Internal Event`)} </span>
          </div>
          </div>
          
          <span className="flex justify-end">July 18, 12 PM</span>
        </div>
      ))}



            </div>
          </div>







          <div className="w-full max-w-full px-3 mt-0 lg:w-6/12 lg:flex-none">
            <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
              <div className="p-4 pb-0 rounded-t-4">
                <h6 className="mb-0 ">{t('Clubs')}</h6>
                <span> List active clubs </span>

              </div>
              

<div className="grid grid-cols-2 mx-4 gap-x-3 mt-3">
  <select className="select select-bordered">
  {eventCategories.map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
  </select>
  <button className="btn btn-outline capitalize">Average Event Turnout  <b>98%</b></button>
</div>


<div className="grid grid-cols-2 mx-4 gap-x-3 mt-3">
  <select className="select select-bordered">
  <option>Last 7 days</option>
    <option>Last month</option>
    <option>Last 3 months</option>
    <option>Last 6 months</option>
    <option>This year</option>
  </select>
  <button className="btn btn-outline capitalize">Average Performance  <b>High</b></button>
</div>

{Clubs.slice(0, 2).reverse().map((club, index) => (
        <div key={index} className="flex p-5 my-2 gap-5 items-center justify-between shadow-xl w-base rounded-xl m-3">
          <div className="flex flex-row gap-4">
          <img
            src={club?.logo}
            alt="event"
            className="h-14 w-14 rounded-full"
          />
          <div>
            <h2 className="text-black mb-0 font-semibold text-lg">{club?.name}</h2>

            <span> {club?.category_name} </span>
          </div>
          </div>
          
          <span className="flex justify-end">July 18, 12 PM</span>
        </div>
      ))}



            </div>
          </div>






        </div>





      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <div className="flex justify-end ">
            <button className="btn btn-circle btn-ghost" onClick={closeModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <AttendancePopUp
            club={selectedClub}
            scheduleID={selectedScheduleID}
            date={selectedDate}
          />
        </Modal>
      )}
    </AdminLayout>
  );
}

export default AdminDashboardPage;
