// App.js
import React, { useContext, useEffect, useState } from "react";
import ReturnNav from "./ReturnNav";
import { createGlobalStyle } from "styled-components";
import PublicNav from "./PublicNav";
import { useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import toast from "react-hot-toast";

import { Calendar, globalizeLocalizer } from "react-big-calendar";
// import 'react-big-calendar/lib/sass/styles';
import "react-big-calendar/lib/css/react-big-calendar.css";
import globalize from "globalize";
import AvatarCard from "../components/AvatarCard";

const Tweet = () => {
  const profile = {
    name: "John Doe",
    age: 25,
    gender: "male",
    location: {
      city: "Randomville",
      country: "Wonderland",
    },
    interests: ["coding", "gaming", "reading"],
    isStudent: true,
    skills: {
      programming: ["JavaScript", "React", "Node.js"],
      languages: ["English", "Spanish"],
    },
  };
  const { id } = useParams();


  let { user } = useContext(AuthContext);
  let profileID = 0;

  if(user[`role`]===`Parent`){
    profileID = 12;
  } else{
    profileID = user["user_id"];
  }

  const handleEnroll = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_KEY}/api/apply_club/`,
        { club_id: id, role: "student", participant_id: profileID }
      );
      checkEnroll();
      toast.success("Successfully applied");
    } catch {
      toast.error("Failed to enroll");
    }
  };
  const [isEnrolled, setIsEnrolled] = useState(false);
  const checkEnroll = async () => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_KEY}/api/check_apply/`, {
          params: {
            club_id: id,
            student_id: profileID,
          },
        })
        .then((res) => {
          setIsEnrolled(res.data);
        });
    } catch {
      setIsEnrolled(false);
    }
  };


  const [clubs, setClubs] = useState([]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}/api/clubs/`
      );
      let data = await response.json();
      
      // Filter clubs based on id
      const filteredClubs = data.filter(club => club.id === parseInt(id)); // Assuming id is accessible in this scope
      
      setClubs(filteredClubs);
      console.info("CLUBS ", filteredClubs);
    } catch (err) {
      console.log("ERROR");
    }
  };

  // Simulate the loader by setting a timer for 3 seconds
  const timer = setTimeout(() => {
    fetchData();
  }, 900);

  // Clean up the timer when the component unmounts
  return () => clearTimeout(timer);
}, [id]); // Make sure to include id in the dependency array if it's used inside the effect


  
  useEffect(() => {
    checkEnroll();
  }, []);






  const localizer = globalizeLocalizer(globalize);

  const [list, setList] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_club_schedules/${id}/`)
      .then((res) => {
        setList(res.data);
        console.warn("SCHEDULE:  ", res.data);
      })
      .catch((err) => {
        console.error("ERROR SCHEDULE:  ", err);
        console.log(err);
      });
  }, []);

  const myNewList = [
    {
      id: 150,
      title: "Strikers Training 1",
      start_date: "2024-02-01T04:00:00Z",
      end_date: "2024-02-01T05:00:00Z",
      club: 51,
    },
    {
      id: 151,
      title: "Strikers Training 2",
      start_date: "2024-02-01T05:00:00Z",
      end_date: "2024-02-01T06:00:00Z",
      club: 51,
    },
    {
      id: 152,
      title: "Strikers Training 3",
      start_date: "2024-02-01T06:00:00Z",
      end_date: "2024-02-01T07:00:00Z",
      club: 51,
    },
    {
      id: 153,
      title: "Strikers Training 4",
      start_date: "2024-02-01T11:00:00Z",
      end_date: "2024-02-01T12:00:00Z",
      club: 51,
    },
    {
      id: 154,
      title: "Strikers Training 5",
      start_date: "2024-02-01T12:00:00Z",
      end_date: "2024-02-01T13:00:00Z",
      club: 51,
    },
  ];

  function mergeAdjacentEvents(events) {
    // Sort events based on start_date
    events.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

    let mergedEvents = [];
    let currentEvent = null;

    for (let event of events) {
      if (!currentEvent) {
        currentEvent = { ...event };
      } else {
        const currentEndDate = new Date(currentEvent.end_date);
        const eventStartDate = new Date(event.start_date);

        if (currentEndDate >= eventStartDate) {
          // Merge events
          currentEvent.end_date = event.end_date;
        } else {
          // Push current merged event
          mergedEvents.push(currentEvent);
          // Start a new merged event
          currentEvent = { ...event };
        }
      }
    }

    // Push the last merged event
    if (currentEvent) {
      mergedEvents.push(currentEvent);
    }

    // Format date strings in ISO format
    mergedEvents.forEach((event) => {
      event.start_date = event.start_date.slice(0, 19);
      event.end_date = event.end_date.slice(0, 19);
    });

    return mergedEvents;
  }

  // Example usage
  const mergedEvents = list;
  console.warn(mergedEvents);

  const newData = mergedEvents?.map((item) => {
    const id = item?.id;
    const title = item?.name;
    const start = new Date(item?.start_date);
    const end = new Date(item?.end_date);
    const club = item.club;

    return { id, title, start, end, club };
  });

  const handleDeleteEvent = (id) => {
    // Update the events array after deleting a schedule
    setList(list.filter((event) => event.id !== id));
  };





  const GlobalStyle = createGlobalStyle`
  html {
    background: #F9F3FF !important;
    /* Add other global styles here if needed */
  }
`;


const [isExpanded, setIsExpanded] = useState(false);

const toggleReadMore = () => {
  setIsExpanded(!isExpanded);
};

// Assuming clubs[0]?.goals is a string
const text = clubs[0]?.goals;
const maxLength = 100; // Adjust as needed

const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;



  return (
    <div>
      <div className="max-w-[85rem] mt-12 px-4 mx-auto">
        {/* <ReturnNav /> */}
        <GlobalStyle />
        <>
          {/* component */}

          <div className="">
            <div class="max-w-[85rem] mt-12 px-4 mx-auto">
              {/* <div class="mb-4 flex flex-col bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-700 darki:shadow-slate-700/[.7]">
                <div class="flex justify-between items-center border-b rounded-t-xl py-3 px-4 md:px-5 darki:border-gray-700">
                  <h3 class="text-lg font-bold text-gray-800 darki:text-white">
                    Enroll in this club <br />{" "}
                    <small className="font-normal text-gray-400">
                      Admin will review your request
                    </small>
                  </h3>

                  <div class="flex items-center gap-x-1">
                    <div class="hs-tooltip inline-block">
                      
                    </div>
                  </div>
                </div>
              </div> */}

              <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                <img
                  class="w-full rounded-t-2xl timelineCover  "
                  src={ clubs[0]?.cover }
                  alt="profile cover image"
                />

                <div class="">
                  <div class="w-4/12 max-w-full px-3 flex-0 ">
                    <div class="mb-6 px-4 -mt-6 lg:mb-0 lg:-mt-16">
                      <div className="flex flex-col justify-center ">
                        <img
                          class="h-36 w-36 border-2 border-gray-400 border-solid  rounded-circle"
                          src={ clubs[0]?.logo }
                          style={{    objectFit: 'cover'}}
                          alt="profile image"
                        />

                        <h4 className="ml-2 text-xl mt-4 text-start text-black font-semibold">
                          {" "}
                          {clubs[0]?.name}{" "}
                        </h4>
                        <p className="ml-2 pb-2 text-gray-500 text-start m-0 ">
                          {clubs[0]?.category_name} Club
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div class="flex-auto p-6 pt-0">
                  <div class="mt-6 text-center">
                    <div class="/80 text-start ml-2 ">
                      The Stikers Football Club is a dynamic and passionate
                      community dedicated to promoting the spirit of teamwork,
                      sportsmanship, and a love for the beautiful game. Whether
                      you're a seasoned player or just starting, our club
                      provides a welcoming environment for everyone to enjoy the
                      thrill of football.
                    </div>
                  </div>
                </div> */}
              </div>
            </div>






            <>
  {/* Blog Article */}
  <div className="max-w-[85rem] mt-12 px-4 mx-auto">
    <div className="grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 lg:gap-x-6">
      {/* Content */}
      <div className="lg:col-span-2 bg-white rounded-xl order-2	">
        <div className="p-8">
        <h4 className="text-xl mt-4 text-start text-black font-semibold">
                          About Club
                        </h4>
          <div className="space-y-5 lg:space-y-8">
            <p className="text-base text-gray-800 darki:text-gray-200">
             {clubs[0]?.description==="" || clubs[0]?.description==="0"? (<span className="opacity-70">No Information</span>): (clubs[0]?.description)} <br/>  <br/> 
              
            </p>
          </div>

          <h4 className="text-xl mt-4 text-start text-black font-semibold">
                          Mission & Goals
                        </h4>
                        <div className="space-y-5 lg:space-y-8">

                        {clubs[0]?.goals==="" || clubs[0]?.goals==="0"? (<span className="opacity-70">No Information</span>): (
                          <>
                          <p className="text-base text-gray-800 m-0 darki:text-gray-200">
        {isExpanded ? text : truncatedText}
      </p>
      {text?.length > maxLength && (
        <button
          onClick={toggleReadMore}
          className="text-blue-500 mt-0 hover:underline"
        >
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      )}
    
                          </>
                        )}
                        </div>

    <h4 className="text-xl mt-8 text-start text-black font-semibold">
                          Schedule
                        </h4>
          {newData.length>0? (
            <div
                  className="bg-white rounded-xl p-2 shadow-xl"
                  style={{ height: 500 }}
                >
                  <Calendar
                    localizer={localizer}
                    events={newData}
                    startAccessor="start"
                    endAccessor="end"
                    popup={true}
                    onShowMore={(events, date) => console.log(date)}
                  />
                </div>
          ):
          
          (

            <div class="mt-4 bg-blue-100 border border-blue-200 text-sm text-blue-800 rounded-lg p-4 darki:bg-blue-800/10 darki:border-blue-900 darki:text-blue-500" role="alert">
This club does not have a planned schedule. Teachers will notify you for any change
</div>
          )

          }

          
        </div>
      </div>
      {/* End Content */}
      {/* Sidebar */}
      
      
      <div className="lg:col-span-1 lg:w-full lg:h-full bg-white rounded-xl order-1	">
        <div className="sticky top-0 start-0 p-8 ">
          {/* Avatar Media */}
          <div className="group flex items-center gap-x-3 border-b border-gray-200 pb-8 mb-8 darki:border-gray-700">

            <a className="group grow block" href="">
              <h5 className="group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
              {parseFloat(clubs[0]?.fees)===0 || parseInt(clubs[0]?.fees)===0 || clubs[0]?.fees === "0.00" ?(
                 <div>Free Membership</div>
              ):(
                <div>AED {parseInt(clubs[0]?.fees)} Membership fee</div>
              )}
                
              </h5>
              {isEnrolled.status === true ? (
                        <span style={{ color: "green" }}>Enrolled</span>
                      ) : isEnrolled.status === false ? (
                        <span style={{ color: "red" }}>Rejected</span>
                      ) : isEnrolled.status === null ? (
                        <span style={{ color: "orange" }}>Pending</span>
                      ) : (
                        <button
                          className="btn btn-primary btn-lg"
                          onClick={handleEnroll}
                        >
                          Enroll
                        </button>
                      )}
            </a>

          </div>

          {clubs[0]?.importantNotes==="" || clubs[0]?.importantNotes==="0"? (<span className="opacity-70"> </span>): (          <div class="mt-2 bg-yellow-100 border border-yellow-200 text-sm text-yellow-800 rounded-lg p-4 darki:bg-yellow-800/10 darki:border-yellow-900 darki:text-yellow-500" role="alert">
  <span class="font-bold">Note: </span> {clubs[0]?.importantNotes}
</div>)}




<h4 className="text-xl mt-8 text-start text-black font-semibold">
                          Meet the team
                        </h4>
                        

                        <AvatarCard name={`Ms Zakir`} role={`Faculty Advisor`} />

                        <AvatarCard name={`Adam Noor`} role={`Student Leader`} />


          {/* End Avatar Media */}
        </div>
      </div>
      {/* End Sidebar */}
    </div>
  </div>
  {/* End Blog Article */}
</>





            
          </div>
        </>
      </div>
    </div>
  );
};

export default Tweet;
