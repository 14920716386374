import React, { useState, useEffect } from 'react';

function SchoolNews(props) {



  return (

    <>
  {/* Card Blog */}
  <div className="container mx-auto mt-8 bg-white py-8 rounded-xl mb-32">
    {/* Title */}
    <div className="mx-auto text-start mb-10 lg:mb-14">
      <h2 className="text-2xl font-bold md:leading-tight darki:text-white">
      School News
      </h2>
      <p className="mt-1 text-gray-600 darki:text-gray-400">
        Stay in the know with insights from industry experts.
      </p>
    </div>
    {/* End Title */}
    {/* Grid */}
    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {/* Card */}
      <a
        className="group darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
        href="#"
      >
        <div className="relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
          <img
            className="w-full h-full absolute top-0 start-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl"
            src="https://iscdubai.sabis.net/Prv/Images/Pages/Page_1165/science-fair16890987-m.jpg"
            alt="Image Description"
          />
          <span className="absolute top-0 end-0 rounded-se-xl rounded-es-xl text-xs font-medium bg-gray-800 text-white py-1.5 px-3 darki:bg-gray-900">
            Important
          </span>
        </div>
        <div className="mt-7">
          <h3 className="text-xl font-semibold text-gray-800 group-hover:text-gray-600 darki:text-gray-200">
          Science Fair Showcases Student Innovation
          </h3>
          <p className="mt-3 text-gray-800 darki:text-gray-200">
          In an exciting display of creativity and scientific prowess, our school recently held its annual Science Fair, ...
          </p>
          <p className="mt-5 inline-flex items-center gap-x-1 text-blue-600 decoration-2 group-hover:underline font-medium">
            Read more
            <svg
              className="flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </p>
        </div>
      </a>
      {/* End Card */}
      {/* Card */}
      <a
        className="group darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
        href="#"
      >
        <div className="relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
          <img
            className="w-full h-full absolute top-0 start-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl"
            src="https://i0.wp.com/calmatters.org/wp-content/uploads/2022/06/081522-BACK-TO-SCHOOL-AP-JCH-CM.jpg?fit=1200%2C800&ssl=1"
            alt="Image Description"
          />
        </div>
        <div className="mt-7">
          <h3 className="text-xl font-semibold text-gray-800 group-hover:text-gray-600 darki:text-gray-200">
          Community Service Day Leaves a Lasting Impact
          </h3>
          <p className="mt-3 text-gray-800 darki:text-gray-200">
          Our school community came together for a day of service, making a positive impact on our local community. Students ...
          </p>
          <p className="mt-5 inline-flex items-center gap-x-1 text-blue-600 decoration-2 group-hover:underline font-medium">
            Read more
            <svg
              className="flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </p>
        </div>
      </a>
      {/* End Card */}
      {/* Card */}
      <a
        className="group relative flex flex-col w-full min-h-[15rem] bg-center bg-cover rounded-xl hover:shadow-lg transition bg-[url('https://images.unsplash.com/photo-1634017839464-5c339ebe3cb4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3000&q=80')] darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
        href="#"
      >
        <div className="flex-auto p-4 md:p-6">
          <h3 className="text-xl text-white/[.9] group-hover:text-white">
          Unlocking Potential: Introducing Co-Curricular Experience Consulting for Students
          </h3>
        </div>
        <div className="pt-0 p-4 md:p-6">
          <div className="inline-flex items-center gap-2 text-sm font-medium text-white group-hover:text-white/[.7]">
            Learn more about the program
            <svg
              className="flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </div>
        </div>
      </a>
      {/* End Card */}
    </div>
    {/* End Grid */}
  </div>
  {/* End Card Blog */}
</>


  )
}

export default SchoolNews;