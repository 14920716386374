import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AuthContext from '../context/AuthContext';
import MobileBottom from '../components/MobileBottom';
import WeeklyLeaderboard from './WeeklyLeaderboard';
import { toast, Toaster } from 'react-hot-toast';
import Confetti from 'react-dom-confetti';
import Sidebar from '../components/Sidebar';
import SideInnerSM from '../components/SideInnerSM';
import Navbar from '../components/Navbar';
import { useLanguage } from '../components/language';
const Challenge = () => {
  const { id } = useParams(); // Get the challengeID from the URL using useParams
  const [challenge, setChallenge] = useState(null); // Use null to indicate no challenge initially
  const [challenge_results, setChallenge_results] = useState(null); // Use null to indicate no challenge initially
  const [is_submitted, setis_submitted] = useState(false); // Use null to indicate no challenge initially
  const [loading, setLoading] = useState(true);
  let {user} = useContext(AuthContext);
  const navigate = useNavigate();
  let userID = user['user_id'];
  const [profile, setProfile] = useState()
  useEffect(()=> {
    const getProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/profile/${userID}`)

        // parse the data in json
        let data = await response.json()

        setProfile(data)
      } catch (err) {
        console.log("The requested profile does not exists.")
    }
        
    }
    getProfile()
  }, [])

  const { t, changeLanguage, lanArabic } = useLanguage();
  const [formData, setFormData] = useState({
    user_name: '',
    truefalse_all_answers: '',
    mcq_all_answers: '',
    fill_all_answers: '',
  });

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handlePrevTab = () => {
    if (selectedTabIndex > 0) {
      setSelectedTabIndex(selectedTabIndex - 1);
    }
  };

  const handleNextTab = () => {
    if (selectedTabIndex < 4) {
      setSelectedTabIndex(selectedTabIndex + 1);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  let challengeScore = 0;

    // Check TF answer
    if (formData.truefalse_all_answers) {
      if (formData.truefalse_all_answers === `"${challenge.truefalse_correct_answer}"` ) {
        challengeScore += 1;
      }
    }

    // Check MCQ answer
    if (formData.mcq_all_answers) {
      if (formData.mcq_all_answers === `"${challenge.mcq_correct_answer}"` ) {
        challengeScore += 1;
      }
    }

    // Check Fill in the Blanks answer

    if (formData.fill_all_answers) {
      if (formData.fill_all_answers ===  `"${challenge.fill_correct_answer}"`) {
        challengeScore += 1;
      }
    }

    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    const toggleSidebar = () => {
      setIsSidebarVisible(prevState => !prevState);
    }
  const handleSubmit = (e) => {
    e.preventDefault();

    
    // Prepare the data to be posted
    const postData = {

        // Calculate the score based on user choices and correct answers

      
      challenge_id: challenge.id,
      challenge_name: challenge.challenge_name,
      user_id: userID, // Assuming user_id is based on the challenge ID
      user_name: profile?.firstname+' '+profile?.lastname,
      score: challengeScore, // Set the initial score as needed
      truefalse_all_answers: formData.truefalse_all_answers,
      mcq_all_answers: formData.mcq_all_answers,
      fill_all_answers: formData.fill_all_answers,
      post_date: new Date().toISOString().split('T')[0], // Get the current date in ISO 8601 format (YYYY-MM-DD)
    };
    setIsButtonClicked(true); // Set the button to clicked
    // Send a POST request to your Django backend to store the data
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/add_challenge_result/`, postData)
      .then((response) => {
        
        toast.success('Good Job! Result Saved', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      })
      .catch((error) => {
        toast.error('Oh No! Something went wrong', {
          icon: '😓',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      });


  };

  let {logoutUser} = useContext(AuthContext)

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [confetti, setConfetti] = useState(false);

  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 20,
    elementCount: 200,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  const handleClick = () => setConfetti(true);

  useEffect(() => {
    // Ensure id is an integer
    const parsedID = parseInt(id);

    // Fetch all weekly challenges data from your Django backend
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_challenge/`)
      .then((response) => {
        // Filter the challenges based on id
        const matchingChallenge = response.data.find((challenge) => challenge.id === parsedID);

        if (matchingChallenge) {
          setChallenge(matchingChallenge);
        }

        setLoading(false); // Update loading state when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching weekly challenges:', error);
        setLoading(false); // Update loading state on error
      });



      axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_challenge_result/`)
      .then((response) => {
        // Filter the challenges based on id
        const matchingChallengeResult = response.data.find((challengeResult) => challengeResult.challenge_id === parsedID);

        const submission = response.data.find((submitssionCheck) => submitssionCheck.challenge_id === parsedID && submitssionCheck.user_id === userID);

        if (matchingChallengeResult) {
          setChallenge_results(matchingChallengeResult);
        }
       
        if (submission) {
          setis_submitted(submission);
        }

        

        setLoading(false); // Update loading state when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching weekly challenges:', error);
        setLoading(false); // Update loading state on error
      });
  }, [id]);

  const submissionCount = is_submitted.length;

  const [isStart, setIsStart] = useState(false);

  const handleClickStart = event => {

    setIsStart(current => !current);

  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (!challenge) {
    return <div>Challenge not found.</div>;
  }

  const currentDate = new Date();
  const dueDate = new Date(challenge.due_date);

  // Check if the current date is greater than the due date
  const isExpired = currentDate > dueDate;



  
  return (
    <div>

<div>


<div><>

<div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>


<Sidebar />

{isSidebarVisible && <SideInnerSM/>}

    <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? 'xl:mr-68' : 'xl:ml-68'} rounded-xl`}>
      {/* Navbar */}
      <Navbar toggleSidebar={toggleSidebar} />

      {/* end Navbar */}
      {/* cards */}
      <div className="w-full px-6 py-6 mx-auto">



<div>


{is_submitted && (
  <div className="bg-complete px-4 py-3 text-white rounded-2xl">
  <p className="text-center m-0 text-sm font-medium"> 🎉 You've already completed this challenge </p>
</div>
                      )}



<div style={{ zIndex: 999999999999999}}>
        <Toaster position="bottom-center" />
      </div>

            {!isStart && (
              <div className='bg-silver-200 mt-4 mx-4'>
                <div className="card bg-base-100 shadow-xl">
                  <div className="card-body">
                    <h2 className="card-title">{challenge.challenge_name}</h2>
                    <p>{challenge.notification}</p>
                    <div className="card-actions justify-end">
                      {!is_submitted && (
                        
                        <div>
                        {isExpired ? (
                          <button className="btn btn-error">Challenged Missed</button>
                            ) : (
                              <button onClick={handleClickStart} className="btn btn-primary"> Start Now</button>
                            )}
                        </div>
                      )}


                    </div>
                  </div>
                </div>
              </div>
            )}

            {isStart && (
              <div className='bg-white shadow-lg mx-8 mt-4 rounded-lg py-2 px-6'>
        <Tabs selectedIndex={selectedTabIndex} onSelect={index => setSelectedTabIndex(index)}>
          <TabList className="hidden">
            <Tab className="tab tab-bordered">Welcome</Tab>
            <Tab className="tab tab-bordered">TF</Tab>
            <Tab className="tab tab-bordered">MCQ</Tab>
            <Tab className="tab tab-bordered">FIB</Tab>
            <Tab className="tab tab-bordered">Submit</Tab>
          </TabList>

          <TabPanel>
            <h5> Hi {profile?.firstname} 👋, Welcome to </h5> <br/> <h3 className='text-lg font-medium'> {challenge.challenge_name} </h3>
          </TabPanel>
          <TabPanel>
            <div>
            <p className='mb-2 font-medium text-gray-500'> Question - 1 </p>
            <h3 className='text-lg font-medium'>{challenge.truefalse_title} </h3>
              <div className="mt-6">


                {challenge.truefalse_all_answers.map((option, index) => (
                  <div className='flex flex-row items-cenetr gap-4 my-4' key={index}>
                    <input
                      type="radio"
                      id={`truefalseOption${index}`}
                      className='radio radio-primary'
                      name="truefalse_all_answers"
                      value={JSON.stringify(option)}
                      checked={formData.truefalse_all_answers === JSON.stringify(option)}
                      onChange={handleInputChange}
                    />
                    <label htmlFor={`truefalseOption${index}`}>{option}</label>
                  </div>
                ))}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
          <div>
          <p className='mb-2 font-medium text-gray-500'> Question - 2 </p>
    <h3 className='text-lg font-medium'>{challenge.mcq_title} </h3>
    <div className="mt-6">
      {/* Map MCQ answers as radio buttons */}
      {challenge.mcq_all_answers.map((option, index) => (
        <div className='flex flex-row items-cenetr gap-4 my-4' key={index}>
          <input
            type="radio"
            id={`mcqOption${index}`}
            name="mcq_all_answers"
            className='radio radio-primary'
            value={JSON.stringify(option)}
            checked={formData.mcq_all_answers === JSON.stringify(option)}
            onChange={handleInputChange}
          />
          <label htmlFor={`mcqOption${index}`}>{option}</label>
        </div>
      ))}
      
    </div>
  </div>
          </TabPanel>
          <TabPanel>
          <div>
          <p className='mb-2 font-medium text-gray-500'> Question - 3 </p>
    <h3 className='text-lg font-medium'>{challenge.fill_title} </h3>
    <div className="mt-4">
      {/* Map Fill in the Blanks answers as radio buttons */}
      {challenge.fill_all_answers.map((option, index) => (
        <div className='flex flex-row items-cenetr gap-4 my-4' key={index}>
          <input
            type="radio"
            id={`fillOption${index}`}
            name="fill_all_answers"
            className='radio radio-primary'
            value={JSON.stringify(option)}
            checked={formData.fill_all_answers === JSON.stringify(option)}
            onChange={handleInputChange}
          />
          <label htmlFor={`fillOption${index}`}>{option}</label>
        </div>
      ))}
    </div>
  </div>
          </TabPanel>
          <TabPanel>
          <div className='flex flex-col justify-center mb-4'>
            <img className='w-9/12 md:w-5/12 block mx-auto' src='https://i.pinimg.com/originals/b7/2d/d9/b72dd998767808115569d5082600118b.gif' />
            <form onSubmit={handleSubmit} className='flex flex-col justify-center'>
              <h3 className='text-lg font-medium text-center'> Ready to submit result? </h3>
              <p className='text-gray-400 text-center'> You can still go back and update your answers otherwise, click to submit to save result.</p>
              
                      {!isButtonClicked && (
                       <div className='flex justify-center'>
                       <button type="submit" onClick={handleClick} className='btn btn-block btn-warning mt-4' >Submit Answers</button>
                       
                       </div> 
                      )}

                      {isButtonClicked && (
                        <button type='button' className="btn btn-success mt-4 gap-2" onClick={() => navigate(-1)}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
</svg>
Great! Go Back</button>
                      )}

            </form>
          </div>
          </TabPanel>
        </Tabs>
        <div className="flex flex-row gap-4 justify-between items-center mb-6">

          

          {(() => {
        if (selectedTabIndex > 0) {
          return (
            <button className="btn btn-primary btn-circle" onClick={handlePrevTab}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path fill-rule="evenodd" d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z" clip-rule="evenodd" />
            </svg>
          </button>
          )
        }
      })()}
      
      <progress className="progress progress-primary w-56" value={(selectedTabIndex / 4) * 100} max="100"></progress>

          {(() => {
        if (selectedTabIndex < 4) {
          return (
            <button className="btn btn-primary btn-circle" onClick={handleNextTab}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path fill-rule="evenodd" d="M3.75 12a.75.75 0 01.75-.75h13.19l-5.47-5.47a.75.75 0 011.06-1.06l6.75 6.75a.75.75 0 010 1.06l-6.75 6.75a.75.75 0 11-1.06-1.06l5.47-5.47H4.5a.75.75 0 01-.75-.75z" clip-rule="evenodd" />
            </svg>
          </button>
          )
        }
      })()}
          

        </div>
        
      </div>
            )}

            {!isStart && (<WeeklyLeaderboard/>)}
            <Confetti active={confetti} config={confettiConfig} />
<MobileBottom/>




</div>


      </div>
    </main>
  </>
  </div>

    
    </div>


    </div>
  );
};

export default Challenge;
