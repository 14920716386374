import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AuthContext from '../context/AuthContext';

import MobileBottom from '../components/MobileBottom';
import './Leaderboard.css';
const WeeklyLeaderboard = () => {
  const { id } = useParams(); // Get the challengeID from the URL using useParams
  const [challenge, setChallenge] = useState(null); // Use null to indicate no challenge initially
  const [challenge_results, setChallenge_results] = useState(null); // Use null to indicate no challenge initially
  const [leaderboard, setleaderboard] = useState(null); // Use null to indicate no challenge initially
  const [loading, setLoading] = useState(true);
  let {user} = useContext(AuthContext);

  let userID = user['user_id'];
  const [profile, setProfile] = useState()
  useEffect(()=> {
    const getProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/profile/${userID}`)

        // parse the data in json
        let data = await response.json()

        setProfile(data)
      } catch (err) {
        console.log("The requested profile does not exists.")
    }
        
    }
    getProfile()
  }, [])

  const [formData, setFormData] = useState({
    user_name: '',
    truefalse_all_answers: '',
    mcq_all_answers: '',
    fill_all_answers: '',
  });

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handlePrevTab = () => {
    if (selectedTabIndex > 0) {
      setSelectedTabIndex(selectedTabIndex - 1);
    }
  };

  const handleNextTab = () => {
    if (selectedTabIndex < 4) {
      setSelectedTabIndex(selectedTabIndex + 1);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  let challengeScore = 0;

    // Check TF answer
    if (formData.truefalse_all_answers) {
      if (formData.truefalse_all_answers === `"${challenge.truefalse_correct_answer}"` ) {
        challengeScore += 1;
      }
    }

    // Check MCQ answer
    if (formData.mcq_all_answers) {
      if (formData.mcq_all_answers === `"${challenge.mcq_correct_answer}"` ) {
        challengeScore += 1;
      }
    }

    // Check Fill in the Blanks answer

    if (formData.fill_all_answers) {
      if (formData.fill_all_answers ===  `"${challenge.fill_correct_answer}"`) {
        challengeScore += 1;
      }
    }



  const handleSubmit = (e) => {
    e.preventDefault();

    
    // Prepare the data to be posted
    const postData = {

        // Calculate the score based on user choices and correct answers

      
      challenge_id: challenge.id,
      challenge_name: challenge.challenge_name,
      user_id: userID, // Assuming user_id is based on the challenge ID
      user_name: profile?.firstname+' '+profile?.lastname,
      score: challengeScore, // Set the initial score as needed
      truefalse_all_answers: formData.truefalse_all_answers,
      mcq_all_answers: formData.mcq_all_answers,
      fill_all_answers: formData.fill_all_answers,
      post_date: new Date().toISOString().split('T')[0], // Get the current date in ISO 8601 format (YYYY-MM-DD)
    };

    // Send a POST request to your Django backend to store the data
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/add_challenge_result/`, postData)
      .then((response) => {
        console.log('Challenge result added successfully:', response.data);
        // You can handle success as needed, e.g., redirect to a confirmation page
      })
      .catch((error) => {
        console.error('Error adding challenge result:', error);
      });


  };

  let {logoutUser} = useContext(AuthContext)

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    // Ensure id is an integer
    const parsedID = parseInt(id);

    // Fetch all weekly challenges data from your Django backend
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_challenge/`)
      .then((response) => {
        // Filter the challenges based on id
        const matchingChallenge = response.data.find((challenge) => challenge.id === parsedID);

        if (matchingChallenge) {
          setChallenge(matchingChallenge);
        }

        setLoading(false); // Update loading state when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching weekly challenges:', error);
        setLoading(false); // Update loading state on error
      });



      axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_challenge_result/`)
      .then((response) => {
        // Filter the challenges based on id
        const matchingChallengeResult = response.data.find((challengeResult) => challengeResult.challenge_id === parsedID);

        if (matchingChallengeResult) {
          setChallenge_results(matchingChallengeResult);
        }

        setLoading(false); // Update loading state when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching weekly challenges:', error);
        setLoading(false); // Update loading state on error
      });


      
      axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_challenge_leaderboard/`)
      .then((response) => {
        // Filter the challenges based on id
        const leaderboardFetch = response.data.find((lead) => lead.challenge_id === parsedID);

        if (leaderboardFetch) {
          setleaderboard(leaderboardFetch);
        }

        setLoading(false); // Update loading state when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching weekly challenges:', error);
        setLoading(false); // Update loading state on error
      });

  }, [id]);

  const [isStart, setIsStart] = useState(false);

  const handleClickStart = event => {

    setIsStart(current => !current);

  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (!challenge) {
    return <div>Challenge not found.</div>;
  }

  
  return (
    <div className=''>


      {leaderboard ? (
        
        <div className='mt-8 mx-4'>


        <div className='grid grid-cols-3 items-center gap-6 bg-purple-200 h-64 mt-8 p-4 rounded-xl hidden'> 
        <div className='flex flex-col items-center justify-center gap-3'>
            <img className='w-8/12' src='https://res.cloudinary.com/ahbranding/image/upload/v1694765507/AHbranding/Group_2_kakw0r.png' />
            <h3 className='text-lg text-primary font-medium text-center'> {leaderboard?.second_pos} </h3>
          </div>
        
          <div className='flex flex-col items-center justify-center'>
            <img className='w-12/12' src='https://res.cloudinary.com/ahbranding/image/upload/v1694765508/AHbranding/Group_3_km4p6b.png' />
            <h3 className='text-xl text-primary font-bold text-center'>  </h3>
          </div>

          <div className='flex flex-col items-center justify-center'>
            <img className='w-8/12' src='https://res.cloudinary.com/ahbranding/image/upload/v1694765507/AHbranding/Group_4_nhczxx.png' />
            <h3 className='text-lg text-primary font-medium text-center'> {leaderboard?.third_pos} </h3>
          </div>

        </div>





        <main className='mainLB'>
      <div id="headerLB">
        <h1 className='font-bold text-lg'>Leaderboard</h1>

      </div>
      <div id="leaderboard">


        <table className='tableLB'>

        <tr className='hidden'>
            <td className=" text-amber-400">1</td>
            <td className="font-medium text-base">Lee Taeyong</td>
            <td className="points"> <img className="gold-medal" src="https://github.com/malunaridev/Challenges-iCodeThis/blob/master/4-leaderboard/assets/gold-medal.png?raw=true" alt="gold medal"/> </td>
          </tr>


          <tr>
            <td className="number text-amber-400">1</td>
            <td className="font-medium text-base">{leaderboard?.first_pos}</td>
            <td className="points"> <img className="gold-medal" src="https://res.cloudinary.com/ahbranding/image/upload/v1694767690/AHbranding/image_5_rmdgid.png" alt="gold medal"/> </td>
          </tr>



          <tr>
            <td className="number text-slate-500	">2</td>
            <td className="font-medium text-base">{leaderboard?.second_pos}</td>
            <td className="points"> <img className="gold-medal" src="https://res.cloudinary.com/ahbranding/image/upload/v1694767690/AHbranding/image_4_xy7or8.png" alt="gold medal"/> </td>
          </tr>

          <tr>
            <td className="number text-amber-600">3</td>
            <td className="font-medium text-base">{leaderboard?.third_pos}</td>
            <td className="points"> <img className="gold-medal" src="https://res.cloudinary.com/ahbranding/image/upload/v1694767690/AHbranding/image_6_qdqziw.png" alt="gold medal"/> </td>
          </tr>
        </table>
      </div>
    </main>





        </div>
        
) : (
  <div className='mt-8 mx-8'>
  <h3 className='text-lg font-medium'> Leaderboard </h3>
    Leaderboard will be live soon
  </div>
)}

<MobileBottom/>
    </div>
  );
};

export default WeeklyLeaderboard;
