import React, { useState, useEffect } from 'react';
import Tweet from './Tweet';
import ClubDetailsInternal from './ClubDetailsInternal';

function ClubDetails(props) {


    const [selectedRole, setSelectedRole] = useState("");

  useEffect(() => {
    // Retrieve the value from localStorage
    const storedRole = localStorage.getItem('selectedRole');

    // Update the state with the retrieved value
    if (storedRole) {
      setSelectedRole(storedRole);
    }
  }, []);


    return (
<div>
{(() => {
        if (selectedRole === 'Public') {
          return (
            <Tweet/>
          )
        } else {
          return (
            <ClubDetailsInternal/>
          )
        }
      })()}
</div>
    )
}

export default ClubDetails;