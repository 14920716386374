// src/components/ClubCard.js
import React from "react";
import { Link } from "react-router-dom";



function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: 'short', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedTime = `${hours % 12 || 12}${minutes === 0 ? '' : ':' + minutes}${ampm}`;
    return `${formattedDate}, ${formattedTime}`;
}

// Example usage
const start_date = "2024-05-19T11:15:00Z";
const formattedDate = formatDate(start_date);

const StdEventCard = ({ id, name, start_date, fees, banner, description, imageUrl }) => {
//   const truncatedCategory =
//     description.length > 20 ? description.slice(0, 20) + "..." : description;
    

  return (
    <div className="mx-auto !z-5 relative flex flex-col rounded-[20px] w-max bg-white bg-clip-border shadow-3xl shadow-shadow-500 flex flex-col w-full !p-4 3xl:p-![18px] bg-white undefined">
      <div className="h-full w-full">
        <div className="relative w-full">
          <img
            src={banner}
            className="h-[10rem] w-full rounded-xl"
            style={{    objectFit: 'cover'}}
            alt=""
          />
        </div>
        <div className="mb-3 flex items-center justify-between px-1 md:items-start">
          <div className="">
            <p className="text-lg font-bold text-navy-700 mb-0 mt-3"> {name} </p>
            <div className="text-sm opacity-80">
                {formattedDate}
            </div>
            
          </div>
        </div>
        <div className="flex items-center justify-between md:items-center lg:justify-between ">
          <Link
            to={`/event_timeline/${id}`}
            className="btn btn-sm btn-ghost text-primary"
          >
            {" "}
            View Details{" "}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StdEventCard;
