import React, { useEffect,useState ,useRef, } from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import { useLanguage } from "../components/language";
import Navbar from "../components/Navbar";
import "react-toggle/style.css" 
import { createGlobalStyle } from "styled-components";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import Select from "react-select";
import SampleFile from "./SampleFile";
import axios from "axios";
import { Line } from 'rc-progress';
import UsersUpload from "./UsersUpload";
import { Link } from "react-router-dom";
// import cloud from "../assets/images/cloud_upload.svg"


const EventDetailsPage_APPRVD = () => {
const [isSidebarVisible, setIsSidebarVisible] = useState(false);
const { t, changeLanguage, lanArabic } = useLanguage();
const [isEditModalOpen, setIsEditModalOpen] = useState(false);
const roleOptions = [
  { value: "admin", label: "Admin" },
  { value: "teacher", label: "Teacher" },
  // Add more role options as needed
];

const permissionOptions = [
  { value: "read", label: "Read" },
  { value: "write", label: "Write" },
  // Add more permission options as needed
];



const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState);
  }

  return (
    <div>


    <div>
      <>
    
    <div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>
    
    
    <Sidebar />
    
    {isSidebarVisible && <SideInnerSM/>}
    
        <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? 'xl:mr-68' : 'xl:ml-68'} rounded-xl`}>
          {/* Navbar */}
          <Navbar toggleSidebar={toggleSidebar} />
    
          {/* end Navbar */}
          {/* cards */}
          <div className="w-full lg:p-6 p-3 mx-auto">
    
          <div className="w-full bg-white rounded-lg  md:p-8 lg:p-14  p-2 flex flex-col gap-10 ">

             {/* Article */}
  <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto">

  <div className="grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 lg:gap-x-6 lg:gap-x-12">
    {/* Content */}
    <div className="lg:col-span-2">
      <div className="">

      <div className='' >

      <div>
      <Link class="inline-flex items-center gap-x-1.5 text-sm text-gray-600 decoration-2 hover:underline darki:text-blue-400 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600" href="#">
            <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m15 18-6-6 6-6"/></svg>
            Back to Activities
          </Link>

          <h2 class="text-3xl font-bold lg:text-3xl lg:text-4xl darki:text-white mt-4">Sports Day <span class="ml-2 inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-teal-500 text-white">Approved</span></h2>

          <div class="flex items-center gap-x-5">
            <a class="inline-flex items-center gap-1.5 py-1 px-3 sm:py-2 sm:px-4 rounded-full text-xs sm:text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 darki:bg-gray-800 darki:hover:bg-gray-800 darki:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600" href="#">
              Full Event
            </a>

            <a class="inline-flex items-center gap-1.5 py-1 px-3 sm:py-2 sm:px-4 rounded-full text-xs sm:text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 darki:bg-gray-800 darki:hover:bg-gray-800 darki:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600" href="#">
              Onsite
            </a>
          </div>
      </div>


      <div className="group mt-12 flex items-center gap-x-3 border-b border-gray-200 pb-8 mb-8 darki:border-gray-700">
  <div className="block flex-shrink-0">
    <img
      className="h-10 w-10 rounded-full img-fit"
      src="https://res.cloudinary.com/ahbranding/image/upload/v1705663616/Group_34784500_avny0v.png"
      alt="Image Description"
    />
  </div>
  <div className="group grow block" >
    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
      Strikers FC
    </h5>
    <p className="text-sm text-gray-500 m-0">Sports Club</p>
  </div>
  
</div>


<div className="group mt-8 flex items-center gap-x-3 border-b border-gray-200 pb-8 mb-8 darki:border-gray-700">
  <div className="block flex-shrink-0">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
</svg>

  </div>
  <div className="group grow block" >
    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
      Start Date
    </h5>
    <p className="text-sm text-gray-500 m-0">March 21, 2024</p>
  </div>

  <div className="block flex-shrink-0">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
</svg>


  </div>
  <div className="group grow block" >
    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
      Block Date
    </h5>
    <p className="text-sm text-gray-500 m-0">-</p>
  </div>


  <div className="block flex-shrink-0">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
</svg>

  </div>
  <div className="group grow block" >
    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
      End Date
    </h5>
    <p className="text-sm text-gray-500 m-0">March 21, 2024</p>
  </div>
  
</div>








<div className="group mt-8 flex items-center gap-x-3 border-b border-gray-200 pb-8 mb-8 darki:border-gray-700">
  <div className="block flex-shrink-0">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>


  </div>
  <div className="group grow block" >
    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
      Start Time
    </h5>
    <p className="text-sm text-gray-500 m-0">1:00 PM</p>
  </div>

  <div className="block flex-shrink-0">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002" />
</svg>



  </div>
  <div className="group grow block" >
    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
      Break Time
    </h5>
    <p className="text-sm text-gray-500 m-0">2:00 - 2:30 PM</p>
  </div>


  <div className="block flex-shrink-0">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>


  </div>
  <div className="group grow block" >
    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
      End Time
    </h5>
    <p className="text-sm text-gray-500 m-0">4:00 PM</p>
  </div>
  
</div>









<div class="space-y-4">
  <details class="group rounded-lg bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden" open>
    <summary class="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900">
      <h2 class="font-medium text-lg">Event Request</h2>

      <span class="relative h-5 w-5 shrink-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
    </summary>

    <p class="mt-4 leading-relaxed text-gray-700">

    <div className="group mt-12 flex items-center gap-x-3 border-b border-gray-200 pb-8 mb-8 darki:border-gray-700">
  <div className="block flex-shrink-0">
    <img
      className="h-10 w-10 rounded-full img-fit"
      src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
      alt="Image Description"
    />
  </div>
  <div className="group grow block" >
    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
      Lauren Walker
    </h5>
    <p className="text-sm text-gray-500 m-0">Faculty Advisor</p>
  </div>



  <div className="block flex-shrink-0">
    <img
      className="h-10 w-10 rounded-full img-fit"
      src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
      alt="Image Description"
    />
  </div>
  <div className="group grow block" >
    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
      Aaron Larson
    </h5>
    <p className="text-sm text-gray-500 m-0">Faculty Advisor</p>
  </div>
  
  
</div>

<h3 className="font-medium text-base"> Event Purpose </h3>
Sports Day aims to promote physical well-being, teamwork, and a healthy competitive spirit among our students, staff, and faculty. The event will feature a variety of sports and recreational activities, including but not limited to:

    </p>
  </details>













  <details class="group rounded-lg bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden" open>
    <summary class="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900">
      <h2 class="font-medium text-lg">Event Options</h2>

      <span class="relative h-5 w-5 shrink-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
    </summary>

    <p class="mt-4 leading-relaxed text-gray-700">
    <h3 className="font-medium text-base">Venues & Locations</h3>
    <div className="group my-6 flex items-center gap-x-3 darki:border-gray-700">
  <div className="block flex-shrink-0">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
</svg>

  </div>

  <div className="group grow block" >
    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
      Main Sports Ground
    </h5>
    <p className="text-sm text-gray-500 m-0">Mar 21, 2024 • 1:00 PM to 4:00 PM</p>
  </div>
  
  
</div>






<div className="divider"></div>


<h3 className="font-medium text-base">Budget</h3>
    <div className="group my-6 flex items-center gap-x-3 darki:border-gray-700">
  <div className="block flex-shrink-0">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
</svg>


  </div>

  <div className="group grow block" >
    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
      Refreshments
    </h5>
    <p className="text-sm text-gray-500 m-0">$450</p>
  </div>
  
  
</div>

<div className="divider"></div>


<h3 className="font-medium text-base">Parent Consents</h3>
    <div className="group my-6 flex items-center gap-x-3 darki:border-gray-700">
  <div className="block flex-shrink-0">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
</svg>



  </div>

  <div className="group grow block" >
    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
      Consent Required?
    </h5>
    <p className="text-sm text-gray-500 m-0">Not Required</p>
  </div>
  
  
</div>
    </p>
  </details>

  
</div>





  </div>
        
      </div>
    </div>
    {/* End Content */}
    {/* Sidebar */}
    <div className="lg:col-span-1 lg:w-full lg:h-full bg-white rounded-xl">
      <div className="sticky top-0 start-0 py-8 px-6">


      <div className="bg-white rounded-xl p-2 shadow-xl my-4">
      <div class="p-4 ">
    <div class="flex gap-x-5">
      <div class="grow">
        <h2 class="text-lg font-semibold text-gray-800 darki:text-white">
          Event Attendance
        </h2>
        <p class="mt-2 text-sm text-gray-600 darki:text-gray-400">
          Manage event attendance and participation
        </p>
        <div class="mt-5 flex flex-col gap-4">
          <Link to={'/event_attendance'} className="btn btn-primary">Track Attendance</Link>
        </div>
      </div>
    </div>
  </div>
      </div>



      <div className="bg-white rounded-xl p-2 shadow-xl my-4">
      <div class="p-4 ">
    <div class="flex gap-x-5">
      <div class="grow">
        <h2 class="text-lg font-semibold text-gray-800 darki:text-white">
          Faculty Advisors
        </h2>
        <p class="mt-2 text-sm text-gray-600 darki:text-gray-400">
          Club advisors that were assigned to supervise this event
        </p>
        <div class="flex flex-col gap-4">
        <div className="group my-6 flex flex-col gap-3 darki:border-gray-700">

        <div className="flex flex-row items-center  gap-4">
  <div className="block flex-shrink-0 ">
    <img
      className="h-10 w-10 rounded-full img-fit"
      src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
      alt="Image Description"
    />
  </div>
  <div className="group grow block  " >
    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
      Lauren Walker
    </h5>
    <p className="text-sm text-gray-500 m-0">Faculty Advisor</p>
  </div>
</div>

<div className="flex flex-row items-center gap-4">
  <div className="block flex-shrink-0 ">
    <img
      className="h-10 w-10 rounded-full img-fit"
      src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
      alt="Image Description"
    />
  </div>
  <div className="group grow block" >
    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
      Aaron Larson
    </h5>
    <p className="text-sm text-gray-500 m-0">Faculty Advisor</p>
  </div>
  </div>
  
</div>
        </div>
      </div>
    </div>
  </div>
      </div>
        
      </div>
    </div>
    {/* End Sidebar */}
  </div>
</div>
{/* End Article */}
            
          </div>
    
              
    
    
          </div>
        </main>
      </>
      </div>

        </div>
  )
}

export default EventDetailsPage_APPRVD