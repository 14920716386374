import React, { useState, useEffect } from "react";
import AdminLayout from "./AdminLayout";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import axios from "axios";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import CouncilMemberTable from "../components/CouncilMemberTable";
import StudentCouncilTracker from "../components/StudentCouncilTracker";
// import OrgChart from "../components/OrgChart";

function StudentCouncilDetails({ councils }) {
  const coreCouncil = {
    headers: ["No.", "Name", "Grade", "Position", "House"],
    data: [
      {
        No: 1,
        Name: "Anant Jain",
        Grade: 13,
        Position: "Head Boy",
        House: "Blue House",
      },
      {
        No: 2,
        Name: "Daniya Fatima",
        Grade: 13,
        Position: "Head Girl",
        House: "Yellow House",
      },
      {
        No: 3,
        Name: "Mohammed Usman",
        Grade: 12,
        Position: "Deputy Head Boy",
        House: "Blue House",
      },
      {
        No: 4,
        Name: "Afifa Khan",
        Grade: 12,
        Position: "Deputy Head Girl",
        House: "Green  House",
      },
      {
        No: 5,
        Name: "Tayyib Hasan Abdullah",
        Grade: 11,
        Position: "Sports Captain",
        House: "Red   House",
      },
      {
        No: 6,
        Name: "Aiman Kashif",
        Grade: 10,
        Position: "Sports Captain",
        House: "Red   House",
      },
    ],
  };

  const generateRandomGrade = (min = 6, max = 11) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const captains = {
    headers: ["No.", "Name", "Grade", "Position", "House"],
    data: [
      {
        No: 1,
        Name: "Nishith Kamlesh",
        Grade: generateRandomGrade(),
        Position: "House Captain",
        House: "Blue House",
      },
      {
        No: 2,
        Name: "Mehak Faisal",
        Grade: generateRandomGrade(),
        Position: "House Captain",
        House: "Yellow House",
      },
      {
        No: 3,
        Name: "Mubashira Khanam",
        Grade: generateRandomGrade(),
        Position: "House Captain",
        House: "Red House",
      },
      {
        No: 4,
        Name: "Hassaan Rashid",
        Grade: generateRandomGrade(),
        Position: "House Captain",
        House: "Green House",
      },
    ],
  };

  const vicecaptains = {
    headers: ["No.", "Name", "Grade", "Position", "House"],
    data: [
      {
        No: 1,
        Name: "Mustafa Khurram",
        Grade: generateRandomGrade(),
        Position: "Vice Captain",
        House: "Blue House",
      },
      {
        No: 2,
        Name: "Qusai Sabri Tayseer Mahmod",
        Grade: generateRandomGrade(),
        Position: "Vice Captain",
        House: "Yellow House",
      },
      {
        No: 3,
        Name: "Sana Faisal",
        Grade: generateRandomGrade(),
        Position: "Vice Captain",
        House: "Red House",
      },
      {
        No: 4,
        Name: "Jibran Salman Ansari",
        Grade: generateRandomGrade(),
        Position: "Vice Captain",
        House: "Green House",
      },
    ],
  };

  const leaders = {
    headers: ["No.", "Name", "Grade", "Position", "House"],
    data: [
      {
        No: 1,
        Name: "",
        Grade: generateRandomGrade(),
        Position: "Captain",
        House: "Green House",
      },
      {
        No: 2,
        Name: "Kimberly Fernandes",
        Grade: generateRandomGrade(),
        Position: "Vice-Captain",
        House: "Green House",
      },
      {
        No: 3,
        Name: "Faseh-ur Rehman, Laiba Farooqi",
        Grade: generateRandomGrade(),
        Position: "Year Representatives",
        House: "Blue House",
      },
      {
        No: 4,
        Name: "Sneha Haresh, Abdullah Malik",
        Grade: generateRandomGrade(),
        Position: "Year Representatives",
        House: "Red House",
      },
      {
        No: 5,
        Name: "Zaid Khalil",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Yellow House",
      },
      {
        No: 6,
        Name: "-",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Yellow House",
      },
      {
        No: 7,
        Name: "Mohammed Sabith",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Green House",
      },
      {
        No: 8,
        Name: "Haniya Abdul Gafar",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Blue House",
      },
      {
        No: 9,
        Name: "Farhat Aiman",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Red House",
      },
      {
        No: 10,
        Name: "Azka Javad",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Yellow House",
      },
      {
        No: 11,
        Name: "Adeen Anas",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Green House",
      },
      {
        No: 12,
        Name: "Uzma Wazir",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Blue House",
      },
      {
        No: 13,
        Name: "Hafsa Abdul Gafar",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Red House",
      },
      {
        No: 14,
        Name: "Zeenat Aiman",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Yellow House",
      },
      {
        No: 15,
        Name: "Mohammed Riyan Ainan",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Green House",
      },
      {
        No: 16,
        Name: "Aisha Asadullah",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Blue House",
      },
      {
        No: 17,
        Name: "Mohammed Altaf",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Red House",
      },
      {
        No: 18,
        Name: "Mohammed Ali",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Yellow House",
      },
      {
        No: 19,
        Name: "Taimullah",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Green House",
      },
      {
        No: 20,
        Name: "Ebad",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Blue House",
      },
      {
        No: 21,
        Name: "Shahvir",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Red House",
      },
      {
        No: 22,
        Name: "Aroush",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Yellow House",
      },
      {
        No: 23,
        Name: "Ehsan ullah",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Green House",
      },
      {
        No: 24,
        Name: "Abiha Fatima",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Blue House",
      },
      {
        No: 25,
        Name: "Arshan",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Red House",
      },
      {
        No: 26,
        Name: "Mohammed Omer",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Yellow House",
      },
      {
        No: 27,
        Name: "Amalia Nigeri",
        Grade: generateRandomGrade(),
        Position: "Year Representative",
        House: "Green House",
      },
    ],
  };

  const leadersActaul = {
    headers: ["No.", "Name", "Grade", "Position", "House"],
    data: [
      {
        No: 1,
        Name: "Sana Faisal",
        Grade: generateRandomGrade(),
        Position: "Entrepreneur Leader",
        House: "Green House",
      },
      {
        No: 2,
        Name: "Kimberly Savia Fernandes, Heba Qaiser",
        Grade: generateRandomGrade(),
        Position: "Well Being Leader",
        House: "Green House",
      },
      {
        No: 3,
        Name: "Wania Khalid, Hassaan Ahmed",
        Grade: generateRandomGrade(),
        Position: "Environment Leader",
        House: "Green House",
      },
      {
        No: 4,
        Name: "Adam Noor",
        Grade: generateRandomGrade(),
        Position: "Innovation Leader",
        House: "Green House",
      },
      {
        No: 5,
        Name: "",
        Grade: generateRandomGrade(),
        Position: "Social Service Leader",
        House: "Green House",
      },
      {
        No: 6,
        Name: "Mohammed Kashan",
        Grade: generateRandomGrade(),
        Position: "Media & Communication Leader",
        House: "Green House",
      },
      {
        No: 7,
        Name: "",
        Grade: generateRandomGrade(),
        Position: "Islamic Culture",
        House: "Green House",
      },
      {
        No: 8,
        Name: "Ali Affan",
        Grade: generateRandomGrade(),
        Position: "Discipline Leader",
        House: "Green House",
      },
      {
        No: 9,
        Name: "Hamdiya Ahmed",
        Grade: generateRandomGrade(),
        Position: "Discipline Leader",
        House: "Green House",
      },
      {
        No: 10,
        Name: "Abdur Rehman",
        Grade: generateRandomGrade(),
        Position: "UAE Culture Leader",
        House: "Green House",
      },
    ],
  };


// Combine all data into a single array
const combinedData = [
  ...coreCouncil.data,
  ...captains.data,
  ...vicecaptains.data,
  ...leaders.data,
  ...leadersActaul.data,
];

// Unified headers
const headers = ["Name", "Grade", "Position", "House"];

  return (
    <div>
      <div>
        <div className="">
          <Tabs className={`cursor-pointer`}>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <TabList className="flex gap-6" aria-label="Tabs">
                  <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                    Council Members
                  </Tab>
                  <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                    Tracker
                  </Tab>
                </TabList>
              </div>
            </div>

            <TabPanel>
              <Tabs className={`cursor-pointer mt-6`}>
                <TabList className="flex gap-6 justify-end" aria-label="Tabs">
                  <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                    <span className="flex flex-row items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                        />
                      </svg>
                      List View
                    </span>
                  </Tab>
                  <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                    <span className="flex flex-row items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
                        />
                      </svg>
                      Hierarchy
                    </span>
                  </Tab>
                </TabList>

                <TabPanel>
                  <CouncilMemberTable
                    headers={headers} data={combinedData}
                  />
                </TabPanel>

                <TabPanel>
                  {/* <OrgChart/> */}
                  <img
                    className="w-full"
                    src="https://res.cloudinary.com/ahbranding/image/upload/v1715634037/AHbranding/Group_34784584_rb6nil.png"
                  />
                </TabPanel>
              </Tabs>
            </TabPanel>
            <TabPanel>
              {" "}
              <StudentCouncilTracker type='council' needWeeks={false} />{" "}
            </TabPanel>
          </Tabs>

          {/* End Card */}
        </div>
        {/* End Table Section */}
      </div>
    </div>
  );
}

export default StudentCouncilDetails;
