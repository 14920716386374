import React from 'react';

const CustomOption = ({ innerRef, innerProps, data, isSelected }) => (
  <div
    ref={innerRef}
    {...innerProps}
    className="flex items-center p-2 cursor-pointer"
  >
    <input
      type="checkbox"
      checked={isSelected}
      onChange={() => {}}
      className="mr-2"
    />
    <div className="inline-flex flex-wrap gap-2">
      <div className="inline-flex flex-nowrap items-center bg-white border border-gray-200 rounded-full p-1.5 pe-3 darki:bg-neutral-900 darki:border-neutral-700">
       {data.logo===null || data.logo===''?(null):(
        <img
          className="me-1.5 inline-block w-5 h-5 rounded-full"
          src={data.logo}
          alt="Image Description"
        />
       )}
        <div className="whitespace-nowrap text-sm font-medium text-gray-800 darki:text-white">
          {data.label}
        </div>
      </div>
    </div>
  </div>
);

export default CustomOption;
