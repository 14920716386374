import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
function EmailForm() {
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");

  const [schools, setSchools] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to mimic content loading
    setTimeout(() => {
      setLoading(false); // Set loading to false when content is loaded
    }, 2000); // Adjust the delay as needed
  }, []);

  const [loader, setloader] = useState(false);

  const handleClick = () => {
    setloader(true);

    // Simulate a delay of 4 seconds before hiding the spinner
    setTimeout(() => {
      setloader(false);
    }, 4000);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/schools/`)
      .then((response) => {
        setSchools(response.data);
      })
      .catch((error) => {
        console.error('Error fetching school data:', error);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailData = {
      subject,
      body,
      school: selectedSchool,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/send_bulk/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        // alert("Email sent successfully!");

        toast.success("Emails sent successfully 👏", {
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });

      } else {
        // alert("Error sending email.");

        toast.error("Something went wrong 🤕", {
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });

      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong 🤕", {
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    }
  };




  return (
    <div>



<div>
    {loading ? (
      <div className="flex items-center gap-4 justify-center h-screen">
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
          </div>
    ) : (
      // Your page content goes here
      


      <div>




<main class="mt-0 transition-all duration-200 ease-in-out">
<section>
  <div class="relative flex items-center min-h-screen p-0 overflow-hidden bg-center bg-cover">
    <div class="container z-1">
      <div class="flex flex-wrap justify-center">
        <div class="flex flex-col w-full max-w-full px-3 mx-auto lg:mx-0 shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-4/12">
          <div class="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none lg:py4 rounded-2xl bg-clip-border">
            <div class="p-6 pb-0 mb-0">
              {/* <img src='https://res.cloudinary.com/ahbranding/image/upload/v1682359716/AHbranding/Start_Up_Early_Logo_vu73y8.png' className='w-20 mb-8' /> */}
              <h4 class="font-bold">Send Emails</h4>
              <p class="mb-0">Enter message content and select school to dispatch bulk emails</p>
            </div>
            <div class="flex-auto p-6">
              <form role="form" onSubmit={handleSubmit}>
            


                <div class="mb-4">

                  <select
            value={selectedSchool}
            onChange={(e) => setSelectedSchool(e.target.value)}
            class="focus:shadow-primary-outline    text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
          >
            <option value=''>Pick One</option>
            {schools.map((school) => (
              <option
                key={school.school_id}
                value={school.school_id}
              >
                {school.name}
              </option>
            ))}
          </select>
                </div>

                <div class="mb-4">
                  <input type="text" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} class="focus:shadow-primary-outline    text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none" />
                </div>


                <div class="mb-4">
                  <textarea type="text" placeholder="Message" value={body} onChange={(e) => setBody(e.target.value)} class="focus:shadow-primary-outline    text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none" ></textarea>
                </div>
            

                <div class="text-center">
                  <button type="submit" onClick={handleClick} disabled={loading} class="inline-block w-full px-16 py-3.5 mt-6 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-primary border-0 rounded-lg cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25">
                  {loader ? (
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        'Send Emails'
      )}

                  </button>

                </div>

              </form>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
</main>



</div>
      

    )}



    
  </div>

{/* 
      <form onSubmit={handleSubmit}>
        <label>
          Subject:
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </label>
        <br />
        <label>
          Body:
          <textarea
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
        </label>
        <br />
        <label>
          School:
          <select
            value={selectedSchool}
            onChange={(e) => setSelectedSchool(e.target.value)}
          >
            <option value=''>Pick One</option>
            {schools.map((school) => (
              <option
                key={school.school_id}
                value={school.school_id}
              >
                {school.name}
              </option>
            ))}
          </select>
        </label>
        <br />
        <button type="submit">Send Email</button>
      </form> */}
    </div>
  );
}

export default EmailForm;
