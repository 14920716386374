import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LanguageDropdown from "../../components/LanguageDropdown";
import { useTranslation } from 'react-i18next';
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      // Add shadow-xl class if scrolled
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  return (
    <nav 
    // className="bg-white sticky top-0 z-50"
    className={`bg-white sticky top-0 z-50 transition duration-300 ${
        isScrolled ? "shadow-xl" : ""
      }`}>


      <div className={`max-w-7xl mx-auto px-4 py-3 flex justify-between items-center`}>
        {/* Left side */}
        <div className="flex items-center space-x-4">
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>

          <div className="flex-shrink-0">
            <Link to="/">
              <img
                className="w-[4rem] h-auto"
                src="https://res.cloudinary.com/ahbranding/image/upload/v1690535517/AHbranding/Start_Up_Early_Logo_vbp2fh.png"
                alt="Logo"
              />
            </Link>
          </div>
          {/* Desktop dropdowns */}
          <div className="hidden md:flex space-x-6 ps-8">



            <DropdownMenu title={t('Key Features')} className="w-full">
              <DropdownItem
                icon={<Icon1 />}
                text="Extracurricular Management System (EMS)"
                link='/features/ems'
              />
              <DropdownItem
                icon={<Icon2 />}
                text="Extracurricular Reports (ECR's)"
                link='/features/cct'
              />
              <DropdownItem
                icon={<Icon8 />}
                text="Guided Pathways (GP)"
                link='/features/gp'
              />
              <DropdownItem icon={<Icon3 />} text="Web / Mobile App" link='/features/leap' />
            </DropdownMenu>

            

            <DropdownMenu title={t('Resources')}>
              <DropdownItem icon={<Icon4 />} text={t('Blogs')} link='/blogs' />
              <DropdownItem icon={<Icon7 />} text={t('Case Studies')} link='/case_studies' />
              {/* <DropdownItem icon={<Icon5 />} text="Option 2" />
              <DropdownItem icon={<Icon6 />} text="Option 3" /> */}
            </DropdownMenu>
           
          </div>
        </div>
        {/* Mobile menu button */}

        {/* Right side */}
        <div className="flex items-center space-x-4">
          <Link to={'/login'} className="btn btn-ghost text-primary">{t('Login')}</Link>
          <Link to={'/book_demo'} className="btn btn-outline border-[#4f0696] text-primary hover:shadow-xl hover:bg-[#fff] hover:text-[#fff]">
          {t('BookDemo')}
          </Link>
          <LanguageDropdown/>
          
        </div>
      </div>
      {/* Mobile menu overlay */}
      {isOpen && (
        <div className="md:hidden bg-white absolute top-0 left-0 right-0 z-20 py-4 px-8 shadow-lg">
          <div className="flex flex-col space-y-4">
            <button
              onClick={toggleMenu}
              className="text-gray-500 hover:text-gray-700 focus:outline-none flex justify-start"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-4 h-4"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <DropdownMenu title={t('Key Features')}>
              <DropdownItem icon={<Icon1 />} text="EMS" link='/features/ems'/>
              <DropdownItem icon={<Icon2 />} text="ECR" link='/features/ems' />
              <DropdownItem icon={<Icon3 />} text="App" link='/features/leap' />
              <DropdownItem icon={<Icon8 />} text="GP" link='/features/gp' />
            </DropdownMenu>
            <DropdownMenu title={t('Resources')}>
              <DropdownItem icon={<Icon4 />}  text="Blogs" link='/blogs' />
              <DropdownItem icon={<Icon7 />}  text="Case Studies" link='/case_studies' />
            </DropdownMenu>
          </div>
        </div>
      )}
    </nav>
  );
};

const DropdownMenu = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  let timeoutId;

  const openDropdown = () => {
    setIsOpen(true);
    clearTimeout(timeoutId); // Clear any existing timeout
  };

  const closeDropdown = () => {
    // Set a timeout to close the dropdown
    timeoutId = setTimeout(() => {
      setIsOpen(false);
    }, 200); // Adjust the delay as needed
  };

  return (
    <div
      className="relative"
      onMouseEnter={openDropdown}
      onMouseLeave={closeDropdown}
    >
      <button className="flex items-center text-gray-600 hover:text-gray-800 focus:outline-none">
        {title}{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4 ml-2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          className="absolute left-0 mt-2 p-2 w-max bg-white border border-gray-200 rounded-xl shadow-md z-10"
          onMouseEnter={openDropdown}
          onMouseLeave={closeDropdown}
        >
          {children}
        </div>
      )}
    </div>
  );
};

const DropdownItem = ({ icon, text, link }) => {
  return (
    <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 w-full  z-10 top-full start-0 min-w-60 bg-white rounded-lg darki:bg-gray-800 darki:divide-gray-700 before:absolute before:-top-5 before:start-0 before:w-full before:h-5">
      <div className="md:grid md:grid-cols-1 lg:grid-cols-1 gap-4">
        <div className="flex flex-col mx-1 md:mx-0">
          <Link 
            to={link}
            className="items-center group flex gap-x-5 hover:bg-gray-100 rounded-lg p-4 darki:text-gray-200 darki:hover:bg-gray-900 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
            href="#"
          >
            {icon}
            <div className="grow">
              <h3 className="font-medium text-sm m-0 text-gray-800 darki:text-gray-200">
                {text}
              </h3>
            </div>
          </Link>

          {/* End Link */}
        </div>
      </div>
    </div>
  );
};

// Define your icons here

const Icon1 = () => (
  <div className="btn btn-circle btn-primary btn-sm">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    class="w-4 h-4"
  >
    {" "}
    <path
      fill-rule="evenodd"
      d="M2.25 5.25a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3V15a3 3 0 0 1-3 3h-3v.257c0 .597.237 1.17.659 1.591l.621.622a.75.75 0 0 1-.53 1.28h-9a.75.75 0 0 1-.53-1.28l.621-.622a2.25 2.25 0 0 0 .659-1.59V18h-3a3 3 0 0 1-3-3V5.25Zm1.5 0v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5Z"
      clip-rule="evenodd"
    />
  </svg>
  </div>
);
const Icon2 = () => (
  <div className="btn btn-circle btn-primary btn-sm">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    class="w-4 h-4"
  >
    <path
      fill-rule="evenodd"
      d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875ZM9.75 17.25a.75.75 0 0 0-1.5 0V18a.75.75 0 0 0 1.5 0v-.75Zm2.25-3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3a.75.75 0 0 1 .75-.75Zm3.75-1.5a.75.75 0 0 0-1.5 0V18a.75.75 0 0 0 1.5 0v-5.25Z"
      clip-rule="evenodd"
    />
    <path d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z" />
  </svg></div>
);
const Icon3 = () => (
  <div className="btn btn-circle btn-primary btn-sm">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    class="w-4 h-4"
  >
    <path d="M10.5 18.75a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" />
    <path
      fill-rule="evenodd"
      d="M8.625.75A3.375 3.375 0 0 0 5.25 4.125v15.75a3.375 3.375 0 0 0 3.375 3.375h6.75a3.375 3.375 0 0 0 3.375-3.375V4.125A3.375 3.375 0 0 0 15.375.75h-6.75ZM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75v.375c0 .621.504 1.125 1.125 1.125h2.25c.621 0 1.125-.504 1.125-1.125V3h1.125c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 0 1 7.5 19.875V4.125Z"
      clip-rule="evenodd"
    />
  </svg></div>
);

const Icon4 = () => (
  <div className="btn btn-circle btn-primary btn-sm">
 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
  <path d="M11.25 4.533A9.707 9.707 0 0 0 6 3a9.735 9.735 0 0 0-3.25.555.75.75 0 0 0-.5.707v14.25a.75.75 0 0 0 1 .707A8.237 8.237 0 0 1 6 18.75c1.995 0 3.823.707 5.25 1.886V4.533ZM12.75 20.636A8.214 8.214 0 0 1 18 18.75c.966 0 1.89.166 2.75.47a.75.75 0 0 0 1-.708V4.262a.75.75 0 0 0-.5-.707A9.735 9.735 0 0 0 18 3a9.707 9.707 0 0 0-5.25 1.533v16.103Z" />
</svg>
</div>
);

const Icon7 = () => (
  <div className="btn btn-circle btn-primary btn-sm">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
  <path fill-rule="evenodd" d="M6.32 2.577a49.255 49.255 0 0 1 11.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 0 1-1.085.67L12 18.089l-7.165 3.583A.75.75 0 0 1 3.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93Z" clip-rule="evenodd" />
</svg>

</div>
);

const Icon8 = () => (
  <div className="btn btn-circle btn-primary btn-sm">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
  <path fill-rule="evenodd" d="M8.161 2.58a1.875 1.875 0 0 1 1.678 0l4.993 2.498c.106.052.23.052.336 0l3.869-1.935A1.875 1.875 0 0 1 21.75 4.82v12.485c0 .71-.401 1.36-1.037 1.677l-4.875 2.437a1.875 1.875 0 0 1-1.676 0l-4.994-2.497a.375.375 0 0 0-.336 0l-3.868 1.935A1.875 1.875 0 0 1 2.25 19.18V6.695c0-.71.401-1.36 1.036-1.677l4.875-2.437ZM9 6a.75.75 0 0 1 .75.75V15a.75.75 0 0 1-1.5 0V6.75A.75.75 0 0 1 9 6Zm6.75 3a.75.75 0 0 0-1.5 0v8.25a.75.75 0 0 0 1.5 0V9Z" clip-rule="evenodd" />
</svg>

</div>
);


const Icon5 = () => <svg className="w-5 h-5" viewBox="0 0 20 20"></svg>;
const Icon6 = () => <svg className="w-5 h-5" viewBox="0 0 20 20"></svg>;

export default Navbar;
