import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import randomColor from "randomcolor";
import axios from "axios";
import AdminLayout from "./AdminLayout";
import AuthContext from "../context/AuthContext";
import { useLanguage } from "../components/language";
import { FaRegCalendarCheck, FaRegClock } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

export default function MyClubs({ selectedRole, onRoleChange }) {
  const { t, changeLanguage, lanArabic } = useLanguage();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  let { user } = useContext(AuthContext);
  let profileID = 0;

  if (user[`role`] === `Parent`) {
    profileID = 12;
  } else {
    profileID = user["user_id"];
  }

  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);
  const [clubs, setClubs] = useState([]);
  const [randomizedClubData, setRandomizedClubData] = useState([]);
  let url = "";
  if (user["role"] === "Student") {
    url = "profile";
  } else if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  } else if (user["role"] === "Staff") {
    url = "staff";
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/${url}/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
      } catch (err) {
        setLoading(false);
      }
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    return () => clearTimeout(timer);
  }, [profileID]);

  const [date, setDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/clubs/`
        );
        let data = await response.json();

        let filteredClubs = data.filter(
          (club) => club.network === profile.network_id
        );
        setClubs(filteredClubs);
        setLoading(false);

        // if (user[`role`] === `Network Admin`) {
        //   if (profile && profile.network_id) {
        //     filteredClubs = data.filter(
        //       (club) => club.network === profile.network_id
        //     ); setClubs(filteredClubs); setLoading(false);
        //   } else {
        //     filteredClubs = data;
        //     setClubs(filteredClubs); setLoading(false);
        //   }
        // } else if (user[`role`] === `School Admin`) {
        //   if (profile && profile.school_id) {
        //     filteredClubs = data.filter(
        //       (club) => club.school === profile.school_id
        //     ); setClubs(filteredClubs); setLoading(false);
        //   } else {
        //     filteredClubs = data; setClubs(filteredClubs); setLoading(false);
        //   }
        // } else if (user.role === "Staff") {
        //   filteredClubs = data.slice(0, 3); setClubs(filteredClubs); setLoading(false);
        // }

        //setClubs(filteredClubs);

        // Randomized club data logic
        const randomizedData = filteredClubs.map((club) => ({
          ...club,
          randomLeader: getRandomElement(leaders).Name,
          randomAdvisor: getRandomElement(facultyAdvisors),
          randomEnrolled: getRandomNumber(5, 30),
        }));

        setRandomizedClubData(randomizedData);
      } catch (err) {
        setLoading(false);
      }
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    return () => clearTimeout(timer);
  }, [profile, profileID]);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_category/`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const [filters, setFilters] = useState({
    category: "",
    price: "",
  });

  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const filteredClubs = randomizedClubData.filter((club) => {
    const categoryMatch = filters.category
      ? club.category_name === filters.category
      : true;
    const priceMatch =
      filters.price === "free"
        ? parseFloat(club.fees) === 0 || parseFloat(club.fees) === null
        : filters.price === "paid"
        ? parseFloat(club.fees) !== 0
        : true;
    return categoryMatch && priceMatch;
  });

  const allClubsCount = randomizedClubData.length;
  const freeClubsCount = randomizedClubData.filter(
    (club) => parseFloat(club.fees) === 0 || parseFloat(club.fees) === null
  ).length;
  const paidClubsCount = randomizedClubData.filter(
    (club) => parseFloat(club.fees) !== 0
  ).length;

  // Sample leaders and faculty advisors arrays
  const leaders = [
    { Name: "Sana Faisal", value: "Sana Faisal" },
    { Name: "Kimberly Savia Fernandes", value: "Kimberly Savia Fernandes" },
    { Name: "Heba Qaiser", value: "Heba Qaiser" },
    { Name: "Wania Khalid", value: "Wania Khalid" },
    { Name: "Hassaan Ahmed", value: "Hassaan Ahmed" },
    { Name: "Adam Noor", value: "Adam Noor" },
    { Name: "Mohammed Kashan", value: "Mohammed Kashan" },
    { Name: "Ali Affan", value: "Ali Affan" },
    { Name: "Hamdiya Ahmed", value: "Hamdiya Ahmed" },
    { Name: "Abdur Rehman", value: "Abdur Rehman" },
  ];

  const facultyAdvisors = [
    { firstname: "Ms", lastname: "Zakir" },
    { firstname: "Ms", lastname: "Lina" },
    { firstname: "Ms", lastname: "Farah" },
  ];

  // Helper function to get a random element from an array
  const getRandomElement = (array) =>
    array[Math.floor(Math.random() * array.length)];
  // Helper function to generate a random number between min and max (inclusive)
  const getRandomNumber = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  return (
    <AdminLayout>
      <div className="">
        <>
          {loading ? (
            <div className="flex items-center gap-4 justify-center h-screen">
              <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
              <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
              <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
            </div>
          ) : (
            <>
              {/* Card Blog */}
              <div className="">
                <div className="flex items-center mb-4 justify-between">
                  <div className="flex flex-wrap gap-4 ">
                    <button
                      className={`flex flex-row items-center gap-2 px-4 py-2 rounded-full ${
                        filters.price === ""
                          ? "bg-primary text-white "
                          : "bg-gray-200 text-gray-800 "
                      }`}
                      onClick={() => handleFilterChange("price", "")}
                    >
                      All{" "}
                      {filters.price === "" ? (
                        <small className="bg-[#8a2be2] opacity-80 border-0 badge badge-sm text-white">
                          {allClubsCount}
                        </small>
                      ) : null}
                    </button>
                    <button
                      className={`flex flex-row items-center gap-2 px-4 py-2 rounded-full ${
                        filters.price === "free"
                          ? "bg-primary text-white"
                          : "bg-gray-200 text-gray-800"
                      }`}
                      onClick={() => handleFilterChange("price", "free")}
                    >
                      During School{" "}
                      {filters.price === "free" ? (
                        <small className="bg-[#8a2be2] opacity-80 border-0 badge badge-sm text-white">
                          {freeClubsCount}
                        </small>
                      ) : null}
                    </button>
                    <button
                      className={`flex flex-row items-center gap-2 px-4 py-2 rounded-full ${
                        filters.price === "paid"
                          ? "bg-primary text-white"
                          : "bg-gray-200 text-gray-800"
                      }`}
                      onClick={() => handleFilterChange("price", "paid")}
                    >
                      After School{" "}
                      {filters.price === "paid" ? (
                        <small className="bg-[#8a2be2] opacity-80 border-0 badge badge-sm text-white">
                          {paidClubsCount}
                        </small>
                      ) : null}
                    </button>
                  </div>
                  {user["role"] !== "Staff" ? (
                    <Link to={"/addclub"} className="btn btn-primary">
                      {" "}
                      + Add Club{" "}
                    </Link>
                  ) : null}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 mt-8">
                  {/* Card */}

                  {filteredClubs.map((club, index) => {
                    const borderColor = randomColor(); // Generate a random color for each card
                    const randomLeader = getRandomElement(leaders).Name;
                    const randomAdvisor = getRandomElement(facultyAdvisors);
                    const randomEnrolled = getRandomNumber(5, 30);

                    return (
                      <div key={index}>
                        <div
                          className="flex flex-col bg-white border shadow-sm rounded-xl"
                          style={{ borderBottom: `4px solid ${borderColor}` }}
                        >
                          <div className="">
                            <img
                              className="w-full h-[10rem] rounded-t-xl"
                              style={{ objectFit: "cover" }}
                              src={club.logo}
                              alt="Image Description"
                            />
                            <div className="p-4 md:p-5">
                              <div className="flex flex-row justify-between">
                                <h3 className="text-lg font-bold m-0 text-gray-800 darki:text-white col-span-3">
                                  {club.name}
                                </h3>

                                <span className="col-span-2 badge badge-ghost text-end">
                                  {club.randomEnrolled} enrolled
                                </span>
                              </div>

                              <div className="flex flex-row justify-between mt-4">
                                <div className="flex-shrink-0 group block">
                                  <div className="flex items-center">
                                    <div className="">
                                      <p className="text-sm font-medium text-gray-400 mb-0">
                                        Faculty Advisor
                                      </p>
                                      <h3 className="font-semibold text-base text-gray-800 mb-0">
                                        {club.randomAdvisor.firstname}{" "}
                                        {club.randomAdvisor.lastname}
                                      </h3>
                                    </div>
                                  </div>
                                </div>

                                <div className="flex-shrink-0 group block">
                                  <div className="flex items-center">
                                    <div className="">
                                      <p className="text-sm font-medium text-gray-400 mb-0">
                                        Student Leader
                                      </p>
                                      <h3 className="font-semibold text-base text-gray-800 mb-0">
                                        {club.randomLeader}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="flex flex-wrap gap-x-4 gap-y-2">
                              <div className="flex-shrink-0 group block mt-3">
                                  <div className="flex items-center">
                                    <div className="">
                                      <p className="btn btn-sm btn-ghost m-0 no-animation hover:bg-transparent capitalize px-0 text-start justify-start">
                                      <FaRegCalendarCheck className="text-primary" size={20} /> Every Monday & Wednesday
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="flex-shrink-0 group block ">
                                  <div className="flex items-center">
                                    <div className="">
                                      <p className="btn btn-sm btn-ghost m-0 no-animation hover:bg-transparent capitalize px-0 text-start justify-start">
                                      <FaRegClock  className="text-primary" size={20} /> 3:30pm - 4:40pm
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="flex-shrink-0 group block ">
                                  <div className="flex items-center">
                                    <div className="">
                                      <p className="btn btn-sm m-0 btn-ghost no-animation hover:bg-transparent capitalize px-0 text-start justify-start">
                                      <FaLocationDot  className="text-primary" size={20} /> Hall - B
                                      </p>
                                    </div>
                                  </div>
                                </div>

                              </div>

                              

                                
                              <Link
                                className="mt-4 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none darki:text-blue-500 darki:hover:text-blue-400 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                                to={`/club_details/${club.id}`}
                              >
                                View details
                                <svg
                                  className="flex-shrink-0 size-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="m9 18 6-6-6-6" />
                                </svg>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* End Card */}
                  {/* Card */}

                  {/* End Card */}
                </div>
                {/* End Grid */}
                {/* Card */}

                {/* End Card */}
              </div>
              {/* End Card Blog */}
            </>
          )}
        </>
      </div>
    </AdminLayout>
  );
}
