import React, { useState, useEffect } from "react";
import AdminLayout from "./AdminLayout";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import CampusMap from "./CampusMap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import axios from "axios";

function SchoolVenues() {
  // Sample venuess data from JSON
  const [venues, setVenues] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/api/get-club-venues/`)
        .then(response => {
            setVenues(response.data.venues);
        })
        .catch(error => {
            console.error('Error fetching venues:', error);
        });
}, []);
  
  
  function getBadgeClass(status) {
    switch (status) {
      case "Booked":
        return "badge-warning";
      case "Available":
        return "badge-success";
      default:
        return "";
    }
  }



   // State variables for sorting
   const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });

   // Function to handle sorting
   const sortTable = (key) => {
     let direction = "ascending";
     if (sortConfig.key === key && sortConfig.direction === "ascending") {
       direction = "descending";
     }
     setSortConfig({ key, direction });
   };
 
   // Effect to sort the table when sortConfig changes
   useEffect(() => {
     const sortedvenuess = [...venues];
     if (sortConfig.key) {
       sortedvenuess.sort((a, b) => {
         if (a[sortConfig.key] < b[sortConfig.key]) {
           return sortConfig.direction === "ascending" ? -1 : 1;
         }
         if (a[sortConfig.key] > b[sortConfig.key]) {
           return sortConfig.direction === "ascending" ? 1 : -1;
         }
         return 0;
       });
     }
     setVenues(sortedvenuess);
   }, [sortConfig]);
 
   // Function to get the arrow icon for sorting direction
   const getSortArrow = (key) => {
     if (sortConfig.key === key) {
       return sortConfig.direction === "ascending" ? "↑" : "↓";
     }
     return null;
   };

  return (
    <AdminLayout>
    <div>
      <div>
        <div className="max-w-[85rem] p-4 mx-auto">


        <Tabs>
        <div className="sm:hidden">
          <label htmlFor="Tab" className="sr-only">
            Tab
          </label>
          <select id="Tab" className="w-full rounded-md border-gray-200">
            <option>Settings</option>
            <option>Messages</option>
            <option>Archive</option>
            <option select="">Notifications</option>
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <TabList className="mb-4 flex gap-6" aria-label="Tabs">
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                All Venues
              </Tab>
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Venues Map
              </Tab>
            </TabList>
          </div>
        </div>

        <TabPanel>
        <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden darki:bg-slate-900 darki:border-gray-700">
                  <table className="min-w-full divide-y divide-gray-200 darki:divide-gray-700">
                    <thead className="bg-gray-50 darki:bg-slate-800">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200 cursor-points" onClick={() => sortTable("venueName")}>
                              venues Name ⇵
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200 cursor-points" onClick={() => sortTable("category")}>
                              Type ⇵
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200 cursor-points" onClick={() => sortTable("capacity")}>
                              Capacity ⇵ 
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200 cursor-points" onClick={() => sortTable("availability")}> 
                              Availability ⇵
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200"></span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 darki:divide-gray-700">
                      {venues.map((venues, index) => (
                        <tr key={index}>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-2">
                              <a
                                className="text-sm text-blue-600 decoration-2 hover:underline darki:text-blue-500"
                                href="#"
                              >
                                {venues.name}
                              </a>
                              <br/><span className="text-sm text-gray-400">{venues.category}</span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-2">
                              <span className="text-sm text-gray-600 darki:text-gray-400 uppercase">
                                {venues.type}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-2">
                              <span className="text-sm text-gray-600 darki:text-gray-400">
                                {venues.capacity}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-2 uppercase">
                              <span
                                className={`badge ${getBadgeClass(
                                  venues.status
                                )}`}
                              >
                                {venues.status}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-1.5 flex justify-center">
                              <Link
                                className="btn btn-ghost text-primary"
                                to={"/venues_details"}
                              >
                                <FaEye />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <CampusMap />
        </TabPanel>
      </Tabs>
         
          {/* End Card */}
        </div>
        {/* End Table Section */}
      </div>
    </div>
    </AdminLayout>
  );
}

export default SchoolVenues;
