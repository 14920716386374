import React, { useState, useEffect, useContext } from "react";
import { createGlobalStyle } from "styled-components";
import RoleDropdown from "./RoleDropdown";
import ClubSidebar from "./ClubSidebar";
import ClubListList from "./ClubListList";
import ClubPagination from "./ClubPagination";
import clubsData from "./ClubsData";
import SchoolNews from "./SchoolNews";
import PublicNav from "./PublicNav";
import AuthContext from "../context/AuthContext";
import StdEventList from "./StdEventList";

function MyEventstd({ props }) {
  const { user } = useContext(AuthContext);
  let userID = 0;

  if (user.role === "Parent") {
    userID = 12;
  } else {
    userID = user.user_id;
  }

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${userID}`
        );
        let data = await response.json();
        setProfile(data);
      } catch (err) {
        console.log(err);
      }
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    return () => clearTimeout(timer);
  }, [userID]);

  const GlobalStyle = createGlobalStyle`
    html {
      background: #F9F3FF !important;
      /* Add other global styles here if needed */
    }
  `;

  const [clubs, setClubs] = useState([]);
  
  useEffect(() => {
    const fetchClubsData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/get_events/`
        );
        let data = await response.json();
        if (profile) {
          const filteredClubs = data.filter(club => club.network === profile.network_in);
          setClubs(filteredClubs);
          console.warn("CLUBS ", filteredClubs);
        }
      } catch (err) {
        console.log("ERROR");
      }
    };

    if (profile) {
      fetchClubsData();
    }
  }, [profile]);

  return (
    <div>
      <GlobalStyle />
      <div className="container mx-auto mt-8">
        {/* <RoleDropdown /> */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-4">
          <div className="md:col-span-4">
            {profile ? <StdEventList myList={true} going={true} blogs={clubs} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyEventstd;
