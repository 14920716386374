import React, { useState, useEffect, useContext } from "react";
import { Calendar, globalizeLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import globalize from "globalize";
import EventList from "./EventList";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import AdminLayout from "./AdminLayout";
import randomColor from "randomcolor";
// import toast from "react-hot-toast";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-modal";
import AddEventForm from "./AddEventForm";
import AuthContext from "../context/AuthContext";
function SchoolCalendar() {
  const localizer = globalizeLocalizer(globalize);
  const id = 2;
  const [list, setList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showEdit, setShowEdit] = useState(false);



  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const storedColors = localStorage.getItem("clubColors");
    if (storedColors) {
      setClubColors(JSON.parse(storedColors));
    }
  }, []);

  let { user } = useContext(AuthContext);
  let profileID = 0;

  if(user[`role`]===`Parent`){
    profileID = 12;
  } else{
    profileID = user["user_id"];
  }
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);

  let url = '';
  if(user['role']==='Student'){
    url = 'profile';
  } else if(user['role']==='Network Admin'){
    url = 'network_profile';
  } else if(user['role']==='School Admin'){
    url = 'school_profile';
  } else if(user['role']==='Staff'){
    url = 'staff';
  }


  let urlCalendar = '';
  if(user['role']==='Network Admin'){
    urlCalendar = 'get_network_schedules';
  } else if(user['role']==='School Admin'){
    urlCalendar = 'get_school_schedules';
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/${url}/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
        // Fetch schedule data after fetching network profile
        if(user['role']==='Network Admin'){
          get_all_schedules(data.network_id); // Pass network ID to fetch schedule
        } else if(user['role']==='School Admin'){
          get_all_schedules(data.school_id); // Pass network ID to fetch schedule
        }
        
      } catch (err) {
        //console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };
  
    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);
  
    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  
  const get_all_schedules = async (networkId) => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${urlCalendar}/${networkId}/`)
      .then((res) => {
        setList(res.data);
        console.warn("SCHEDULE: ", res.data);
      })
      .catch((err) => {
        console.error("ERROR SCHEDULE: ", err);
        console.log(err);
      });
  };
  

  const generateClubColors = (events) => {
    // Get existing colors from localStorage
    const storedColors = localStorage.getItem("clubColors");
    let colors = storedColors ? JSON.parse(storedColors) : {};

    events.forEach((event) => {
      if (!colors[event.club]) {
        colors[event.club] = randomColor();
      }
    });

    // Save updated colors to localStorage
    localStorage.setItem("clubColors", JSON.stringify(colors));

    return colors;
  };

  useEffect(() => {
    if (list.length > 0) {
      const colors = generateClubColors(list);
      setClubColors(colors);
    }
  }, [list]);

  const [clubColors, setClubColors] = useState({});

  function mergeAdjacentEvents(events) {
    // Sort events based on start_date
    events.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

    let mergedEvents = [];
    let currentEvent = null;

    for (let event of events) {
      if (!currentEvent) {
        currentEvent = { ...event };
      } else {
        const currentEndDate = new Date(currentEvent.end_date);
        const eventStartDate = new Date(event.start_date);

        if (currentEndDate >= eventStartDate) {
          // Merge events
          currentEvent.end_date = event.end_date;
        } else {
          // Push current merged event
          mergedEvents.push(currentEvent);
          // Start a new merged event
          currentEvent = { ...event };
        }
      }
    }

    // Push the last merged event
    if (currentEvent) {
      mergedEvents.push(currentEvent);
    }

    // Format date strings in ISO format
    mergedEvents.forEach((event) => {
      event.start_date = event.start_date.slice(0, 19);
      event.end_date = event.end_date.slice(0, 19);
    });

    return mergedEvents;
  }

  const mergedEvents = mergeAdjacentEvents(list);

  // Generate random colors for each club
  const newData = mergedEvents?.map((item) => {
    const id = item?.id;
    const title = item?.name;
    const start = new Date(item?.start_date);
    const end = new Date(item?.end_date);
    const club = item.club;
    return { id, title, start, end, club };
  });

  const handleDeleteEvent = (id) => {
    // Update the events array after deleting a schedule
    setList(list.filter((event) => event.id !== id));
  };

  const handleEventClick = (event) => {
    // Set the selected event when an event is clicked
    setSelectedEvent(event);
    console.warn("EVENT", event);
  };

  const handleClosePopup = () => {
    // Close the popup by resetting the selected event
    setSelectedEvent(null);
  };

  const formatDateString = (date) => {
    // Format the date string to "Wed. Feb 21, 2024 (6:00 PM)" format
    return date.toLocaleString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  // const handleDelete = (id) => {
  //   axios
  //     .delete(`${process.env.REACT_APP_API_KEY}/api/delete_schedule/${id}/`)
  //     .then((res) => {
  //       toast.success("Schedule Deleted Successfully!");
  //       get_all_schedules();
  //       handleClosePopup();
  //     })
  //     .catch((err) => {
  //       toast.error("Something went wrong!");
  //       handleClosePopup();
  //       console.log(err);
  //     });
  // };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this schedule?"
    );
    if (confirmDelete) {
      axios
        .delete(`${process.env.REACT_APP_API_KEY}/api/delete_schedule/${id}/`)
        .then((res) => {
          toast.success("Schedule Deleted Successfully!");
          get_all_schedules();
          handleClosePopup();
        })
        .catch((err) => {
          toast.error("Something went wrong!");
          handleClosePopup();
          console.log(err);
        });
    }
  };

  const handleEdit = (id) => {
    axios
      .put(`${process.env.REACT_APP_API_KEY}/api/update_schedule/${id}/`, {
        start_date: startDate,
        end_date: endDate,
      })
      .then((res) => {
        toast.success("Schedule Updated Successfully!");
        setStartDate(null);
        setEndDate(null);
        setShowEdit(false);
        handleClosePopup();
      })
      .catch((err) => {
        toast.error("Something went wrong!");

        console.log(err);
      });
  };

  const toggleEdit = () => {
    setShowEdit((prevState) => !prevState);
  };

  const formatDateWithOffset = (dateString) => {
    const date = new Date(dateString);
    const offset = -date.getTimezoneOffset();
    const offsetHours = Math.floor(offset / 60);
    const offsetMinutes = offset % 60;
    const offsetString = `GMT${offsetHours < 0 ? "-" : "+"}${Math.abs(
      offsetHours
    )
      .toString()
      .padStart(2, "0")}${Math.abs(offsetMinutes).toString().padStart(2, "0")}`;
    return date.toISOString().slice(0, 16) + offsetString;
  };

  return (
    <>
    {/* // <AdminLayout> */}
      {/* <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto"> */}
        <div
          role="alert"
          className="alert flex mb-8 justify-between bg-white shadow-xl mt-8"
        >
          <p className="m-0">Add an event / session to your calendar</p>
          <button className="btn btn-sm btn-primary" onClick={openModal}>
            Add Event
          </button>
        </div>

        <div className="bg-white rounded-xl p-2 shadow-xl h-screen">
          <Calendar
            localizer={localizer}
            events={newData}
            startAccessor="start"
            endAccessor="end"
            popup={true}
            onSelectEvent={handleEventClick}
            eventPropGetter={(event) => ({
              style: {
                backgroundColor: clubColors[event.club] || randomColor(),
                color: "white",
              },
            })}
            onShowMore={(events, date) => console.log(date)}
          />
        </div>
      {/* </div> */}

      {selectedEvent && (
        <div>
          <div
            className="fixed inset-0 flex items-center justify-center bg-[#00000080] bg-opacity-999 w-full"
            style={{ zIndex: 999 }}
          >
            <div className="bg-white p-6 rounded-lg min-w-[30rem]">
              <div className="flex flex-row items-center justify-between">
                <div>
                  <h2 className="text-xl font-bold m-0">
                    {selectedEvent.title}
                  </h2>
                  <p className="text-gray-500 text-sm m-0">Session details</p>
                </div>

                <div>
                  <button
                    className="btn btn-sm btn-ghost text-error"
                    onClick={() => handleDelete(selectedEvent?.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>

                  <button
                    onClick={() => {
                      toggleEdit();
                      setStartDate(selectedEvent.start);
                      setEndDate(selectedEvent.end);
                    }}
                    className={`btn btn-sm btn-ghost ${
                      showEdit ? "bg-info text-white" : "text-info"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-5 h-5"
                    >
                      <path d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                      <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="flex flex-row items-start gap-4 mt-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                  />
                </svg>

                <div>
                  <p className="text-gray-500 text-sm m-0">Starts at</p>
                  <p className="text-gray-700">
                    {formatDateString(selectedEvent.start)}
                  </p>
                </div>
              </div>

              <div className="flex flex-row items-start gap-4 mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                  />
                </svg>

                <div>
                  <p className="text-gray-500 text-sm m-0">Ends at</p>
                  <p className="text-gray-700">
                    {formatDateString(selectedEvent.end)}
                  </p>
                </div>
              </div>

              {!showEdit && (
                <div className="flex justify-end items-center gap-2 mt-6">
                  <button onClick={handleClosePopup} className="btn btn-ghost">
                    Close
                  </button>

                  {selectedEvent?.club === null && (
                    <Link to={`/event_details/${selectedEvent.id}`} className="btn btn-primary">
                      Go to Event Details
                    </Link>
                  )}
                </div>
              )}
              {showEdit && (
                <div>
                  <div className="my-2 p-4 bg-gray-100 rounded-xl">
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        New Start Date
                      </label>
                      <input
                        type="datetime-local"
                        value={
                          startDate
                            ? new Date(
                                new Date(startDate).setHours(
                                  new Date(startDate).getHours() + 5
                                )
                              )
                                .toISOString()
                                .slice(0, 16)
                            : ""
                        }
                        onChange={(e) => setStartDate(e.target.value)}
                        className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        New End Date
                      </label>
                      <input
                        type="datetime-local"
                        // value={
                        //   endDate
                        //     ? new Date(endDate).toISOString().slice(0, 16)
                        //     : ""
                        // }

                        value={
                          startDate
                            ? new Date(
                                new Date(endDate).setHours(
                                  new Date(endDate).getHours() + 5
                                )
                              )
                                .toISOString()
                                .slice(0, 16)
                            : ""
                        }
                        onChange={(e) => setEndDate(e.target.value)}
                        className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div className="flex justify-end items-center gap-2 mt-6">
                    <button
                      onClick={handleClosePopup}
                      className="btn btn-ghost"
                    >
                      Close
                    </button>

                    <button
                      onClick={() => handleEdit(selectedEvent?.id)}
                      className="btn btn-warning"
                    >
                      Confirm Changes
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <AddEventForm isOpen={modalIsOpen} onModalClose={closeModal} />
      </Modal>

      <Toaster position="bottom-center" />
    {/* // </AdminLayout> */}
    </>
  );
}

export default SchoolCalendar;
