import React, { useState, useEffect, useContext } from "react";
import AdminLayout from "./AdminLayout";

import AuthContext from "../context/AuthContext";

import SchoolEvents from "./SchoolEvents";
import AddEventBar from "../components/AddEventBar";
function ScheduleAdmin() {
  let { user } = useContext(AuthContext);
  let profileID = 0;

  if (user[`role`] === `Parent`) {
    profileID = 12;
  } else {
    profileID = user["user_id"];
  }

  return (
    <AdminLayout>
      {/* <div className="">
        <Tabs>
          <div className="">
            <div className="border-b border-gray-200">
              <TabList className="-mb-px flex gap-6" aria-label="Tabs">
                <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                  Calendar
                </Tab>
                <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                  Event List
                </Tab>
              </TabList>
            </div>
          </div>

          <TabPanel>
            <SchoolCalendar />
          </TabPanel>

          <TabPanel>
           
          </TabPanel>
        </Tabs>
      </div> */}


      {/* <AddEventBar/> */}

      <SchoolEvents />
    </AdminLayout>
  );
}

export default ScheduleAdmin;
