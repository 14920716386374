import React, { useState } from "react";
import { axiosInstance } from "../Axios";

import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import MyCalendar from "../components/EventCalendar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";
import ApexChart from "../components/AreaChart";
import Navbar from "../components/Navbar";
import BarChart from '../components/Barchart';
import RadarChart from '../components/RadarChart';
import BarStacked from '../components/BarStacked'
import CoCurricularEventsTable from '../components/CoCurricularEventsTable'
import NetworkStats from "../components/NetworkStats";
import Network_StudentList from "../components/Network_StudentList";
import Calendar from "react-calendar";
import EventCalendar from "../components/EventCalendar";
import BarchartSchoolTrend from "./BarchartSchoolTrend";
import NetworkStudents from "../components/NetworkStudents";
import { useLanguage } from "../components/language";
import SidebarPilot from "../components/SidebarPilot";
import SideInnerSMPilot from "../components/SideInnerSMPilot";
import CampusStats from "../components/CampusStats";
import MyClubs from "./MyClubs";
import NetworkSuperAdmin from "./NetworkSuperAdmin";

export default function Network() {
 
  const { t, changeLanguage, lanArabic } = useLanguage();
const [isSidebarVisible, setIsSidebarVisible] = useState(false);
const toggleSidebar = () => {
  setIsSidebarVisible(prevState => !prevState);
}

let {user} = useContext(AuthContext);
let profileID = user['user_id'];
const [profile, setProfile] = useState();
const [loading, setLoading] = useState(true);
let profiler = 0;



let url = '';
if(user['role']==='Student'){
  url = 'profile';
} else if(user['role']==='Network Admin'){
  url = 'network_profile';
} else if(user['role']==='School Admin'){
  url = 'school_profile';
} else if(user['role']==='Staff'){
  url = 'staff';
}
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/${url}/${profileID}`);
      let data = await response.json();
      setProfile(data);
      setLoading(false);
      if(user['role']==='Network Admin'){
        profiler = data?.network_id;
      } else if (user['role']==='Network Admin'){
        profiler = data?.school_id;
      }
    } catch (err) {
      //console.log("The requested profile does not exist.");
      setLoading(false); // Set loading to false even on error to stop showing the loader
    }
  };

  // Simulate the loader by setting a timer for 3 seconds
  const timer = setTimeout(() => {
    fetchData();
  }, 900);

  // Clean up the timer when the component unmounts
  return () => clearTimeout(timer);
}, [profileID]);


const [date, setDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch data from the '/get_events/' endpoint
    fetch(`${process.env.REACT_APP_API_KEY}/api/get_events/`)
      .then((response) => response.json())
      .then((data) => {
        // Filter events where campus matches the username
        const filteredEvents = data.filter((event) => event.campus === user.username);
        setEvents(filteredEvents);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [user.username]); // Include username as a dependency to re-fetch when it changes


  
  return (
    <div>


<div><>

<div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>

{(() => {
        if (profileID===263) {
          return (
            <SidebarPilot />
          )
        } else {
          return (
            
           <div>
           {(() => {
        if (user['role']!=='Staff') {
          return (
            <Sidebar />
          )
        } 
      })()}
           </div>
            
          )
        }
      })()}





      {isSidebarVisible && (
  (() => {
    if (profileID === 263) {
      return <SideInnerSMPilot />;
    } else {
      return <SideInnerSM />;
    }
  })()
)}



    {/* <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? 'xl:mr-68' : 'xl:ml-68'} rounded-xl`}> */}
    <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? (user.role === 'Staff' ? '' : 'xl:mr-68') : (user.role === 'Staff' ? '' : 'xl:ml-68')} rounded-xl`}>


    {(() => {
        if (user['role']==='School Admin') {
          return (
            <div>
              
      {/* Navbar */}
      <Navbar toggleSidebar={toggleSidebar} />

{/* end Navbar */}
{/* cards */}
<div className="w-full px-6 py-6 mx-auto">


{/* {(() => {
  if (user['role']==='Network Admin') {
    return (
      <NetworkStats/>
    )
  } else if (user['role']==='School Admin') {
    return (
      <CampusStats/>
    )
  }
})()} */}

<CampusStats/>
  {/* cards row 2 */}
  
  {/* cards row 3 */}
  <div className="flex flex-wrap mt-6 -mx-3">
    <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-7/12 lg:flex-none">
      <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid shadow-xl    border-black-125 rounded-2xl bg-clip-border">
        <div className="p-4 pb-0 mb-0 rounded-t-4">
          <div className="flex flex-col justify-start">
            <h6 className="mb-2 ">Student</h6>
            <p className="text-sm  leading-normal ">List of students</p>
          </div>
        </div>
        <Network_StudentList/>

        {/* <NetworkStudents/> */}
      </div>
    </div>
    <div className="w-full max-w-full px-3 mt-0 lg:w-5/12 lg:flex-none">
      <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
        <div className="p-4 pb-0 rounded-t-4">
        <h6 className="mb-2 ">Calendar</h6>
            <p className="text-sm  leading-normal ">Your scheduled events will list down here</p>
        </div>
        <div className="flex-auto p-4">


  <EventCalendar events={events}/>
</div>
      </div>
    </div>
  </div>




  <div className="flex flex-wrap mt-6 -mx-3">
    <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-7/12 lg:flex-none">
      <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid shadow-xl    border-black-125 rounded-2xl bg-clip-border">
        <div className="p-4 pb-0 mb-0 rounded-t-4">
          <div className="flex flex-col justify-start">
            <h6 className="mb-2 ">Activities Trend</h6>
            <p className="text-sm  leading-normal ">Count of activities in each category</p>
          </div>
        </div>
        <BarchartSchoolTrend/>
      </div>
    </div>
    <div className="w-full max-w-full px-3 mt-0 lg:w-5/12 lg:flex-none">
      <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">


      <div className="p-4 pb-0 mb-0 rounded-t-4">
          <div className="flex flex-col justify-start">
            <h6 className="mb-2 ">Monthly Timeline </h6>
            <p className="text-sm  leading-normal ">Monthly timeline of participation</p>
          </div>
        </div>

    <ApexChart userID={profileID} profileID={profiler} />

</div>


    </div>
  </div>


</div>


            </div>
          )
        } 

        else if (user['role']==='Staff') {
          return(
            <div>

        <MyClubs/>
        </div>
          )
       
        }


        else if (user['role']==='Network Admin') {
          return(
            <div>

        <NetworkSuperAdmin/>
        </div>
          )
       
        }
      })()}



    </main>



  </>
  </div>

    
    </div>
  );
}
