import React from 'react';

const KeyStageChecker = ({ grade }) => {
  const getKeyStage = (grade) => {
    if (grade >= 1 && grade <= 2) {
      return 1;
    } else if (grade >= 3 && grade <= 6) {
      return 2;
    } else if (grade >= 7 && grade <= 9) {
      return 3;
    } else if (grade >= 10 && grade <= 11) {
      return 4;
    } else if (grade >= 12 && grade <= 13) {
      return 5;
    } else {
      return 'Invalid Grade';
    }
  };

  return (
    <div>
      {getKeyStage(grade)}
    </div>
  );
};

export default KeyStageChecker;
