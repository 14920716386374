import React, {useContext} from 'react';
import AuthContext from '../context/AuthContext'
import { Link } from 'react-router-dom';

export default function SignUp() {
  let {registerUser} = useContext(AuthContext)
  const handleSubmit = (event) => {
    /*
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    //console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    */
  };

  return (

    <div>
<section className="bg-white">
  <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
    <aside
      className="relative block h-16 lg:order-last lg:col-span-5 lg:h-full xl:col-span-6"
    >
      <img
        alt="Pattern"
        src="https://images.unsplash.com/photo-1605106702734-205df224ecce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
        className="absolute inset-0 h-full w-full object-cover"
      />
    </aside>

    <main
      aria-label="Main"
      className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:py-12 lg:px-16 xl:col-span-6"
    >
      <div className="max-w-xl lg:max-w-3xl">
      <img className='authLogo' src="https://lums.edu.pk.pk/sites/default/files/inline-images/LUMS%20Logo.png" />

        <h1
          className="mt-6 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl"
        >
          Welcome to CCA
        </h1>

        <p className="mt-4 leading-relaxed text-gray-500">
        Sign Up for a new account
        </p>


        <form className="mt-8 grid grid-cols-8 gap-6" onSubmit={registerUser}>


          <div className="col-span-4">
            <label for="username" className="block text-sm font-medium text-gray-700">
              Username
            </label>

            <input
              type="text"
              id="username"
              name="username"
              className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm email"
              style={{borderColor: "rgb(31 41 55 / 23%)", borderWidth: "1px", height: "3rem"}}
            />
          </div>


          <div className="col-span-4">
            <label for="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>

            <input
              type="email"
              id="email"
              name="email"
              className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm email"
              style={{borderColor: "rgb(31 41 55 / 23%)", borderWidth: "1px", height: "3rem"}}
            />
          </div>

          <div className="col-span-4">
            <label for="email" className="block text-sm font-medium text-gray-700">
              Password
            </label>

            <input
              type="password"
              id="password"
              name="password"
              className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm email"
              style={{borderColor: "rgb(31 41 55 / 23%)", borderWidth: "1px", height: "3rem"}}
            />
          </div>


          <div className="col-span-4">
            <label for="email" className="block text-sm font-medium text-gray-700">
              Confirm Password
            </label>

            <input
              type="password"
              id="password2"
              name="password2"
              className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm email"
              style={{borderColor: "rgb(31 41 55 / 23%)", borderWidth: "1px", height: "3rem"}}
            />
          </div>

          <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
            <button
              className="inline-block shrink-0 rounded-md border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-blue-600 focus:outline-none focus:ring active:text-blue-500"
              onClick={handleSubmit}
            >
              Create Account
            </button>

            <p className="mt-4 text-sm text-gray-500 sm:mt-0">
              Already have an account?

              <Link to="/login">
              <a className="text-gray-700 underline"> Log In</a>.
              </Link>
            </p>
          </div>
        </form>

        <div className='passHint'>
            <p className='font-bold'> Password should have: </p>
            <ul>
              <li> At least one Capital letter </li>
              <li> Both Character and Numbers </li>
              <li> Length greater than 6 </li>
            </ul>
          </div>
      </div>
    </main>
  </div>
</section>

{/*


    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" style={{ marginTop: 100 }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={registerUser} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="username"
                  name="username"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password2"
                  label="Confirm Password"
                  type="password"
                  id="password2"
                  autoComplete="confirm-password"
                />
              </Grid>
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
*/}
    </div>
  );
}