import React from "react";
import { useTranslation } from 'react-i18next';

const LogoCloud = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="font-bold text-2xl text-center text-black darki:text-gray-200">
            {t('Schools that trust Start Up Early')}
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            Check out the schools we collaborate with.
          </p>
        </div>
        <div className="mt-10 overflow-x-auto pb-6">
          <div className="flex justify-center space-x-6 md:space-x-8 lg:space-x-12">
            {/* Logo 1 */}
            <div className="">
              <img
                className="h-auto w-[11rem]"
                src="https://res.cloudinary.com/ahbranding/image/upload/v1708414683/AHbranding/Group_34784435_nxbpul.png"
                alt="Logo 1"
              />
            </div>
            {/* Logo 2 */}
            <div className="">
              <img
                className="h-auto w-[11rem]"
                src="https://res.cloudinary.com/ahbranding/image/upload/v1708414683/AHbranding/Group_34784436_mdjdc7.png"
                alt="Logo 2"
              />
            </div>
            {/* Logo 3 */}
            <div className="">
              <img
                className="h-auto w-[11rem]"
                src="https://res.cloudinary.com/ahbranding/image/upload/v1708414683/AHbranding/Group_34784433_u2m3rq.png"
                alt="Logo 3"
              />
            </div>
            {/* Logo 4 */}
            <div className="">
              <img
                className="h-auto w-[11rem]"
                src="https://res.cloudinary.com/ahbranding/image/upload/v1708414683/AHbranding/Group_34784432_dbiish.png"
                alt="Logo 4"
              />
            </div>
            {/* Logo 5 */}
            <div className="">
              <img
                className="h-auto w-[11rem]"
                src="https://res.cloudinary.com/ahbranding/image/upload/v1708414682/AHbranding/Group_34784434_mytvjq.png"
                alt="Logo 5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoCloud;
