import React, { useEffect, useState, useContext } from "react";
import { useParams, Link, useNavigate, NavLink } from "react-router-dom";
import AuthContext from "../context/AuthContext";

import ProfileEditForm from "../components/ProfileEditForm";


import { getRoles } from "@testing-library/react";
import { Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//competency data
import Achievements_Data from "./CompetencyData/AchievementsCompetency";
import Certifications_Data from "./CompetencyData/CertificationsCompetency";
import Clubs_Data from "./CompetencyData/ClubsCompetency";
import Event_Management_Data from "./CompetencyData/EventManagementData";
import Languages_Data from "./CompetencyData/LanguagesCompetency";
import Leadership_Data from "./CompetencyData/LeadershipCompetency";
import Social_Work_Data from "./CompetencyData/SocialWorkCompetency";
import Special_Talents_Data from "./CompetencyData/SpecialTalentsCompetency";
import Sports_Data from "./CompetencyData/SportsCompetency";
import Barchart from "../components/Barchart";
import RadarChart from "../components/RadarChart";
import MobileBottom from "../components/MobileBottom";
import BarStacked from "../components/BarStacked";
import Calendar from "react-calendar";
import axios from "axios";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import SideInner from "../components/SideInner";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const StdChallenges = () => {
  let { user } = useContext(AuthContext);
  const {t} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  
  const lanArabic = i18n.language === 'ar'
  // extract profile id
  let params = useParams();
  let profileID = params.id;

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`);
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  //console.log(profile);
  let { logoutUser } = useContext(AuthContext);


  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [allEvents, setallEvents] = useState([]);

  useEffect(() => {
    // Define a function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/get_events/`);
        setallEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    // Call the fetch function when the component mounts
    fetchData();
  }, []);


  const [eventDescription, setEventDescription] = useState('');

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  const maxCharactersToShow = 100;


  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const [matchedDates, setMatchedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventName, setEventName] = useState(null);

  useEffect(() => {
    // Get the list of matched event start dates from the `allEvents` array
    const matchedDatesList = allEvents.map((event) => new Date(event.start_date));
    setMatchedDates(matchedDatesList);
  }, [allEvents]);


  const tileContent = ({ date, view }) => {
    if (view === "month") {
      // Check if the date exists in the `matchedDates` array
      const dateIndex = matchedDates.findIndex((d) => d.toDateString() === date.toDateString());
      if (dateIndex !== -1) {
        // Return a div with a specific style to highlight the matched dates
        return <div className="highlighted-date" style={{ backgroundColor: "#E91E63" , height: "12px", width: "12px", margin: "auto" }} />;
      }
    }
    return null;
  };


/*  const handleDateClick = (date) => {
    const selectedDateString = date.toISOString().slice(0, 10); // Format date to "YYYY-MM-DD"
    const matchedEvent = allEvents.find((event) => {
      const eventStartDateString = new Date(event.start_date).toISOString().slice(0, 10);
      return eventStartDateString === selectedDateString;
    });
    if (matchedEvent) {
      setSelectedDate(date);
      setEventName(matchedEvent.event_name);
    } else {
      setSelectedDate(null);
      setEventName(null);
    }
  };*/


  const handleDateClick = (date) => {
    const selectedDateString = date.toISOString().slice(0, 10); // Format date to "YYYY-MM-DD"
    const matchedEvent = allEvents.find((event) => {
      const eventStartDateString = new Date(event.start_date).toISOString().slice(0, 10);
      return eventStartDateString === selectedDateString;
    });
    if (matchedEvent) {
      setSelectedDate(date);
      setEventName(matchedEvent.event_name);
      setEventDescription(matchedEvent.event_description); // Update eventDescription here
    } else {
      setSelectedDate(null);
      setEventName(null);
      setEventDescription(''); // Reset eventDescription here
    }
  };
  

  const [challenges, setChallenges] = useState([]);

  useEffect(() => {
    // Fetch weekly challenges data from your Django backend
    axios.get(`${process.env.REACT_APP_API_KEY}/api/get_challenge/`)
      .then((response) => {
        // Reverse the array after receiving the response
        const reversedChallenges = response.data.reverse();
        setChallenges(reversedChallenges);
      })
      .catch((error) => {
        console.error('Error fetching weekly challenges:', error);
      });
  }, []);
  

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState);
  }

  return (
    

    <div>


<div>


<div><>

<div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>


<Sidebar />

{isSidebarVisible && <SideInner/>}

    <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? 'xl:mr-68' : 'xl:ml-68'} rounded-xl`}>
      {/* Navbar */}
      <Navbar toggleSidebar={toggleSidebar} />

      {/* end Navbar */}
      {/* cards */}
      <div className="w-full px-6 py-6 mx-auto ">


      <div className="bg-white rounded-lg pt-4">

      <Tabs className="tabs block mt-4 px-4">
      <TabList className='hidden'>
        <Tab className="tab tab-bordered">Challenges</Tab>
        <Tab className="tab tab-bordered">My Progress</Tab>
      </TabList>


      <TabPanel>
      <div className=''>
      <div className="challenge-list ">
      {challenges.map((challenge) => {
      const currentDate = new Date();
      const dueDate = new Date(challenge.due_date);

      // Check if the current date is greater than the due date
      const isExpired = currentDate > dueDate;

      return (
        
        <div key={challenge.id} className="challenge-card card shadow-xl my-4 p-8">
          <div className="mb-4 flex flex-col-reverse items-start gap-4">
            <h3 className="text-xl font-bold">{challenge.challenge_name}</h3>
            
            {isExpired ? (
                <span className="badge badge-error"> Expired </span>
            ) : (
                <span className="badge badge-success"> Available </span>
            )}
          </div>
          <p className="text-gray-500">{challenge.notification}</p>


          <Link to={`/challenge/${challenge.id}`}>
              <button className="btn btn-primary mt-4">Go to Challenge</button>
            </Link>


        </div>
      );
    })}
      </div>
    </div>
      
      </TabPanel>


</Tabs>


</div>

      </div>
    </main>
  </>
  </div>

    
    </div>




<MobileBottom/>

    </div>

  );
};

export default StdChallenges;
