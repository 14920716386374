// language.js

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

export const useLanguage = () => {
  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const lanArabic = i18n.language === 'ar';

  return { t, changeLanguage, lanArabic };
};
