// import React from 'react';
import AdminStats from "./AdminStats";
import TopClubs from "./TopClubs";
import React, { useContext, useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import CampusTable from "./CampusTable";
import ScheduleTrend from "../components/ScheduleTrend";
import ClubSegregation from "./ClubSegregation";
import ClubBudgetBreakdown from "./ClubBudgetBreakdown";
import ClubMemberSegregation from "./ClubMemberSegregation";
import RetentionChart from "./RetentionChart";
import ReportDownloadCard from "./ReportDownloadCard";
import AdminLayout from "./AdminLayout";
import CCAStats from "../components/CCAStats";
import RequestsList from "../components/RequestsList";
import MiniCalendar from "../components/MiniCalendar";

function SchoolDashboard() {
  const [profile, setProfile] = useState();
  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);

  const userID = user["user_id"];

  let url = "";
  if (user["role"] === "Student") {
    url = "profile";
  } else if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  } else if (user["role"] === "Staff") {
    url = "staff";
  }

  let role = user["role"];

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");

  const [profileData, setProfileData] = useState({
    name: "",
    dob: "",
    gender: "",
    contact: "",
    bio: "",
    active_status: "",
    employeeID: "",
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((response) => {
        const data = response.data;
        setProfileData(data);
        setProfile(data);
        setProfilePhotoUrl(data.profile_photo);
        //console.log("Profile Photo URL: ", data.profile_photo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);

  const campusesData = [
    {
      name: "Northville North",
      location: "north@northville.com",
      students: 120,
      staffMembers: 15,
      events: 13,
      budget: "$1,500",
      participation: "28%",
    },
    {
      name: "Northville South",
      location: "south@northville.com",
      students: 105,
      staffMembers: 15,
      events: 12,
      budget: "$1,300",
      participation: "60%",
    },
    {
      name: "North Guardian",
      location: "guardian@northville.com",
      students: 216,
      staffMembers: 25,
      events: 26,
      budget: "$3,500",
      participation: "89%",
    },
  ];




  const campusesDataDemo = [
    {
      id:2,
      name: "DC UAE Campus",
      location: "uae@dc.com",
      students: 5,
      staffMembers: 4,
      events: 13,
      budget: "$1,500",
      participation: "79%",
    }
  ];

  const clubsData = [
    {
      name: "Strikers FC",
      campus: " Northern",
      members: 22,
      staffMembers: 5,
      events: 5,
      budget: "$500",
      participation: "69%",
    },
    {
      name: "Active8",
      campus: " South",
      members: 36,
      staffMembers: 15,
      events: 4,
      budget: "$1,300",
      participation: "65%",
    },
    {
      name: "Swimming Club",
      campus: " Guardian",
      members: 11,
      staffMembers: 25,
      events: 1,
      budget: "$3,500",
      participation: "65%",
    },
  ];




  
  const clubsDataDemo = [
    {
      name: "BasketBall",
      campus: "DC UAE",
      members: 4,
      staffMembers: 1,
      events: 10,
      budget: "$3,500",
      participation: "89%",
    },
    {
      name: "Brass Ensemble",
      campus: "DC UAE",
      members: 5,
      staffMembers: 1,
      events: 3,
      budget: "$4,500",
      participation: "65%",
    },
    {
      name: "Science Communicator",
      campus: "DC UAE",
      members: 5,
      staffMembers: 1,
      events: 0,
      budget: "$3,500",
      participation: "55%",
    },
  ];





  

  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch data from the '/get_events/' endpoint
    fetch(`${process.env.REACT_APP_API_KEY}/api/get_events/`)
      .then((response) => response.json())
      .then((data) => {
        // Filter events where campus matches the username
        const filteredEvents = data.filter(
          (event) => event.campus === user.username
        );
        setEvents(filteredEvents);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [user.username]); // Include username as a dependency to re-fetch when it changes

  return (
    <AdminLayout>
      <CCAStats />

      
      {/* <ReportDownloadCard user={user}/> */}

      

      <div className="my-4">
        {/* <TopClubs/> */}




        
        <div className="flex flex-wrap mt-6 -mx-3">
          <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-7/12 lg:flex-none">
            <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid shadow-xl    border-black-125 rounded-2xl bg-clip-border">
              <div className="p-4 pb-0 mb-0 rounded-t-4">
                <div className="flex justify-between">
                  <h6 className="mb-2 ">Requests</h6>
                </div>
              </div>
              <div className="overflow-x-auto">
              <RequestsList />
              </div>
            </div>
          </div>
          <div className="w-full max-w-full px-3 mt-0 lg:w-5/12 lg:flex-none">
            <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
              <div className="p-4 pb-0 rounded-t-4">
                <h6 className="mb-0 ">Calendar</h6>
              </div>
              <div className="flex-auto p-4">
                <MiniCalendar events={events} />
              </div>
            </div>
          </div>
        </div>



        
      </div>




      <div className="my-4">
        {/* <TopClubs/> */}

        <div className="flex flex-wrap mt-2 -mx-3">
          <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-7/12 lg:flex-none">
            <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid  border-black-125 rounded-2xl bg-clip-border">
              {/* <ScheduleTrend/> */}

              <div className="overflow-hidden">
                {/* Card */}
                <div className="flex flex-col">
                  <div className="overflow-x-auto">
                    <div className="min-w-full inline-block align-middle">
                      <div className="bg-white border border-gray-200 rounded-xl h-[50vh]  overflow-hidden darki:bg-slate-900 darki:border-gray-700">
                        <div className="p-4 pb-0 mb-0 rounded-t-4">
                          <div className="flex flex-col justify-start">
                          <h6 className="mb-2 ">Club Budget Breakdown</h6>
                        <p className="text-sm  leading-normal ">Breakdown of general expenditures for student societies</p>
                          </div>
                        </div>
                        <ClubBudgetBreakdown/>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Card */}
              </div>
            </div>
          </div>
          <div className="w-full max-w-full px-3 mt-0 lg:w-5/12 lg:flex-none">
            <div className="my-4">
              {/* <ApexChart userID={profileID} profileID={profiler} /> */}

              <div className="my-4">
                {/* Card */}
                <div className="flex flex-col">
                  <div className="-m-1.5 overflow-x-auto">
                    <div className="p-1.5 min-w-full inline-block align-middle">
                      <div className="bg-white border border-gray-200 rounded-xl  h-[50vh] overflow-hidden  darki:border-gray-700">
                        <div className="p-4 pb-0 mb-0 rounded-t-4">
                          <div className="flex flex-col justify-start">
                            <h6 className="mb-2 ">Activity Peaks</h6>
                            <p className="text-sm  leading-normal ">
                              Explore the busiest days for extracurricular
                              events at a glance.
                            </p>
                          </div>
                        </div>
                        {/* <ClubSegregation/> */}
                        <ScheduleTrend />
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Card */}
              </div>
            </div>
          </div>
        </div>
      </div>









      
    </AdminLayout>
  );
}

export default SchoolDashboard;
