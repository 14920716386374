import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const AreaChart = ({ userID, profileID }) => {
  const [networkID, setnetworkID] = useState(profileID);
  const [authID, setAuthID] = useState(userID);

  useEffect(() => {
    // If the profileID changes, update the state in AreaChart
    setnetworkID(profileID);
    setAuthID(userID);
  }, [userID, profileID]);

  const getLastFiscalQuarterMonths = () => {
    const currentMonth = new Date().getMonth();
    const fiscalQuarterStartMonth = (currentMonth - 2 + 12) % 12; // Adjust for fiscal year start

    const monthNames = [];
    for (let i = 0; i < 3; i++) {
      const monthIndex = (fiscalQuarterStartMonth + i) % 12;
      const monthName = new Date(0, monthIndex).toLocaleString('en', { month: 'long' });
      monthNames.push(monthName);
    }

    return monthNames;
  };

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: 'area-chart',
      },
      xaxis: {
        categories: getLastFiscalQuarterMonths(),
      },
    },
    series: [
      {
        name: 'Number of Activities',
        data: Array(3).fill(0),
      },
    ],
  });

  useEffect(() => {
    fetchData();
  }, [networkID, authID]);

  const fetchData = async () => {
    try {
      // Fetch data from the first API
      const responseActivities = await fetch('https://dev.api.startupearly.com/api/get_all_activities/');
      const activitiesData = await responseActivities.json();

      // Fetch data from the second API
      const responseJoinActivities = await fetch('https://dev.api.startupearly.com/api/join_activities/');
      const joinActivitiesData = await responseJoinActivities.json();

      // Process data from the second API to update the chart
      const updatedData = Array(3).fill(0);

      joinActivitiesData.forEach((user) => {
        // Check if the user's network_in matches the specified networkID
        if (user.network_in === networkID) {
          user.activities.forEach((activity) => {
            const startDate = new Date(activity.start_date);
            const month = startDate.getMonth();

            // Check if the month is in the last fiscal quarter
            if (month >= 0 && month <= 2) {
              updatedData[month] += 1;
            }
          });
        }
      });

      setChartData((prevData) => ({
        ...prevData,
        series: [{ data: updatedData }],
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="area-chart">
      <Chart options={chartData.options} series={chartData.series} type="area" height={350} />
    </div>
  );
};

export default AreaChart;
