import React, { useState, useEffect } from 'react';
import BottomCTA from './BottomCTA';
import Footer from './Footer';
import Navbar from './Navbar';

import {
    EmailShareButton,
    FacebookShareButton,
    GabShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
  } from "react-share";
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

  

function BlogArticle() {


    const copyURLToClipboard = () => {
        const currentURL = window.location.href;
        navigator.clipboard.writeText(currentURL)
          .then(() => {
            //alert('URL copied to clipboard!');
            toast.success(`Link copied to clipboard!`)
          })
          .catch((error) => {
            console.error('Failed to copy URL: ', error);
          });
      };
    

      const [liked, setLiked] = useState(false);
      const [likesCount, setLikesCount] = useState(875);
    
      const handleLikeClick = () => {
        setLiked(!liked);
        setLikesCount(liked ? likesCount - 1 : likesCount + 1);
      };
    

    return (
        <>

        <Navbar/>
        




        <>
  {/* Blog Article */}
  <div className="max-w-[64rem] px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
    <div className="max-w-4xl">
      {/* Avatar Media */}
      



      {/* End Avatar Media */}
      {/* Content */}
      <div className="space-y-5 md:space-y-8">
        <div className="">


<div>
  <Link to={'/'} className='p-0 mb-2 btn btn-sm btn-ghost'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
</svg>
  Back to home</Link>
</div>


<h2 className="text-2xl font-bold md:text-3xl darki:text-white mb-6 py-4">
The Role of Extracurricular Activities In Shaping Future Leaders
          </h2>


          



          <div className="flex justify-between items-center">
        <div className="flex w-full sm:items-center gap-x-5 sm:gap-x-3">
          <div className="flex-shrink-0">
            <img
              className="w-12 h-auto rounded-full rounded-full"
              src="https://media.licdn.com/dms/image/D4D03AQHOAkdBR2pH4g/profile-displayphoto-shrink_400_400/0/1692804446754?e=1716422400&v=beta&t=xM1Zx92Wt6AbQoG5mAkvu-tbUDIHs3RpA-9cUt6ZTyA"
              alt="Image Description"
            />
          </div>
          <div className="grow">
            <div className="flex justify-between items-center gap-x-2">
              <div>
                {/* Tooltip */}
                <div className="hs-tooltip inline-block [--trigger:hover] [--placement:bottom]">
                  <div className="hs-tooltip-toggle sm:mb-1 block text-start cursor-pointer">
                    <span className="font-semibold text-gray-800 darki:text-gray-200">
                    Shayan Aamir • <span className='text-gray-400'> Product Development Manager </span>
                    </span>
                  </div>
                </div>
                {/* End Tooltip */}
                <ul className="text-xs text-gray-500">
                  <li className="inline-block relative pe-6 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-2 before:-translate-y-1/2 before:size-1 before:bg-gray-300 before:rounded-full darki:text-gray-400 darki:before:bg-gray-600">
                    Mar 17, 2024
                  </li>
                  <li className="inline-block relative pe-6 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-2 before:-translate-y-1/2 before:size-1 before:bg-gray-300 before:rounded-full darki:text-gray-400 darki:before:bg-gray-600">
                    5 min read
                  </li>
                </ul>
              </div>
              {/* Button Group */}
              <div>
                <TwitterShareButton
                  type="button"
                  title={"Role of Extracurricular Activities in Shaping Future Leaders"}
        url={"https://startupearly.com/blog/1710667298"}
        hashtags={["blog", "extracurriculars"]}
                  className="btn btn-ghost border-2"
                >
                  <svg
                    className="size-3.5"
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                  </svg>
                  Tweet
                </TwitterShareButton>
              </div>
              {/* End Button Group */}
            </div>
          </div>
        </div>
      </div>

      <div className='divider'></div>
      <figure className='mb-6 mt-2'>
          <img
            className="w-full  img-fit rounded-xl"
            src="https://res.cloudinary.com/ahbranding/image/upload/v1710828091/AHbranding/Frame_3_drjexh.png"
            alt="Image Description"
          />
          <figcaption className="mt-3 text-sm text-center text-gray-500 hidden">
            A School sports team.
          </figcaption>
        </figure>





    

          <p className="text-base text-gray-800 darki:text-gray-200 mt-6">
          In today's digital age, as electronic devices and social media have become more popular, there has been a decline in the interest for extracurricular activities. This has had a significant impact on the Leadership development of today's generation of students. In such circumstances, it is important to re-explore the importance of strong extracurricular involvement for young children. In this blog post, we will explore the importance of extracurricular activities in developing leadership skills and other important competencies, share success stories of individuals who have excelled in extracurricular pursuits, discuss the realistic challenges around extracurricular activities, and provide recommendations for parents and educators to overcome these challenges.          </p>
        </div>


        

        <p className="text-base text-gray-800 darki:text-gray-200 flex flex-col">
        <strong className='text-lg pb-4'>Beyond Academics—The Value of Extracurricular Activities<br/></strong>
        Extracurricular activities play a crucial role in the holistic development of students, going beyond mere academics to foster mental and physical growth alongside the cultivation of vital leadership capabilities. Participation in a diverse array of activities, including but not limited to sports, arts, debate clubs, and community service, provide invaluable experiences to the students that significantly contribute to their personal and professional development. These claims are supported by research which states that students that are involved in extracurricular activities exhibit superior academic performance, improved self-esteem, and higher interpersonal skills.
        </p>



        <p className="text-base text-gray-800 darki:text-gray-200 flex flex-col">
        <strong className='text-lg pb-4'>The Crucial Role of Extracurriculars in Developing Leadership Skills<br/></strong>
        Extracurricular activities are really significant in developing leadership qualities. These activities offer practical experiences that present real-world problems to the students that require teamwork and responsibility. These experiences are really important in developing adaptability, initiative, the ability to motivate, and many other essential skills that are necessary for future leadership roles.
Understanding the importance of developing leadership from a young age is critical. Extracurricular activities provide a robust platform for students to explore and refine leadership skills. Active participation in activities like sports, debate, and student government positions are especially responsive to this development. For instance, sports develop teamwork, perseverance, and strategic thinking. Similarly, participation in debate teams sharpens students' ability to think critically, articulate thoughts clearly, and construct coherent arguments.

        </p>




        <p className="text-base text-gray-800 darki:text-gray-200 flex flex-col">
        <strong className='text-lg pb-4'>Inspirational Stories of Leadership Through Extracurricular Engagement<br/></strong>
        The impact of extracurricular involvement on strong leadership development is presented through the stories of numerous prominent figures who attribute their leadership success to early engagements in extracurriculars alongside academics. Oprah Winfrey's journey from overcoming adversity to becoming a global leader in media and philanthropy, Michael Jordan's rise from facing strong rejection to becoming a basketball icon, and Malala Yousafzai's courageous activism for girls' education from a young age. Each highlights how extracurricular activities can help develop resilient and determined leaders.
        </p>



        <p className="text-base text-gray-800 darki:text-gray-200 flex flex-col">
        <strong className='text-lg pb-4'>Overcoming Barriers to Extracurricular Participation<br/></strong>
        However, obstacles may arise in your extracurricular journey. In most cases, affordability and logistical issues often prevent equal access to extracurricular activities, calling out the need for initiatives aimed at removing these barriers. Scholarship programs, community efforts to provide free or low-cost access, and transportation solutions are some of the measures that help ensure all students can benefit from the numerous opportunities that extracurricular activities offer.
        </p>


        <p className="text-base text-gray-800 darki:text-gray-200 flex flex-col">
        <strong className='text-lg pb-4'>Empowering Students: The Role of Parents and Educators<br/></strong>
        The support of parents and educators in the extracurricular involvements of children is crucial. This support involves identifying and nurturing individual interests, celebrating and encouraging achievements in extracurriculars as well as academics, and providing constructive feedback that leads to reflection and growth. Addressing logistical and financial challenges is also really important to encourage students' participation, ensuring that every young individual has the opportunity to develop their leadership competencies through diverse extracurricular activities.        
         </p>



        <p className="text-base text-gray-800 darki:text-gray-200 flex flex-col">
        <strong className='text-lg pb-4'>Conclusion: Shaping the Future Through Extracurricular Engagement<br/></strong>
        In conclusion, extracurricular activities play an important role in shaping future leaders, providing an environment to students where they can learn and grow outside the classroom. By developing skills such as teamwork, resilience, and critical thinking, these activities prepare students for successful leadership roles in various aspects of life. Overcoming participation barriers and ensuring supportive environments for all students are essential steps toward achieving the full potential and benefits of extracurricular activities. As educators and parents prioritize these opportunities, they pave the way for nurturing empowered visionary leaders who are equipped to face the challenges of tomorrow.        
        
        </p>




        <br/> 


        <p className="text-base text-gray-800 darki:text-gray-200 font-semibold">
          If you want to have a detailed discussion about this topic or are interested in elevating your educational institution's extracurricular management, please reach out to me at <a className='link link-primary' href="mailto:shayan.aamir@startupearly.com">shayan.aamir@startupearly.com</a>
        </p>
        
        <div>
          <a
            className="m-1 inline-flex items-center gap-1.5 py-2 px-3 rounded-full text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 darki:bg-gray-800 darki:hover:bg-gray-700 darki:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
            href="#"
          >
            Extracurriculars
          </a>
          <a
            className="m-1 inline-flex items-center gap-1.5 py-2 px-3 rounded-full text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 darki:bg-gray-800 darki:hover:bg-gray-700 darki:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
            href="#"
          >
            Student Development
          </a>
          <a
            className="m-1 inline-flex items-center gap-1.5 py-2 px-3 rounded-full text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 darki:bg-gray-800 darki:hover:bg-gray-700 darki:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
            href="#"
          >
            Quick Read
          </a>
          
        </div>
      </div>
      {/* End Content */}
    </div>
  </div>
  {/* End Blog Article */}
  {/* Sticky Share Group */}
  <div className="sticky bottom-6 inset-x-0 text-center mb-8">
    <div className="inline-block bg-white shadow-md rounded-full py-3 px-4 darki:bg-gray-800">
      <div className="flex items-center gap-x-1.5">
        {/* Button */}
        <div className="hs-tooltip inline-block">
        <button
      type="button"
      className="btn btn-ghost btn-sm rounded-full"
      onClick={handleLikeClick}
    >
      <svg
        className="flex-shrink-0 size-4"
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        stroke={liked ? 'red' : 'currentColor'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
      </svg>
      {likesCount}
      <span
        className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm darki:bg-black"
        role="tooltip"
      >
        Like
      </span>
    </button>

        </div>
        {/* Button */}
        <div className="block h-3 border-e border-gray-300 mx-3 darki:border-gray-600" />
        {/* Button */}
        <div className="hs-dropdown relative inline-flex">
          <button
            type="button"
            id="blog-article-share-dropdown"
            className="btn btn-ghost btn-sm rounded-full"
            onClick={copyURLToClipboard}
          >
            <svg
              className="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" />
              <polyline points="16 6 12 2 8 6" />
              <line x1={12} x2={12} y1={2} y2={15} />
            </svg>
            Share
          </button>
          
        </div>
        {/* Button */}
      </div>
    </div>
  </div>
  {/* End Sticky Share Group */}
</>





        <BottomCTA/>
        <Footer/>
      </>
      
    )
}

export default BlogArticle;