import React from "react";

const Comments = ({ comment }) => (
  <div className="flex-col mt-1">
    <div className="flex-shrink-0 group block">
      <div className="flex items-center">
        <img
          className="inline-block flex-shrink-0 size-[62px] rounded-full w-12 h-12"
          src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
          alt="Image Description"
        />
        <div className="ms-3">
          <h3 className="font-semibold text-gray-800 text-base m-0">
            {comment?.name}
          </h3>
          <p className="text-sm font-medium text-gray-400 text-sm m-0">
            {comment.email}
          </p>
        </div>
      </div>
    </div>

    <div className="flex-1 px-2 ml-2 text-sm font-medium leading-loose text-gray-600 mt-4">
      {comment.text}
    </div>
    <div className="flex">
      <button className="inline-flex items-center px-1 pt-2 ml-1 flex-column">
        {/* SVG for like button */}
      </button>
      <button className="inline-flex items-center px-1 -ml-1 flex-column">
        {/* SVG for reply button */}
      </button>
    </div>
    {/* {comment.replies && (
      <div className="ml-8">
        {comment.replies.map((reply, index) => (
          <Comments key={index} comment={reply} />
        ))}
      </div>
    )} */}
  </div>
);

export default Comments;
