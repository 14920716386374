import React, { useState } from "react";
import { axiosInstance } from "../Axios";

import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import MyCalendar from "../components/EventCalendar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";
import ApexChart from "../components/AreaChart";
import Navbar from "../components/Navbar";
import BarChart from "../components/Barchart";
import RadarChart from "../components/RadarChart";
import BarStacked from "../components/BarStacked";
import CoCurricularEventsTable from "../components/CoCurricularEventsTable";
import NetworkStats from "../components/NetworkStats";
import Network_StudentList from "../components/Network_StudentList";
import Calendar from "react-calendar";
import EventCalendar from "../components/EventCalendar";
import BarchartSchoolTrend from "./BarchartSchoolTrend";
import NetworkStudents from "../components/NetworkStudents";
import { useLanguage } from "../components/language";
import SidebarPilot from "../components/SidebarPilot";
import SideInnerSMPilot from "../components/SideInnerSMPilot";
import NetworkClubAnalytics from "./NetworkClubAnalytics";
import ClubMembersList from "./ClubMembersList";
import ClubEvents from "./ClubEvents";
import AttendancePage from "./AttendancePage";
import KanBan from "./kanban/KanBan";
import ClubEnrolments from "./ClubEnrolments";
import NominationsPrompt from "./NominationsPrompt";

import AdminLayout from "./AdminLayout";
import { Link, useParams } from "react-router-dom";
import Feed from "./Feeds/Feed";
import Profile from "./Feeds/ClubProfile";
import ClubProfile from "./Feeds/ClubProfile";
import ClubPageTimeline from "./ClubPageTimeline";
import ClubDetailsSImple from "./ClubDetailsSImple";
// import Profile from "./Feed/Profile";

export default function ClubDetailsInternal() {
  const { t, changeLanguage, lanArabic } = useLanguage();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  let { user } = useContext(AuthContext);
  let profileID = 0;

  if (user[`role`] === `Parent`) {
    profileID = 12;
  } else {
    profileID = user["user_id"];
  }
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        //console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  const [date, setDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch data from the '/get_events/' endpoint
    fetch(`${process.env.REACT_APP_API_KEY}/api/get_events/`)
      .then((response) => response.json())
      .then((data) => {
        // Filter events where campus matches the username
        const filteredEvents = data.filter(
          (event) => event.campus === user.username
        );
        setEvents(filteredEvents);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [user.username]); // Include username as a dependency to re-fetch when it changes
  const { id } = useParams();
  const [soceity, setSociety] = useState("");
  useEffect(() => {
    axiosInstance
      .get(`get_club_details/${id}/`)
      .then((res) => {
        setSociety(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <AdminLayout>


{/* <ClubProfile soceity={soceity} /> */}

{/* <ClubPageTimeline soceity={soceity}/> */}

<ClubDetailsSImple soceity={soceity}/>

      {/* <Tabs>
        <div className="">
          <div className="border-b border-gray-200">
            <TabList className="-mb-px flex gap-6" aria-label="Tabs">
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Analytics
              </Tab>
   
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Attendance
              </Tab>

              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Feeds
              </Tab>
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Members
              </Tab>
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Events / Activities
              </Tab>

              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Enrollments
              </Tab>
            </TabList>
          </div>
        </div>

        <TabPanel>
          <NetworkClubAnalytics />
        </TabPanel>


        <TabPanel>
          <AttendancePage />
        </TabPanel>

        <TabPanel>
          <Feed />
        </TabPanel>

        <TabPanel>
          <ClubMembersList />
        </TabPanel>

        <TabPanel>
          <ClubEvents />
        </TabPanel>

        <TabPanel>
          <ClubEnrolments />
        </TabPanel>
      </Tabs> */}
    </AdminLayout>
  );
}
