import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { withTranslation } from "react-i18next";

class MonthlyAttendanceChart extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props; // use t function for translation
    this.state = {
      series: [
        {
          name: `${t('Student Engagement')} %`,
          data: [80, 85, 90, 88, 92],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 0.9,
        },
        title: {
          text: "",
          align: "left",
          enabled: false,
          show: false,
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May"], // Months
        },
      },
    };
  }

  render() {
    

    return (
      <div>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="line"
        />
      </div>
    );
  }
}

export default withTranslation()(MonthlyAttendanceChart);
