import React, { useEffect, useState, useRef } from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import { useLanguage } from "../components/language";
import Navbar from "../components/Navbar";
import "react-toggle/style.css";
import { createGlobalStyle } from "styled-components";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import Select from "react-select";
import SampleFile from "./SampleFile";
import axios from "axios";
import { Line } from "rc-progress";
import UsersUpload from "./UsersUpload";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminLayout from "./AdminLayout";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import StudentCouncilTracker from "../components/StudentCouncilTracker";
import FAQs from "./FAQs";
import CouncilRosterStats from "../components/CouncilRosterStats";
import StudentPerformanceTable from "../components/StudentPerformanceTable";
import StudentCouncilTrackerWeekly from "../components/StudentCouncilTrackerWeekly";
import ClubMembersListSample from "./ClubMembersListSample";
// import cloud from "../assets/images/cloud_upload.svg"

const EventDetailsPage = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const { t, changeLanguage, lanArabic } = useLanguage();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "teacher", label: "Teacher" },
    // Add more role options as needed
  ];
  const { id } = useParams();

  const permissionOptions = [
    { value: "read", label: "Read" },
    { value: "write", label: "Write" },
    // Add more permission options as needed
  ];


  let {user} = useContext(AuthContext)

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };
  const navigate = useNavigate();
  const [event, setEvents] = useState(null);
  console.warn(event?.faculty_advisors);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_event_by_id/${id}/`)
      .then((response) => {
        setEvents(response.data);
      });
  }, [id]);

  function formatDate(dateString) {
    const options = { month: "long", day: "2-digit", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
  }

  const EventApproval = (status) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/event_approval/${id}/`, {
        status: status,
      })
      .then((res) => {
        toast.success("Event Approved Successfully");
        console.log(res);
      })
      .catch((err) => {
        toast.error("Event Approval Failed");
        console.log(err);
      });
  };

  const attendances = [
    {
      id: 1,
      title: "Pre Event Attendance",
      datetime: "Feb 29, 06:00",
      status: "marked",
      presents: "15",
      late: "04",
      absents: "02",
    },
    {
      id: 2,
      title: "Event Start Fall-in",
      datetime: "Feb 29, 08:00",
      status: "active",
      presents: "0",
      late: "0",
      absents: "0",
    },
    {
      id: 3,
      title: "Post Event Fall-out",
      datetime: "Feb 29, 01:00",
      status: "pending",
      presents: "0",
      late: "0",
      absents: "0",
    },
  ];

  const studentsData = [
    { id: 1, name: "John Doe", school: "Engineering", semester: 4 },
    {
      id: 2,
      name: "Jane Smith",
      school: "Business Administration",
      semester: 6,
    },
    { id: 3, name: "Alex Johnson", school: "Computer Science", semester: 3 },
    { id: 4, name: "Emily Davis", school: "Psychology", semester: 5 },
    { id: 5, name: "Michael Brown", school: "Biology", semester: 2 },
    { id: 6, name: "Sophia Wilson", school: "Mathematics", semester: 3 },
    { id: 7, name: "William Martinez", school: "Chemistry", semester: 4 },
    { id: 8, name: "Olivia Anderson", school: "Art History", semester: 5 },
    { id: 9, name: "James Thomas", school: "Sociology", semester: 2 },
    { id: 10, name: "Emma Taylor", school: "English Literature", semester: 6 },
    { id: 11, name: "Daniel Jackson", school: "Economics", semester: 4 },
    {
      id: 12,
      name: "Isabella White",
      school: "Political Science",
      semester: 3,
    },
    { id: 13, name: "Ethan Harris", school: "Physics", semester: 5 },
    { id: 14, name: "Ava Moore", school: "Environmental Science", semester: 2 },
    { id: 15, name: "Liam Clark", school: "History", semester: 3 },
  ];

  const [sortBy, setSortBy] = useState({ field: null, asc: true });
  const [searchTerm, setSearchTerm] = useState("");

  const handleSort = (field) => {
    if (sortBy.field === field) {
      setSortBy({ ...sortBy, asc: !sortBy.asc });
    } else {
      setSortBy({ field, asc: true });
    }
  };

  const filteredData = studentsData.filter((student) =>
    student.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (sortBy.field) {
    filteredData.sort((a, b) => {
      const aValue = a[sortBy.field];
      const bValue = b[sortBy.field];
      if (sortBy.asc) {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });
  }

  let approvalRequired = false;
  let attendanceRequired = false;

  return (
    <AdminLayout>
      <div className="">
        <div
          className={``}
        >
          {/* Content */}
          <div className="lg:col-span-2 bg-white shadow-xl rounded-xl p-6 w-full mb-8">
            <div className="">
              <div className="">
                <div>
                  <Link
                    className="inline-flex items-center gap-x-1.5 text-sm text-gray-600 decoration-2 hover:underline darki:text-blue-400 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                    href="#"
                    onClick={() => navigate(-1)}
                  >
                    <svg
                      className="flex-shrink-0 w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m15 18-6-6 6-6" />
                    </svg>
                    Back to Events
                  </Link>

                  {/* <img class="object-cover h-48 w-full my-3 rounded-xl" src={event?.banner}/> */}

<div className="flex flex-wrap justify-between">




<h2 class="text-3xl font-bold lg:text-3xl lg:text-4xl darki:text-white mt-4">
                    {event?.name}
                  </h2>




<div className="group flex items-center gap-x-[1.5rem]  darki:border-gray-700">
                  <div className="flex flex-row items-center gap-x-4 bg-gray-100 px-3 rounded-xl">
                    <div className="block flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                        />
                      </svg>
                    </div>
                    <div className="group grow block">
                      <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
                        Start Date
                      </h5>
                      <p className="text-sm text-gray-500 m-0">
                        {formatDate(event?.start_date)},{" "}
                        {formatTime(event?.start_date)}
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-row items-center gap-x-4 bg-gray-100  px-3 rounded-xl">
                    <div className="block flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                        />
                      </svg>
                    </div>
                    <div className="group grow block">
                      <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
                        End Date
                      </h5>
                      <p className="text-sm text-gray-500 m-0">
                        {formatDate(event?.end_date)},{" "}
                        {formatTime(event?.end_date)}
                      </p>
                    </div>
                  </div>
                </div>
</div>
                 

                 

                  <div class="flex items-center gap-x-5 mt-4">
                    <a
                      class="inline-flex items-center gap-1.5 py-1 px-3 sm:py-2 sm:px-4 rounded-full text-xs sm:text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 darki:bg-gray-800 darki:hover:bg-gray-800 darki:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                      href="#"
                    >
                      Full Event
                    </a>

                    <a
                      class="inline-flex items-center gap-1.5 py-1 px-3 sm:py-2 sm:px-4 rounded-full text-xs sm:text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 darki:bg-gray-800 darki:hover:bg-gray-800 darki:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                      href="#"
                    >
                      {event?.external_event === true ? (
                        <span>External</span>
                      ) : (
                        <span>Onsite</span>
                      )}
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>



<div >
<Tabs className="mt-4 mb-[12rem]"  >
                  <TabList className="tab-list flex-wrap gap-3 mb-6 mx-2">
                    <Tab className="tab">Event details</Tab>
                    {user.role==='Network Admin'?(
<>
<Tab className="tab">Tracker</Tab>
<Tab className="tab">Attend request</Tab>
</>
                    ):null}
                    
                  </TabList>

<div className="lg:col-span-2 bg-white shadow-xl rounded-xl p-6 w-full mt-3">



                  <TabPanel>

                  <div>
                  <h3 className="text-base text-primary">About event</h3>
                  <p className="mt-3 text-sm">
                    {" "}
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English.{" "}
                  </p>


                  <h3 className="text-base text-primary">Frequently Asked Questions</h3>
                  <p className="mt-3 text-sm ">
                    {" "}
                    Find all your answers related to this event.{" "}
                  </p>

                  <FAQs/>
                  </div>

                  </TabPanel>

                  <TabPanel>
                  <CouncilRosterStats needTypeFilter={false} type={'Event'}  />
                  <StudentCouncilTracker type='event' singleEvent={true} needWeeks={false}/>
                  </TabPanel>

                  <TabPanel><ClubMembersListSample/></TabPanel>
</div>
                  </Tabs>
</div>

  
        </div>
        
      </div>
      {/* End Article */}

{user.role===`Student`?(


  <div className="sticky bottom-[2rem] bg-white px-4 py-2 rounded-xl shadow-xl w-full">
<div className="flex flex-row justify-between items-center">

<div className="flex flex-row gap-x-[4rem] items-center">

<div className="flex flex-col gap-y-2">
<span className="text-sm text-gray-400">Date</span>
<h3 className="text-base text-primary">{formatDate(event?.start_date)}</h3>
</div>

<div className="flex flex-col gap-y-2">
<span className="text-sm text-gray-400">Time</span>
<h3 className="text-base text-primary">{formatTime(event?.start_date)}</h3>
</div>

</div>

<div className="flex flex-row gap-x-[4rem] items-center">
  

<div className="flex flex-col gap-y-2">
<span className="text-sm text-gray-400">Ticket</span>
<h3 className="text-base text-primary">AED 40</h3>
</div>

<button className="btn btn-primary"> Request Booking </button>

</div>

</div>
</div>
):null}


    </AdminLayout>
  );
};

export default EventDetailsPage;
