import React, { useState } from 'react';
import '../pages/Pilot.css';
import Navbar from '../components/Navbar';
import { Planet } from 'react-planet';

function PilotPageRadial() {

    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);
    
        // 👇️ or simply set it to true
        // setIsShown(true);
      };


  return (
    <div className="" >

    <Navbar/>





    <div className="flex justify-center flex-col h-screen mr-12 items-center">



    {isShown ? (
        null
      ) : (



<div className='text-center ml-12 mb-8'>
        <p className='textInfo text-3xl text-center'> Click on that circle </p>
    </div>

      )}

        <div className='Radmain'>
<Planet
	centerContent={
    <button onClick={handleClick} className='btn btn-circle bg-white btn-lg text-2xl'> 
    
    {isShown ? (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
      ) : (

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
</svg> 

      )}



     </button>
    }
	hideOrbit
	autoClose
	orbitRadius={140}
	bounceOnClose
	rotation={105}
	// the bounce direction is minimal visible
	// but on close it seems the button wobbling a bit to the bottom
	bounceDirection="BOTTOM"
>
<div class="btn btn-circle bg-white btn-lg text-2xl tooltip-left tooltip flex"  data-tip="Leadership">
      🏆
    </div>
    <div class="btn btn-circle bg-white btn-lg text-2xl tooltip-top tooltip flex"  data-tip="Society & Clubs">
      ♠️
    </div>
    <div class="btn btn-circle bg-white btn-lg text-2xl tooltip-top tooltip flex"  data-tip="Social Work">
      🎗️
    </div>
    <div class="btn btn-circle bg-white btn-lg text-2xl tooltip-top tooltip flex"  data-tip="Awards">
      🎖️
    </div>
    <div class="btn btn-circle bg-white btn-lg text-2xl tooltip-top tooltip flex"  data-tip="Sports">
      ⚽
    </div>
    <div class="btn btn-circle bg-white btn-lg text-2xl tooltip-top tooltip flex"  data-tip="Internship">
      💼
    </div>
    <div class="btn btn-circle bg-white btn-lg text-2xl tooltip-bottom tooltip flex"  data-tip="Certificate">
      📃
    </div>
    <div class="btn btn-circle bg-white btn-lg text-2xl tooltip-bottom tooltip flex"  data-tip="Event Management">
      📆
    </div>
    <div class="btn btn-circle bg-white btn-lg text-2xl tooltip-left tooltip flex"  data-tip="Languages">
      🔠
    </div>
    <div class="btn btn-circle bg-white btn-lg text-2xl tooltip-top tooltip flex"  data-tip="Talents">
      🎨
    </div>

</Planet>
</div>
</div>

    </div>
  );
}

export default PilotPageRadial;
