import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import ThreadWrapper from "./ThreadWrapper";
import AddCommentForm from "./AddCommentForm";
import { axiosInstance } from "../../Axios";
import AuthContext from "../../context/AuthContext";
import { FreeMode, Pagination } from 'swiper/modules';
// import 'swiper/swiper-bundle.min.css';
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";

function PostDetails() {
  const { id } = useParams();
  const [post, setPost] = useState();
  const { user } = useContext(AuthContext);
  const [comments, setComments] = useState([]);
  const getFeed = async () => {
    axiosInstance
      .get(`get_feed_by_id/${id}/`)
      .then((res) => {
        setPost(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getComments = async () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_comments/${id}/`)
      .then((res) => {
        setComments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFeed();
    getComments();
  }, []);

  //   const handleLike = async (postId) => {
  //     try {
  //       const response = await axiosInstance.put(`update_like/`, {
  //         feedId: postId,
  //         user: user.user_id,
  //       });

  //       const { Liked, like_count } = response.data; // Retrieve data from the response

  //       // Update posts based on the response data
  //       const updatedPosts = posts.map((post) =>
  //         post.id === postId
  //           ? { ...post, liked: Liked, like_count: like_count }
  //           : post
  //       );

  //       setPosts(updatedPosts); // Update state with the modified post data
  //     } catch (error) {
  //       console.error("Error toggling like:", error);
  //     }
  //   };
  //   let post = {
  //     id: 1,
  //     postedby: "LES Society",
  //     username: "LESSociety",
  //     avatar:
  //       "https://res.cloudinary.com/ahbranding/image/upload/v1714756706/AHbranding/Group_34784448_pib670.png",
  //     date: "2024-04-25T10:00:00Z",
  //     text: "Join us for an interactive coding workshop this Saturday at 3 PM in Room 201! Learn React.js from industry experts. Limited seats available, RSVP now!",
  //     image:
  //       "https://summer.lums.edu.pk/get-picture.php?_ID=VkZaU2FtVkZNVFpUVkVVeVRsVXhjV0V6Y0U5V1JXdDM=&_T=VkZaU2FtVkZNVFpUVkVaRFZFVTVTRmd4UWxCVk1WSk9ZVzEwTmxSc1VrcE5RVDA5",
  //     comment_count: 15,
  //     like_count: 50,
  //   };

  let cardStyle = true;
  const formatDate = (dateString) => {
    const options = { month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <>
      <div className="max-w-screen-md mx-auto">
        <div
          className={
            cardStyle
              ? "px-5 py-4 bg-white darki:bg-gray-800 shadow-xl rounded-lg mb-6"
              : "px-5 py-4 bg-white darki:bg-gray-800 shadow rounded-lg mb-6"
          }
        >
          <div className="flex mb-4">
            <img className="w-12 h-12 rounded-full" src={post?.profile_photo} />
            <div className="ml-2 mt-0.5">
              <span className="block font-medium text-base leading-snug text-black darki:text-gray-100">
                {post?.club_name}
              </span>
              <span className="block text-sm text-gray-500 darki:text-gray-400 font-light leading-snug">
                {formatDate(post?.date)}
              </span>
            </div>
          </div>

          <p className="text-gray-800 darki:text-gray-100 leading-snug md:leading-normal">
            {post?.text}
          </p>

          {(() => {
            if (post?.image !== null) {
              return (
                <Swiper
        slidesPerView={1}
        spaceBetween={20}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        className="mySwiper cursor-pointer "
      >
{post?.images?.map((image, index) => (
          <SwiperSlide>
          <img
                              src={image.image}
                              className="rounded-box w-full"
                            />
          </SwiperSlide>
        ))}
      </Swiper>
              );
            }
          })()}

          <div className="flex justify-between items-center mt-5">
            <div className="flex ">
              <svg
                className="p-0.5 h-6 w-6 rounded-full z-20 bg-white darki:bg-gray-800 "
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 16 16"
              >
                <defs>
                  <linearGradient id="a1" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#18AFFF" />
                    <stop offset="100%" stopColor="#0062DF" />
                  </linearGradient>
                  <filter
                    id="c1"
                    width="118.8%"
                    height="118.8%"
                    x="-9.4%"
                    y="-9.4%"
                    filterUnits="objectBoundingBox"
                  >
                    <feGaussianBlur
                      in="SourceAlpha"
                      result="shadowBlurInner1"
                      stdDeviation={1}
                    />
                    <feOffset
                      dy={-1}
                      in="shadowBlurInner1"
                      result="shadowOffsetInner1"
                    />
                    <feComposite
                      in="shadowOffsetInner1"
                      in2="SourceAlpha"
                      k2={-1}
                      k3={1}
                      operator="arithmetic"
                      result="shadowInnerInner1"
                    />
                    <feColorMatrix
                      in="shadowInnerInner1"
                      values="0 0 0 0 0 0 0 0 0 0.299356041 0 0 0 0 0.681187726 0 0 0 0.3495684 0"
                    />
                  </filter>
                  <path
                    id="b1"
                    d="M8 0a8 8 0 00-8 8 8 8 0 1016 0 8 8 0 00-8-8z"
                  />
                </defs>
                <g fill="none">
                  <use fill="url(#a1)" xlinkHref="#b1" />
                  <use fill="black" filter="url(#c1)" xlinkHref="#b1" />
                  <path
                    fill="white"
                    d="M12.162 7.338c.176.123.338.245.338.674 0 .43-.229.604-.474.725a.73.73 0 01.089.546c-.077.344-.392.611-.672.69.121.194.159.385.015.62-.185.295-.346.407-1.058.407H7.5c-.988 0-1.5-.546-1.5-1V7.665c0-1.23 1.467-2.275 1.467-3.13L7.361 3.47c-.005-.065.008-.224.058-.27.08-.079.301-.2.635-.2.218 0 .363.041.534.123.581.277.732.978.732 1.542 0 .271-.414 1.083-.47 1.364 0 0 .867-.192 1.879-.199 1.061-.006 1.749.19 1.749.842 0 .261-.219.523-.316.666zM3.6 7h.8a.6.6 0 01.6.6v3.8a.6.6 0 01-.6.6h-.8a.6.6 0 01-.6-.6V7.6a.6.6 0 01.6-.6z"
                  />
                </g>
              </svg>
              <span className="ml-1 text-gray-500 darki:text-gray-400  font-light">
                {post?.like_count}
              </span>
            </div>
            <div className="ml-1 text-gray-500 darki:text-gray-400 font-light">
              {post?.comment_count} comments
            </div>
          </div>

          <div className="divider my-2"></div>

          <div className="flex flex-row justify-between items-center">
            <button className="btn btn-sm btn-ghost">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                />
              </svg>
              Like
            </button>

            <button className="btn btn-sm btn-ghost">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
                />
              </svg>
              Share
            </button>
          </div>
        </div>

        <AddCommentForm getFeed={getFeed} getComments={getComments} />

        <ThreadWrapper comments={comments} />
      </div>
    </>
  );
}

export default PostDetails;
