import React, { useState, useEffect, useContext } from "react";

import { Link, Navigate, Redirect, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

import axios from "axios";

const Home = () => {
  const navigate = useNavigate();

  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  let role = user["role"];
  let userID = user["user_id"];

  const [profile, setProfile] = useState([]);

  let endpoint = "";

  if (role === "Student" || role === "Parent") {
    endpoint = "profile";
  } else if (role === "School Admin") {
    endpoint = "school_profile";
  } else if (role === "School Admin") {
    endpoint = "school_profile";
  } else if (role === "Network Admin") {
    endpoint = "network_profile";
  } else if (role === "Staff") {
    endpoint = "staff";
  } else if (role === "Super Admin") {
    endpoint = "super-admin/profile";
  }

  const [profileData, setProfileData] = useState([]);
  useEffect(() => {
    if(role !== "Staff" || role !=="Super Admin" ){
      axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${endpoint}/${userID}`)
      .then((response) => {
        const data = response.data;
        setProfileData(data);
      })
      
      .catch((error) => {
        console.error(error);
      });



      


    }


    if (role === "Student" && profileData?.is_moderator === 0 && profileData?.trial_student === 1) {
      navigate(`/student`);
    } else if (role === "Student" && profileData?.is_moderator === 0 && profileData?.trial_student === 0) {
      navigate(`/student`);
    } else if (role === "Student" && profileData?.is_moderator === 1) {
      navigate(`/moderator`);
    } else if (role === "Network Admin") {
      navigate(`/admin`);
    }  else if (role === "Parent") {
      navigate(`/guardian`);
    }  
    else if (role === "School Admin" || role === "Staff") {
      navigate(`/school`);
    } 
    // else if (role === "School Admin") {
    //   navigate(`/school/${user.user_id}`);
    // } 
    else if (role === "Super Admin") {
      navigate(`/super/${user.user_id}`);
    }

  }, [userID, navigate, user.user_id , profileData?.is_moderator , profileData?.trial_student, endpoint, role]);


  return (
    <div className="home_main">
    {}
      <div className="flex items-center justify-center space-x-2 h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
        <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
        <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
      </div>
    </div>
  );
};
export default Home;
