import React, { useState } from "react";
import { axiosInstance } from "../Axios";

import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import MyCalendar from "../components/EventCalendar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";
import ApexChart from "../components/AreaChart";

import Navbar from "../components/Navbar";
import BarChart from "../components/Barchart";
import RadarChart from "../components/RadarChart";
import BarStacked from "../components/BarStacked";
import CoCurricularEventsTable from "../components/CoCurricularEventsTable";
import NetworkStats from "../components/NetworkStats";
import Network_StudentList from "../components/Network_StudentList";
import Calendar from "react-calendar";
import EventCalendar from "../components/EventCalendar";
import NetworkStudents from "../components/NetworkStudents";
import { useLanguage } from "../components/language";
import SidebarPilot from "../components/SidebarPilot";
import SideInnerSMPilot from "../components/SideInnerSMPilot";
import AddStaff from "../pages/AddStaff";
import randomColor from "randomcolor";
import NetworkAddBulkStd from "./NetworkAddBulkStd";
import ReturnNav from "../pages/ReturnNav";
import AdminLayout from "../pages/AdminLayout";
import AddStudentAPI from "../pages/AddStudentAPI";
import AddStaffAPI from "../pages/AddStaffAPI";

export default function NetworkStaffUpload() {
  const { t, changeLanguage, lanArabic } = useLanguage();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  // const randomBgColor = randomColor();

  let { user } = useContext(AuthContext);
  let profileID = 0;

  if(user[`role`]===`Parent`){
    profileID = 12;
  } else{
    profileID = user["user_id"];
  }
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);

  const [staff, setStaff] = useState([]);

  let url = "";
  if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/${url}/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
      } catch (err) {
        //console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  const [date, setDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch data from the '/get_events/' endpoint
    fetch(`${process.env.REACT_APP_API_KEY}/api/get_events/`)
      .then((response) => response.json())
      .then((data) => {
        // Filter events where campus matches the username
        const filteredEvents = data.filter(
          (event) => event.campus === user.username
        );
        setEvents(filteredEvents);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [user.username]); // Include username as a dependency to re-fetch when it changes

  useEffect(() => {
    // Fetch data from the '/get_staffs/' endpoint
    fetch(`${process.env.REACT_APP_API_KEY}/api/get_staffs/`)
      .then((response) => response.json())
      .then((data) => {
        let filteredStaff = [];
        if (user.role === "Network Admin") {
          filteredStaff = data.filter(
            (staff) => staff.network === profile.network_id
          );
        } else if (user.role === "School Admin") {
          filteredStaff = data.filter(
            (staff) => staff.school === profile.school_id
          );
        }

        setStaff(filteredStaff);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [user, profile]);

  return (
    <AdminLayout>

<div className="w-full px-4 py-2 mx-auto">
            {/* <ReturnNav /> */}
<>
        <Tabs >
        <div className="mt-4">
          <div className="bg-transparent">
            <TabList role="tablist" className="tabs tabs-boxed bg-transparent" >
              <Tab role="tab" className="tab">
                Import via File
              </Tab>
              <Tab role="tab" className="tab">
                Import via API
              </Tab>
            </TabList>
          </div>
        </div>

        <TabPanel>
       <div className={`mt-4`}>
       <AddStaff />
       </div> 
        </TabPanel>
        <TabPanel>
         <AddStaffAPI/>
        </TabPanel>
      </Tabs>
        </>
     
            
            </div>
    </AdminLayout>
  );
}
