import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TrackerCreation = ({ students, clubs, events, staff, studentLeaders, addTracker }) => {
  const [trackerName, setTrackerName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [isMonthly, setIsMonthly] = useState(false);
  const [selectedIntegrationType, setSelectedIntegrationType] = useState("");
  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [selectedStudentLeaders, setSelectedStudentLeaders] = useState([]);

  const handleCreateTracker = () => {
    const newTracker = {
      trackerName,
      startDate,
      isMonthly,
      integrationType: selectedIntegrationType,
      selectedClub,
      selectedEvent,
      assignedStaff: selectedStaff,
      studentLeaders: selectedStudentLeaders,
    };
    addTracker(newTracker);
  };

  return (
    <div className="tracker-creation">
      <h2>Create Tracker</h2>
      <div className="form-group">
        <label>Tracker Name</label>
        <input
          type="text"
          value={trackerName}
          onChange={(e) => setTrackerName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Start Date</label>
        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
      </div>
      <div className="form-group">
        <label>
          Monthly Tracker
          <input
            type="checkbox"
            checked={isMonthly}
            onChange={() => setIsMonthly(!isMonthly)}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Integration Type</label>
        <select
          value={selectedIntegrationType}
          onChange={(e) => setSelectedIntegrationType(e.target.value)}
        >
          <option value="">Select Integration Type</option>
          <option value="custom">Custom</option>
          <option value="club">With Club</option>
          <option value="event">With Event</option>
        </select>
      </div>
      {selectedIntegrationType === "club" && (
        <div className="form-group">
          <label>Select Club</label>
          <Select
            options={clubs}
            value={selectedClub}
            onChange={(club) => setSelectedClub(club)}
          />
        </div>
      )}
      {selectedIntegrationType === "event" && (
        <div className="form-group">
          <label>Select Event</label>
          <Select
            options={events}
            value={selectedEvent}
            onChange={(event) => setSelectedEvent(event)}
          />
        </div>
      )}
      <div className="form-group">
        <label>Assign to Staff</label>
        <Select
          options={staff}
          isMulti
          value={selectedStaff}
          onChange={(staff) => setSelectedStaff(staff)}
        />
      </div>
      <div className="form-group">
        <label>Assign Student Leaders</label>
        <Select
          options={studentLeaders}
          isMulti
          value={selectedStudentLeaders}
          onChange={(leaders) => setSelectedStudentLeaders(leaders)}
        />
      </div>
      <button onClick={handleCreateTracker}>Create Tracker</button>
    </div>
  );
};

export default TrackerCreation;
