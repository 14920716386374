import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

function CouncilMemberTable({ headers, data }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [selectedHouses, setSelectedHouses] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter((row) => {
    const matchesSearchTerm = headers.some((header) =>
      row[header]
        ? row[header].toString().toLowerCase().includes(searchTerm.toLowerCase())
        : false
    );

    const matchesHouseFilter = selectedHouses.length > 0
      ? selectedHouses.some(house => house.value === row["House"])
      : true;

    const matchesPositionFilter = selectedPositions.length > 0
      ? selectedPositions.some(position => position.value === row["Position"])
      : true;

    return matchesSearchTerm && matchesHouseFilter && matchesPositionFilter;
  });

  const houseOptions = Array.from(new Set(data.map((item) => item.House))).map((house) => ({
    label: house,
    value: house
  }));

  const positionOptions = Array.from(new Set(data.map((item) => item.Position))).map((position) => ({
    label: position,
    value: position
  }));

  return (
    <div className="flex flex-col p-3 bg-white rounded-xl mt-6">
      <div className="flex flex-row flex-wrap justify-between">
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search..."
        className="input input-bordered "
      />
      <div className="flex mb-4">
        <MultiSelect
          options={houseOptions}
          value={selectedHouses}
          onChange={setSelectedHouses}
          labelledBy="Select Houses"
          className="mr-4"
        />
        <MultiSelect
          options={positionOptions}
          value={selectedPositions}
          onChange={setSelectedPositions}
          labelledBy="Select Positions"
        />
      </div>
      </div>
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      onClick={() => handleSort(header)}
                      className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase cursor-pointer"
                    >
                      {header}
                      {sortConfig.key === header ? (
                        sortConfig.direction === "asc" ? (
                          " ↑"
                        ) : (
                          " ↓"
                        )
                      ) : null}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {headers.map((key) => (
                      <td
                        key={key}
                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800"
                      >
                        {row[key] !== undefined && row[key] !== null ? row[key].toString() : ""}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CouncilMemberTable;
