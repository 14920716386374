import React, {useEffect, useState, useContext} from 'react'
import { Link, NavLink } from 'react-router-dom'
import AuthContext from '../context/AuthContext'



import SideInnerPilot from './SideInnerPilot'

const SidebarPilot = () => {

    let {user} = useContext(AuthContext)

    return (
    


<SideInnerPilot/>


)
}

export default SidebarPilot