import React, { useState, useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import RoleDropdown from './RoleDropdown';
import ClubSidebar from './ClubSidebar';
import ClubListList from './ClubListList';
import ClubPagination from './ClubPagination';
import clubsData from "./ClubsData";
import SchoolNews from './SchoolNews';
import PublicNav from './PublicNav';

function AllClubsPublic({props}) {
    const [state, setState] = useState('');

    useEffect(() => {
        return () => {

        }
    }, []);

    const GlobalStyle = createGlobalStyle`
    html {
      background: #F9F3FF !important;
      /* Add other global styles here if needed */
    }
  `;

  

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 5;

  // Filter blogs based on selected category
  const filteredBlogs = selectedCategory
    ? clubsData.filter((blog) => blog.category === selectedCategory)
    : clubsData;

  // Pagination logic
  const totalBlogs = filteredBlogs.length;
  const totalPages = Math.ceil(totalBlogs / blogsPerPage);
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const handleCategorySelect = (category) => {
    setCurrentPage(1);
    setSelectedCategory(category);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleShowAll = () => {
    setCurrentPage(1);
    setSelectedCategory(null);
  };

  const uniqueCategories = [...new Set(clubsData.map(blog => blog.category))];

    return (
<div>
<GlobalStyle />
<PublicNav/>
 
<div className="container mx-auto mt-8">
<RoleDropdown  />
  {/* <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mt-4"> 
    <div className="md:col-span-1">
      <ClubSidebar categories={uniqueCategories} onSelectCategory={handleCategorySelect} onShowAll={handleShowAll} />
    </div>
    <div className="md:col-span-3"> 
      <ClubListList blogs={currentBlogs} />
      <ClubPagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
    </div>
  </div> */}
</div>


<SchoolNews/>
</div>
    )
}

export default AllClubsPublic;