import React from 'react';
import ReactApexChart from 'react-apexcharts';

class GenderDiversityChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [60, 40], // Example data for male and female diversity percentages
      options: {
        stroke: {
            width: 1,
            colors: undefined
          },
          yaxis: {
            show: false
          },
          legend: {
            position: 'bottom'
          },
          plotOptions: {
            polarArea: {
              rings: {
                strokeWidth: 0
              },
              spokes: {
                strokeWidth: 0
              },
            }
          },
        labels: ['Boys', 'Girls'], // Labels for the data
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart options={this.state.options} series={this.state.series} type="polarArea" />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default GenderDiversityChart;
