import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { useLanguage } from './language';

const BarStacked = ({source}) => {
  let { user } = useContext(AuthContext);
  const { t, lanArabic } = useLanguage(); // Use the useLanguage hook to access translations and lanArabic

  // extract profile id
  let params = useParams();
  let profileID = 0;

  if (source === 'Param') {
    profileID = params.id;
  } else if (source === 'Self') {
    profileID = user['user_id'];
  } else if (source === 'Parent') {
    profileID = 12;
  } 

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user['user_id'] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`);

        // parse the data in json
        let data = await response.json();

        setProfile(data);
      } catch (err) {
        console.log('The requested profile does not exists.');
      }
    };

    getProfile();
  }, [profileID]);




  // Fetch number of activities found in each category
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          leadResponse,
          societyResponse,
          socialResponse,
          awardsResponse,
          sportsResponse,
          internResponse,
          certResponse,
          talentResponse,
          langResponse,
          eventResponse,
        ] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_lead/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_society/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_social/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_awards/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_sports/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_intern/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_cert/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_talent/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_lang/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_eventStd/`),
        ]);

        // Filter data to include only entries where posted_by is equal to userID
        const filterByUserID = (data, userID) =>
          data.filter((entry) => parseInt(entry.posted_by) === userID);

        const userID = parseInt(profileID);

        const leadData = filterByUserID(leadResponse.data, userID);
        const societyData = filterByUserID(societyResponse.data, userID);
        const socialData = filterByUserID(socialResponse.data, userID);
        const awardsData = filterByUserID(awardsResponse.data, userID);
        const sportsData = filterByUserID(sportsResponse.data, userID);
        const internData = filterByUserID(internResponse.data, userID);
        const certData = filterByUserID(certResponse.data, userID);
        const talentData = filterByUserID(talentResponse.data, userID);
        const langData = filterByUserID(langResponse.data, userID);
        const eventData = filterByUserID(eventResponse.data, userID);

        // Count the number of activities found for each category
        const leadCount = leadData.length;
        const societyCount = societyData.length;
        const socialCount = socialData.length;
        const awardsCount = awardsData.length;
        const sportsCount = sportsData.length;
        const internCount = internData.length;
        const certCount = certData.length;
        const talentCount = talentData.length;
        const langCount = langData.length;
        const eventCount = eventData.length;

        // Modify the series data with fetched counts
        setSeriesData([
          { name: t('Current'), data: [leadCount, societyCount, sportsCount, socialCount, awardsCount, internCount, certCount, talentCount, langCount, eventCount] },
          { name: t('Forecast - till next academic year'), data: [3, 2, 4, 2, 3, 1, 4, 3, 1, 4] }, // Your other data (e.g., [3, 2, 4, 2, 3, 1, 4, 3, 1])
        ]);
      } catch (err) {
        console.log('Error fetching data:', err);
      }
    };

    fetchData();
  }, [profileID]);

  const [seriesData, setSeriesData] = useState([]); // State to hold series data



  // ... (rest of the code)

  const options = {


    colors: [
      '#477BFF',
      '#47D1CB',
      '#6F47FF',
      '#FFCB47',
      '#FF477B',
      '#59DBFF',
      '#CB47FF',
      '#FF884D',
      '#00897B',
    ],

    bar: {
      columnWidth: '45%',
    },

    chart: {
      type: 'bar',
      stacked: true,
    },

    stroke: {
      colors: ['transparent'],
      width: 5,
    },


    xaxis: {
      categories: [t('Leadership'), t('Clubs'), t('Sports'), t('Social Work'), t('Awards'), t('Internship'), t('Certificates'), t('Talents'), t('Languages'), t('Events')],

      labels: {
        show: true, // Set to true to show data labels on the x-axis
        style: {
          fontSize: '12px', // Customize font size for the data labels
        },
      },
    },


  };


  
  const [screenSizeType, setScreenSizeType] = useState('');

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth >= 1600) {
        setScreenSizeType('600');
      } else if (screenWidth >= 1200) {
        setScreenSizeType('500');
      } else if (screenWidth >= 992) {
        setScreenSizeType('400');
      } else if (screenWidth >= 768) {
        setScreenSizeType('350');
      } else if (screenWidth >= 300) {
        setScreenSizeType('300');
      } else {
        setScreenSizeType('330');
      }
      
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial screen size on component mount

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const series = seriesData;

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      width={screenSizeType}
      height={520}
      style={{ margin: 'auto' }}
      className="chartMain"
    />
  );
};

export default BarStacked;
