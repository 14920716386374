// src/components/BlogList.js
import React, { useState, useEffect } from "react";
import ClubCard from "./ClubCard";

const ClubList = ({ blogs }) => {
  const [filters, setFilters] = useState({
    category: "",
    price: "",
  });

  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const [randomizedClubData, setRandomizedClubData] = useState([]);

  useEffect(() => {
    // Assuming that 'blogs' is the initial data you want to use
    setRandomizedClubData(blogs);
  }, [blogs]);

  const filteredClubs = randomizedClubData.filter((club) => {
    const categoryMatch = filters.category
      ? club.category_name === filters.category
      : true;
    const priceMatch =
      filters.price === "free"
        ? parseFloat(club.fees) === 0 || parseFloat(club.fees) === null
        : filters.price === "paid"
        ? parseFloat(club.fees) !== 0
        : true;
    return categoryMatch && priceMatch;
  });

  const allClubsCount = randomizedClubData.length;
  const freeClubsCount = randomizedClubData.filter(
    (club) => parseFloat(club.fees) === 0 || parseFloat(club.fees) === null
  ).length;
  const paidClubsCount = randomizedClubData.filter(
    (club) => parseFloat(club.fees) !== 0
  ).length;

  return (
    <div>
      <div className="max-w-[85rem] py-2 rounded-xl mx-auto">
        <div className="flex flex-wrap gap-4 justify-end  mb-8">
          <button
            className={`flex flex-row items-center gap-2 px-4 py-2 rounded-full ${
              filters.price === ""
                ? "bg-primary text-white "
                : "bg-gray-200 text-gray-800 "
            }`}
            onClick={() => handleFilterChange("price", "")}
          >
            All{" "}
            {filters.price === "" ? (
              <small className="bg-[#8a2be2] opacity-80 border-0 badge badge-sm text-white">
                {allClubsCount}
              </small>
            ) : null}
          </button>
          <button
            className={`flex flex-row items-center gap-2 px-4 py-2 rounded-full ${
              filters.price === "free"
                ? "bg-primary text-white"
                : "bg-gray-200 text-gray-800"
            }`}
            onClick={() => handleFilterChange("price", "free")}
          >
            During School{" "}
            {filters.price === "free" ? (
              <small className="bg-[#8a2be2] opacity-80 border-0 badge badge-sm text-white">
                {freeClubsCount}
              </small>
            ) : null}
          </button>
          <button
            className={`flex flex-row items-center gap-2 px-4 py-2 rounded-full ${
              filters.price === "paid"
                ? "bg-primary text-white"
                : "bg-gray-200 text-gray-800"
            }`}
            onClick={() => handleFilterChange("price", "paid")}
          >
            After School{" "}
            {filters.price === "paid" ? (
              <small className="bg-[#8a2be2] opacity-80 border-0 badge badge-sm text-white">
                {paidClubsCount}
              </small>
            ) : null}
          </button>
        </div>
        <div className="grid grid-cols-3 lg:gap-y-8 gap-10">
          {filteredClubs.map((club) => (
            <ClubCard key={club.id} {...club} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClubList;
