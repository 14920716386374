import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import BottomCTA from './BottomCTA';
import Footer from './Footer';
import { Link } from 'react-router-dom';

function AllBlogs() {


  return (
    <>
    <Navbar/>




    <>
  {/* Card Blog */}
  <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
    {/* Title */}
    <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
      <h2 className="text-2xl font-bold md:text-4xl md:leading-tight darki:text-white">
        Blogs
      </h2>
      <p className="mt-1 text-gray-600 darki:text-gray-400">
      Go-to resource for everything related to extracurricular activities and how our SaaS platform is revolutionizing the way students and educators engage outside the classroom.
      </p>
    </div>
    {/* End Title */}
    {/* Grid */}
    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {/* Card */}
      <Link
        className="group flex flex-col h-full border border-gray-200 hover:border-transparent hover:shadow-lg transition-all duration-300 rounded-xl p-5 darki:border-gray-700 darki:hover:border-transparent darki:hover:shadow-black/[.4] darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
        to={`/blog/1710667298`}
      >
        <div className="aspect-w-16 aspect-h-11">
          <img
            className="w-full object-cover rounded-xl"
            src="https://res.cloudinary.com/ahbranding/image/upload/v1710828091/AHbranding/Frame_3_drjexh.png"
            alt="Image Description"
          />
        </div>
        <div className="my-6">
          <h3 className="text-xl font-semibold text-gray-800 darki:text-gray-300 darki:group-hover:text-white">
          The Role of Extracurricular Activities In Shaping Future Leaders

          </h3>
          <p className="mt-5 text-gray-600 darki:text-gray-400">
          In today's digital age, as electronic devices and social media have become more popular..
          </p>
        </div>
        <div className="mt-auto flex items-center gap-x-3">
          <img
            className="w-8 h-8 rounded-full"
            src="https://media.licdn.com/dms/image/D4D03AQHOAkdBR2pH4g/profile-displayphoto-shrink_400_400/0/1692804446754?e=1716422400&v=beta&t=xM1Zx92Wt6AbQoG5mAkvu-tbUDIHs3RpA-9cUt6ZTyA"
            alt="Image Description"
          />
          <div>
            <h5 className="text-sm text-gray-800 darki:text-gray-200 m-0">
              By Shayan Aamir
            </h5>
          </div>
        </div>
      </Link>

    </div>


  </div>
  {/* End Card Blog */}
</>



<BottomCTA/>
<Footer/>

    </>
  )
}

export default AllBlogs;