import React, { useState, useRef, useEffect } from "react";
import studentIcon from "../assets/images/student.png";
import reportIcon from "../assets/images/reporticon.svg";
import hub from "../assets/images/hub.svg";
import identify from "../assets/images/quick_reference_all.svg";
import upToDate from "../assets/images/published_with_changes.svg";
import careerPath from "../assets/images/route.svg";
import efforts from "../assets/images/open_with.svg";
import accImg from "../assets/images/accimg.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ReactApexChart from "react-apexcharts";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import smallLogo from "../assets/images/smalllogo.svg";
import thlLogo from "../assets/images/thllogo.svg";
import head from "../assets/images/head.svg";
import arrowRight from "../assets/images/arrowright.svg";
import profileData from "../assets/images/profiledata.svg";
import qr from "../assets/images/qr.svg";
import schoolhead from "../assets/images/schoolhead.svg";
import support from "../assets/images/support.svg";
import carrier from "../assets/images/carrer.svg";
import headphones from "../assets/images/headphones.svg";
import bookmark from "../assets/images/bookmark.svg";
import groupbtn from "../assets/images/groupbtn.svg";
import groupside from "../assets/images/groupside.svg";
import pending from "../assets/images/pending.svg";
import rejected from "../assets/images/rejected.svg";
import approved from "../assets/images/approved.svg";
import { axiosInstance } from "../Axios";
import axios from "axios";
import { useParams } from "react-router-dom";
import imgOne from "../assets/images/imgOne.png";
import imgTwo from "../assets/images/imgTwo.png";
import imgThree from "../assets/images/imgThree.png";
import imgFour from "../assets/images/imgFour.png";
import imgFive from "../assets/images/imgFive.png";
import imgSix from "../assets/images/imgSix.png";

import user from "../assets/images/user.png";
import legendActivity from "../assets/images/legendActivity.png";
import legendCompare from "../assets/images/legendCompare.png";

const Report = () => {
  const currentDate = new Date();

  // Get various components of the date
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const day = currentDate.getDate();

  const [studentData, setstudentData] = useState([]);
  const [isShown, setIsShown] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API_KEY}/api/student_data/${id}/`)
      .then((res) => {
        setstudentData(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  }, []);

  console.warn(studentData)
  
  const pageRefs = useRef([]);


  const badgeLevelsArrayMap = [
    {
    name:"Critical Thinking",
    value:1,
  },
  {
    name:"Responsibility",
    value: 1,
  },
  {
    name:"Physical",
    value:1,
  },
  {
    name:"Leadership",
    value: 1,
  },
  {
    name:"Interpersonal",
    value:  1,
  }

];

    
    const badgeLevelsArray = [
      1,
      1,
      1,
      1,
      1,
      1,
    ];


    
    const badgeUrlsArray = [
      studentData?.badges?.critical_badgeURL,
      studentData?.badges?.interpersonal_badgeURL,
      studentData?.badges?.leadership_badgeURL,
      studentData?.badges?.physical_badgeURL,
      studentData?.badges?.social_badgeURL,
      studentData?.badges?.technology_badgeURL,
    ];

  //  const timeLineData = {
  //   studentData?.timeline
      
  // }

  // const   structureData = Object?.entries(studentData?.timeline)?.map(([grade, gradeItems]) => {
  //   return {
  //     grade,
  //     activities: gradeItems.map((item) => ({
  //       activity_name: item.activity_name,
  //       category: item.category,
  //       status: item.status,
  //       // Add other properties as needed
  //     })),
  //   };
  // });

  const structureData = (studentData?.timeline && Object.entries(studentData.timeline))
        ? Object.entries(studentData.timeline).map(([grade, gradeItems]) => {
            return {
                grade,
                activities: (gradeItems && Array.isArray(gradeItems))
                    ? gradeItems.map((item) => ({
                        activity_name: item?.activity_name,
                        category: item?.category,
                        status: item?.status,
                        // Add other properties as needed
                    }))
                    : [],
            };
        })
        : [];

   
  console.warn(structureData, "==============>structureData");
  

  const chartStateRadar = {
    series: [
      {
        name: "Series 1",
        data: badgeLevelsArray,
      },
    ],

    // series: [
    //   {
    //     name: "Series 1",
    //     data: [
    //       studentData?.badges?.technology_score,
    //       studentData?.badges?.interpersonal_skills_score,
    //       studentData?.badges?.leadership_score,
    //       studentData?.badges?.social_responsibility_score,
    //       studentData?.badges?.critical_thinking_score,
    //       studentData?.badges?.physical_fitness_score,
    //     ],
    //   },
    // ],

    options: {
      chart: {
        height: 350,
        type: "radar",
      },
      //   title: {
      //     text: 'Basic Radar Chart'
      //   },
      xaxis: {
        categories: ["Technology", "Leadership", "Interpersonal", "Social Responsibility", "Critical Thinking", "Physical Fitness"],
      },
    },
  };
  

  const formatGrade = (grade) => {
    return grade?.toUpperCase()?.replace('_', ' ');
  };

  let newData = studentData?.exp ? Object.values(studentData.exp) : [];

  // let newData = [5,5,5,5,5,5,5,5,5,5];

  const chartData = {
    
    series: [{
      data: newData
    }],
    options: {
      chart: {
        height: 350,
        toolbar: {
          show: false
        },
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      colors: [
        '#477BFF',
        '#47D1CB',
        '#6F47FF',
        '#FFCB47',
        '#FF477B',
        '#59DBFF',
        '#CB47FF',
        '#FF884D',
        '#00897B',
        '#9288F8',
        '#FF00FF' 
      ],
      plotOptions: {
        bar: {
          columnWidth: "60%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        // categories: [
        //   ["Award"],
        //   ["Certificate"],
        //   ["Event"],
        //   ["Exp"],
        //   ["Intern"],
        //   ["Language"],
        //   ["Lead"],
        //   ["Social"],
        //   ["Society"],
        //   ["Sport"],
        //   ["Talent"],
        // ],
        labels: {
          show: false,
        },
      },
      yaxis: {
        min: 0,
        max: 25,
        tickAmount: 5,
      },
      toolbar: {
        show: false
      },
    },
  };

  function generateRandom() {
    return Math.random() < 0.5 ? 2 : 3;
}

// Create a new array by adding a random number to each value
let newData2 = studentData?.exp ? Object.values(studentData.exp).map(value => value + generateRandom()) : [];

  const chartState = {
    series: [
      {
        data: newData,
      },
      {
        data: newData2,
      },
    ],

    // series: [
    //   {
    //     data: [
    //       parseInt(`${studentData?.exp?.lead}`),
    // parseInt(`${studentData?.exp?.society}`),
    // parseInt(`${studentData?.exp?.social}`),
    // parseInt(`${studentData?.exp?.sport}`),
    // parseInt(`${studentData?.exp?.intern}`),
    // parseInt(`${studentData?.exp?.language}`),
    // parseInt(`${studentData?.exp?.certificate}`),
    // parseInt(`${studentData?.exp?.talent}`),
    // parseInt(`${studentData?.exp?.event}`),
    // parseInt(`${studentData?.exp?.award}`),
    //     ],
    //   },
    //   {
    //     data: [53, 32, 33, 52, 13, 44, 32],
    //   },
    // ],
    options: {
      chart: {
        type: "bar",
        height: 430,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      toolbar: {
        show: false,
        tools:{
          download:false // <== line to add
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: ["Leadership", "Clubs/Societies", "Sports", "Awards", "Social Work", "Internship(s)", "Certificate(s)","Special Talent(s)","Language(s)"],
      },
    },
  };

  const styles = buildStyles({
    // Rotation of path and trail, in number of turns (0-1)
    rotation: 0.25,

    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    // strokeLinecap: 'butt',
    // Text size
    textSize: "20px",

    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 0.5,

    // Can specify path transition in more detail, or remove it entirely
    // pathTransition: 'none',

    // Colors
    pathColor: `#4F0696`,
    textColor: "#4F0696",
    trailColor: "#d6d6d6",
    backgroundColor: "#F2F2F2",
  });

  // const generatePDF = async () => {
  //     const element = targetRef.current;
  //     await new Promise(resolve => setTimeout(resolve, 800)); // Increase the timeout to 500 milliseconds

  //     try {
  //         const canvas = await html2canvas(element, { scale: 2 });

  //         const aspectRatio = canvas.width / canvas.height;
  //         const pdfWidth = 210; // A4 width in mm
  //         const pdfHeight = pdfWidth / aspectRatio;

  //         const pdf = new jsPDF({
  //             orientation: 'portrait',
  //             unit: 'mm',
  //             format: [pdfWidth, pdfHeight],
  //             precision: 16
  //         });

  //         pdf.addImage(
  //             canvas.toDataURL("image/png"),
  //             "PNG",
  //             0,
  //             0,
  //             pdfWidth,
  //             pdfHeight
  //         );

  //         pdf.save("transcript.pdf");
  //     } catch (error) {
  //         console.error("Error generating PDF:", error);
  //     }
  // };

  function getYearFromDate(dateString) {
    // Split the date string into an array of parts using the hyphen as the delimiter
    const dateParts = dateString?.split('-');
  
    // Extract the year part (the first element of the array)
    const year = dateParts[0];
  
    // Convert the year to a number (optional, depending on your use case)
    return parseInt(year, 10);
  }

  const generatePDF = async () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pages = pageRefs.current;

    for (const [index, pageRef] of pages.entries()) {
      const canvas = await html2canvas(pageRef, { scale: 2 });

      const paddingTop = 25;
      const paddingRight = 25;
      const paddingBottom = 25;
      const paddingLeft = 25;
      
      const canvasWidth = canvas.width + paddingLeft + paddingRight;
      const canvasHeight = canvas.height + paddingTop + paddingBottom;

      const newCanvas = document.createElement("canvas");
      newCanvas.width = canvasWidth;
      newCanvas.height = canvasHeight;
      const ctx = newCanvas.getContext("2d");

      if (ctx) {
        ctx.fillStyle = "#ffffff"; // Background color
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);
        ctx.drawImage(canvas, paddingLeft, paddingTop);
      }

      const imgData = newCanvas.toDataURL("image/png");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      if (index < pages.length - 1) {
        pdf.addPage();
      }
    }

    // Save or display the generated PDF
    pdf.save(studentData?.student?.firstname+studentData?.student?.lastname+".pdf");
  };


  
  

  return (
    <>
      <div  ref={(el) => (pageRefs.current[0] = el)} className="page w-[1100px] m-auto flex flex-col ">
        <div className="w-full flex flex-col items-center gap-4 bg-[#f7f0fe] p-4 border-b-8 border-[#4f0696]">
          <div className="w-full flex  items-center">
            <div className="w-2/3 flex items-center gap-2">
              <div className="w-32 rounded-full flex justify-center items-center ">
                <img className="w-10/12" src={reportIcon} alt="reportIcon" />
              </div>
              <div>
                <h3 className="m-0 text-[#4f0696] font-normal">
                  The Learning Hub Cambridge
                </h3>
                <h3 className="m-0 text-[#4f0696] font-bold">
                  Extracurricular Transcript (ECT)
                </h3>
              </div>
            </div>
            <div className="w-1/3 flex items-center justify-end">
              <img
                className="w-20 rounded-full"
                src={user}
                alt="studentIcon"
              />
            </div>
          </div>
          <div className="w-full flex justify-between  items-center">
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-1">
                <h5 className="text-base  font-semibold m-0">Name:</h5>
                <span>
                  {studentData?.student?.firstname.charAt(0).toUpperCase() +
                    studentData?.student?.firstname.slice(1)}{" "}
                  {studentData?.student?.lastname.charAt(0).toUpperCase() +
                    studentData?.student?.lastname.slice(1)}
                </span>
              </div>
              <div className="flex items-center gap-1">
                <h5 className="text-base  font-semibold m-0">Grade:</h5>
                <span>{studentData?.student?.grade}</span>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-1">
                <h5 className="text-base  font-semibold m-0">Area:</h5>
                <span>Extracurricular</span>
              </div>
              <div className="flex items-center gap-1">
                <h5 className="text-base  font-semibold m-0">Student No. :</h5>
                <span>-</span>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-1">
                <h5 className="text-base  font-semibold m-0">Campus:</h5>
                <span>Cambridge Campus</span>
              </div>
              <div className="flex items-center gap-1">
                <h5 className="text-base  font-semibold m-0">Date:</h5>
                <span>{`${month}/${day}/${year}`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 flex flex-col gap-4 ">
          <div className="w-full flex flex-col gap-2">
            <h4 className="m-0 text-[#4f0696] font-semibold ">
              What are Co-curricular Transcripts (CCTs)
            </h4>
            <p className="font-normal m-0">
              A Co-Curricular Transcript is an official record of your
              co-curricular activities (clubs, organizations, activities),
              awards, accomplishments, and honors received during your time in
              school. Some benefits include:
            </p>
          </div>
          <div className="w-full flex items-center gap-8">
            <div className="w-1/5 flex flex-col items-center gap-3">
              <div className="flex items-center justify-center">
                <img className="w-12" src={hub} alt="hub" />
              </div>
              <p className="m-0 text-center text-base">
                <strong>Centralized Hub</strong> of Co-curricular activities
              </p>
            </div>
            <div className="w-1/5 flex flex-col items-center gap-3">
              <div className="flex items-center justify-center">
                <img className="w-12" src={identify} alt="identify" />
              </div>
              <p className="m-0 text-center text-base">
                <strong>Identify</strong> Areas of <strong>Improvements</strong>
              </p>
            </div>
            <div className="w-1/5 flex flex-col items-center gap-3">
              <div className="flex items-center justify-center">
                <img className="w-12" src={upToDate} alt="upToDate" />
              </div>
              <p className="m-0 text-center text-base">
                <strong>Up-to-date</strong> Co-curriculars{" "}
                <strong>Progress</strong>
              </p>
            </div>
            <div className="w-1/5 flex flex-col items-center gap-3">
              <div className="flex items-center justify-center">
                <img className="w-12" src={careerPath} alt="careerPath" />
              </div>
              <p className="m-0 text-center text-base">
                <strong>Identify</strong> Co-curriculars Linked to{" "}
                <strong>Career Paths</strong>
              </p>
            </div>
            <div className="w-1/5 flex flex-col items-center gap-3">
              <div className="flex items-center justify-center">
                <img className="w-12" src={efforts} alt="efforts" />
              </div>
              <p className="m-0 text-center text-base">
                <strong>Maximize</strong> Co-curriculars{" "}
                <strong>Efforts</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full p-4 flex flex-col gap-4">
          <h2 className="m-0 text-[#4f0696] text-3xl font-bold">My Overview</h2>
          <h4 className="m-0 text-[#4f0696] font-medium">Progress</h4>

          <div className=" my-combined-class w-full flex items-center justify-between bg-[#f5f0f9] p-8 rounded-2xl ">
            <div className="flex flex-col gap-2 items-center">
              <h4 className="m-0 font-medium text-[#4f0696] text-lg">
                Current Level
              </h4>
              <span className="pb-3 font-medium text-[#4f0696] text-4xl">
                {studentData?.level}
              </span>
            </div>
            <div className="flex flex-col gap-2 items-center">
              <h4 className=" m-0 font-medium text-[#4f0696] text-lg">
                Completions
              </h4>
              <span className="pb-3 font-medium text-[#4f0696] text-4xl">
                {studentData?.completions}
              </span>
            </div>
            <div className="flex flex-col gap-2 items-center">
              <h4 className=" m-0 font-medium text-[#4f0696] text-lg">
                Total Experience
              </h4>
              <span className="pb-3 font-medium text-[#4f0696] text-4xl">
                {studentData?.experiences}
              </span>
            </div>
            <div className="flex flex-col gap-2 items-center">
              <h4 className=" m-0 font-medium text-[#4f0696] text-lg">
                Achievements
              </h4>
              <span className="mb-3 font-medium text-[#4f0696] text-4xl">
                {studentData?.achievements}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center justify-center p-4 gap-4">
          <div className="w-2/4 flex flex-col ">
            <h3 className="m-0 text-[#4f0696] pb-5 font-medium">Achievement</h3>
            <div className="w-full flex items-center  gap-4 bg-[#f5f0f9] p-6 rounded-2xl ">
            {/* <img style={{ width: 70, height: 90 }} src={'https://res.cloudinary.com/ahbranding/image/upload/v1698863802/AHbranding/Badges/cct%20bADGES/Group_34784395_hyk0yh.png'} alt="Critical Badge" loading="lazy" />
<img style={{ width: 70, height: 90 }} src={'https://res.cloudinary.com/ahbranding/image/upload/v1698863799/AHbranding/Badges/cct%20bADGES/Group_34784383_gyvc3w.png'} alt="Interpersonal Badge" loading="lazy" />
<img style={{ width: 70, height: 90 }} src={'https://res.cloudinary.com/ahbranding/image/upload/v1698863797/AHbranding/Badges/cct%20bADGES/Group_34784377_puf9xy.png'} alt="Leadership Badge" loading="lazy" />
<img style={{ width: 70, height: 90 }} src={'https://res.cloudinary.com/ahbranding/image/upload/v1698863803/AHbranding/Badges/cct%20bADGES/Group_34784399_xnqmy6.png'} alt="Physical Badge" loading="lazy" />
<img style={{ width: 70, height: 90 }} src={'https://res.cloudinary.com/ahbranding/image/upload/v1698863796/AHbranding/Badges/cct%20bADGES/Group_34784357_uoz09d.png'} alt="Social Badge" loading="lazy" />
<img style={{ width: 70, height: 90 }} src={'https://res.cloudinary.com/ahbranding/image/upload/v1698863804/AHbranding/Badges/cct%20bADGES/Group_34784402_whqbhg.png'} alt="Technology Badge" loading="lazy" />
           */}

           <img style={{ width: 70, height: 90 }} src={imgOne} alt="Critical Badge" loading="lazy" />
<img style={{ width: 70, height: 90 }} src={imgTwo} alt="Interpersonal Badge" loading="lazy" />
<img style={{ width: 70, height: 90 }} src={imgThree} alt="Leadership Badge" loading="lazy" />
<img style={{ width: 70, height: 90 }} src={imgFour} alt="Physical Badge" loading="lazy" />
<img style={{ width: 70, height: 90 }} src={imgFive} alt="Social Badge" loading="lazy" />
<img style={{ width: 70, height: 90 }} src={imgSix} alt="Technology Badge" loading="lazy" />
             
             
            </div>
          </div>
          <div className="w-2/4 flex flex-col  ">
            <h3 className="m-0 text-[#4f0696] pb-5 font-medium">
              Competencies
            </h3>
            <div className="w-full flex items-center  gap-4 bg-[#f5f0f9] p-6 rounded-2xl ">
              {badgeLevelsArrayMap?.map((item, index) => (
                <div key={index} className="flex flex-col gap-1 items-center ">
                  <div
                    className="flex items-center justify-center"
                    style={{ width: 70, height: 70 }}
                  >
                    <CircularProgressbar
                      strokeWidth={7}
                      styles={styles}
                      value={item?.value * 20}
                      text={`LVL ${item?.value}`}
                    />
                  </div>
                  <span className="m-0 text-[#4f0696] text-xs text-center">
                     {item?.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-4 p-4">
          <h3 className="m-0 text-[#4f0696] font-medium">
            My Co-curricular Activities
          </h3>
          <div className="w-full flex items-center gap-4">
            <div className="w-2/4 border-2 rounded-2xl p-2 " id="chart">
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                width={500}
                height={350}
              />
            </div>

            <div className="w-2/4 border-2 rounded-2xl p-2 " id="chart">
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                width={500}
                height={350}
              />
            </div>
          </div>

          <img src={legendActivity} className="w-full" />
        </div>
        <div className="w-full flex justify-between items-center p-4 border-t-4 border-[#2957A4]">
          <h6>
            {studentData?.student?.firstname.charAt(0).toUpperCase() +
              studentData?.student?.firstname.slice(1)}{" "}
            {studentData?.student?.lastname.charAt(0).toUpperCase() +
              studentData?.student?.lastname.slice(1)}{" "}
            | TLH
          </h6>
          <span>Page 1 of 4</span>
          <div className="flex items-center gap-3">
            <span>powered by</span>
            <img className="w-10" src={smallLogo} alt="img" />
          </div>
        </div>
      </div>
      <div ref={(el) => (pageRefs.current[1] = el)} className="page w-[1100px] m-auto flex flex-col  mt-20">
        <div className="w-full flex flex-col items-center gap-6 bg-[#f7f0fe] p-4 border-b-8 border-[#4f0696]">
          <div className="w-full flex  items-center">
            <div className="w-2/3 flex items-center gap-1.5">
              <div className="w-24 rounded-full flex justify-center items-center ">
                <img className="w-8/12" src={thlLogo} alt="reportIcon" />
              </div>
            </div>
            <div className="w-1/3 flex items-center justify-end gap-16">
              <h4 className="m-0 text-[#4f0696]">
                {studentData?.student?.firstname.charAt(0).toUpperCase() +
                  studentData?.student?.firstname.slice(1)}{" "}
                {studentData?.student?.lastname.charAt(0).toUpperCase() +
                  studentData?.student?.lastname.slice(1)}
              </h4>
              <img
                className="w-12 rounded-full"
                src={user}
                alt="studentIcon"
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col p-4 gap-2 ">
          <h3 className="m-0 text-[#4f0696]">Top Profiles</h3>
          <p className="m-0">
            In this section, we have mapped your co-curricular’s effort against
            the the top profiled graduate students from previous years.
          </p>

          <div className="flex justify-end">
            <img src={legendCompare} />
          </div>
          <div id="chart">
            <ReactApexChart
              options={chartState.options}
              series={chartState.series}
              type="bar"
              height={600}
            />
          </div>
        </div>
        <div className="w-full flex flex-col p-4 gap-2 ">
          <h3 className="m-0 text-[#4f0696]">Competency Map</h3>
          <p className="m-0">
            A comprehensive visualization of a student’s skill set based on
            their unique involvement journey.
          </p>
          <div className="w-full flex items-center gap-4">
            <div id="chart" className="w-3/4">
              <ReactApexChart
                options={chartStateRadar.options}
                series={chartStateRadar.series}
                type="radar"
                height={500}
              />
            </div>
            <div className="w-1/4  flex flex-col gap-4 p-3">
            
              <div className="w-full flex items-center  gap-2">
                {/* <div className="bg-[#00897B] h-6 w-6 rounded-full"></div> */}
                <div className="flex flex-col">
                  <strong className="m-0 text-md">
                    Level 1
                  </strong>
                  <span className="m-0 text-xs">Technology</span>
                </div>
              </div>
              <div className="w-full flex items-center  gap-2">
                {/* <div className="bg-[#00897B] h-6 w-6 rounded-full"></div> */}
                <div className="flex flex-col">
                  <strong className="m-0 text-md">
                    Level 1
                  </strong>
                  <span className="m-0 text-xs">Leadership</span>
                </div>
              </div>

              <div className="w-full flex items-center  gap-2">
                {/* <div className="bg-[#00897B] h-6 w-6 rounded-full"></div> */}
                <div className="flex flex-col">
                  <strong className="m-0 text-md">
                    Level 1
                  </strong>
                  <span className="m-0 text-xs">Interpersonnel Skills</span>
                </div>
              </div>

              <div className="w-full flex items-center  gap-2">
                {/* <div className="bg-[#00897B] h-6 w-6 rounded-full"></div> */}
                <div className="flex flex-col">
                  <strong className="m-0 text-md">
                    Level 1
                  </strong>
                  <span className="m-0 text-xs"> Social Responsibility</span>
                </div>
              </div>

              <div className="w-full flex items-center  gap-2">
                {/* <div className="bg-[#00897B] h-6 w-6 rounded-full"></div> */}
                <div className="flex flex-col">
                  <strong className="m-0 text-md">
                    Level 1
                  </strong>
                  <span className="m-0 text-xs">Critical Thinking</span>
                </div>
              </div>
              <div className="w-full flex items-center  gap-2">
                {/* <div className="bg-[#00897B] h-6 w-6 rounded-full"></div> */}
                <div className="flex flex-col">
                  <strong className="m-0 text-md">
                    Level 1
                  </strong>
                  <span className="m-0 text-xs">Physical Fitness</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-between items-center p-4 border-t-4 border-[#2957A4]">
          <h6>
            {studentData?.student?.firstname.charAt(0).toUpperCase() +
              studentData?.student?.firstname.slice(1)}{" "}
            {studentData?.student?.lastname.charAt(0).toUpperCase() +
              studentData?.student?.lastname.slice(1)}{" "}
            | TLH
          </h6>
          <span>Page 2 of 4</span>
          <div className="flex items-center gap-3">
            <span>powered by</span>
            <img className="w-10" src={smallLogo} alt="img" />
          </div>
        </div>
      </div>

      <div ref={(el) => (pageRefs.current[2] = el)} className="page w-[1100px]  m-auto flex flex-col  mt-20">
        <div className="w-full flex flex-col items-center gap-6 bg-[#f7f0fe] p-4 border-b-8 border-[#4f0696]">
          <div className="w-full flex  items-center">
            <div className="w-2/3 flex items-center gap-1.5">
              <div className="w-24 rounded-full flex justify-center items-center ">
                <img className="w-8/12" src={thlLogo} alt="reportIcon" />
              </div>
            </div>
            <div className="w-1/3 flex items-center justify-end gap-16">
              <h4 className="m-0 text-[#4f0696]">
                {studentData?.student?.firstname.charAt(0).toUpperCase() +
                  studentData?.student?.firstname.slice(1)}{" "}
                {studentData?.student?.lastname.charAt(0).toUpperCase() +
                  studentData?.student?.lastname.slice(1)}
              </h4>
              <img
                className="w-12 rounded-full"
                src={user}
                alt="studentIcon"
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col p-4 gap-4 ">
          <h3 className="m-0 text-[#4f0696] pt-2 pb-2">Roadmap</h3>

          <div className="w-full flex flex-col rounded-2xl  border ">
            <div className="bg-[#4F0696] w-full flex items-center justify-center rounded-t-2xl p-3">
              <h5 className="text-white font-normal pt-2 pb-3.5  m-0">
                Suggestions
              </h5>
            </div>
            <p className="font-normal  m-3 p-4">
              {studentData?.student?.roadmap}
            </p>

            <table class="w-full rounded-b-2xl  ">
              <thead className="bg-[#ECD8FF] ">
                <tr style={{ borderStyle: "none" }}>
                  <th className="p-5 text-[#4F0696] font-semibold ">
                    Areas to Improve
                  </th>
                  <th className="p-5 text-[#4F0696] font-semibold">
                    Organization
                  </th>
                  <th className="p-5 text-[#4F0696] font-semibold">Timeline</th>
                </tr>
              </thead>
              <tbody className="bg-[#F6EDFF] p-2">
                <tr>
                  <td className="p-4 text-[#4F0696] font-bold">
                    1. Technology
                  </td>
                  <td className="p-4">
                    Basics of Python Programming Course - Udemy & Coursera
                  </td>
                  <td className="p-4">During summer break</td>
                </tr>
                <tr>
                  <td className="p-4 text-[#4F0696] font-bold">
                    2. Leadership
                  </td>
                  <td className="p-4">
                    Take initiative by organizing a small writing exhibition at
                    schoo
                  </td>
                  <td className="p-4">During academic Year</td>
                </tr>
                <tr>
                  <td className="p-4 text-[#4F0696] font-bold">
                    3. Interpersonal Skills
                  </td>
                  <td className="p-4">
                    Participate in an internship in a field of your choice,
                    reach out to companies
                  </td>
                  <td className="p-4">During summer break</td>
                </tr>
                <tr>
                  <td className="p-4 text-[#4F0696] font-bold">
                    4. Social Responsibility
                  </td>
                  <td className="p-4">
                    Volunteer to collect donations for Palestine
                  </td>
                  <td className="p-4">During summer break</td>
                </tr>
                <tr>
                  <td className="p-4 text-[#4F0696] font-bold">
                    5. Critical Thinking
                  </td>
                  <td className="p-4">
                    Write and publish an article on the importance of protecting
                    the environment
                  </td>
                  <td className="p-4">During academic Year</td>
                </tr>
                <tr>
                  <td className="p-4 text-[#4F0696] font-bold">
                    6. Physical Fitness
                  </td>
                  <td className="p-4">
                    Join the school sports team of your preference
                  </td>
                  <td className="p-4">During academic Year</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3 className=" text-[#4f0696] pt-1 mt-16">Next Steps</h3>

          <div className="w-full flex pt-3 gap-4 mb-16 ">
            <div className="w-2/4 flex-col gap-4 ">
              <div className="w-full flex items-center  ">
                {/* <div className="bg-[#E3D6EE] px-5 py-3    justify-center  flex items-center gap-1 rounded-xl">
                  <img className="w-7 h-7" src={head} alt="head" />
                  <span className="text-[#4F0696] font-semibold">
                    Stream / Career Selector
                  </span>
                </div>
                <div className="">
                  <img className="w-8" src={arrowRight} />
                </div>
                <div className="bg-[#E3D6EE] px-4 py-3   justify-center  flex items-center gap-1 rounded-xl">
                  <img
                    className="w-7 h-7"
                    src={profileData}
                    alt="profileData"
                  />
                  <span className="text-[#4F0696] font-semibold">
                    {" "}
                    Profile Builder
                  </span>
                </div> */}
                <img className="w-full" src={groupbtn} alt={groupbtn} />
              </div>
              <p className="m-0 text-xl pt-2 text-black tracking-wide">
                Discover your perfect career with our stream and career guidance
                tools that help students get confidence and career clarity.
              </p>
              <div className="flex flex-col pt-2">
                <h6 className="m-0">Disclaimer</h6>
                <p className="m-0">
                  Start Up Early is an education analytics platform for
                  students. The material in this service could include technical
                  inaccuracies or typographical errors.
                </p>
              </div>
            </div>
            <div className="w-1/4 flex flex-col gap-3  ">
              <h5>Scan QR code to get access to:</h5>
              <img className="w-9/12" src={qr} alt="qr" />
            </div>
            <div className="w-1/4 flex">
              {/* <div className="flex items-center gap-2">
                <img className="w-8 h-8" src={schoolhead} alt="img" />
                <h6 className="m-0">Career Prep Courses</h6>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-8 h-8" src={support} alt="img" />
                <h6 className="m-0">Personalized Guidance</h6>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-8 h-8" src={carrier} alt="img" />
                <h6 className="m-0">Career Assessment</h6>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-8 h-8" src={headphones} alt="img" />
                <h6 className="m-0">Expert Counsellors</h6>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-8 h-8" src={bookmark} alt="img" />
                <h6 className="m-0">Career Library</h6>
              </div> */}
              <img className="w-full h-full" src={groupside} alt={groupside} />
            </div>
          </div>
        </div>

        <div className="w-full flex justify-between items-center p-4 border-t-4 border-[#2957A4]">
          <h6>{studentData?.student?.firstname.charAt(0).toUpperCase() +
              studentData?.student?.firstname.slice(1)}{" "}
            {studentData?.student?.lastname.charAt(0).toUpperCase() +
              studentData?.student?.lastname.slice(1)+' '}| TLH</h6>
          <span>Page 3 of 4</span>
          <div className="flex items-center gap-3">
            <span>powered by</span>
            <img className="w-10" src={smallLogo} alt="img" />
          </div>
        </div>
      </div>

      <div ref={(el) => (pageRefs.current[3] = el)} className="page relative w-[1100px] h-[1565px]  m-auto flex flex-col  mt-20">
        <div className="w-full flex flex-col items-center  gap-6 bg-[#f7f0fe] p-4 border-b-8 border-[#4f0696]">
          <div className="w-full flex  items-center">
            <div className="w-2/3 flex items-center gap-1.5">
              <div className="w-24 rounded-full flex justify-center items-center ">
                <img className="w-8/12" src={thlLogo} alt="reportIcon" />
              </div>
            </div>
            <div className="w-1/3 flex items-center justify-end gap-16">
              <h4 className="m-0 text-[#4f0696]">
                {studentData?.student?.firstname.charAt(0).toUpperCase() +
                  studentData?.student?.firstname.slice(1)}{" "}
                {studentData?.student?.lastname.charAt(0).toUpperCase() +
                  studentData?.student?.lastname.slice(1)}
              </h4>
              <img
                className="w-12 rounded-full"
                src={user}
                alt="studentIcon"
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col p-4 gap-4 ">
          <h3 className="m-0 text-[#4f0696] pt-2 pb-1">
            Extracurricular Experience Timeline
          </h3>

          <div className="pt-2 pb-2">
           
          
          {structureData.map((item, index) => (
            <div key={index} className="relative pl-8 sm:pl-32 py-6 group">
              <div className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-2 before:translate-y-1 after:absolute after:left-2 sm:after:left-0 after:w-3 after:h-8 after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                <time className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-lg font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-black rounded-full">
                  {/* {getYearFromDate(item.start_date)} */}
                </time>
                
                <div  className="w-full flex flex-col gap-4">
                <div className="text-xl font-bold text-[#4f0696]">{formatGrade(item?.grade)}</div>
                  {item?.activities.map((x,xIndex) => (
                    <div className="w-full flex items-center justify-center  gap-3 pt-3">
                    <h6 className="m-0 w-48">{x.category.replace(/Activities/g, "")}</h6>

                    {/* Add your pending status or image here */}
                    <div className="w-28 ">
                    <div className="w-32">
  {/* {x.status === "1" ? (
    <span style={{verticalAlign: "middle",boxSizing:"border-box"}} className="w-full font-bold rounded-2xl px-4 py-1 leading-normal text-[#4CDCD6] ">
      Approved
    </span>
  ) : x.status === "2" ? (
    <span style={{verticalAlign: "middle",boxSizing:"border-box"}} className="w-full font-bold  rounded-2xl px-4 py-1 leading-normal text-[#FF827E] ">
      Rejected
    </span>
  ) : (
    <span style={{verticalAlign: "middle",boxSizing:"border-box"}} className="w-full font-bold  rounded-2xl px-4 py-1 leading-normal text-[#FFCB47] ">
      Pending
    </span>
  )} */}


  { x.status === "1" ? (
    <span style={{verticalAlign: "middle", boxSizing: "border-box"}} className="w-full font-bold rounded-2xl px-4 py-1 leading-normal text-[#4CDCD6] ">
      Approved
    </span>
  ) : x.status === "2" ? (
    <span style={{verticalAlign: "middle", boxSizing: "border-box"}} className="w-full font-bold rounded-2xl px-4 py-1 leading-normal text-[#FF827E] ">
      Rejected
    </span>
  ) : x.status === "3" ? (
    <span style={{verticalAlign: "middle", boxSizing: "border-box"}} className="w-full font-bold rounded-2xl px-4 py-1 leading-normal text-[#FFCB47] ">
      Need Proof
    </span>
  ) : (
    <span style={{verticalAlign: "middle", boxSizing: "border-box"}} className="w-full font-bold rounded-2xl px-4 py-1 leading-normal text-[#FFCB47] ">
      Pending
    </span>
)}

</div>
                    </div>
                    <h6 className="w-1/2 ml-7 mb-0">{x.activity_name}</h6>
                  </div>
                  ))}
               
                 </div>
                 
              </div>
            </div>
          ))}
       
          
         

          
          </div>
        </div>

        <div className="w-full absolute bottom-0 flex justify-between items-center p-4 border-t-4 border-[#2957A4]">
          <h6>
            {studentData?.student?.firstname.charAt(0).toUpperCase() +
              studentData?.student?.firstname.slice(1)}{" "}
            {studentData?.student?.lastname.charAt(0).toUpperCase() +
              studentData?.student?.lastname.slice(1)}
            | TLH
          </h6>
          <span>Page 4 of 4</span>
          <div className="flex items-center gap-3">
            <span>powered by</span>
            <img className="w-10" src={smallLogo} alt="img" />
          </div>
        </div>
      </div>
     
      {isShown && (
        <button
  onClick={() => generatePDF(pageRefs)}
  type="button"
  className="print-hide btn btn-primary btn-circle btn-lg rounded-full fixed bottom-20 right-6 z-50"
>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
  <path fill-rule="evenodd" d="M12 2.25a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3a.75.75 0 0 1 .75-.75Zm-9 13.5a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z" clip-rule="evenodd" />
</svg>


</button>
      )}
    </>
  );
};

export default Report;





