import React, { useContext, useState } from "react";
import { Link, useMatch } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import {
  FaHouseChimney,
  FaStarOfLife,
  FaFileCircleCheck,
  FaBoxArchive,
  FaLocationDot,
  FaHouseFlag,
  FaBuildingUser,
  FaTable,
  FaCircleCheck,
  FaGraduationCap,
  FaCalendarCheck,
  FaUsers,
  FaBuildingColumns,
  FaUserTie,
  FaUserGraduate,
  FaCalendarDays,
} from "react-icons/fa6";

function Drawer({ contentTitle, profile }) {
  let { user, logoutUser } = useContext(AuthContext);
  let matchDashboard = useMatch("/admin");
  let matchDashboardSchool = useMatch("/school");
  let matchTeams = useMatch("/network_schools");
  let matchStaff = useMatch("/network_staff");
  let matchStd = useMatch("/network_students");
  let matchattendance = useMatch("/attendance");
  let matchCalendar = useMatch("/admin_schedule");
  let matchmoderation = useMatch("/moderate_network");
  let matchclubs = useMatch("/clubs");
  let matchEvents = useMatch("/school_events");
  let matchstudent_councils = useMatch("/student_councils");
  let matchVenues = useMatch("/school_venues");
  let matchHouse = useMatch("/house_program");
  let matchFixtures = useMatch("/school_fixtures");
  let matchDep = useMatch("/network_departments");
  let matchInv = useMatch("/inventory");

  const getLinkClassName = (match) => {
    return `flex items-center gap-2 rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700 ${
      match ? "bg-[#ede7f6a1] text-primary" : ""
    }`;
  };

  return (
    <div className="">
      <div className="mb-8">
        {user["role"] === "Network Admin" ? (
          <img src={profile?.avatar} className="h-10 w-auto mx-auto" />
        ) : user["role"] === "School Admin" ? (
          <img src={profile?.logo} className="h-10 w-auto mx-auto" />
        ) : null}
      </div>

      <div id="application-sidebar" className="">
        <div className="">
          <div className="px-4">
            <ul className="mt-2 space-y-1 flex flex-col gap-4">
              {(() => {
                if (user["role"] === "Network Admin") {
                  return (
                    <li>
                      <Link
                        to="/admin"
                        className={getLinkClassName(matchDashboard)}
                      >
                        <FaHouseChimney />
                        Dashboard
                      </Link>
                    </li>
                  );
                } else if (user["role"] === "School Admin") {
                  return (
                    <li>
                      <Link
                        to="/school"
                        className={getLinkClassName(matchDashboardSchool)}
                      >
                        <FaHouseChimney />
                        Dashboard
                      </Link>
                    </li>
                  );
                } else {
                  return null;
                }
              })()}

              <li>
                <details className="group [&_summary::-webkit-details-marker]:hidden">
                  <summary className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                    <span className="text-sm font-medium flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                        />
                      </svg>
                      Management{" "}
                    </span>
                  </summary>
                  <ul className="mt-2 space-y-1 px-4">
                    {(() => {
                      if (user["role"] === "Network Admin") {
                        return (
                          <>
                            <li>
                              <Link
                                to="/network_schools"
                                className={getLinkClassName(matchTeams)}
                              >
                                <FaBuildingColumns />
                                Campuses
                              </Link>
                            </li>
                            {/* <li>
                              <Link
                                to="/network_departments"
                                className={getLinkClassName(matchDep)}
                              >
                                <FaBuildingUser />
                                Departments
                              </Link>
                            </li> */}
                          </>
                        );
                      }
                    })()}
                    <li>
                      <details className="group [&_summary::-webkit-details-marker]:hidden">
                        <summary className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                          <span className="text-sm font-medium flex items-center gap-2">
                            <FaUsers /> Users{" "}
                          </span>
                        </summary>
                        <ul className="mt-2 space-y-1 px-4">
                          <li>
                            <Link
                              to="/network_staff"
                              className={getLinkClassName(matchStaff)}
                            >
                              <FaUserTie />
                              Staff Members
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/network_students"
                              className={getLinkClassName(matchStd)}
                            >
                              <FaUserGraduate />
                              Students
                            </Link>
                          </li>
                        </ul>
                      </details>
                    </li>
                    <li>
                      <Link
                        to="/school_venues"
                        className={getLinkClassName(matchVenues)}
                      >
                        <FaLocationDot />
                        Venues
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/inventory"
                        className={getLinkClassName(matchInv)}
                      >
                        <FaBoxArchive />
                        Inventory
                      </Link>
                    </li>
                  </ul>
                </details>
              </li>

              <li>
                <details className="group [&_summary::-webkit-details-marker]:hidden">
                  <summary className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                    <span className="text-sm font-medium flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z" />
</svg>
 Activities{" "}
                    </span>
                  </summary>
                  <ul className="mt-2 space-y-1 px-4">
                    <li>
                <Link
                  to="/admin_schedule"
                  className={getLinkClassName(matchCalendar)}
                >
                  <FaCalendarDays />
                  Schedule
                </Link>
              </li>
                 
              <li>
                <Link to="/clubs" className={getLinkClassName(matchclubs)}>
                  <FaStarOfLife />
                  Student Clubs
                </Link>
              </li>
              <li>
                <Link
                  to="/attendance"
                  className={getLinkClassName(matchattendance)}
                >
                  <FaFileCircleCheck />
                  Attendance
                </Link>
              </li>

              
              {(() => {
                if (user["role"] === "Network Admin") {
                  return (
                    <>
                    <li>
                        <Link
                          to="/student_councils"
                          className={getLinkClassName(matchstudent_councils)}
                        >
                          <FaGraduationCap />
                          Student Councils
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/house_program"
                          className={getLinkClassName(matchHouse)}
                        >
                          <FaHouseFlag />
                          House Program
                        </Link>
                      </li>

                      
                    </>
                  );
                }
              })()}
              <li>
                <Link
                  to="/moderate_network"
                  className={getLinkClassName(matchmoderation)}
                >
                  <FaCircleCheck />
                  Moderation
                </Link>
              </li>

                  </ul>
                </details>
              </li>
{/* 
             
              <li>
                <Link
                  to="/school_events"
                  className={getLinkClassName(matchEvents)}
                  profile={profile}
                >
                  <FaCalendarCheck />
                  Events
                </Link>
              </li> */}

              
             

              
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Drawer;
