import React, { useEffect, useState } from "react";
import AdminLayout from "./AdminLayout";
import { Link, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";

function HousePointSheet() {
  const [rubric, setRubric] = useState([
    {
      rubric_name: "😇 Good behaviour",
      points_allocated: "4,500",
      type: "school",
    },
    {
      rubric_name: "😊 Being nice to neighbour",
      points_allocated: "1,360",
      type: "home",
    },
    {
      rubric_name: "👥 Participation",
      points_allocated: "6,840",
      type: "school",
    },
    {
      rubric_name: "📅 Attendance",
      points_allocated: "2,000",
      type: "school",
    },
    {
      rubric_name: "📝 Homework Completion",
      points_allocated: "3,750",
      type: "school",
    },
    {
      rubric_name: "🤝 Respect for Others",
      points_allocated: "1,980",
      type: "school",
    },
    {
      rubric_name: "🧹 Cleaning up after oneself",
      points_allocated: "1,200",
      type: "home",
    },
    {
      rubric_name: "🎨 Creativity",
      points_allocated: "2,500",
      type: "school",
    },
    {
      rubric_name: "👫 Teamwork",
      points_allocated: "3,600",
      type: "school",
    },
    {
      rubric_name: "💡 Problem-solving",
      points_allocated: "4,200",
      type: "school",
    },
    {
      rubric_name: "🐾 Kindness to Animals",
      points_allocated: "1,500",
      type: "home",
    },
    // Other rubric items
  ]);

  // Group rubric items by type
  const groupedRubrics = rubric.reduce((acc, curr) => {
    acc[curr.type] = [...(acc[curr.type] || []), curr];
    return acc;
  }, {});

  const [profile, setProfile] = useState();
  const params = useParams();
  let profileID = params.id;
  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        // parse the data in json
        let data = await response.json();
        setProfile(data);
      } catch (err) {
        // console.log('The requested profile does not exist.');
      }
    };

    getProfile();
  }, [profileID]);

  //   const [points, setPoints] = useState(() => {
  //     const storedPoints = localStorage.getItem('points');
  //     return storedPoints ? JSON.parse(storedPoints) : 596;
  //   });

  //   const [demerits, setDemerits] = useState(() => {
  //     const storedDemerits = localStorage.getItem('demerits');
  //     return storedDemerits ? JSON.parse(storedDemerits) : 21;
  //   });

  //   useEffect(() => {
  //     // Save points and demerits to localStorage
  //     localStorage.setItem('points', JSON.stringify(points));
  //     localStorage.setItem('demerits', JSON.stringify(demerits));
  //   }, [points, demerits]);

  const [points, setPoints] = useState(() => {
    const storedPoints = localStorage.getItem("points");
    return storedPoints ? JSON.parse(storedPoints) : 0;
  });

  const [demerits, setDemerits] = useState(() => {
    const storedDemerits = localStorage.getItem("demerits");
    return storedDemerits ? JSON.parse(storedDemerits) : 0;
  });

  const addPoint = () => {
    setPoints(points + 1);
    toast.success("+1 point awarded to" + profile?.firstname);
  };

  const addDemerit = () => {
    setDemerits(demerits + 1);
    toast.success("+1 demerit assigned to" + profile?.firstname);
  };

  useEffect(() => {
    // Save points and demerits to localStorage
    localStorage.setItem("points", JSON.stringify(points));
    localStorage.setItem("demerits", JSON.stringify(demerits));
  }, [points, demerits]);

  return (
    <AdminLayout>
      <div className="max-w-[85rem] p-4 mx-auto">


        <div role="alert" className="alert mt-6 bg-white shadow-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="opacity-0 stroke-info shrink-0 w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <div>
            <h3 className="font-bold text-lg">
              {profile?.firstname} {profile?.lastname}
            </h3>
            <div className="text-xs">Grade {profile?.grade}</div>
          </div>
          <div className="flex flex-row gap-4 text-center mr-6">
            <div>
              <p className="text-xl text-success font-bold m-0">{points}</p>
              <p className="text-base font-normal text-gray-500 m-0">points</p>
            </div>
            <div>
              <p className="text-xl text-error font-bold m-0">{demerits}</p>
              <p className="text-base font-normal text-gray-500 m-0">
                demerits
              </p>
            </div>
          </div>
        </div>

        {/* Render grouped rubrics */}
        {Object.entries(groupedRubrics).map(([type, rubrics]) => (
          <div key={type}>
            <h2 className="text-2xl font-semibold mt-6 uppercase">{type}</h2>
            <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 sm:gap-6 mt-4">
              {/* Render cards for each rubric */}
              {rubrics.map((rubricItem, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-white border shadow-sm rounded-xl"
                >
                  <div className="text-center">
                    <h3 className="text-lg font-semibold text-gray-800 m-0">
                      {rubricItem.rubric_name}
                    </h3>
                  </div>
                  <div className="flex justify-center items-center gap-4 divide-x divide-gray-200">
                    <button
                      className="btn btn-success btn-outline btn-circle btn-sm"
                      onClick={addPoint}
                    >
                      + {/* Add your button icons here */}
                    </button>
                    {/* <span>0</span> */}
                    <button
                      className="btn btn-error btn-outline btn-circle btn-sm"
                      onClick={addDemerit}
                    >
                      - {/* Add your button icons here */}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div style={{ zIndex: 999999999999999 }}>
        <Toaster position="bottom-center" />
      </div>
    </AdminLayout>
  );
}

export default HousePointSheet;
