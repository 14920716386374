import React, { useEffect, useState } from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import { useLanguage } from "../components/language";
import Navbar from "../components/Navbar";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import ReturnNav from "./ReturnNav";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import ReactModal from "react-modal";
import AddClubCategory from "./AddClubCategory";
import { Link } from "react-router-dom";
import { Calendar, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
// import WeeklySchedule from 'react-weekly-schedule';
// import 'react-week-calendar/dist/style.less';
import ScheduleSelector from "react-schedule-selector";
import AdminLayout from "./AdminLayout";
import TimeSlotMaker from "./TimeSlotMaker";
import { createGlobalStyle } from "styled-components";
import RichTextEditor from "../components/RichTextEditor";
import Select from "react-select";
import DaysSelection from "../components/DaysSelection";
import ScheduleDisplay from "../components/ScheduleDisplay";
import TimeSlots from "../components/TimeSlots";
import FaqFormBuilder from "./FaqFormBuilder";

const AddEventForm = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [selected, setSelected] = useState([]);
  const [grades, setgrades] = useState([]);
  const [leader, setleader] = useState([]);
  const [scope, setscope] = useState([]);

  // const [startDate, setStartDate] = useState(new Date());

  // const [schedule, setSchedule] = useState([]);

  // const handleSelection = (newSchedule) => {
  //   setSchedule(newSchedule);
  // };
  const [schools, setSchools] = useState([]);
  const [venues, setvenues] = useState([]);

  const GlobalStyle = createGlobalStyle`
    .kAjHZz{
      display: none !important;
    }
`;
  const [schedule, setSchedule] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleSelection = (newSchedule) => {
    console.info("schedule ", newSchedule);
    console.info("schedule ", schedule);
    setSchedule(newSchedule);
  };

  const handleStartDateChange = (e) => {
    setStartDate(new Date(e.target.value));
  };

  const handleEndDateChange = (e) => {
    setEndDate(new Date(e.target.value));
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const { t, changeLanguage, lanArabic } = useLanguage();
  const [toggle, setToggle] = useState(false);
  const [toggleASC, settoggleASC] = useState(false);
  const [toggleBudget, settoggleBudget] = useState(false);

  let { user } = useContext(AuthContext);
  let profileID = 0;

  if (user[`role`] === `Parent`) {
    profileID = 12;
  } else {
    profileID = user["user_id"];
  }
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    category: 0,
    name: "",
    description: "",
    importantNotes: "",
    faculty_advisors: [],
    goals: "",
    logo: "",
    cover: "",
    start_date: "",
    end_date: "",
    schedule_short: [],
    schedule_long: [],
    numWeeks: "",
    max_student: "",
    created_by: profileID,
    status: "",
    fees: "",
    budget: "",
    network: "",
    school: "",
    status: true,
  });

  const [selectedSchool, setselectedSchool] = useState(null);
  const [selectedSchoolNW, setselectedSchoolNW] = useState(null);
  const [selectedVenue, setselectedVenue] = useState(null);
  const [schoolID, setschoolID] = useState(null);
  const [networkID, setnetworkID] = useState(null);
  const [constraints, setConstrainsts] = useState({});
  const handleConstraintChange = (name, value) => {
    const newConstraints = { ...constraints, [name]: value };
    setConstrainsts(newConstraints);
  };
  const gradeOptions = [
    { value: "3", label: "Grade 3" },
    { value: "4", label: "Grade 4" },
    { value: "5", label: "Grade 5" },
    { value: "6", label: "Grade 6" },
    { value: "7", label: "Grade 7" },
    { value: "8", label: "Grade 8" },
    { value: "9", label: "Grade 9" },
    { value: "10", label: "Grade 10" },
    { value: "11", label: "Grade 11" },
    { value: "12", label: "Grade 12" },
    { value: "13", label: "Grade 13" },
  ];

  const scopes = [
    { value: "1", label: "Class" },
    { value: "2", label: "Section" },
    { value: "3", label: "House" },
    { value: "4", label: "School" },
  ];

  const leaders = [
    { Name: "Sana Faisal", value: "Sana Faisal" },
    { Name: "Kimberly Savia Fernandes", value: "Kimberly Savia Fernandes" },
    { Name: "Heba Qaiser", value: "Heba Qaiser" },
    { Name: "Wania Khalid", value: "Wania Khalid" },
    { Name: "Hassaan Ahmed", value: "Hassaan Ahmed" },
    { Name: "Adam Noor", value: "Adam Noor" },
    { Name: "Mohammed Kashan", value: "Mohammed Kashan" },
    { Name: "Ali Affan", value: "Ali Affan" },
    { Name: "Hamdiya Ahmed", value: "Hamdiya Ahmed" },
    { Name: "Abdur Rehman", value: "Abdur Rehman" },
  ];

  const externalTeachers = [
    { Name: "Sara Ali", value: 1 },
    { Name: "Mohammed Ahmed", value: 2 },
    { Name: "Fatima Abdullah", value: 3 },
    { Name: "Ali Khalil", value: 4 },
    { Name: "Nora Mahmoud", value: 5 },
  ];

  const ageOptions = [
    { value: "All", label: "All" },
    { value: "<18", label: "Below 18" },
    { value: ">18", label: "18 and above" },
  ];

  const [network, setNetwork] = useState("");
  const [facultyAdvisors, setFacultyAdvisors] = useState([]);
  const [externalfacultyAdvisors, seexternalfacultyAdvisors] = useState([]);
  const [categories, setCategories] = useState([]);

  console.warn(network);

  let url = "";
  if (user["role"] === "Student") {
    url = "profile";
  } else if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  } else if (user["role"] === "Staff") {
    url = "staff";
  }
  const userID = user["user_id"];
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((response) => {
        const data = response.data;
        //setNetwork(res?.data?.network_id);
        setNetwork(data);
        setschoolID(data.school_id);
        if (user["role"] === "Network Admin") {
          setnetworkID(data.network_id);
        }
        if (user["role"] === "School Admin") {
          setnetworkID(data.network);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);

  const handleSelectChange = (event) => {
    // if(user[`role`]===`Network Admin`){
    //   setselectedSchool(1);
    // } else if(user[`role`]===`School Admin`){
    //   setselectedSchool(network.school_id);
    // }

    setselectedSchool(event.target.value);
  };

  let fac = 1;

  if (user[`role`] === `Network Admin`) {
    fac = selectedSchool;
  } else if (user[`role`] === `School Admin`) {
    fac = network.school_id;
  }

  const handleSelectVenueChange = (event) => {
    setselectedVenue(event.target.value);
  };

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`)
  //     .then((res) => {
  //       setNetwork(res?.data?.network_id);
  //     });
  // }, [profileID]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_KEY}/api/schools/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const filteredSchools = data.filter(
          (school) => school.network === network
        );
        setSchools(data);
      })
      .catch((error) => {
        console.error("Error fetching schools:", error);
      });
  }, [network]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get-club-venues/`)
      .then((response) => {
        setvenues(response.data.venues);
      })
      .catch((error) => {
        console.error("Error fetching venues:", error);
      });
  }, [network, facultyAdvisors]);

  console.warn("Venues : ", venues);

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_KEY}/api/faculty/${selectedSchool}/`)
  //     .then((res) => {
  //       setFacultyAdvisors(res?.data);
  //       setLoading(false);
  //     });

  //   axios
  //     .get(`${process.env.REACT_APP_API_KEY}/api/get_category/`)
  //     .then((res) => {
  //       setCategories(res.data);
  //     });
  // }, [selectedSchool]);

  useEffect(() => {
    if (user.role === "Network Admin") {
      axios
        .get(
          `${process.env.REACT_APP_API_KEY}/api/get_network_staffs/${network?.network_id}/`
        )
        .then((res) => {
          setFacultyAdvisors(res?.data);
          setLoading(false);
        });
    } else if (user.role === "School Admin") {
      axios
        .get(`${process.env.REACT_APP_API_KEY}/api/faculty/${schoolID}/`)
        .then((res) => {
          setFacultyAdvisors(res?.data);
          setLoading(false);
        });
    }

    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_category/`)
      .then((res) => {
        setCategories(res.data);
      });
  }, [selectedSchool, schoolID, user.role]);

  // const [school, setSchool] = useState("");
  // const [network, setNetwork] = useState("");

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_KEY}/api/school_profile/${profileID}`)
  //     .then((res) => {
  //       setSchool(res?.data?.school_id);
  //       setNetwork(res?.data?.network);
  //     });
  // }, []);
  // const [facultyAdvisors, setFacultyAdvisors] = useState([]);
  // const [categories, setCategories] = useState([]);
  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_KEY}/api/faculty/${school}/`)
  //     .then((res) => {
  //       setFacultyAdvisors(res.data);
  //       setLoading(false);
  //     });

  //   axios
  //     .get(`${process.env.REACT_APP_API_KEY}/api/get_category/`)
  //     .then((res) => {
  //       setCategories(res.data);
  //     });
  // }, []);

  const options = [
    {
      label: "Sarah J.",
      value: "grapes",
      avatar:
        "https://images.unsplash.com/photo-1541101767792-f9b2b1c4f127?ixlib=rb-4.0.3&ixid=MAdnBGUrnasHyYNySTqhJPh4p4jnzusTQwbE98sHmnH&&auto=format&fit=facearea&facepad=3&w=300&h=300&q=80",
    },
    {
      label: "Adam M.",
      value: "mango",
      avatar:
        "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MAdnBGUrnasHyYNySTqhJPh4p4jnzusTQwbE98sHmnH&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80",
    },
    {
      label: "Mike K.",
      value: "strawberry",
      avatar:
        "https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MAdnBGUrnasHyYNySTqhJPh4p4jnzusTQwbE98sHmnH&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleSubmit = async () => {
  //   const selectedIds = selected.map((item) => item.value);

  //   if (selectedIds.length <= 0) {
  //     setFormData({
  //       ...formData,
  //       faculty_advisors: selectedIds,
  //       // schedule: dateRange,
  //       start_date: startDate,
  //       end_date: endDate,
  //       schedule: schedule,
  //     });console.warn("Data: ",formData)
  //   } else {
  //     toast.error("Please select at least one faculty advisor.");
  //     return;
  //   }
  // };

  const submitImage = async (e, type) => {
    const file = e.target.files[0];
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "sda7kxdr");
    data.append("cloud_name", "doxi2odzm");
    const response = await fetch(
      "https://api.cloudinary.com/v1_1/doxi2odzm/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    if (!response.ok) {
      toast.error("Error uploading image");
      throw new Error("Image upload failed");
    } else {
      const sendData = await response.json();
      toast.success("Image Upload Success");
      console.log("URL---", sendData.secure_url);
      // setPicture(sendData.secure_url);
      setFormData({
        ...formData,
        [type]: sendData.secure_url,
      });
    }
  };

  const handleSubmit = async () => {
    const selectedIds = selected.map((item) => item.value);

    // if (selectedIds.length <= 0) {

    // } else {
    //   toast.error("Please select at least one faculty advisor.");
    //   return;
    // }

    // Calculate the number of weeks between start and end dates
    const start = startDate.getTime();
    const end = endDate.getTime();
    const numWeeks = Math.ceil((end - start) / (7 * 24 * 60 * 60 * 1000));

    // Replicate the schedule for each week
    const replicatedSchedule = [];
    for (let i = 0; i < numWeeks; i++) {
      const newSchedule = schedule.map((slot) => {
        const newSlot = new Date(slot);
        newSlot.setDate(newSlot.getDate() + i * 7); // Increment by weeks
        return newSlot;
      });
      replicatedSchedule.push(...newSchedule);
    }

    let school = 0;
    if (user[`role`] === `Network Admin`) {
      school = selectedSchool;
    } else if (user[`role`] === `School Admin`) {
      school = schoolID;
    }

    let networkValue = 0;
    if (user[`role`] === `Network Admin`) {
      networkValue = network.network;
    } else if (user[`role`] === `School Admin`) {
      networkValue = network.network_id;
    }

    setFormData({
      ...formData,
      faculty_advisors: selectedIds,
      start_date: startDate,
      end_date: endDate,
      schedule_long: replicatedSchedule,
      schedule: schedule,
      constraints_required: toggle,
      constraints: constraints,
      numWeeks: numWeeks,
      school: school,
      venue: selectedVenue,
      network: networkID,
    });
    console.warn("Data: ", formData);
  };


  const [schoolselectedVenue, setschoolselectedVenue] = useState('');

  const schoolVenues = [
    { id: 1, name: 'Greenwood High School' },
    { id: 2, name: 'Riverside Academy' },
    { id: 3, name: 'Sunrise Elementary' },
    { id: 4, name: 'Maple Leaf International School' },
    { id: 5, name: 'Lakeside Middle School' }
  ];

  const handleChangeschoolselectedVenue = (event) => {
    setschoolselectedVenue(event.target.value);
  };

  useEffect(() => {
    const submitClubData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_KEY}/api/clubs/`,
          formData
        );

        console.log(response.data);
        toast.success("Club created successfully");
        setFormData({
          category: "",
          name: "",
          description: "",
          importantNotes: "",
          faculty_advisors: [],
          goals: "",
          logo: "",
          cover: "",
          // schedule: "ok",
          start_date: "",
          end_date: "",
          schedule: [],
          max_student: "",
          created_by: profileID,
          status: "",
          fees: "",
          budget: "",
        });
      } catch (error) {
        console.error("Error creating Club:", error);

        toast.error("Error creating Club", error);
      }
    };

    if (formData.faculty_advisors.length > 0) {
      submitClubData();
    }
  }, [formData]);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`
  //       );
  //       let data = await response.json();
  //       setProfile(data);
  //       setLoading(false);
  //     } catch (err) {
  //       //console.log("The requested profile does not exist.");
  //       setLoading(false); // Set loading to false even on error to stop showing the loader
  //     }
  //   };

  //   // Simulate the loader by setting a timer for 3 seconds
  //   const timer = setTimeout(() => {
  //     fetchData();
  //   }, 900);

  //   // Clean up the timer when the component unmounts
  //   return () => clearTimeout(timer);
  // }, [profileID]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [selectedDays, setSelectedDays] = useState([]);
  const [scheduleNEW, setscheduleNEW] = useState({});

  return (
    <AdminLayout>
      {/* {/* <ReturnNav /> */}
      <>
        {loading ? (
          <div className="flex items-center justify-center space-x-2">
            <div className="w-4 h-4 rounded-full animate-pulse darki:bg-violet-400"></div>
            <div className="w-4 h-4 rounded-full animate-pulse darki:bg-violet-400"></div>
            <div className="w-4 h-4 rounded-full animate-pulse darki:bg-violet-400"></div>
          </div>
        ) : (
          <div className="">
            <div className="bg-white rounded-lg px-2 py-4 flex flex-col shadow-xl ">
              <div className="flex flex-col w-11/12 mx-auto bg-white px-8 py-7 rounded-lg ">
                <h4>Add Event</h4>

                <div className="grid grid-cols-2 gap-6">
                  <div className="flex flex-col ">
                    <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                      Event name
                    </label>

                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="input input-bordered"
                      placeholder="Enter event name"
                    />
                  </div>

                  <div className="flex flex-col ">
                    <label className="flex justify-between text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                      <span>Category</span>{" "}
                    </label>

                    <select
                      className="input input-bordered"
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                    >
                      <option value="" selected disabled>
                        Select a category
                      </option>
                      <option value={`During School`}>Internal Event</option>
                      <option value={`After School`}>External Event</option>
                    </select>
                  </div>

                  {formData.category === "After School" && (
                    <>
                      <div className="flex flex-col gap-1  ">
                        <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                          Membership Fee
                        </label>
                        <input
                          type="number"
                          className="input input-bordered w-full"
                          placeholder="eg: 350"
                          name="max_student"
                          value={formData.fees}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="flex flex-col gap-1  ">
                        <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                          External Instructor / Advisor
                        </label>
                        <MultiSelect
                          className=""
                          options={externalTeachers.map((instructor) => ({
                            value: instructor.value,
                            label: instructor.Name,
                          }))}
                          value={leader}
                          onChange={setleader}
                          labelledBy="Select"
                          renderOption={({ label, value, avatar }) => (
                            <div>{label}</div>
                          )}
                        />
                      </div>
                    </>
                  )}

                  <div className="flex flex-col gap-1  ">
                    <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                      Club capacity
                    </label>
                    <input
                      type="number"
                      className="input input-bordered w-full"
                      placeholder="eg: 350"
                      name="max_student"
                      value={constraints.max_student}
                      onChange={(e) =>
                        handleConstraintChange("max_student", e.target.value)
                      }
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                      Grades allowed
                    </label>

                    <MultiSelect
                      className=""
                      options={gradeOptions.map((grade) => ({
                        value: grade.value,
                        label: grade.label,
                      }))}
                      value={grades}
                      onChange={setgrades}
                      labelledBy="Select"
                      renderOption={({ label, value, avatar }) => (
                        <div>{label}</div>
                      )}
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                      Faculty Advisor(s)
                    </label>

                    <MultiSelect
                      className=""
                      options={facultyAdvisors.map((faculty) => ({
                        value: faculty.id,
                        label: faculty.first_name + " " + faculty.last_name,
                      }))}
                      value={leader}
                      onChange={setleader}
                      labelledBy="Select"
                      renderOption={({ label, value, avatar }) => (
                        <div>{label}</div>
                      )}
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                      Student Leaders(s)
                    </label>

                    <MultiSelect
                      className=""
                      options={leaders.map((leader) => ({
                        value: leader.value,
                        label: leader.Name,
                      }))}
                      value={selected}
                      onChange={setSelected}
                      labelledBy="Select"
                      renderOption={({ label, value, avatar }) => (
                        <div>
                          {avatar && (
                            <img
                              src={avatar}
                              alt={`${label}'s Avatar`}
                              style={{
                                width: "24px",
                                height: "24px",
                                borderRadius: "50%",
                                marginRight: "8px",
                              }}
                              crossOrigin="anonymous"
                            />
                          )}
                          {label}
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-col mt-4">
                  <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                    Event description
                  </label>
                  <textarea
                    className="textarea textarea-bordered"
                    placeholder="Tell others more about the event"
                  ></textarea>
                </div>

                <div className="py-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 darki:text-white darki:before:border-gray-600 darki:after:border-gray-600">
                  Schedule
                </div>

                <div className="flex flex-col gap-4">
                  <div className="p-4  rounded-xl">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
                      <div className="flex flex-col justify-start">
                        <label htmlFor="startDate" className="text-gray-400">
                          Start Date
                        </label>
                        <input
                          className="input input-bordered"
                          type="date"
                          id="startDate"
                          value={startDate.toISOString().split("T")[0]}
                          onChange={handleStartDateChange}
                        />
                      </div>
                      <div className="flex flex-col justify-start">
                        <label htmlFor="endDate" className="text-gray-400">
                          End Date
                        </label>
                        <input
                          className="input input-bordered"
                          type="date"
                          id="endDate"
                          value={endDate.toISOString().split("T")[0]}
                          onChange={handleEndDateChange}
                        />
                      </div>
                    </div>

                    <div className="py-2 px-3 mt-4 rounded-xl flex bg-secondary justify-between items-center">
                      <span className="text-primary font-medium">
                        Make this a recurring event
                      </span>

                      <Toggle
                        color="red"
                        defaultChecked={toggleASC}
                        // onChange={(e) => setToggle(e.target.value)}
                        onChange={() => settoggleASC(!toggleASC)}
                      />
                    </div>

                    {toggleASC && (
                      <div className="mt-8">
                        <div className="flex flex-col md:flex-row gap-6 items-start justify-between">
                          <div className="flex flex-col w-full">
                            <DaysSelection
                              selectedDays={selectedDays}
                              setSelectedDays={setSelectedDays}
                            />
                            {selectedDays.length > 0 && (
                              <TimeSlots
                                selectedDays={selectedDays}
                                schedule={schedule}
                                setSchedule={setSchedule}
                              />
                            )}
                          </div>
                          <>
                            {Object.keys(schedule).length > 0 && (
                              <ScheduleDisplay
                                schedule={schedule}
                                setSchedule={setSchedule}
                              />
                            )}
                          </>
                        </div>
                      </div>
                    )}
                  </div>
                </div>



                
                <div className="py-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 darki:text-white darki:before:border-gray-600 darki:after:border-gray-600">
                  Constraints
                </div>


                <div className="grid grid-cols-2 gap-6">
                  <div className="flex flex-col ">
                    <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                      Location
                    </label>


                    <select
        value={schoolselectedVenue}
        onChange={handleChangeschoolselectedVenue}
        className=" select select-bordered"
      >
        <option value="" disabled>Select a venue</option>
        {schoolVenues.map((venue) => (
          <option key={venue.id} value={venue.name}>
            {venue.name}
          </option>
        ))}
      </select>
                 
                  </div>



                  <div className="flex flex-col ">
                    <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                      Capacity
                    </label>


                   <input className="input input-bordered " type="number" placeholder="350"/>
                 
                  </div>

                  </div>


                <div className="py-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 darki:text-white darki:before:border-gray-600 darki:after:border-gray-600">
                  Add FAQs
                </div>

                <FaqFormBuilder/>

                <div className="py-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 darki:text-white darki:before:border-gray-600 darki:after:border-gray-600">
                  Tags
                </div>

<div className="grid grid-cols-2 gap-6">

<div className="form-control items-start">
  <label className="label cursor-pointer flex-row-reverse justify-start gap-x-3">
    <span className="label-text">Direct interaction with the instructor</span> 
    <input type="checkbox"  className="checkbox checkbox-primary" />
  </label>
</div>

<div className="form-control items-start">
  <label className="label cursor-pointer flex-row-reverse justify-start gap-x-3">
    <span className="label-text">Access on mobile and web</span> 
    <input type="checkbox"  className="checkbox checkbox-primary" />
  </label>
</div>

<div className="form-control items-start">
  <label className="label cursor-pointer flex-row-reverse justify-start gap-x-3">
    <span className="label-text">Session recording after the workshop</span> 
    <input type="checkbox"  className="checkbox checkbox-primary" />
  </label>
</div>

<div className="form-control items-start">
  <label className="label cursor-pointer  flex-row-reverse justify-start gap-x-3">
    <span className="label-text">1 hour live session</span> 
    <input type="checkbox"  className="checkbox checkbox-primary" />
  </label>
</div>


<button className="btn btn-sm btn-warning btn-outline w-max">+ Add more tags</button>

</div>



<div className="py-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 darki:text-white darki:before:border-gray-600 darki:after:border-gray-600">
                  Budget
                </div>


                <div className="grid grid-cols-2 gap-6">
               



                  <div className="flex flex-col ">
                    <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                      Ticket Fee
                    </label>


                   <input className="input input-bordered " type="number" placeholder="350"/>
                 
                  </div>


                  <div className="flex flex-col ">
                    <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                      Budget
                    </label>


                   <input className="input input-bordered " type="number" placeholder="400"/>
                 
                  </div>

                  </div>

                <div className="w-full flex items-center pt-12 gap-4">
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Save & Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>

      <Toaster position="bottom-center" />
    </AdminLayout>
  );
};

export default AddEventForm;
