const Clubs_Data = [
    {
        "Main Categories": "School club/society",
        "Sub Categories": "STEM/STEAM Society",
        "Technology": "3",
        "Leadership": "3",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "2",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Music Society",
        "Technology": "",
        "Leadership": "2",
        "Interpersonal Skills": "3",
        "Social Responsibility": "",
        "Critical Thinking": "",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Art Society",
        "Technology": "",
        "Leadership": "2",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Debates Society",
        "Technology": "",
        "Leadership": "3",
        "Interpersonal Skills": "2",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "MUN Society",
        "Technology": "",
        "Leadership": "3",
        "Interpersonal Skills": "2",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Drama/Theater Society",
        "Technology": "",
        "Leadership": "3",
        "Interpersonal Skills": "2",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Math Society",
        "Technology": "3",
        "Leadership": "2",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Astronomy Society",
        "Technology": "2",
        "Leadership": "2",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Literature Society",
        "Technology": "",
        "Leadership": "2",
        "Interpersonal Skills": "2",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Film Society",
        "Technology": "3",
        "Leadership": "2",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Environment Society",
        "Technology": "",
        "Leadership": "2",
        "Interpersonal Skills": "",
        "Social Responsibility": "3",
        "Critical Thinking": "",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Community Service Society",
        "Technology": "",
        "Leadership": "2",
        "Interpersonal Skills": "",
        "Social Responsibility": "3",
        "Critical Thinking": "",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Media & Publications Society",
        "Technology": "3",
        "Leadership": "2",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Sports Society",
        "Technology": "",
        "Leadership": "2",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "",
        "Physical Fitness": "3"
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Choir Society",
        "Technology": "",
        "Leadership": "1",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "2",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Cooking Society",
        "Technology": "",
        "Leadership": "1",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "1",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Cardio Society",
        "Technology": "",
        "Leadership": "1",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "",
        "Physical Fitness": "2"
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Yoga Society",
        "Technology": "",
        "Leadership": "1",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "",
        "Physical Fitness": "2"
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Book Club",
        "Technology": "",
        "Leadership": "1",
        "Interpersonal Skills": "2",
        "Social Responsibility": "",
        "Critical Thinking": "2",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Cooking Club",
        "Technology": "",
        "Leadership": "1",
        "Interpersonal Skills": "2",
        "Social Responsibility": "",
        "Critical Thinking": "2",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "School Magazine",
        "Technology": "",
        "Leadership": "1",
        "Interpersonal Skills": "2",
        "Social Responsibility": "",
        "Critical Thinking": "2",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "Social Media Club",
        "Technology": "",
        "Leadership": "1",
        "Interpersonal Skills": "2",
        "Social Responsibility": "",
        "Critical Thinking": "2",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "School club/society",
        "Sub Categories": "School Media Club",
        "Technology": "",
        "Leadership": "1",
        "Interpersonal Skills": "2",
        "Social Responsibility": "",
        "Critical Thinking": "2",
        "Physical Fitness": ""
    }
];

export default Clubs_Data;