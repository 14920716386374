import React, { useState } from 'react';

const TagSelector = () => {
  const [selectedTags, setSelectedTags] = useState([]);

  const tags = ['Emirati Student', 'Determination', 'Gifted & Talented'];

  const toggleTag = (tag) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag)
        ? prevSelectedTags.filter((t) => t !== tag)
        : [...prevSelectedTags, tag]
    );
  };

  return (
    <div className="flex space-x-2 mt-6">
      {tags.map((tag) => (
        <button
          key={tag}
          onClick={() => toggleTag(tag)}
          className={`px-4 py-2 rounded-full text-white text-sm font-semibold ${
            selectedTags.includes(tag) ? 'bg-primary' : 'bg-gray-400'
          }`}
        >
          {tag}
        </button>
      ))}
    </div>
  );
};

export default TagSelector;
