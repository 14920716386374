import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import SideInner from "../components/SideInner";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import SideInnerSM from "../components/SideInnerSM";

const StudentAttendance = () => {
  let { user } = useContext(AuthContext);
  let userID = 0;

  if (user[`role`] === `Parent`) {
    userID = 12;
  } else {
    userID = user["user_id"];
  }
  const { t } = useTranslation();
  const lanArabic = i18n.language === "ar";

  let params = useParams();
  let profileID = userID;
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        setProfile(response.data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false);
      }
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    return () => clearTimeout(timer);
  }, [profileID]);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  const [attendance, setAttendance] = useState([]);
  useEffect(() => {
    if (user && user.clubs && user.clubs.length > 0) {
      const defaultClubID = user.clubs[0].id;
      axios
        .get(
          `${process.env.REACT_APP_API_KEY}/api/get_student_attendance/${userID}/${defaultClubID}/`
        )
        .then((res) => {
          setAttendance(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userID, user]);

  const [myClubs, setMyClubs] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/my_clubs/${userID}/`)
      .then((res) => {
        setMyClubs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userID]);

  const [selectedClub, setSelectedClub] = useState(null);

  // useEffect(() => {
  //   if (myClubs.length > 0) {
  //     setSelectedClub(myClubs[0].id); // Select the first club by default
  //   }
  // }, [myClubs]);

  const handleSelectChange = (event) => {
    setSelectedClub(event.target.value);
    axios
      .get(
        `${process.env.REACT_APP_API_KEY}/api/get_student_attendance/${userID}/${event.target.value}/`
      )
      .then((res) => {
        setAttendance(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function formatDate(dateString) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear() % 100;

    return `${day}-${months[monthIndex]}-${year}`;
  }

  const presentsCount = attendance.filter((entry) => entry.status).length;
  const absentsCount = attendance.length - presentsCount;
  const generateRandomNumber = () => Math.floor(Math.random() * 9) + 1;


  const totalInvolvement = attendance.reduce((acc, entry) => acc + (entry.status ? generateRandomNumber() : 0), 0);
const totalParticipation = attendance.reduce((acc, entry) => acc + (entry.status ? generateRandomNumber() : 0), 0);
const totalPerformance = attendance.reduce((acc, entry) => acc + (entry.status ? generateRandomNumber() : 0), 0);


  return (
    <div>
      <div>
        <div>
          <>
            <div className="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>
            <Sidebar />
            {isSidebarVisible && <SideInnerSM />}
            <main
              className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${
                lanArabic ? "xl:mr-68" : "md:ml-[17rem]"
              } rounded-xl`}
            >
              <Navbar toggleSidebar={toggleSidebar} />

              <div class="px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
                <div class="">
                  <div className="flex flex-row flex-wrap mb-6 gap-4 bg-white p-4 rounded-xl items-center">
                    <select
                      className="select select-bordered w-full  max-w-xs "
                      value={selectedClub}
                      onChange={handleSelectChange}
                    >
                      <option value={null} selected>
                        Select a society
                      </option>
                      {myClubs.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>

                    {/* {(() => {
                      if (presentsCount > 0) {
                        return (
                          <div class="flex flex-col bg-white  rounded-xl darki:bg-slate-900 darki:border-gray-800">
                            <div class="p-4 md:p-5">
                              <div class="flex items-center gap-x-2 justify-center">
                                <p class="text-xs m-0 uppercase tracking-wide text-gray-500">
                                  Presents
                                </p>
                              </div>

                              <div class="mt-1 flex items-center gap-x-2 justify-center">
                                <h3 class="text-xl  m-0 sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
                                  <span className="text-success">
                                    {presentsCount}
                                  </span>{" "}
                                  <span className="text-gray-400 font-normal">
                                    (
                                    {(
                                      (presentsCount / attendance.length) *
                                      100
                                    ).toFixed(1)}
                                    )%
                                  </span>
                                </h3>
                              </div>
                            </div>
                          </div>
                        );
                      } else if (presentsCount < 1) {
                        return (
                          <div class="flex flex-col bg-white  rounded-xl darki:bg-slate-900 darki:border-gray-800">
                            <div class="p-4 md:p-5">
                              <div class="flex items-center gap-x-2 justify-center">
                                <p class="text-xs m-0 uppercase tracking-wide text-gray-500">
                                  Presents
                                </p>
                              </div>

                              <div class="mt-1 flex items-center gap-x-2 justify-center">
                                <h3 class="text-xl  m-0 sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
                                  <span className="text-success"> 0 </span>
                                </h3>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })()} */}



                    <div className="flex flex-row flex-wrap">
      {(() => {
        if (presentsCount > 0) {
          return (
            <div className="flex flex-col bg-white rounded-xl mt-4 darki:bg-slate-900 darki:border-gray-800">
              <div className="p-4 md:p-5">
                <div className="flex items-center gap-x-2 justify-center">
                  <p className="text-xs m-0 uppercase tracking-wide text-gray-500">
                    Presents
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 justify-center">
                  <h3 className="text-xl m-0 sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
                    <span className="text-success">
                      {presentsCount}
                    </span>{" "}
                    {/* <span className="text-gray-400 font-normal">
                      ({((presentsCount / attendance.length) * 100).toFixed(1)}%)
                    </span> */}
                  </h3>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="flex flex-col bg-white rounded-xl mt-4 darki:bg-slate-900 darki:border-gray-800">
              <div className="p-4 md:p-5">
                <div className="flex items-center gap-x-2 justify-center">
                  <p className="text-xs m-0 uppercase tracking-wide text-gray-500">
                    Presents
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 justify-center">
                  <h3 className="text-xl m-0 sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
                    <span className="text-success">0</span>
                  </h3>
                </div>
              </div>
            </div>
          );
        }
      })()}

      <div className="flex flex-col bg-white rounded-xl darki:bg-slate-900 darki:border-gray-800 mt-4">
        <div className="p-4 md:p-5">
          <div className="flex items-center gap-x-2 justify-center">
            <p className="text-xs m-0 uppercase tracking-wide text-gray-500">
              Total Involvement
            </p>
          </div>
          <div className="mt-1 flex items-center gap-x-2 justify-center">
            <h3 className="text-xl m-0 sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
              <span className="text-warning">{totalInvolvement}</span>
            </h3>
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-white rounded-xl darki:bg-slate-900 darki:border-gray-800 mt-4">
        <div className="p-4 md:p-5">
          <div className="flex items-center gap-x-2 justify-center">
            <p className="text-xs m-0 uppercase tracking-wide text-gray-500">
              Total Participation
            </p>
          </div>
          <div className="mt-1 flex items-center gap-x-2 justify-center">
            <h3 className="text-xl m-0 sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
              <span className="text-warning">{totalParticipation}</span>
            </h3>
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-white rounded-xl darki:bg-slate-900 darki:border-gray-800 mt-4">
        <div className="p-4 md:p-5">
          <div className="flex items-center gap-x-2 justify-center">
            <p className="text-xs m-0 uppercase tracking-wide text-gray-500">
              Total Performance
            </p>
          </div>
          <div className="mt-1 flex items-center gap-x-2 justify-center">
            <h3 className="text-xl m-0 sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
              <span className="text-warning">{totalPerformance}</span>
            </h3>
          </div>
        </div>
      </div>
    </div>

                    {(() => {
                      if (absentsCount > 0) {
                        return (
                          <div class="flex flex-col bg-white  rounded-xl darki:bg-slate-900 darki:border-gray-800 mt-4">
                            <div class="p-4 md:p-5">
                              <div class="flex items-center gap-x-2 justify-center">
                                <p class="text-xs  m-0 uppercase tracking-wide text-gray-500">
                                  Absents
                                </p>
                              </div>

                              <div class="mt-1 flex items-center gap-x-2 justify-center">
                                <h3 class="text-xl  m-0 sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
                                  <span className="text-error">
                                    {absentsCount}
                                  </span>{" "}
                                  {/* <span className="text-gray-400 font-normal">
                                    (
                                    {(
                                      (absentsCount / attendance.length) *
                                      100
                                    ).toFixed(1)}
                                    )%
                                  </span> */}
                                </h3>
                              </div>
                            </div>
                          </div>
                        );
                      } else if (absentsCount < 1) {
                        return (
                          <div class="flex flex-col bg-white  rounded-xl darki:bg-slate-900 darki:border-gray-800 mt-4">
                            <div class="p-4 md:p-5">
                              <div class="flex items-center gap-x-2 justify-center">
                                <p class="text-xs m-0 uppercase tracking-wide text-gray-500">
                                  Absents
                                </p>
                              </div>

                              <div class="mt-1 flex items-center gap-x-2 justify-center">
                                <h3 class="text-xl  m-0 sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
                                  <span className="text-error"> 0 </span>
                                </h3>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })()}
                  </div>

                  <div className="overflow-hidden bg-white rounded-xl">
      <table className="min-w-full divide-y divide-gray-200 darki:divide-gray-700">
        <thead>
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
            >
              No
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
            >
              Date
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
            >
              Presence
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
            >
              Involvement
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
            >
              Participation
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
            >
              Performance
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 darki:divide-gray-700">
          {attendance.map((entry, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 darki:text-gray-200">
                {index + 1}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 darki:text-gray-200">
                {formatDate(entry.date)}
              </td>
              <td
                className={`px-6 py-4 whitespace-nowrap text-sm font-medium ${
                  entry.status ? "text-green-600" : "text-red-600"
                } darki:text-gray-200`}
              >
                {entry.status ? "Present" : "Absent"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 darki:text-gray-200">
                {entry.status ? generateRandomNumber() : 0}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 darki:text-gray-200">
                {entry.status ? generateRandomNumber() : 0}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 darki:text-gray-200">
                {entry.status ? generateRandomNumber() : 0}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

                  {(() => {
                    if (
                      selectedClub !== null &&
                      presentsCount < 1 &&
                      absentsCount < 1
                    ) {
                      return (
                        <p className="text-center mt-8 opacity-60">
                          {" "}
                          No attendance record found{" "}
                        </p>
                      );
                    } else if (
                      selectedClub === null &&
                      presentsCount < 1 &&
                      absentsCount < 1
                    ) {
                      return (
                        <p className="text-center mt-8 opacity-60">
                          Select a club to see record{" "}
                        </p>
                      );
                    }
                  })()}
                </div>{" "}
              </div>
            </main>
          </>
        </div>
      </div>
    </div>
  );
};

export default StudentAttendance;
