import React, { useContext, useEffect, useState } from 'react';
import AdminDashSideBar from './AdminDashSideBar';
import AdminDashNavbar from './AdminDashNavbar';
import { createGlobalStyle } from 'styled-components';
import AuthContext from "../context/AuthContext";
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import Drawer from '../components/Drawer';
import Breadcrumbs from '../components/Breadcrumbs';
import { useLocation } from 'react-router-dom';

function AdminLayout({ children }) {

  const [profile, setProfile] = useState();
  const [isDept, setisDept] = useState(false);

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);

  const userID = user["user_id"];

    let url = '';
    if(user['role']==='Student'){
      url = 'profile';
    } else if(user['role']==='Network Admin'){
      url = 'network_profile';
    } else if(user['role']==='School Admin'){
      url = 'school_profile';
    } else if(user['role']==='Staff'){
      url = 'staff';
    }
  
    
    let role = user["role"];

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState('');

  const [profileData, setProfileData] = useState({
    name: "",
    dob: "",
    gender: "",
    contact: "",
    bio: "",
    active_status: "",
    employeeID: "",

  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((response) => {
        const data = response.data;
        setProfileData(data);
        setProfile(data);
        setProfilePhotoUrl(data.profile_photo);
        setisDept(data.is_department);
        //console.log("Profile Photo URL: ", data.profile_photo);

      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);


  const [page, setPage] = useState("");

  function extractAfter3000(url) {
    // Allow for http or https, optional "www", and match any hostname
    const match = url.match(/https?:\/\/(?:www\.)?([^/]+)\/([^/0-9]+)/);
    if (match && match[2]) {
      return match[2];
    }
    return null; // Return null if the pattern is not found
  }
  
      
  const GlobalStyle = createGlobalStyle`
  html {
    background: #f9f3ff78 !important;
    ${'' /* background: ${profile?.primaryColor}; */}
    /* Add other global styles here if needed */
  }
`;
    
  useEffect(() => {
    setPage(extractAfter3000(window.location.href));
  }, []);

  let contentTitle = "";
  let contentSubtitle = "";

  if (page === "student" || page === "network" || page === "preview" ||  page === "admin") {
    contentTitle = "Dashboard";
    contentSubtitle = "Overview of activities";
  } 
  else if (page === "cats") {
    contentTitle = "Extracurricular Categories";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "leads") {
    contentTitle = "Leadership Activities";
    contentSubtitle = "Details of current event";
  } 


  else if (page === "add_lead") {
    contentTitle = "Leadership Form";
    contentSubtitle = "Details of current event";
  } 

  
  else if (page === "add_society") {
    contentTitle = "Society Form";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "societies") {
    contentTitle = "Society Activities";
    contentSubtitle = "Details of current event";
  } 


  else if (page === "add_award") {
    contentTitle = "Award Form";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "awards") {
    contentTitle = "Awards & Achievements Activities";
    contentSubtitle = "Details of current event";
  } 

  
  else if (page === "student_nw") {
    contentTitle = "Student Details";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "network_students") {
    contentTitle = "Students";
    contentSubtitle = "Details of current event";
  } 

  
  else if (page === "network_schools") {
    contentTitle = "Campuses";
    contentSubtitle = "Details of current event";
  } 
  
  else if (page === "clubs") {
    contentTitle = "My Clubs";
    contentSubtitle = "Details of current event";
  } 
  else if (page === "categories") {
    contentTitle = "Add Activities";
    contentSubtitle = "Details of current event";
  } 


  else if (page === "student_profile") {
    contentTitle = "Student Profile";
    contentSubtitle = "Details of current event";
  } 

  
  else if (page === "badges") {
    contentTitle = "Student Badges";
    contentSubtitle = "Details of current event";
  } 


    
  else if (page === "notifications") {
    contentTitle = "Notifications";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "challenges") {
    contentTitle = "Challenges";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "moderate_network") {
    contentTitle = "Moderation";
    contentSubtitle = "Details of current event";
  } 
  

  else if (page === "challenge") {
    contentTitle = "Challenge Details";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "pathway") {
    contentTitle = "Guided Pathways";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "bisr" || page === "doe" || page === "rowad") {
    contentTitle = "Extracurricular Activity Form";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "myclub") {
    contentTitle = "My Clubs";
    contentSubtitle = "Details of current event";
  } 


  
  else if (page === "club_details") {
    contentTitle = "Club Details";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "network_staff") {
    contentTitle = "Staff Members";
    contentSubtitle = "Details of current event";
  } 
  
  else if (page === "addevent") {
    contentTitle = "Add Event";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "addclub") {
    contentTitle = "Add Club";
    contentSubtitle = "Details of current event";
  } 


  else if (page === "add_club_category") {
    contentTitle = "Add Club Category";
    contentSubtitle = "Details of current event";
  } 


  else if (page === "event_details" || page === "event_details_green") {
    contentTitle = "Event Details";
    contentSubtitle = "Details of current event";
  } 

  
  else if (page === "event_attendance" || page==='attendance') {
    contentTitle = "Attendance";
    contentSubtitle = "Details of current event";
  } 
  
       
  else if (page === "school_calendar" || page === "admin-calendar") {
    contentTitle = "Calendar";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "school_events" ) {
    contentTitle = "School Events";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "student_councils" ) {
    contentTitle = "Student Council";
    contentSubtitle = "Details of current event";
  } 
  
  else if (page === "school_venues" ) {
    contentTitle = "Venues";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "school_houses" || page === "house_program") {
    contentTitle = "House Program";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "school" && isDept) {
    contentTitle = "Department Dashboard";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "school" && !isDept) {
    contentTitle = "School Dashboard";
    contentSubtitle = "Details of current event";
  } 
  
  else if (page === "inventory") {
    contentTitle = "Inventory";
    contentSubtitle = "Details of current event";
  }    else if (page === "admin_schedule") {
    contentTitle = "Schedule";
    contentSubtitle = "Details of current event";
  } 
  
  else {
    contentTitle = "SSMS";
    contentSubtitle = "Other Name Overview";
  }

  const location = useLocation();
  const [crumbs, setCrumbs] = useState([]); // Initialize crumbs state
  // Update breadcrumb state based on the current route


  

  useEffect(() => {
     if (page === 'bulk_students') {
      setCrumbs([
        { label: 'Students', path: '/network_students' },
        { label: 'Import Students', path: '' },
      ]); 
    } else if (page === 'bulk_staff') {
      setCrumbs([
        { label: 'Staff', path: '/network_staff' },
        { label: 'Import Staff', path: '' },
      ]); 
    } else if (page === 'student_nw') {
      setCrumbs([
        
        { label: 'Students', path: '/network_students' },
        { label: 'Student Details', path: '' },
      ]); 
    }  else if (page === 'addclub') {
      setCrumbs([
        
        { label: 'Clubs', path: '/clubs' },
        { label: 'Add Club', path: '' },
      ]);
    } else if (page === 'club_details') {
      setCrumbs([
        
        { label: 'Clubs', path: '/clubs' },
        { label: 'Details', path: '' },
      ]);
    } else if (page === 'create_council') {
      setCrumbs([
        
        { label: 'Student Council', path: '/student_councils' },
        { label: 'Add Student Council', path: '/student_councils' },

      ]);
      
    } else if (page === 'council-details') {
      setCrumbs([
        
        { label: 'Student Council', path: '/student_councils' },
        { label: 'Student Council Details', path: '/council-details' },

      ]);
      
    } else if (page === 'house_students') {
      setCrumbs([
        
        { label: 'House Program', path: '/house_program' },
        { label: 'Points', path: '' },
      ]);
    } else if (page === 'pointsheet') {
      setCrumbs([
        
        { label: 'House Program', path: '/house_program' },
        { label: 'Students', path: '/house_students' },
        { label: 'Point Sheet', path: '' },
      ]);
    }   else {
      setCrumbs([]);
    }
  }, [location.pathname, page]);


  
    return (
      <div className=''>
        <AdminDashNavbar profile={profile} page={page} contentTitle={contentTitle} />
        <AdminDashSideBar profile={profile} page={page} contentTitle={contentTitle} />
        <GlobalStyle />
        <div className={`w-full pt-10 px-2 sm:px-2 md:px-4 ${i18n.language==='en'? 'lg:pl-[17rem]':'lg:pr-[17rem]'} `}>
        <div className="px-4 sm:px-2 lg:px-4 ">
        <Breadcrumbs crumbs={crumbs}/>
        {children} {/* Render children here */}
        </div>
          
        </div>
      </div>
    );
  }
  

export default AdminLayout;
