import React from 'react';

const DynamicTable = ({ data }) => {
    console.log(data,"=============")
    if (!data || data?.length === 0) {
        return <div className='flex items-center justify-center p-2'>
            <p className='text-2xl'>No data available.</p>
        </div>
    }

    const columns = Object?.keys(data[0]);

    return (
        <table className="min-w-full divide-y divide-gray-200 overflow-x-auto">
        <thead className="bg-gray-50">
          <tr>
            {columns?.map((column) => (
              <th
                key={column}
                scope="col"
                className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data?.map((row, rowIndex) => (
            <tr key={rowIndex} className="hover:bg-gray-100">
              {columns?.map((column) => (
                <td key={column} className="px-6 py-4 whitespace-nowrap">
                  {column === 'Actions' ? (
                    <div className="flex items-center space-x-2">
                      <a
                        href="#"
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit
                      </a>
                      <a
                        href="#"
                        className="text-red-600 hover:text-red-900"
                      >
                        Delete
                      </a>
                    </div>
                  ) : (
                    row[column]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      
    );
};

export default DynamicTable;
