import axios from "axios";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';

function AttendancePopUp({ club, scheduleID, date }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [clubs, setClubs] = useState([]);
  const [selectedClub, setSelectedClub] = useState(club);
  const [selectedDate, setSelectedDate] = useState(date);

  // Set the default value of selectedSchedule to the date prop
//   const [selectedSchedule, setSelectedSchedule] = useState(date);
  const [selectedSchedule, setSelectedSchedule] = useState(scheduleID || "");


  console.warn("Club: "+club+" ScheduleID: "+scheduleID+" Date: "+date)

  const [schedules, setSchedules] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/clubs/`)
      .then((res) => {
        setClubs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedClub) {
    //   axios
    //     .get(
    //       `${process.env.REACT_APP_API_KEY}/api/get_club_schedules/${selectedClub}/`
    //     )
    //     .then((res) => {
    //       const currentDate = new Date();
    //       const filteredSchedules = res.data.filter(
    //         (schedule) => new Date(schedule.start_date) <= currentDate
    //       );
    //       setSchedules(filteredSchedules);
    //       if (filteredSchedules.length > 0) {
    //         setSelectedSchedule(
    //           filteredSchedules[filteredSchedules.length - 1].id
    //         );
    //       } else {
    //         setSelectedSchedule(""); // Reset selected schedule if no schedules available
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });

    setSelectedSchedule(scheduleID);
    }
  }, [selectedClub,scheduleID]); 

  const getAttendees = () => {
    if (!selectedClub || !selectedSchedule) {
      setAttendees([]);
      return;
    }

    axios
      .get(
        `${process.env.REACT_APP_API_KEY}/api/get_club_attendance/${selectedSchedule}/`
      )
      .then((res) => {
        setAttendees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAttendees(); // Fetch attendees when selectedClub or selectedSchedule changes
  }, [selectedClub, selectedSchedule]);

  const handleClubChange = (e) => {
    setSelectedClub(e.target.value);
    setSelectedSchedule("");
  };

  const handleScheduleChange = (e) => {
    setSelectedSchedule(e.target.value);
  };

  const handleAttendence = (id, status) => {
    axios
      .put(`${process.env.REACT_APP_API_KEY}/api/handle_attendance/`, {
        id: id,
        status: status,
      })
      .then((res) => {
        getAttendees();
        // toast.success("Attendance Updated Successfully");
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${day} ${monthNames[monthIndex]} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}`;
  };

  const handleSelectAllCheckbox = () => {
    setSelectAllChecked((prevState) => !prevState);
    if (!selectAllChecked) {
      const allAttendeeIds = attendees.map((attendee) => attendee.id);
      setSelectedStudents(allAttendeeIds);
    } else {
      setSelectedStudents([]);
    }
  };

  const handleCheckboxAttendance = async (id, isChecked) => {
    if (isChecked) {
      setSelectedStudents((prevSelected) => [...prevSelected, id]);
    } else {
      setSelectedStudents((prevSelected) =>
        prevSelected.filter((studentId) => studentId !== id)
      );
    }
  };

  const markSelectedAttendeesPresent = () => {
    selectedStudents.forEach((studentId) => {
      handleAttendence(studentId, true);
    });
    setSelectedStudents([]);
    setSelectAllChecked(false);
  };

  return (
    <>
      {/* Table Section */}
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        {/* Card */}
        {/* <div className="flex flex-row justify-between items-center bg-white p-6 rounded-xl mb-8">
          <span className="text-sm font-medium">
            Select Club
            <span>
              <select
                className="select select-bordered w-full max-w-xs"
                value={selectedClub}
                onChange={handleClubChange}
              >
                <option selected disabled value="">
                  Select a club
                </option>
                {clubs.map((club) => (
                  <option key={club.id} value={club.id}>
                    {club.name}
                  </option>
                ))}
              </select>
            </span>
          </span>

          <span className="text-sm font-medium">
            Select Time Slot
            <select
              className="select select-bordered w-full max-w-xs"
              value={selectedSchedule}
              onChange={handleScheduleChange}
            >
              <option value="">Select Time</option>
              {schedules.map((schedule) => (
                <option key={schedule.id} value={schedule.id}>
                  {formatDate(schedule.start_date)} -{" "}
                  {formatDate(schedule.end_date)}
                </option>
              ))}
            </select>
          </span>
        </div> */}
        <div className="flex justify-end mb-6">
          <button
            onClick={markSelectedAttendeesPresent}
            className="btn btn-primary "
          >
            Mark as Present
          </button>
        </div>
        <div className="flex flex-col bg-white rounded-xl">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                      >
                        <input
                          type="checkbox"
                          className="form-checkbox h-5 w-5 text-primary ml-7 rounded-md border-gray-300 focus:ring-primary"
                          checked={selectAllChecked}
                          onChange={handleSelectAllCheckbox}
                        />
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                      ></th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                      >
                        Grade
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="text-center px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 darki:divide-gray-700">
                    {attendees?.map((attendee) => (
                      <tr key={attendee.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 darki:text-gray-200">
                          <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-primary ml-7 rounded-md border-gray-300 focus:ring-primary"
                            checked={selectedStudents.includes(attendee.id)}
                            onChange={(e) =>
                              handleCheckboxAttendance(
                                attendee.id,
                                e.target.checked
                              )
                            }
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 darki:text-gray-200">
                          <div className="avatar placeholder">
                            <div className="bg-purple-100 text-primary rounded-full w-10">
                              <span className="text-base">
                                {attendee?.firstname?.substring(0, 1)}
                                {attendee?.lastname?.substring(0, 1)}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 darki:text-gray-200">
                          {attendee?.firstname} {attendee?.lastname} <br />{" "}
                          <span className="text-gray-400">
                            {attendee?.email}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 darki:text-gray-200">
                          {attendee?.grade}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 darki:text-gray-200">
                          {attendee?.status === true ? (
                            <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-teal-100 text-teal-800 darki:bg-teal-800/30 darki:text-teal-500">
                              Present
                            </span>
                          ) : attendee?.status === false ? (
                            <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-100 text-red-800 darki:bg-red-800/30 darki:text-red-500">
                              Absent
                            </span>
                          ) : (
                            <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 darki:bg-yellow-800/30 darki:text-yellow-500">
                              Pending
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                          <div className="inline-flex rounded-lg gap-2">
                            <button
                              type="button"
                              className="btn btn-sm btn-success"
                              onClick={() =>
                                handleAttendence(attendee?.id, true)
                              }
                            >
                              Mark Present
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-error"
                              onClick={() =>
                                handleAttendence(attendee?.id, false)
                              }
                            >
                              Mark Absent
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AttendancePopUp;
