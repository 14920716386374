import React, { useEffect, useState, useRef } from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import { useLanguage } from "../components/language";
import Navbar from "../components/Navbar";
import "react-toggle/style.css";
import { createGlobalStyle } from "styled-components";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import Select from "react-select";
import SampleFile from "./SampleFile";
import axios from "axios";
import { Line } from "rc-progress";
import UsersUpload from "./UsersUpload";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminLayout from "./AdminLayout";
import StudentCouncilTracker from "../components/StudentCouncilTracker";
// import cloud from "../assets/images/cloud_upload.svg"

const CampDetailsPage = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const { t, changeLanguage, lanArabic } = useLanguage();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "teacher", label: "Teacher" },
    // Add more role options as needed
  ];
  const { id } = useParams();

  const permissionOptions = [
    { value: "read", label: "Read" },
    { value: "write", label: "Write" },
    // Add more permission options as needed
  ];

  const [campaigns, setcampaigns] = useState(
    [
        {
          "id": 1,
          "name": "Sustainability Campaign",
          "status": "active",
          "start_date": "May 1, 2024",
          "end_date": "May 31, 2024"
        },
        {
          "id": 2,
          "name": "Ramzan Campaign",
          "status": "active",
          "start_date": "May 1, 2024",
          "end_date": "May 31, 2024"
        },
        {
          "id": 3,
          "name": "Entrepreneurship Campaign",
          "status": "active",
          "start_date": "May 1, 2024",
          "end_date": "May 31, 2024"
        }
      ]
      
  );
  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };
  const navigate = useNavigate();
  const [event, setEvents] = useState(null);

  useEffect(() => {
    if (id) {
      const selected = campaigns.find(camp => camp.id.toString() === id);
      setEvents(selected || null);
    }
  }, [id, campaigns]);
  console.warn(event?.faculty_advisors);

  function formatDate(dateString) {
    const options = { month: "long", day: "2-digit", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
  }

  const EventApproval = (status) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/event_approval/${id}/`, {
        status: status,
      })
      .then((res) => {
        toast.success("Event Approved Successfully");
        console.log(res);
      })
      .catch((err) => {
        toast.error("Event Approval Failed");
        console.log(err);
      });
  };

  const attendances = [
    {
      id:1,
      title:'Pre Event Attendance',
      datetime: 'Feb 29, 06:00',
      status: 'marked',
      presents: '15',
      late: '04',
      absents: '02',
    },
    {
      id:2,
      title:'Event Start Fall-in',
      datetime: 'Feb 29, 08:00',
      status: 'active',
      presents: '0',
      late: '0',
      absents: '0',
    },
    {
      id:3,
      title:'Post Event Fall-out',
      datetime: 'Feb 29, 01:00',
      status: 'pending',
      presents: '0',
      late: '0',
      absents: '0',
    }
]


const studentsData = [
  { id: 1, name: 'John Doe', school: 'Engineering', semester: 4 },
  { id: 2, name: 'Jane Smith', school: 'Business Administration', semester: 6 },
  { id: 3, name: 'Alex Johnson', school: 'Computer Science', semester: 3 },
  { id: 4, name: 'Emily Davis', school: 'Psychology', semester: 5 },
  { id: 5, name: 'Michael Brown', school: 'Biology', semester: 2 },
  { id: 6, name: 'Sophia Wilson', school: 'Mathematics', semester: 3 },
  { id: 7, name: 'William Martinez', school: 'Chemistry', semester: 4 },
  { id: 8, name: 'Olivia Anderson', school: 'Art History', semester: 5 },
  { id: 9, name: 'James Thomas', school: 'Sociology', semester: 2 },
  { id: 10, name: 'Emma Taylor', school: 'English Literature', semester: 6 },
  { id: 11, name: 'Daniel Jackson', school: 'Economics', semester: 4 },
  { id: 12, name: 'Isabella White', school: 'Political Science', semester: 3 },
  { id: 13, name: 'Ethan Harris', school: 'Physics', semester: 5 },
  { id: 14, name: 'Ava Moore', school: 'Environmental Science', semester: 2 },
  { id: 15, name: 'Liam Clark', school: 'History', semester: 3 },
];

const [sortBy, setSortBy] = useState({ field: null, asc: true });
const [searchTerm, setSearchTerm] = useState('');

const handleSort = (field) => {
  if (sortBy.field === field) {
    setSortBy({ ...sortBy, asc: !sortBy.asc });
  } else {
    setSortBy({ field, asc: true });
  }
};

const filteredData = studentsData.filter((student) =>
  student.name.toLowerCase().includes(searchTerm.toLowerCase())
);

if (sortBy.field) {
  filteredData.sort((a, b) => {
    const aValue = a[sortBy.field];
    const bValue = b[sortBy.field];
    if (sortBy.asc) {
      return aValue.localeCompare(bValue);
    } else {
      return bValue.localeCompare(aValue);
    }
  });
}

let approvalRequired=false
let attendanceRequired=false


  return (
    <AdminLayout>
      <div className="">
      <div className={`grid ${approvalRequired ? 'lg:grid-cols-3' : ''} gap-y-8 lg:gap-y-0 lg:gap-x-6 lg:gap-x-12`}>
          {/* Content */}
          <div className="lg:col-span-2 bg-white shadow-xl rounded-xl py-6 px-12 w-full overflow-x-scroll">
            <div className="">
              <div className="">
                <div>
                  <Link
                    className="inline-flex items-center gap-x-1.5 text-sm text-gray-600 decoration-2 hover:underline darki:text-blue-400 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                    href="#"
                    onClick={() => navigate(-1)}
                  >
                    <svg
                      className="flex-shrink-0 w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m15 18-6-6 6-6" />
                    </svg>
                    Back to Activities
                  </Link>

                  <h2 class="text-3xl font-bold lg:text-3xl lg:text-4xl darki:text-white mt-4">
                    {event?.name}
                  </h2>

                  <div class="flex items-center gap-x-5">
                    <a
                      class="inline-flex items-center gap-1.5 py-1 px-3 sm:py-2 sm:px-4 rounded-full text-xs sm:text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 darki:bg-gray-800 darki:hover:bg-gray-800 darki:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                      href="#"
                    >
                      Full Event
                    </a>

                    <a
                      class="inline-flex items-center gap-1.5 py-1 px-3 sm:py-2 sm:px-4 rounded-full text-xs sm:text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 darki:bg-gray-800 darki:hover:bg-gray-800 darki:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                      href="#"
                    >
                      {event?.external_event === true ? (
                        <span>External</span>
                      ) : (
                        <span>Onsite</span>
                      )}
                    </a>
                  </div>
                </div>

                {/* <div className="group mt-12 flex items-center gap-x-3 border-b border-gray-200 pb-8 mb-8 darki:border-gray-700">
                  <div className="block flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full img-fit"
                      src="https://res.cloudinary.com/ahbranding/image/upload/v1705663616/Group_34784500_avny0v.png"
                      alt="Image Description"
                    />
                  </div>
                  <div className="group grow block">
                    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
                      Strikers FC
                    </h5>
                    <p className="text-sm text-gray-500 m-0">Sports Club</p>
                  </div>
                </div> */}

                <div className="group mt-8 flex items-center gap-x-3 border-b border-gray-200 pb-8 mb-8 darki:border-gray-700">
                  <div className="block flex-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                      />
                    </svg>
                  </div>
                  <div className="group grow block">
                    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
                      Start Date
                    </h5>
                    <p className="text-sm text-gray-500 m-0">
                      {formatDate(event?.start_date)}
                    </p>
                  </div>

                  <div className="block flex-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                      />
                    </svg>
                  </div>
                  <div className="group grow block">
                    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
                      Block Date
                    </h5>
                    <p className="text-sm text-gray-500 m-0">-</p>
                  </div>

                  <div className="block flex-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                      />
                    </svg>
                  </div>
                  <div className="group grow block">
                    <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
                      End Date
                    </h5>
                    <p className="text-sm text-gray-500 m-0">
                      {formatDate(event?.end_date)}
                    </p>
                  </div>
                </div>

                

                <div class="space-y-4">
                  {/* <details
                    class="group rounded-lg bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden"
                    open
                  >
                    <summary class="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900">
                      <h2 class="font-medium text-lg">Event Request</h2>

                      <span class="relative h-5 w-5 shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </span>
                    </summary>

                    <p class="mt-4 leading-relaxed text-gray-700">
                      <div className="group mt-12 flex items-center gap-x-3 border-b border-gray-200 pb-8 mb-8 darki:border-gray-700">
                        {event?.faculty_advisors.map((faculty) => (
                          <>
                            <div className="block flex-shrink-0">
                              <img
                                className="h-10 w-10 rounded-full img-fit"
                                src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MAdnBGUrnasHyYNySTqhJPh4p4jnzusTQwbE98sHmnH&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                alt="Image Description"
                              />
                            </div>
                            <div className="group grow block">
                              <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
                                {faculty}
                              </h5>
                              <p className="text-sm text-gray-500 m-0">
                                Faculty Advisor
                              </p>
                            </div>
                          </>
                        ))}
                      </div>
                      <h3 className="font-medium text-base"> Event Purpose </h3>
                      {event?.description}
                    </p>
                  </details> */}

                  {attendanceRequired? (
                    <details
                    class="group rounded-lg bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden"
                    
                  >
                    <summary class="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900 mb-4" >
                      <h2 class="font-medium text-lg">Attendance</h2>

                      <span class="relative h-5 w-5 shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </span>
                    </summary>


                    {attendances.map((attendance) => (
                    <label
  htmlFor="hs-vertical-checkbox-in-form "
  className="justify-between my-2 w-full flex flex-col md:flex-row flex-wrap gap-4 p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 darki:bg-neutral-900 darki:border-neutral-700 darki:text-neutral-400"
>

  <span className="text-sm text-gray-500 ms-3 darki:text-neutral-400">
    <b>{attendance?.title}</b> <br/> <span className="opacity-70">{attendance?.datetime}</span>
  </span>
  
  {(() => {
        if (attendance?.status==='marked') {
          return (
            <div className="flex flex-row items-center gap-3">

<span className="badge text-xs font-medium bg-teal-500 text-white">
{attendance?.presents} presents
</span>

<span className="badge  text-xs font-medium bg-yellow-500 text-white">
{attendance?.late} lates
</span>

<span className="badge text-xs font-medium bg-red-500 text-white">
{attendance?.absents} absents
</span>

</div>
          )
        } else if (attendance?.status==='active') {
          return (
<Link to={`/attendance`} className="btn btn-primary"> Mark Attendance </Link>
          )
        } else if (attendance?.status==='pending') {
          return (
<button className="btn btn-primary" disabled> Not available yet </button>
          )
        }
      })()} 

</label>
))}

           <button className="btn btn-outline mt-4"> + Add more attendances </button>         
                  </details>
                  ):(
                    
                    <details
                    class="group rounded-lg bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden"
                    
                  >
                    <summary class="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900 mb-4" >
                      <h2 class="font-medium text-lg">Roster</h2>

                      <span class="relative h-5 w-5 shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </span>
                    </summary>

                    {/* <div className="overflow-x-auto">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="input border-0"
      />
      <table className="table table-zebra">
        <thead>
          <tr>
            <th onClick={() => handleSort('id')}>ID</th>
            <th onClick={() => handleSort('name')}>Name</th>
            <th onClick={() => handleSort('school')}>School</th>
            <th onClick={() => handleSort('semester')}>Semester</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((student) => (
            <tr key={student.id}>
              <td>{student.id}</td>
              <td>{student.name}</td>
              <td>{student.school}</td>
              <td>{student.semester}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div> */}


                     <StudentCouncilTracker  singleEvent={true}/> 
                  </details>
                  )}


                 




              
 

                </div>
              </div>
            </div>
          </div>
          {/* End Content */}
          {/* Sidebar */}
          {approvalRequired? (
            
          <div className="lg:col-span-1 lg:w-full lg:h-full rounded-xl">
            <div className="sticky top-0 start-0">
              <div className="bg-white rounded-xl p-2 shadow-xl mb-4">
                <div class="p-4 ">
                  <div class="flex gap-x-5">
                    <div class="grow">
                      <h2 class="text-lg font-semibold text-gray-800 darki:text-white">
                        Event Approval
                      </h2>
                      <p class="mt-2 text-sm text-gray-600 darki:text-gray-400">
                        You can approve, reject, request specific info. or edit
                        event request
                      </p>
                      <div class="mt-5 flex flex-col gap-4">
                        <button
                          className="btn btn-success"
                          onClick={() => EventApproval(true)}
                        >
                          Approve
                        </button>
                        <button
                          className="btn btn-error"
                          onClick={() => EventApproval(false)}
                        >
                          Reject
                        </button>
                        <button
                          className="btn btn-warning"
                          onClick={() => EventApproval(null)}
                        >
                          Request more Info.
                        </button>
                        <button className="btn btn-info">
                          Edit Submission
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-xl p-2 shadow-xl my-4">
                <div class="p-4 ">
                  <div class="flex gap-x-5">
                    <div class="grow">
                      <h2 class="text-lg font-semibold text-gray-800 darki:text-white">
                        Faculty Advisors
                      </h2>
                      <p class="mt-2 text-sm text-gray-600 darki:text-gray-400">
                        Club advisors that were assigned to supervise this event
                      </p>
                      <div class="flex flex-col gap-4">
                        <div className="group my-6 flex flex-col gap-3 darki:border-gray-700">
                          {/* {event?.faculty_advisors.map((faculty) => (
                            <div className="flex flex-row items-center  gap-4">
                              <div className="block flex-shrink-0 ">
                                <img
                                  className="h-10 w-10 rounded-full img-fit"
                                  src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MAdnBGUrnasHyYNySTqhJPh4p4jnzusTQwbE98sHmnH&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                  alt="Image Description"
                                />
                              </div>
                              <div className="group grow block  ">
                                <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
                                  {faculty}
                                </h5>
                                <p className="text-sm text-gray-500 m-0">
                                  Faculty Advisor
                                </p>
                              </div>
                            </div>
                          ))} */}

                          {/* {filteredFacultyAdvisors.map((faculty, index) => (

                            <div className="flex flex-row items-center gap-4" key={index}>
        <div className="block flex-shrink-0 relative">
            <div className="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center">
                <span className="text-white text-xl font-bold">{faculty.charAt(0)}</span>
            </div>
        </div>
        <div className="group grow block">
            <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
                {faculty}
            </h5>
            <p className="text-sm text-gray-500 m-0">
                Faculty Advisor
            </p>
        </div>
    </div>
 
))} */}



{event?.faculty_advisors.slice(0, 2).map((faculty, index) => (

<div className="flex flex-row items-center gap-4" key={index}>
<div className="block flex-shrink-0 relative">
<div className="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center">
<span className="text-white text-xl font-bold">{faculty.charAt(0)}</span>
</div>
</div>
<div className="group grow block">
<h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
{faculty}
</h5>
<p className="text-sm text-gray-500 m-0">
Faculty Advisor
</p>
</div>
</div>

))}

<button className="btn btn-block btn-outline"> + Add more staff </button>

                          {/* <div className="flex flex-row items-center gap-4">
                            <div className="block flex-shrink-0 ">
                              <img
                                className="h-10 w-10 rounded-full img-fit"
                                src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                                alt="Image Description"
                              />
                            </div>
                            <div className="group grow block">
                              <h5 className="m-0 group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
                                Aaron Larson
                              </h5>
                              <p className="text-sm text-gray-500 m-0">
                                Faculty Advisor
                              </p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          ):null}

          {/* End Sidebar */}
        </div>
      </div>
      {/* End Article */}
    </AdminLayout>
  );
};

export default CampDetailsPage;