import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { useLanguage } from './language';

const Barchart = ({source}) => {
  let { user } = useContext(AuthContext);
  const params = useParams();
  
  
  // const { t, changeLanguage, lanArabic } = useLanguage();
  const { t, lanArabic } = useLanguage(); // Use the useLanguage hook to access translations and lanArabic
  const [showLegends, setShowLegends] = useState(true);
  const [showAxisLabels, setShowAxisLabels] = useState(true);

  
  let profileID;
  if (source === 'Param') {
    profileID = params.id;
  } else if (source === 'Self') {
    profileID = user['user_id'];
  }  else if (source === 'Parent') {
    profileID = 12;
  } 

  if (!profileID) {
    console.error('Invalid source provided to TimelineStack');
    profileID = 0;
  }

  const [leadpt, setleadpt] = useState(0);
  const [societypt, setsocietypt] = useState(0);
  const [socialpt, setsocialpt] = useState(0);
  const [awardpt, setawardpt] = useState(0);
  const [sportspt, setsportspt] = useState(0);
  const [internpt, setinternpt] = useState(0);
  const [certpt, setcertpt] = useState(0);
  const [talentpt, settalentpt] = useState(0);
  const [langpt, setlangpt] = useState(0);
  const [eventpt, seteventpt] = useState(0);


  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user['user_id'] === parseInt(profileID)) {
    isMyself = true;
  }

  // initialize thread and posts component state
  const [profile, setProfile] = useState();

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`);
        // parse the data in json
        let data = await response.json();
        setProfile(data);
      } catch (err) {
        // console.log('The requested profile does not exist.');
      }
    };

    getProfile();
  }, [profileID]);

  const [screenSizeType, setScreenSizeType] = useState('');

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth >= 1600) {
        setScreenSizeType('600');
      } else if (screenWidth >= 1200) {
        setScreenSizeType('500');
      } else if (screenWidth >= 992) {
        setScreenSizeType('400');
      } else if (screenWidth >= 768) {
        setScreenSizeType('350');
      } else if (screenWidth >= 576) {
        setScreenSizeType('300');
      } else {
        setScreenSizeType('330');
      }


      // if (screenWidth >= 1600) {
      //   setScreenSizeType('600');
      // } else if (screenWidth >= 1440) {
      //   setScreenSizeType('550');
      // } else if (screenWidth >= 1200) {
      //   setScreenSizeType('500');
      // } else if (screenWidth >= 992) {
      //   setScreenSizeType('450');
      // } else if (screenWidth >= 768) {
      //   setScreenSizeType('600');
      // } else if (screenWidth >= 360) {
      //   setScreenSizeType('450');
      // } else if (screenWidth >= 360) {
      //   setScreenSizeType('450');
      // } else if (screenWidth >= 360) {
      //   setScreenSizeType('450');
      // } else {
      //   setScreenSizeType('450');
      // }
      
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial screen size on component mount

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const options = {
    // xaxis: {
    //   categories: [t('Activities')],
    // },
    xaxis: {
      categories: [t('Activities')],
      labels: {
        show: showAxisLabels, // Conditional rendering of x-axis labels
      },
    },
    yaxis: {
      labels: {
        show: showAxisLabels, // Conditional rendering of y-axis labels
      },
    },
    colors: [
      '#477BFF',
      '#47D1CB',
      '#6F47FF',
      '#FFCB47',
      '#FF477B',
      '#59DBFF',
      '#CB47FF',
      '#FF884D',
      '#00897B',
      '#9288F8',
    ],
    bar: {
      columnWidth: '45%',
      distributed: true,
    },
    stroke: {
      colors: ['transparent'],
      width: 5,
    },
    // legend: {
    //   position: 'bottom',
    // },
    legend: {
      position: 'bottom',
      show: showLegends, // Conditional rendering of legends
    },
  };

  // Function to calculate XP points for a category
  const calculateXPLead = (activityName, scope, duration, serviceHours) => {
    // Parse the values to integers
    activityName = parseInt(activityName);
    scope = parseFloat(scope);
    duration = parseInt(duration);
    serviceHours = parseFloat(serviceHours);

    // console.log("DATA Detailed: "+scope+parseFloat(scope)+duration+parseInt(duration)+serviceHours+parseFloat(serviceHours))

    return activityName * scope * (duration * 0.1) * serviceHours;
  };


  const calculateXPSociety = (activityName, role, duration, serviceHours) => {
    // Parse the values to integers
    activityName = parseFloat(activityName);
    role = parseFloat(role);
    duration = parseFloat(duration);
    serviceHours = parseFloat(serviceHours);
    return activityName * role * (duration * 0.1) * serviceHours;
  };


  const calculateXPSocial = (activityName, role, duration, serviceHours) => {
    // Parse the values to integers
    activityName = parseFloat(activityName);
    role = parseFloat(role);
    duration = parseFloat(duration);
    serviceHours = parseFloat(serviceHours);
    return activityName * role * (duration * 0.1) * serviceHours;
  };


  const calculateXPAwards = (activityName, rank, scope, serviceHours) => {
    // Parse the values to integers
    activityName = parseFloat(activityName);
    rank = parseFloat(rank);
    scope = parseFloat(scope);
    serviceHours = parseFloat(serviceHours);
    return (activityName * rank * scope * serviceHours)/5;
  };


  const calculateXPSports = (activityName, role, scope, duration, serviceHours) => {
    // Parse the values to integers
    activityName = parseFloat(activityName);
    role = parseFloat(role);
    scope = parseFloat(scope);
    duration = parseFloat(duration);
    serviceHours = parseFloat(serviceHours);
    return activityName * role * scope * (duration * 0.1) * serviceHours;
  };

  const calculateXPIntern = (activityName, role, type, duration) => {
    // Parse the values to integers
    activityName = parseFloat(activityName);
    role = parseFloat(role);
    type = parseFloat(type);
    duration = parseFloat(duration);
    return activityName * role * type * (duration * 0.1);
  };


  const calculateXPCert = (activityName) => {
    // Parse the values to integers
    activityName = parseFloat(activityName);
    return activityName ;
  };


  const calculateXPTalent = (serviceHours) => {
    // Parse the values to integers
    return  (8 * serviceHours)/4;
  };

  const calculateXPLang = (prof) => {
    // Parse the values to integers
    prof = parseFloat(prof);
    return (7 * prof) / 4;
  };


  const calculateXPEvent = (activityName, role, scope, duration, serviceHours) => {
    // Parse the values to integers
    activityName = parseFloat(activityName);
    role = parseFloat(role);
    scope = parseFloat(scope);
    duration = parseFloat(duration);
    serviceHours = parseFloat(serviceHours);
    return activityName * role * scope * (duration * 0.1) * serviceHours;
  };
  

  const [series, setSeries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          leadResponse,
          societyResponse,
          socialResponse,
          awardsResponse,
          sportsResponse,
          internResponse,
          certResponse,
          talentResponse,
          langResponse,
          eventResponse,
        ] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_lead/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_society/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_social/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_awards/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_sports/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_intern/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_cert/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_talent/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_lang/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_eventStd/`),
        ]);


        function getLeadershipActivityLabel(activity_name) {
          const labelMap = {
            "Student Council General Secretary": 8,
            "Deputy Head Student": 9,
            "House Captain": 8,
            "Class Prefect": 8,
            "Captain Sports Team": 8,
            "Class Monitor": 7,
            "Head Student": 10,
          };
          // console.log("Pushed Activity: "+labelMap[activity_name]);
          return labelMap[activity_name] || 1;
        }


        function getAwardsLabel(activity_name) {
          const awardsMapping = {
            "Full attendance": 10,
            "Academic excellence": 10,
            "Sports scholarship": 10,
            "Merit scholarship": 10,
            "Research grant": 10,
            "Poem Recitation": 6,
            "Spoken Word Contest": 6,
            "Play/Musical": 7,
            "Choir Performance": 7,
            "Aerobics Competition": 7,
            "Bake Sale": 6,
            "Artbeat": 7,
            "Debates Competition": 8,
            "School/college MUN": 8,
            "Math Competition": 8,
            "Trivia Competition": 7,
            "Science Competition": 8,
            "Science Fair": 8,
            "Dance Competition": 7,
            "Short Film Competition": 8,
            "Robotics Competition": 8,
            "Poster Making Competition": 7,
            "Gaming Competition": 7,
            "Fashion Design Competition": 7,
            "Cooking Competition": 7,
            "Modeling Competition": 7,
            "Talent Show": 7,
            "Handwriting Competition": 5,
            "Essay Competition": 8,
            "Acting Competition": 7,
            "Drawing Competition": 8,
            "Photography Competition": 8,
            "Painting Competition": 8,
            "Composition Competition": 8,
            "Lucky Draw": 6,
            "Spelling Competition": 7,
            "Certificate of Achievement": 10
          };
      
          return awardsMapping[activity_name] || 1;
        }


        function getCertificationLabel(cert_name) {
          const certificationMapping = {
            "First aid certification": 10,
            "Driving license": 7,
            "Google certification": 8,
            "Coursera course": 8,
            "edX course": 8,
            "Udemy course": 8,
            "Masterclass course": 8,
            "Duolingo course": 8,
            "Khan Academy course": 8,
            "Codecademy course": 8,
            "Domestika course": 8,
            "Udacity course": 8
          };
      
          return certificationMapping[cert_name] || 1;
        }

        function getEventManagementLabels2(activity_name) {
          const labelMap = {
            "Executive Council": 9,
            "Logistics Team": 8,
            "Registrations Team": 8,
            "Socials Team": 8,
            "Publications Team": 8,
            "Finance Team": 8,
            "Promotions Team": 8,
            "Communications Team": 8,
            "IT Team": 8,
            "Security Team": 8,
            "Operations Team": 8
          };
          return labelMap[activity_name] || 1;
        }
      

        function getInternIndustry(activity_name) {
          const labelMap = {
            "Research": 8,
            "Engineering": 8,
            "Marketing/Advertising": 8,
            "Engineering/Technical": 8,
            "Finance/Accounting": 8,
            "Media/Entertainment": 8,
            "Nonprofit/NGO": 8,
            "Medical": 8,
            "Legal": 8,
            "Graphic Design/Visual Arts": 8,
            "Software Development/IT": 8,
            "Startup/Entrepreneurship": 8
          };
      
          return labelMap[activity_name] || 1;
        }
      

        function getSocialActivityLabel(activity_name) {
          const labelMap = {
            "Food drive": 8,
            "Clothes drive": 8,
            "Charity collection": 8,
            "Blood donation drive": 9,
            "Hospital volunteer": 9,
            "Trash clean-up": 8,
            "Charity bake sale": 8
          };
          return labelMap[activity_name] || 1;
        }


        function getSocietyNameLabel(activity_name) {
          const labelMap = {
            "STEM/STEAM Society": 8,
            "Music Society": 8,
            "Art Society": 8,
            "Debates Society": 8,
            "MUN Society": 8,
            "Drama/Theater Society": 8,
            "Math Society": 8,
            "Astronomy Society": 7,
            "Literature Society": 8,
            "Film Society": 7,
            "Environment Society": 8,
            "Community Service Society": 8,
            "Media & Publications Society": 8,
            "Sports Society": 8,
            "Choir Society": 7,
            "Cooking Society": 7,
            "Cardio Society": 7,
            "Yoga Society": 7,
            "Book Club": 7,
            "Cooking Club": 7,
            "School Magazine": 8,
            "School Social Media": 7
          };
          return labelMap[activity_name] || 1;
        }




        function getSportsLabel(activity_name) {
          const sportsMapping = {
            "Football": 7,
            "Basketball": 7,
            "Cricket": 7,
            "Hockey": 7,
            "Badminton": 7,
            "Tennis": 7,
            "Gymnastics": 7,
            "Throwball": 7,
            "Futsal": 7,
            "Archery": 7,
            "Cycling": 7,
            "Sprint": 7,
            "Relay race": 7,
            "Baseball": 7,
            "Fencing": 7,
            "Golf": 7,
            "Handball": 7,
            "Netball": 7,
            "Rowing": 7,
            "Skateboarding": 7,
            "Road bicycle racing": 7,
            "Ballet": 7,
            "Kabaddi": 7,
            "Kickboxing": 7,
            "Boxing": 7,
            "Strongman": 7,
            "Lacrosse": 7,
            "Polo": 7,
            "Water Polo": 7,
            "Long Jump": 7,
            "High Jump": 7,
            "Shot put": 7,
            "Pole Vault": 7,
            "Pickleball": 7,
            "Chess": 8,
            "Track & field": 7,
            "Curling": 7,
            "Endurance running": 7,
            "Hurdle races": 7,
            "Ice skating": 7,
            "Surfing": 7,
            "Skiing": 7,
            "Snowboarding": 7,
            "Dodgeball": 7,
            "Climbing": 7,
            "Snooker": 7,
            "Squash": 7,
            "Swimming": 7,
            "Table Tennis": 7,
            "Volleyball": 7,
            "Weightlifting": 7,
            "Rugby": 7
          };
      
          return sportsMapping[activity_name] || 1;
        }
      

        // Filter data to include only entries where posted_by is equal to userID
        const filterByUserID = (data, userID) =>
          data.filter((entry) => parseInt(entry.posted_by) === userID);

        const userID = parseInt(profileID);

        const leadData = filterByUserID(leadResponse.data, userID);
        const societyData = filterByUserID(societyResponse.data, userID);
        const socialData = filterByUserID(socialResponse.data, userID);
        const awardsData = filterByUserID(awardsResponse.data, userID);
        const sportsData = filterByUserID(sportsResponse.data, userID);
        const internData = filterByUserID(internResponse.data, userID);
        const certData = filterByUserID(certResponse.data, userID);
        const talentData = filterByUserID(talentResponse.data, userID);
        const langData = filterByUserID(langResponse.data, userID);
        const eventData = filterByUserID(eventResponse.data, userID);


        const calculateXPForLead = (data) => {
          let categoryXP = 0;
          data.forEach((activity) => {
            categoryXP += calculateXPLead(getLeadershipActivityLabel(activity.activity_name), activity.scope, activity.duration, activity.service_hours);
            // console.log("Activity: "+activity.activity_name+'/Scope:'+activity.scope+'/Duration:'+activity.duration+'/Service:'+ activity.service_hours);
          });
          // console.log("DATA LEAD: "+categoryXP);
          return Math.floor(categoryXP);
          
        };
        

        const calculateXPForSociety = (data) => {
          let categoryXP = 0;
          data.forEach((activity) => {
            categoryXP += calculateXPSociety(getSocietyNameLabel(activity.activity_name), activity.role, activity.duration, activity.service_hours);
          });
          return Math.floor(categoryXP);
        };

        const calculateXPForSocial = (data) => {
          let categoryXP = 0;
          data.forEach((activity) => {
            categoryXP += calculateXPSocial(getSocialActivityLabel(activity.activity_name), activity.role, activity.duration, activity.service_hours);
          });
          return Math.floor(categoryXP);
        };

        const calculateXPForAward = (data) => {
          let categoryXP = 0;
          data.forEach((activity) => {
            categoryXP += calculateXPAwards(getAwardsLabel(activity.activity_name), activity.rank, activity.scope, activity.service_hours);
          });
          return Math.floor(categoryXP);
        };


        const calculateXPForSports = (data) => {
          let categoryXP = 0;
          data.forEach((activity) => {
            categoryXP += calculateXPSports(getSportsLabel(activity.activity_name), activity.role, activity.scope, activity.duration, activity.service_hours);
          });
          return Math.floor(categoryXP);
        };


        const calculateXPForIntern = (data) => {
          let categoryXP = 0;
          data.forEach((activity) => {
            categoryXP += calculateXPIntern(getInternIndustry(activity.activity_name), activity.role, activity.type, activity.duration);
          });
          return Math.floor(categoryXP);
        };


        const calculateXPForCert = (data) => {
          let categoryXP = 0;
          data.forEach((activity) => {
            categoryXP += calculateXPCert(getCertificationLabel(activity.cert_name));
          });
          return Math.floor(categoryXP);
        };

        const calculateXPForTalent = (data) => {
          let categoryXP = 0;
          data.forEach((activity) => {
            categoryXP += calculateXPTalent(activity.service_hours);
          });
          return Math.floor(categoryXP);
        };

        const calculateXPForLang = (data) => {
          let categoryXP = 0;
          data.forEach((activity) => {
            categoryXP += calculateXPLang(activity.prof*7);
          });
          return Math.floor(categoryXP);
        };

        const calculateXPForEvent = (data) => {
          let categoryXP = 0;
          data.forEach((activity) => {
            categoryXP += calculateXPEvent(getEventManagementLabels2(activity.activity_name), activity.role, activity.scope, activity.duration, activity.service_hours);
          });
          return Math.floor(categoryXP);
        };

  
        // Calculate XP for each category
        const leadXP = calculateXPForLead(leadData);
        const societyXP = calculateXPForSociety(societyData);
        const socialXP = calculateXPForSocial(socialData);

        const awardXP = calculateXPForAward(awardsData);
        const sportsXP = calculateXPForSports(sportsData);
        const internXP = calculateXPForIntern(internData);

        const certXP = calculateXPForCert(certData);
        const talentXP = calculateXPForTalent(talentData);
        const langXP = calculateXPForLang(langData);
        const eventXP = calculateXPForEvent(eventData);

        setleadpt(leadXP);
        setsocialpt(socialXP);
        setsocietypt(societyXP);
        setsportspt(sportsXP);
        setinternpt(internXP);
        setawardpt(awardXP);
        setcertpt(certXP);
        settalentpt(talentXP);
        setlangpt(langXP);
        seteventpt(eventXP);
        

        // console.log("Lead, Soci, Socl, Awrd, Sport, Intr, Cert, Tlnt, Lng, Evnt: "+leadXP,societyXP,socialXP,awardXP,sportsXP, internXP,certXP,talentXP,langXP,langXP,eventXP)

        // Update the series array with the XP points for each category
        setSeries([
          {
            name: t('Leadership'),
            data: [leadXP],
          },
          {
            name: t('Clubs'),
            data: [societyXP],
          },
          {
            name: t('Sports'),
            data: [sportsXP],
          },
          {
            name: t('Social Work'),
            data: [socialXP],
          },
          {
            name: t('Awards'),
            data: [awardXP],
          },
          {
            name: t('Internship'),
            data: [internXP],
          },
          {
            name: t('Certificates'),
            data: [certXP],
          },
          {
            name: t('Talents'),
            data: [talentXP],
          },
          {
            name: t('Languages'),
            data: [langXP],
          },
          {
            name: t('Events'),
            data: [eventXP],
          },
          // Include other categories here...
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [profileID]);

  const axisButtonTooltip = showAxisLabels ? 'Hide X-Y Axis lines' : 'Show Axis X-Y Axis lines';
  const legendsButtonTooltip = showLegends ? 'Hide Chart Legends' : 'Show Chart Legends';

  const axisButton = showAxisLabels ? 'Hide Axis' : 'Show Axis';
  const legendsButton = showLegends ? t('Hide Legends') : t('Show Legends');

  return (

    <div className='mx-auto'>
    <Chart
    options={options}
    series={series}
    type="bar"
    width={screenSizeType}
    height={430}
    style={{ margin: 'auto' }}
    className="chartMain"
  />

{/* <button onClick={() => setShowLegends(!showLegends)}>
        Toggle Legends
      </button>
      <button onClick={() => setShowAxisLabels(!showAxisLabels)}>
        Toggle Axis Labels
      </button> */}

<div className='flex justify-center gap-4 items-center mb-4 mt-2' onClick={() => setShowAxisLabels(!showAxisLabels)}>
      {/* <div className="tooltip " data-tip={axisButtonTooltip}>
  <button className="btn btn-sm"> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
</svg>
 {axisButton}</button>
</div> */}
    

    <div className="tooltip " data-tip={legendsButtonTooltip}  onClick={() => setShowLegends(!showLegends)}>
  <button className="btn btn-sm"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6z" />
</svg>

 {legendsButton}</button>
</div>
    </div>


    </div>

  );
};

export default Barchart;
