import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import randomColor from "randomcolor";
import AddEventBar from "../components/AddEventBar";
import AddCampaignBar from "../components/AddCampaignBar";

function CampaignsList({campaigns}) {
  const [profile, setProfile] = useState();
  const { user } = useContext(AuthContext);
  const { logoutUser } = useContext(AuthContext);

  const userID = user["user_id"];

  let url = "";
  if (user["role"] === "Student") {
    url = "profile";
  } else if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  } else if (user["role"] === "Staff") {
    url = "staff";
  }

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");

  const [profileData, setProfileData] = useState({
    name: "",
    dob: "",
    gender: "",
    contact: "",
    bio: "",
    active_status: "",
    employeeID: "",
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((response) => {
        const data = response.data;
        setProfileData(data);
        setProfile(data);
        setProfilePhotoUrl(data.profile_photo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);

  const [events, setEvents] = useState(campaigns);
  const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_API_KEY}/api/get_events/`)
//       .then((res) => {
//         const data = res.data;
//         let filteredEvents;

//         if (user["role"] === "Network Admin") {
//           filteredEvents = data.filter(
//             (event) => event.network === profile?.network_id
//           );
//         } else if (user["role"] === "School Admin") {
//           filteredEvents = data.filter(
//             (event) => event.school === profile?.school_id
//           );
//         } else {
//           filteredEvents = data; // No filtering for other roles
//         }

//         setEvents(filteredEvents);
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Error fetching events:", error);
//         setLoading(false);
//       });
//   }, [user, profile]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  }

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
  }

  const [filter, setFilter] = useState("All Events");
  const handleFilterChange = (filterValue) => {
    setFilter(filterValue);
  };

  const filteredEvents = events.filter((event) => {
    if (filter === "All Events") {
      return true;
    } else if (filter === "Internal Event") {
      return !event.external_event;
    } else if (filter === "External Event") {
      return event.external_event;
    }
  });

  const leaders = [
    { Name: "Sana Faisal", value: "Sana Faisal" },
    { Name: "Heba Qaiser", value: "Heba Qaiser" },
    { Name: "Wania Khalid", value: "Wania Khalid" },
    { Name: "Hassaan Ahmed", value: "Hassaan Ahmed" },
    { Name: "Adam Noor", value: "Adam Noor" },
    { Name: "Mohammed Kashan", value: "Mohammed Kashan" },
    { Name: "Ali Affan", value: "Ali Affan" },
    { Name: "Hamdiya Ahmed", value: "Hamdiya Ahmed" },
    { Name: "Abdur Rehman", value: "Abdur Rehman" },
  ];

  const facultyAdvisors = [
    { firstname: "Ms", lastname: "Zakir" },
    { firstname: "Ms", lastname: "Lina" },
    { firstname: "Ms", lastname: "Farah" },
  ];

  // Helper function to get a random element from an array
  const getRandomElement = (array) =>
    array.length > 0 ? array[Math.floor(Math.random() * array.length)] : null;

  // Helper function to generate a random number between min and max (inclusive)
  const getRandomNumber = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  return (
    <>
      {loading ? (
        <div className="flex items-center gap-4 justify-center h-screen">
          <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
          <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
          <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
        </div>
      ) : (
        <>
          <div className="flex items-center mb-8 justify-between">
            <div className="flex flex-wrap gap-4">
              <button
                className={`flex flex-row items-center gap-2 px-4 py-2 rounded-full ${
                  filter === "All Events"
                    ? "bg-primary text-white"
                    : "bg-gray-200 text-gray-800"
                }`}
                onClick={() => handleFilterChange("All Events")}
              >
                All Campaigns
              </button>
            </div>
            {user["role"] !== "Staff" ? (
             <AddCampaignBar/>
            ) : null}
          </div>

          <div className="grid grid-cols-3 gap-6">
            {filteredEvents.map((club, index) => {
              const borderColor = randomColor(); // Generate a random color for each card
              const randomLeader = getRandomElement(leaders)?.Name || "N/A";
              const randomAdvisor = getRandomElement(facultyAdvisors) || {
                firstname: "N/A",
                lastname: "",
              };
              const randomEnrolled = getRandomNumber(5, 30);

              return (
                <div key={index}>
                  <div
                    className="flex flex-col bg-white border shadow-sm rounded-xl"
                    style={{ borderBottom: `4px solid ${borderColor}` }}
                  >
                    <div className="">
                      <img
                        className="w-full h-[10rem] rounded-t-xl"
                        style={{ objectFit: "cover" }}
                        src={club.banner}
                        alt="Image Description"
                      />
                      <div className="p-4 md:p-5">
                        <div className="flex flex-row justify-between">
                          <h3 className="text-lg font-bold m-0 text-gray-800 darki:text-white col-span-3">
                            {club.name}
                          </h3>

                          <span className="col-span-2 badge badge-ghost text-end">
                            {randomEnrolled} attendees
                          </span>
                        </div>

                        <div className="flex flex-row justify-between mt-4">
                          <div className="flex-shrink-0 group block">
                            <div className="flex items-center">
                              <div className="">
                                <p className="text-sm font-medium text-gray-400 mb-0">
                                  Faculty Advisor
                                </p>
                                <h3 className="font-semibold text-base text-gray-800 mb-0">
                                  {randomAdvisor.firstname}{" "}
                                  {randomAdvisor.lastname}
                                </h3>
                              </div>
                            </div>
                          </div>

                          <div className="flex-shrink-0 group block">
                            <div className="flex items-center">
                              <div className="">
                                <p className="text-sm font-medium text-gray-400 mb-0">
                                  Student Leader
                                </p>
                                <h3 className="font-semibold text-base text-gray-800 mb-0">
                                  {randomLeader}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Link
                          className="mt-4 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none darki:text-blue-500 darki:hover:text-blue-400 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                          to={`/event_details/${club.id}`}
                        >
                          View details
                          <svg
                            className="flex-shrink-0 size-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="m9 18 6-6-6-6" />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

export default CampaignsList;
