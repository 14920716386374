import React, { useState, useEffect, useContext } from "react";
import AdminLayout from "./AdminLayout";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import AuthContext from "../context/AuthContext";
import DCSchoolEvents from "./DCSchoolEvents";
import AllSchoolEvents from "./AllSchoolEvents";
function SchoolEvents() {
  let { user } = useContext(AuthContext);
  let profileID = 0;

  if(user[`role`]===`Parent`){
    profileID = 12;
  } else{
    profileID = user["user_id"];
  }

  return (

    <>
    {/* <AdminLayout> */}
      <AllSchoolEvents/>
    {/* </AdminLayout> */}

    </>

  );
}

export default SchoolEvents;
